import { useEffect } from 'react';

import { useRegionContext } from '../../../context/ProviderPage/Regiao';
import { FilterInput } from '../../Inputs/FilterInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterRegions = () => {
  const {
    openSideModalFilterRegion,
    openSideModalFilterRegionAction,
    cleanAllInputsAction,
    filterRegionAction,
    DFDESCREGIONAL,
    DFIDREGIONAL,
    setDFDESCREGIONAL,
    setDFIDREGIONAL,
  } = useRegionContext();

  useEffect(() => {
    if (Number(DFIDREGIONAL) < 0) {
      setDFIDREGIONAL('0');
    }
  }, [DFIDREGIONAL]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterRegion}
      onchange={openSideModalFilterRegionAction}
      title="Filtrar Região"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterRegionAction}
    >
      <FilterInput
        onChange={setDFIDREGIONAL}
        value={DFIDREGIONAL}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFDESCREGIONAL}
        value={DFDESCREGIONAL}
        title="Descrição"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
