import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  label {
    color: ${({ theme }) => theme.COLORS.GREY};
    font-size: 1rem;
    font-weight: 700;
  }

  .container-filter-input {
    display: flex;
    flex-direction: row;
    height: 60px;
    margin-top: 0.2rem;
    img {
      background-color: ${({ theme }) => theme.COLORS.GREY_50};
      cursor: pointer;
      width: 60px;
      height: 60px;
      padding: 1.4rem;
      border-radius: 0 0.6rem 0.6rem 0;
      transition: 0.3s all;
      &:hover {
        background-color: ${({ theme }) => darken(0.06, theme.COLORS.GREY_50)};
      }
    }

    input {
      width: 100%;
      height: 60px;
      padding: 0 1.6rem;
      border-radius: 0.6rem 0 0 0.6rem;
      border: 0;
      outline: 0;
      font-size: 1rem;
      background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
    }
  }
`;
