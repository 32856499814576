import Filter from '../../../assets/Filter.svg';
import { ButtonTextLight } from '../../../styles/global.styles';
import { ContainerSmallButtonIcon } from './styles';

interface IFilterButton {
  width: number;
  icon?: string;
  action?: () => void;
}

const FilterButton = ({ width, action }: IFilterButton) => {
  return (
    <ContainerSmallButtonIcon width={width} onClick={action}>
      <img src={Filter} />
      <ButtonTextLight>Filtrar</ButtonTextLight>
    </ContainerSmallButtonIcon>
  );
};

export { FilterButton };
