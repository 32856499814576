import ArrowDown from '../../../assets/ArrowRight.svg';
import { BackButtonContainer } from './styles';

interface IBackButton {
  action: () => void;
  title: string;
}

export const BackButton = ({ action, title }: IBackButton) => {
  return (
    <BackButtonContainer>
      <img onClick={action} src={ArrowDown} className="arrow-down" />
      <span>{title}</span>
    </BackButtonContainer>
  );
};
