import { useEffect } from 'react';

import { useVehicleContext } from '../../../context/ProviderPage/Veiculo';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterVehicles = () => {
  const {
    openSideModalFilterVehicle,
    openSideModalFilterVehicleAction,
    cleanAllInputsAction,
    filterVehicleAction,
    DFIDVEICULO,
    setDFIDVEICULO,
    DFDESCVEICULO,
    setDFDESCVEICULO,
    DFATIVO,
    setDFATIVO,
    DFPLACAVEICULO,
    setDFPLACAVEICULO,
  } = useVehicleContext();

  useEffect(() => {
    if (Number(DFIDVEICULO) < 0) {
      setDFIDVEICULO('0');
    }
  }, [DFIDVEICULO]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterVehicle}
      onchange={openSideModalFilterVehicleAction}
      title="Filtrar Veículo"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterVehicleAction}
    >
      <FilterSelectInput onChange={setDFATIVO} value={DFATIVO} title="Ativo" />
      <FilterInput
        onChange={setDFIDVEICULO}
        value={DFIDVEICULO}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFPLACAVEICULO}
        value={DFPLACAVEICULO}
        title="Placa"
        type="text"
      />
      <FilterInput
        onChange={setDFDESCVEICULO}
        value={DFDESCVEICULO}
        title="Descrição"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
