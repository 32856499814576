/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import Close from '../../../assets/Close.svg';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { Option } from '../../../types/Option';
import { returnIcon } from '../../../utils/returnIcon';
import { ContainerOverlayModal, ContentModal, ContentInfo } from './styles';

export interface IModal extends Option {
  isOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  titleModal: string;
  medium?: boolean;
}

interface IModalInfoTemplete extends IModal {
  children: ReactNode;
}

export const ModalInfoTemplete = ({
  children,
  closeModal,
  isOpen,
  titleModal,
  option,
  medium,
}: IModalInfoTemplete) => {
  const { sideMenuWidth } = useSideMenuContext();

  const verifyClose = (e: any) => {
    if (e.target.id === 'container-overlay-modal') {
      closeModal(false);
    }
  };

  const handleCloseModal = () => {
    if (isOpen) {
      closeModal(false);
    }
  };

  const RenderHeader = () => {
    return (
      <header>
        <div>
          <img src={returnIcon({ option })} />
          <span>{titleModal}</span>
        </div>
        <img
          className="header-filter-close-button"
          src={Close}
          onClick={handleCloseModal}
        />
      </header>
    );
  };
  const [showContentModal, setShowContentModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowContentModal(true);
      }, 0);
    } else {
      setTimeout(() => {
        setShowContentModal(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ContainerOverlayModal
      isOpen={isOpen}
      onClick={verifyClose}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal medium={medium} isOpen={isOpen} periodic={showContentModal}>
        <div className="not-showed-modal">
          <RenderHeader />
          <ContentInfo>{children}</ContentInfo>
        </div>
      </ContentModal>
    </ContainerOverlayModal>
  );
};
