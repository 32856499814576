import { ButtonTextLight } from '../../../styles/global.styles';
import { ContainerSmallButtonIcon } from './styles';

interface ICustomButtonWithIcon {
  width: number;
  icon?: string;
  title: string;
  action?: () => void;
}

const CustomButtonWithIcon = ({
  width,
  action,
  icon,
  title,
}: ICustomButtonWithIcon) => {
  return (
    <ContainerSmallButtonIcon width={width} onClick={action}>
      {icon && <img src={icon} />}
      <ButtonTextLight>{title}</ButtonTextLight>
    </ContainerSmallButtonIcon>
  );
};

export { CustomButtonWithIcon };
