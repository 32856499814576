import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { OpenCollect } from '../../../types/OpenCollect';
import { ReleaseCollectTankProducer } from '../../../types/ReleaseCollect';
import { getCurrentDate } from '../../../utils/date/getCurrentDate';
import { getCurrentDateTime } from '../../../utils/date/getCurrentDateTime';

interface IHandleDischargeCollect {
  collect: OpenCollect | null;
  setLoadingAndNotify: Dispatch<SetStateAction<boolean>>;
  path: string;
  loadCollectWithInterval: () => void;
  closeModal: () => void;
}

interface ILoadReleasedCollectTanks {
  setTank: Dispatch<SetStateAction<ReleaseCollectTankProducer[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  collectionOrder: number;
  path: string;
}

const handleDischargeCollect = ({
  closeModal,
  collect,
  loadCollectWithInterval,
  path,
  setLoadingAndNotify,
}: IHandleDischargeCollect) => {
  let DFLATITUDE: number;
  let DFLONGITUDE: number;
  setLoadingAndNotify(true);

  const error = () => {
    setLoadingAndNotify(false);
    return toast.error(
      'Houve um erro durante a operação, tente novamente dentro de instantes!',
    );
  };

  const headers = {
    headers: {
      path,
    },
  };

  const { DFIDCOLETA } = collect! || 0;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        DFLATITUDE = latitude;
        DFLONGITUDE = longitude;
      },
      () => {
        return toast.error(
          'Atenção, você precisa permitir o GPS para que a descarga possa ser registrada!',
        );
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
    );
    setTimeout(() => {
      setLoadingAndNotify(false);
    }, 2000);
    META_COLLECT_API.patch(
      '/coleta/descarregar/painel',
      {
        DFIDCOLETA,
        DFSTATUS: 'D',
      },
      headers,
    )
      .then(() => {
        META_COLLECT_API.post(
          '/registro/painel/criar',
          {
            DFIDCOLETA,
            DFDATAREGISTRO: getCurrentDate(),
            DFHORAREGISTRO: getCurrentDateTime(),
            DFTIPOREGISTRO: 'D',
            DFOBSERVACAO: 'Descarga da coleta',
            DFLONGITUDE,
            DFLATITUDE,
          },
          headers,
        )
          .then(() => {
            loadCollectWithInterval();
            closeModal();
            setLoadingAndNotify(false);
            return toast.success('Coleta descarregada com sucesso!');
          })
          .catch(() => {
            error();
          });
      })
      .catch(e => {
        error();
      });
  } else {
    error();
  }
};

const loadReleasedCollectTanks = ({
  setTank,
  setLoading,
  collectionOrder,
  path,
}: ILoadReleasedCollectTanks) => {
  const headers = {
    headers: {
      path,
    },
  };
  META_COLLECT_API.get(
    `/itemcoleta/laboratorio?DFIDCOLETA=${collectionOrder}`,
    headers,
  )
    .then(res => {
      setTank(res.data.itens);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export { handleDischargeCollect, loadReleasedCollectTanks };
