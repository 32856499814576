import { ListConfiguration } from '../../../types/Configuration';
import { formatDate } from '../../../utils/date/formatDate';
import { returnCheckType } from '../../../utils/returnCheckType';
import { AnimateTr, ContainerListPage } from '../styles';

export const ListConfigurations = ({ data }: ListConfiguration) => {
  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Carreteiro</th>
            <th className="list-th">Imei</th>
            <th className="list-th">Data Sincronização</th>
            <th className="list-th">Régua atrás</th>
            <th className="lits-th">Qualidade</th>
            <th className="lits-th">Distribuição</th>
            <th className="lits-th">Termômetro</th>
            <th className="last-table-th">Liberar</th>
            {/* <th className="last-table-th">Opções</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((configuration, index) => {
            const {
              // DFIDCARRETEIRO,
              // DFHORASINCRONIZACAO,
              DFDATASINCRONIZACAO,
              DFIMEI,
              DFDISTRIBUIR,
              DFQUALIDADE,
              DFREGUAATRAS,
              DFNOMECARRETEIRO,
              DFLIBERARCOLETA,
              DFTERMOMENTRO,
            } = configuration;

            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">{DFIMEI || 'Não informado'}</td>
                <td className="list-td-medium">
                  {formatDate(DFDATASINCRONIZACAO)}
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFREGUAATRAS)}
                  />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFQUALIDADE)}
                  />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFDISTRIBUIR)}
                  />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFTERMOMENTRO)}
                  />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFLIBERARCOLETA)}
                  />
                </td>
                {/* <td className="list-td-small">
                  <div>
                    <ListButton
                      {...{
                        action: () => {
                          setSelectedConfiguration({
                            isOpen: true,
                            configuration,
                          });
                        },
                        icon: 'edit',
                      }}
                    />
                  </div>
                </td> */}
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
