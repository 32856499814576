import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppVersion } from '../../../types/AppVersion';
import { Panel } from '../components/Panel';
import { Carreteiro } from '../pages/AppPages/Carreteiro';
import { Coleta } from '../pages/AppPages/Coleta';
import { ColetaAbertaSelecionada } from '../pages/AppPages/Coleta/ColetaAbertaSelecionada';
import { Configuracoes } from '../pages/AppPages/Configuracoes';
import { PanelConfigurations } from '../pages/AppPages/ConfiguracoesDoPainel';
import { Descarga } from '../pages/AppPages/Descarga';
import { ColetaDescargaSelecionada } from '../pages/AppPages/Descarga/ColetaDescargaSelecionada';
import { DescargaSelecionada } from '../pages/AppPages/DescargaSelecionada';
import { Devices } from '../pages/AppPages/Devices';
import { Encerradas } from '../pages/AppPages/Encerradas';
import { ColetaEncerradaSelecionada } from '../pages/AppPages/Encerradas/ColetaEncerradaSelecionada';
import { Excluida } from '../pages/AppPages/Excluida';
import { Liberar } from '../pages/AppPages/Liberar';
import SelectedReleasedCollect from '../pages/AppPages/Liberar/SelectedReleasedCollect';
import { Linha } from '../pages/AppPages/Linha';
import { Programacao } from '../pages/AppPages/Programacao';
import { Propriedade } from '../pages/AppPages/Propriedade';
import { Redirect } from '../pages/AppPages/Redirect';
import { Regiao } from '../pages/AppPages/Regiao';
import { Silo } from '../pages/AppPages/Silo';
import { Tanque } from '../pages/AppPages/Tanque';
import { TanquesVinculados } from '../pages/AppPages/TanquesVinculados';
import { Unidade } from '../pages/AppPages/Unidade';
import { Veiculo } from '../pages/AppPages/Veiculo';
import { Vinculada } from '../pages/AppPages/Vinculada';
import { SelectedBondCollect } from '../pages/AppPages/Vinculada/SelectedBondCollect';
import { BondCollect } from '../pages/AppPages/Vincular';
import { SelectedTankToRelease } from '../pages/AppPages/Vincular/SelectedTankToRelease';

export const AppRoutes: FC<AppVersion> = ({ version }) => {
  return (
    <Panel>
      <Routes>
        <Route path="*" element={<Redirect {...{ version }} />} />
        <Route path={`/unidade${version}`} element={<Unidade />} />
        <Route path={`/silo${version}`} element={<Silo />} />
        <Route path={`/regiao${version}`} element={<Regiao />} />
        <Route path={`/linha${version}`} element={<Linha />} />
        <Route path={`/tanque${version}`} element={<Tanque />} />
        <Route path={`/propriedade${version}`} element={<Propriedade />} />
        <Route path={`/carreteiro${version}`} element={<Carreteiro />} />
        <Route path={`/veiculo${version}`} element={<Veiculo />} />
        <Route path={`/programacao${version}`} element={<Programacao />} />
        <Route path={`/coleta${version}`} element={<Coleta />} />
        <Route
          path={`/coleta/:collectionOrder/acompanhar${version}`}
          element={<ColetaAbertaSelecionada />}
        />
        <Route
          path={`/tanques-vinculados${version}`}
          element={<TanquesVinculados />}
        />
        <Route path={`/liberar${version}`} element={<Liberar />} />
        <Route
          path={`/liberar/:collectionOrder${version}`}
          element={<SelectedReleasedCollect />}
        />
        <Route path={`/vincular${version}`} element={<BondCollect />} />
        <Route
          path={`/vincular/:tankid/armazenamento${version}`}
          element={<SelectedTankToRelease />}
        />
        <Route path={`/vinculada${version}`} element={<Vinculada />} />
        <Route
          path={`/vinculada/:collectionOrder${version}`}
          element={<SelectedBondCollect />}
        />
        <Route
          path={`/plataforma/:collectionOrder/acompanhar${version}`}
          element={<ColetaDescargaSelecionada />}
        />
        <Route
          path={`/encerrada/:collectionOrder/acompanhar${version}`}
          element={<ColetaEncerradaSelecionada />}
        />
        <Route path={`/configuracoes${version}`} element={<Configuracoes />} />
        <Route path={`/plataforma${version}`} element={<Descarga />} />
        <Route
          path={`/plataforma/:DFIDCOLETA${version}`}
          element={<DescargaSelecionada />}
        />
        <Route path={`/encerrada${version}`} element={<Encerradas />} />
        <Route path={`/dispositivos${version}`} element={<Devices />} />
        <Route
          path={`/configuracoes-do-painel${version}`}
          element={<PanelConfigurations />}
        />
        <Route path={`/excluida${version}`} element={<Excluida />} />
      </Routes>
    </Panel>
  );
};
