import { darken } from 'polished';
import styled from 'styled-components';

export const SelectNewUnityToWorkContainer = styled.div`
  transition: 0.3s all;
  /* min-width: 24s0px; */
  max-width: 250px;
  /* border: 1px solid grey; */
  background-color: ${({ theme }) => theme.COLORS.GREEN_30};
  border-radius: 0.2rem;
  border-radius: 0.4rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.COLORS.GREEN_30)};
  }
  span {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  svg {
    transition: 0.3s all;
    margin-right: 0.4rem;
    width: 20px;
    height: 20px;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;
