import { darken } from 'polished';
import styled from 'styled-components';

interface IContainer {
  openOption: boolean;
  timedOpenOption: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  margin-bottom: 1rem;

  label {
    color: ${({ theme }) => theme.COLORS.GREY};
    font-size: 1rem;
    font-weight: 700;
  }

  .content-list-options {
    display: flex;
    width: 100%;
    transition: 0.3s all;
  }

  .list-options {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    background-color: ${({ theme }) => darken(0.1, theme.COLORS.GREY_50)};
    min-height: ${({ openOption }) => (openOption ? '100px' : '0px')};
    border-radius: 0 0 0.6rem 0.6rem;
    transition: 0.3s all;
    padding-top: ${({ openOption }) => (openOption ? '5px' : '0px')};

    span {
      color: ${({ theme }) => theme.COLORS.WHITE};
      cursor: pointer;
      transition: 0.3s all;
      padding: 1rem;
      border-radius: 0.6rem;
      display: ${({ timedOpenOption }) => (timedOpenOption ? 'flex' : 'none')};
      &:hover {
        background-color: ${({ theme }) => darken(0.5, theme.COLORS.WHITE)};
      }
    }
  }

  .container-filter-input {
    display: flex;
    flex-direction: row;
    height: 60px;
    position: relative;
    top: 5px;

    .button-open-options {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0.2rem;
      transition: 0.3s all;
      transform: ${({ openOption }) =>
        openOption ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    .button-clear {
      background-color: ${({ theme }) => theme.COLORS.GREY_50};
      cursor: pointer;
      width: 60px;
      height: 60px;
      padding: 1.4rem;
      border-radius: 0 0.6rem 0.6rem 0;
      transition: 0.3s all;
      &:hover {
        background-color: ${({ theme }) => darken(0.06, theme.COLORS.GREY_50)};
      }
    }

    .filter-select {
      width: 100%;
      border: 0 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 60px;
      padding: 0 1.6rem;
      border-radius: 0.6rem 0 0 0.6rem;
      outline: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
    }
  }
`;
