import { Link, useNavigate } from 'react-router-dom';

import { usePageContext } from '../../../context/usePage';
import { IListCollectData } from '../../../types/ListCollectData';
import { formatDate } from '../../../utils/date/formatDate';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

export const ListReleasedCollects = ({ data }: IListCollectData) => {
  const navigate = useNavigate();
  const { version } = usePageContext();

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ordem Coleta</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Total em litros</th>
            <th className="list-th">Veículo</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Data Programada</th>
            <th className="list-th">Data Saída</th>
            <th className="list-th">Data Coleta</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((collect, index) => {
            const {
              DFDATACOLETA,
              DFDATASAIDA,
              DFIDCOLETA,
              DFNOMECARRETEIRO,
              DFNOMELINHA,
              DFORDEMCOLETA,
              DFPLACAVEICULO,
              DFTOTALARMAZENADO,
              DFDATAPROGRAMADA,
            } = collect;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => navigate(`/liberar/${DFIDCOLETA}${version}`)}
              >
                <td className="list-td-small">
                  {DFORDEMCOLETA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFTOTALARMAZENADO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {formatDate(DFDATAPROGRAMADA)}
                </td>
                <td className="list-td-small">{formatDate(DFDATASAIDA)}</td>
                <td className="list-td-small">{formatDate(DFDATACOLETA)}</td>
                <td className="list-td-small">
                  <div>
                    <Link to={`/liberar/${DFIDCOLETA}${version}`}>
                      <ListButton
                        {...{
                          action: () => null,
                          icon: 'search',
                        }}
                      />
                    </Link>
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
