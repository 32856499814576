import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Region } from '../../../types/Region';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterRegion: boolean;
  openSideModalFilterRegionAction: () => void;

  DFIDREGIONAL: string;
  setDFIDREGIONAL: Dispatch<SetStateAction<string>>;
  DFDESCREGIONAL: string;
  setDFDESCREGIONAL: Dispatch<SetStateAction<string>>;

  filterRegionAction: () => void;
  cleanAllInputsAction: () => void;
  loadRegionAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedRegion: Region;
  setSelectedRegion: Dispatch<SetStateAction<Region>>;

  region: Region[];
  setRegion: Dispatch<SetStateAction<Region[]>>;

  loadingRegion: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const RegionContextProvider = ({ children }: IContextProps) => {
  const [DFIDREGIONAL, setDFIDREGIONAL] = useState<string>('');
  const [DFDESCREGIONAL, setDFDESCREGIONAL] = useState<string>('');

  const [loadingRegion, setIsLoadingRegion] = useState<boolean>(false);
  const [openSideModalFilterRegion, setOpenSideModalFilterRegion] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedRegion, setSelectedRegion] = useState<Region>({});
  const [region, setRegion] = useState<Region[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDREGIONAL = `DFIDREGIONAL=${DFIDREGIONAL}`;
  const DESCREGIONAL = `DFDESCREGIONAL=${DFDESCREGIONAL}`;

  const openSideModalFilterRegionAction = () => {
    setOpenSideModalFilterRegion(!openSideModalFilterRegion);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingRegion(true);
      META_COLLECT_API.get(
        `/regiao?${Limit}&${Offset}&${IDREGIONAL}&${DESCREGIONAL}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setRegion(response.data.unidades);
          setQuantity(response.data.quantidade);
          setIsLoadingRegion(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingRegion(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingRegion(true);
    META_COLLECT_API.get(`/regiao?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setRegion(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingRegion(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingRegion(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDREGIONAL('');
    setDFDESCREGIONAL('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterRegionAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterRegionAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterRegionAction();
    setIsLoadingRegion(true);
    META_COLLECT_API.get(
      `/regiao?${Limit}&offset=0&${IDREGIONAL}&${DESCREGIONAL}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setRegion(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingRegion(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingRegion(false);
        setStart(1);
      });
  };

  const loadRegionAction = () => {
    setIsLoadingRegion(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'regiao') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFDESCREGIONAL,
        DFIDREGIONAL,
        filterRegionAction,
        loadRegionAction,
        limit,
        loadingRegion,
        offset,
        openInfo,
        openSideModalFilterRegion,
        openSideModalFilterRegionAction,
        page,
        quantity,
        region,
        selectedRegion,
        setDFDESCREGIONAL,
        setDFIDREGIONAL,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setRegion,
        setSelectedRegion,
        setStart,
        start,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useRegionContext() {
  const context = useContext(Context);
  return context;
}

export { RegionContextProvider, useRegionContext };
