import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Silo } from '../../../types/Silo';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterSilo: boolean;
  openSideModalFilterSiloAction: () => void;

  DFIDSILO: string;
  setDFIDSILO: Dispatch<SetStateAction<string>>;
  DFDESCRICAOSILO: string;
  setDFDESCRICAOSILO: Dispatch<SetStateAction<string>>;
  DFCAPACIDADE: string;
  setDFCAPACIDADE: Dispatch<SetStateAction<string>>;
  DFCOLETASELETIVA: string;
  setDFCOLETASELETIVA: Dispatch<SetStateAction<string>>;
  DFIDUNIDADE: string;
  setDFIDUNIDADE: Dispatch<SetStateAction<string>>;

  filterSiloAction: () => void;
  cleanAllInputsAction: () => void;
  loadSiloAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedSilo: Silo;
  setSelectedSilo: Dispatch<SetStateAction<Silo>>;

  silo: Silo[];
  setSilo: Dispatch<SetStateAction<Silo[]>>;

  loadingSilo: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const SiloContextProvider = ({ children }: IContextProps) => {
  const [DFIDSILO, setDFIDSILO] = useState<string>('');
  const [DFDESCRICAOSILO, setDFDESCRICAOSILO] = useState<string>('');
  const [DFCAPACIDADE, setDFCAPACIDADE] = useState<string>('');
  const [DFCOLETASELETIVA, setDFCOLETASELETIVA] = useState<string>('');
  const [DFIDUNIDADE, setDFIDUNIDADE] = useState<string>('');

  const [loadingSilo, setIsLoadingSilo] = useState<boolean>(false);
  const [openSideModalFilterSilo, setOpenSideModalFilterSilo] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedSilo, setSelectedSilo] = useState<Silo>({});
  const [silo, setSilo] = useState<Silo[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDSILO = `DFIDSILO=${DFIDSILO}`;
  const DESCRICAOSILO = `DFDESCRICAOSILO=${DFDESCRICAOSILO}`;
  const CAPACIDADE = `DFCAPACIDADE=${DFCAPACIDADE}`;
  const COLETASELETIVA = `DFCOLETASELETIVA=${DFCOLETASELETIVA}`;
  const IDUNIDADE = `DFIDUNIDADE=${DFIDUNIDADE}`;

  const openSideModalFilterSiloAction = () => {
    setOpenSideModalFilterSilo(!openSideModalFilterSilo);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingSilo(true);
      META_COLLECT_API.get(
        `/silo?${Limit}&${Offset}&${IDSILO}&${DESCRICAOSILO}&${CAPACIDADE}&${COLETASELETIVA}&${IDUNIDADE}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setSilo(response.data.unidades);
          setQuantity(response.data.quantidade);
          setIsLoadingSilo(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingSilo(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingSilo(true);
    META_COLLECT_API.get(`/silo?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setSilo(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingSilo(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingSilo(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDSILO('');
    setDFDESCRICAOSILO('');
    setDFCAPACIDADE('');
    setDFCOLETASELETIVA('');
    setDFIDUNIDADE('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterSiloAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterSiloAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterSiloAction();
    setIsLoadingSilo(true);
    META_COLLECT_API.get(
      `/silo?${Limit}&offset=0&${IDSILO}&${DESCRICAOSILO}&${CAPACIDADE}&${COLETASELETIVA}&${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setSilo(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingSilo(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingSilo(false);
        setStart(1);
      });
  };

  const loadSiloAction = () => {
    setIsLoadingSilo(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'silo') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFCAPACIDADE,
        DFCOLETASELETIVA,
        DFDESCRICAOSILO,
        DFIDSILO,
        DFIDUNIDADE,
        filterSiloAction,
        limit,
        loadingSilo,
        loadSiloAction,
        offset,
        openInfo,
        openSideModalFilterSilo,
        openSideModalFilterSiloAction,
        page,
        quantity,
        selectedSilo,
        setDFCAPACIDADE,
        setDFCOLETASELETIVA,
        setDFDESCRICAOSILO,
        setDFIDSILO,
        setDFIDUNIDADE,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setSelectedSilo,
        setSilo,
        silo,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useSiloContext() {
  const context = useContext(Context);
  return context;
}

export { SiloContextProvider, useSiloContext };
