import { transparentize } from 'polished';
import styled from 'styled-components';

export const DischargeSiloItemContainer = styled.div`
  /* padding: 1rem; */
  border-radius: 0.2rem;
  margin-bottom: 0.4rem;
  align-items: center;
  background-color: ${({ theme }) => transparentize(0.8, theme.COLORS.GREY)};
  display: flex;
  flex-direction: row;
  transition: 0.3s all;
  @media (max-width: 800px) {
    min-width: 420px;
  }
  .silo-background {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    width: 70px;
    height: 100%;
    background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .silo-icon {
    width: 30px;
    transition: 0.3s all;
    @media (max-width: 600px) {
      width: 24px;
    }
  }
  .delete-button {
    cursor: pointer;
    margin-right: 1rem;
  }
  .silo-description {
    padding: 0.6rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    span {
      transition: 0.3s all;
      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
      @media (max-width: 420px) {
        font-size: 0.6rem;
      }
    }
  }
`;
