import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { META_COLLECT_API } from '../../../../api';
import { BondTankAndProperty } from '../../../../components/AnyCollectSelected/BondTankAndProperty';
import { CollectStatistic } from '../../../../components/AnyCollectSelected/CollectStatistic';
import { Laboratorio } from '../../../../components/AnyCollectSelected/Laboratorio';
import { Map } from '../../../../components/AnyCollectSelected/Map';
import { CollectOptions } from '../../../../components/CollectOptions';
import { ModalSelectedImage } from '../../../../components/Modal/ModalSelectedImage';
import { PageHeader } from '../../../../components/PageHeader';
import { useSigninContext } from '../../../../context/AuthProviderPages/Signin';
import { usePageContext } from '../../../../context/usePage';
import {
  CollectRowContainer,
  ScrollContainerPage,
} from '../../../../styles/global.styles';
import { Collect } from '../../../../types/Collect';
import { MapOption } from '../../../../types/MapOption';
import { Register } from '../../../../types/Register';

const ColetaAbertaSelecionada = () => {
  const [loadingCollect, setLoadingCollect] = useState<boolean>();
  const [selectedOption, setSelectedOption] = useState<MapOption>({
    id: 1,
    name: 'statistic',
    title: 'Estatísticas',
  });
  const [collect, setCollect] = useState<Collect>();
  const [isOpenRegister, setIsOpenRegister] = useState<boolean>(false);
  const [selectedRegister, setSelectedRegister] = useState<Register>();
  const [isOpenLaboratory, setIsOpenLaboratory] = useState<boolean>(false);
  const [selectedLaboratory] = useState<Register>();
  const { setSelectedPage } = usePageContext();
  const { collectionOrder } = useParams();
  const { path } = useSigninContext();

  useEffect(() => {
    setSelectedPage({ option: 'coleta' });
    setLoadingCollect(true);
    META_COLLECT_API.get(`/coleta?DFIDCOLETA=${collectionOrder}`, {
      headers: {
        path,
      },
    })
      .then(res => {
        setLoadingCollect(false);
        setCollect(res.data.coletas[0]);
        if (res.data.coletas[0] === undefined) {
          if (window.history.length > 1) {
            window.history.back();
          }
        }
      })
      .catch(() => {
        setLoadingCollect(false);
      });
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'coleta' }}
        pageTitle={`Coleta #${collectionOrder}`}
        hasFilter={false}
      />
      <CollectRowContainer>
        <CollectOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        {loadingCollect === false && (
          <ScrollContainerPage>
            {selectedOption.id === 1 && collect?.DFIDCOLETA && (
              <CollectStatistic DFIDCOLETA={collect.DFIDCOLETA} />
            )}
            {selectedOption.id === 2 && collect?.DFIDCOLETA && (
              <Map DFIDCOLETA={collect.DFIDCOLETA} />
            )}
            {selectedOption.id === 3 && collect?.DFIDCOLETA && (
              <BondTankAndProperty
                DFIDCOLETA={collect.DFIDCOLETA}
                isOpen={isOpenRegister}
                setIsOpen={setIsOpenRegister}
                setSelectedRegister={setSelectedRegister}
              />
            )}
            {selectedOption.id === 4 && collect?.DFIDCOLETA && (
              <Laboratorio
                DFIDCOLETA={collect.DFIDCOLETA}
                setIsOpen={setIsOpenRegister}
                setSelectedRegister={setSelectedRegister}
              />
            )}
          </ScrollContainerPage>
        )}
      </CollectRowContainer>
      <ModalSelectedImage
        selectedRegister={selectedRegister!}
        isOpen={isOpenRegister}
        closeModal={() => setIsOpenRegister(false)}
      />
      <ModalSelectedImage
        selectedRegister={selectedLaboratory!}
        isOpen={isOpenLaboratory}
        closeModal={() => setIsOpenLaboratory(false)}
      />
    </>
  );
};

export { ColetaAbertaSelecionada };
