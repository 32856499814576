import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { returnCardStatisticColor } from '../../utils/returnCardStatisticColor';
/* eslint-disable no-nested-ternary */

interface ITimeLineCard {
  DFTIPOITEMCOLETA?: 'N' | 'C' | 'S';
  DFTIPOREGISTRO?:
    | 'N'
    | 'A'
    | 'D'
    | 'F'
    | 'C'
    | 'G'
    | 'Q'
    | 'R'
    | 'T'
    | 'O'
    | 'P';
}

export const ContainerStatisticsTimeLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
  @media (max-width: 400px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
`;

export const TimeLineCard = styled.div<ITimeLineCard>`
  min-width: 200px;
  max-width: 400px;
  background-color: ${({ theme }) => darken(0.05, theme.COLORS.WHITE)};
  /* background-color: white; */
  /* -webkit-box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.08); */

  border-radius: 0 0.2rem 0.2rem 0;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    margin-left: 2rem;
  }

  @media (max-width: 750px) {
    min-width: 300px;
    max-width: 400px;
  }
  @media (max-width: 600px) {
    min-width: 250px;
    max-width: 350px;
  }
  ${({ DFTIPOITEMCOLETA, DFTIPOREGISTRO }) => css`
    border-right: 4px solid
      ${returnCardStatisticColor({
        DFTIPOITEMCOLETA,
        DFTIPOREGISTRO,
      })};
    .icon {
      background-color: ${returnCardStatisticColor({
        DFTIPOITEMCOLETA,
        DFTIPOREGISTRO,
      })};
    }
  `}

  .icon {
    min-width: 50px;
    min-height: 50px;
    border-radius: 30px;
    right: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .card-column {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0rem;
    span {
      font-size: 0.8rem;
    }
    span:first-child {
      font-weight: bold;
      font-size: 1.2rem;
      ${({ DFTIPOITEMCOLETA, DFTIPOREGISTRO }) => css`
        color: ${returnCardStatisticColor({
          DFTIPOITEMCOLETA,
          DFTIPOREGISTRO,
        })};
      `}
    }
  }
`;

export const TimeLineSpaceContainer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
`;

export const TimeLineCurrentTime = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;
  padding: 1rem;
  background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
  min-height: 40px;
  border-radius: 0 0.2rem 0.2rem 0;
  border-right: 4px solid ${({ theme }) => darken(0.6, theme.COLORS.WHITE)};
  span {
    color: black;
  }
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    margin-left: 1rem;
  }
`;

export const TimeLineSpace = styled.div`
  min-height: 50px;
  width: 3px;
  margin: 1rem 1rem 1rem 2rem;
  background-color: ${({ theme }) => theme.COLORS.GREY};
`;
