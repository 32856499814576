import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useLineContext } from '../../../context/ProviderPage/Linha';
import { Line } from '../../../types/Line';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';
import { generateQRCode } from './services';

interface IListLines {
  data: Line[];
}

export const ListLines = ({ data }: IListLines) => {
  const { setOpenInfo, setSelectedLine } = useLineContext();
  const { path, bufferImage } = useSigninContext();
  const handleSelectLine = (line: Line) => {
    setSelectedLine(line);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Código</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Região</th>
            <th className="list-th">Unidade</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Tanques</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((line, index) => {
            const {
              DFATIVO,
              DFIDLINHA,
              DFNOMELINHA,
              DFNOMECARRETEIRO,
              DFNOMEFANTASIA,
              DFDESCREGIONAL,
              DFQUANTIDADEDETANQUES,
            } = line;
            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  {DFIDLINHA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCREGIONAL || 'Não informado'}
                </td>
                <td style={{ whiteSpace: 'pre' }} className="list-td-large">
                  {DFNOMEFANTASIA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFQUANTIDADEDETANQUES || '0'}
                </td>
                <td className="list-td-small">
                  <div>
                    <ListButton
                      {...{
                        action: () => handleSelectLine(line),
                        icon: 'search',
                      }}
                    />
                    <ListButton
                      id="QRCODE"
                      {...{
                        action: () =>
                          generateQRCode({ line, path, bufferImage }),
                        icon: 'qrcode',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
