import { Dispatch, SetStateAction } from 'react';

import { Unity } from '../../../types/Unity';

interface IExecuteFilterUnities {
  search: string;
  unities: Unity[];
  setReceiveUnities: Dispatch<SetStateAction<Unity[]>>;
}

const executeFilterUnities = ({
  search,
  unities,
  setReceiveUnities,
}: IExecuteFilterUnities) => {
  if (search) {
    const filter = unities.filter(item => {
      if (
        item
          .DFNOMEFANTASIA!.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase()
          .includes(
            search
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase(),
          ) === true
      ) {
        if (
          item
            .DFNOMEFANTASIA!.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase()
            .indexOf(
              search
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLocaleLowerCase(),
            ) > -1
        ) {
          return true;
        }
        return false;
      }
      return false;
    });
    setReceiveUnities(filter);
  } else {
    setReceiveUnities(unities);
  }
};

export { executeFilterUnities };
