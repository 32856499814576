/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction } from 'react';

import { Register } from '../../../types/Register';
import { LaboratoryCard } from '../../Cards/LaboratoryCard';
import { DontExistsItem } from '../../DontExistsItem';
import { LoadingData } from '../../LoadingData';
import { ListLaboratoryItensContainer } from './styles';

interface IListLaboratoryItens {
  loading: boolean;
  registers: Register[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const ListLaboratoryItens = ({
  loading,
  registers,
  setSelectedRegister,
  setIsOpen,
}: IListLaboratoryItens) => {
  return (
    <ListLaboratoryItensContainer
      style={{
        display: `${
          registers.length > 0 && loading === false ? 'grid' : 'flex'
        }`,
        alignItems: `${loading ? 'center' : 'normal'}`,
        justifyContent: `${loading ? 'center' : 'normal'}`,
      }}
    >
      {loading === true ? (
        <LoadingData title="Carregando dados!" />
      ) : registers.length > 0 && loading === false ? (
        registers.map((register, index) => {
          return (
            <LaboratoryCard
              setSelectedRegister={setSelectedRegister}
              register={register}
              setIsOpen={setIsOpen}
              key={index}
            />
          );
        })
      ) : (
        loading === false &&
        registers.length === 0 && (
          <DontExistsItem title="Nenhum item foi encontrado!" />
        )
      )}
    </ListLaboratoryItensContainer>
  );
};

export { ListLaboratoryItens };
