/* eslint-disable no-nested-ternary */
import { darken, transparentize } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const blinkAnimation = keyframes`
  0%{
    opacity: 0.2;
    border: 0.1px solid darkgreen;
  }
  60%{
    opacity: 0.4;
    border: 0.1px solid lightgreen;
    transform: scale(2.5);
  }
  100%{    
    border: 0.1px solid lightgreen;
    transform: scale(2.7);
    opacity: 0.1;
  }
`;

interface IDeviceCard {
  active: boolean;
}

interface IDeviceHardware {
  meter: number;
  activeMeter: boolean;
}

const DeviceCardContainer = styled.div<IDeviceCard>`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
  .card-title {
    min-height: 55px;
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.COLORS.GREY_50)};
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 0.9rem;
      color: ${({ theme }) => darken(0.45, theme.COLORS.GREY_50)};
    }
    .badge {
      display: inline-block;
      border: 0.3px solid ${({ theme }) => darken(0.25, theme.COLORS.GREY_50)};
      width: 12px;
      height: 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      background-color: ${({ theme }) =>
        transparentize(0.8, theme.COLORS.GREY_50)};
      justify-content: center;
      ${({ active, theme }) =>
        active &&
        css`
          background-color: ${theme.COLORS.METER_40};
          .animation {
            width: 100%;
            height: 100%;
            border-color: ${theme.COLORS.METER_40};
            border-radius: 6px;
            animation: ${blinkAnimation} 1.3s infinite;
          }
        `}
    }
  }

  .card-row {
    min-height: 35px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem;
    border-bottom: 1px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
  }
  .card-row-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 0.25rem;
    }
  }
  .card-row-item-medium-with-bar {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.6rem;
    border-right: 1px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
    justify-content: space-between;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 0.25rem;
    }
  }
  .card-row-item-medium {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.6rem;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 0.25rem;
    }
  }
  span {
    font-size: 0.65rem;
    font-weight: 400;
  }
  .card-footer {
    border-top: 1px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .modify {
      background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    }
    .delete {
      margin-left: 0.3rem;
      background-color: ${({ theme }) => darken(0.2, theme.COLORS.GREEN_30)};
    }
    button {
      transition: 0.3s all;
      border: 0;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.7rem;
      svg {
        color: white;
      }
      span {
        color: white;
        font-weight: 600;
        font-size: 0.8rem;
        margin-left: 0.2rem;
      }
      &:hover {
        filter: brightness(0.9);
      }
      :disabled {
        cursor: not-allowed;
      }
    }
  }
`;

const DeviceHardware = styled.div<IDeviceHardware>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.6rem;
  /* border-bottom: 1px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)}; */
  span {
    font-size: 0.7rem;
    font-weight: 400;
  }
  .card-row-item {
    margin-bottom: 0.4rem;
  }
  .device-hardware-device-info {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .device-hardware-storage-size {
    display: flex;
    width: 100%;
    margin: 0.2rem 0;
    height: 0.6rem;
    border-radius: 0.3rem;
    background-color: ${({ theme }) =>
      transparentize(0.4, theme.COLORS.GREY_50)};
    .device-hardware-storage-meter {
      height: 100%;
      border-radius: 0.3rem;
      transition: 0.3s all;
      ${({ meter, activeMeter, theme }) =>
        meter &&
        css`
          background-color: ${meter <= 40
            ? theme.COLORS.METER_40
            : meter > 40 && meter < 66
            ? theme.COLORS.METER_41
            : theme.COLORS.METER_66};
          transition: 1s all;
          width: ${activeMeter ? meter : 0}%;
        `}
    }
  }
`;

export { DeviceCardContainer, DeviceHardware };
