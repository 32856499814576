/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction } from 'react';

import { Register } from '../../../types/Register';
import { RegisterCard } from '../../Cards/RegisterCard';
import { DontExistsItem } from '../../DontExistsItem';
import { LoadingData } from '../../LoadingData';
import { RegisterContainer } from './styles';

interface IListRegister {
  loading: boolean;
  registers: Register[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const ListRegister = ({
  loading,
  registers,
  isOpen,
  setIsOpen,
  setSelectedRegister,
}: IListRegister) => {
  return (
    <RegisterContainer
      style={{
        display: `${
          registers.length > 0 && loading === false ? 'grid' : 'flex'
        }`,
        alignItems: `${loading ? 'center' : 'normal'}`,
        justifyContent: `${loading ? 'center' : 'normal'}`,
      }}
    >
      {loading === true ? (
        <LoadingData title="Carregando dados!" />
      ) : registers.length > 0 && loading === false ? (
        registers.map((register, index) => {
          return (
            <RegisterCard
              setSelectedRegister={setSelectedRegister}
              register={register}
              key={index}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
          );
        })
      ) : (
        loading === false &&
        registers.length === 0 && (
          <DontExistsItem title="Nenhum item foi encontrado!" />
        )
      )}
    </RegisterContainer>
  );
};

export { ListRegister };
