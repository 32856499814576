/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction } from 'react';

import { Register } from '../../../types/Register';
import { TankAndProperty } from '../../../types/TankAndProperty';
import { LoadingData } from '../../LoadingData';
import { TankItem } from '../../TankListOption';
import { ListBondTankAndPropertyContainer } from './styles';

interface IListBondTankAndProperty {
  loading?: boolean;
  ITENSCOLETA: TankAndProperty[];
  setSelectedItem: Dispatch<SetStateAction<TankAndProperty | null>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const ListBondTankAndProperty = ({
  loading,
  ITENSCOLETA,
  setSelectedItem,
}: IListBondTankAndProperty) => {
  return (
    <ListBondTankAndPropertyContainer
      style={{
        display: loading ? 'flex' : 'grid',
        justifyContent: `${
          !loading && ITENSCOLETA.length > 0 && 'space-between'
        }`,
      }}
    >
      {loading === true ? (
        <LoadingData title="Carregando dados!" />
      ) : ITENSCOLETA.length > 0 && loading === false ? (
        ITENSCOLETA.map((item, index) => {
          return (
            <TankItem
              action={() => setSelectedItem(item)}
              data={item}
              key={index}
            />
          );
        })
      ) : (
        <></>
      )}
    </ListBondTankAndPropertyContainer>
  );
};

export { ListBondTankAndProperty };
