/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { META_COLLECT_API } from '../../../api';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { PageContent } from '../../../styles/global.styles';
import { LaboratoryCollectItem } from '../../../types/LaboratoryCollectItem';
import { Register } from '../../../types/Register';
import { DontExistsItem } from '../../DontExistsItem';
import { DontExistsItemFlex } from '../../DontExistsItem/styles';
import { ListColllectLaboratory } from '../../List/ListColllectLaboratory';
import { LoadingData } from '../../LoadingData';
import { SelectedCollectItemLaboratory } from '../SelectedCollectItemLaboratory';

interface ILaboratorio {
  DFIDCOLETA?: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

export const Laboratorio = ({
  DFIDCOLETA,
  setIsOpen,
  setSelectedRegister,
}: ILaboratorio) => {
  const { path } = useSigninContext();

  const [collectItem, setCollectItem] = useState<LaboratoryCollectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] =
    useState<LaboratoryCollectItem | null>(null);
  useEffect(() => {
    META_COLLECT_API.get(`/itemcoleta/laboratorio?DFIDCOLETA=${DFIDCOLETA}`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setCollectItem(response.data.itens);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <PageContent
      style={{
        justifyContent: `${loading === false ? 'normal' : 'center'}`,
      }}
    >
      {loading ? (
        <LoadingData title="Buscando dados" />
      ) : (
        <>
          {selectedItem ? (
            <SelectedCollectItemLaboratory
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setIsOpen={setIsOpen}
              setSelectedRegister={setSelectedRegister}
            />
          ) : (
            <>
              {collectItem.length === 0 && loading === false ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum item foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <ListColllectLaboratory
                  setSelectedItem={setSelectedItem}
                  data={collectItem}
                  loading={loading}
                />
              )}
            </>
          )}
        </>
      )}
    </PageContent>
  );
};
