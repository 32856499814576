const returnTrueOrFalse = (value: string) => {
  switch (value) {
    case 'N':
      return false;
    case 'S':
      return true;
    default:
      return false;
  }
};

export { returnTrueOrFalse };
