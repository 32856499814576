import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import themes from './themes';
import { SelectAppVersion } from './version';
import { GlobalStyle } from './version/v1/styles/global.styles';

export const App = () => {
  const fullURL = window.location.href;
  const url = new URL(fullURL);
  const version = url.pathname;

  return (
    <ThemeProvider theme={themes}>
      <ToastContainer />
      <GlobalStyle />
      <SelectAppVersion {...{ version }} />
    </ThemeProvider>
  );
};
