import { Buffer } from 'buffer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Menu from '../../../../assets/menuCircle.svg';
import { ReactComponent as User } from '../../../../assets/user.svg';
import { useSigninContext } from '../../../../context/AuthProviderPages/Signin';
import { usePageContext } from '../../../../context/usePage';
import { ContainerConectedUser, DropdownMenu, UnityLogo } from './styles';

const ConectedUser = () => {
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { handleSignout, user } = useSigninContext();
  const { openAndCloseModalToSelectUnity, version } = usePageContext();
  const { DFNOMEUSUARIO } = user!;

  const handleNavigate = () => {
    setOpenDropdownMenu(false);
    navigate(`/configuracoes-do-painel${version}`);
  };

  const handleExecuteSignout = () => {
    setOpenDropdownMenu(false);
    handleSignout();
    window.location.href = version;
  };
  const handleSelectUnity = () => {
    setOpenDropdownMenu(false);
    openAndCloseModalToSelectUnity();
  };

  const buffer =
    user?.DFLOGOEMPRESA && Buffer.from(user?.DFLOGOEMPRESA!.data).toString();

  return (
    <ContainerConectedUser>
      <div className="content_conected_user">
        <span>{DFNOMEUSUARIO}</span>
        <div>
          {buffer ? (
            <UnityLogo src={`data:image/png;base64,${buffer}`}></UnityLogo>
          ) : (
            <User className="content_user" />
          )}
        </div>
      </div>
      <DropdownMenu openDropdownMenu={openDropdownMenu}>
        <img
          src={Menu}
          onClick={() => {
            setOpenDropdownMenu(!openDropdownMenu);
          }}
        />
        <div className="open-menu">
          <div className="menu-item" onClick={() => handleNavigate()}>
            <span>Configurações</span>
          </div>
          <div className="menu-item" onClick={() => handleSelectUnity()}>
            <span>Selecionar Unidade</span>
          </div>
          <div className="menu-item" onClick={() => handleExecuteSignout()}>
            <span>Sair</span>
          </div>
        </div>
      </DropdownMenu>
    </ContainerConectedUser>
  );
};

export { ConectedUser };
