import { darken, transparentize } from 'polished';
import styled from 'styled-components';

export const PanelConfigurationOptionContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
  border-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: ${({ theme }) => darken(0.03, theme.COLORS.WHITE)};
  }
  div {
    width: 50px;
    margin-left: 5px;
  }
`;
