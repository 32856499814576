import Loading from 'react-loading';

import { ContainerLoadScreen } from './styles';

const LoadScreen = () => {
  return (
    <ContainerLoadScreen>
      <Loading color="grey" type="spin" width={100} height={100} />
    </ContainerLoadScreen>
  );
};

export { LoadScreen };
