import { useSiloContext } from '../../../context/ProviderPage/Silo';
import { Silo } from '../../../types/Silo';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListSilos {
  data: Silo[];
}

export const ListSilos = ({ data }: IListSilos) => {
  const { setOpenInfo, setSelectedSilo } = useSiloContext();

  const handleSelectSilo = (silo: Silo) => {
    setSelectedSilo(silo);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Código</th>
            <th className="list-th">Coleta Seletiva</th>
            <th className="list-th">Descrição</th>
            <th className="list-th">Unidade</th>
            <th className="list-th">Capacidade</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((silo, index) => {
            const {
              DFCAPACIDADE,
              DFCOLETASELETIVA,
              DFDESCRICAOSILO,
              DFIDSILO,
              DFNOMEFANTASIA,
            } = silo;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSelectSilo(silo)}
              >
                <td className="list-td-small">{DFIDSILO || 'Não informado'}</td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFCOLETASELETIVA!)}
                  />
                </td>
                <td className="list-td-large">
                  {DFDESCRICAOSILO || 'Não informado'}
                </td>
                <td style={{ whiteSpace: 'pre' }} className="list-td-large">
                  {DFNOMEFANTASIA || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {DFCAPACIDADE || 'Não informado'}
                </td>

                <td className="list-td-small">
                  <div>
                    <ListButton
                      {...{
                        action: () => handleSelectSilo(silo),
                        icon: 'search',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
