import { useSiloContext } from '../../../context/ProviderPage/Silo';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import {
  ColumnItens,
  ContainerItemModal,
  RowItens,
} from '../ModalInfoTemplete/styles';

export const InfoSilo = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedSilo } = useSiloContext();
  const {
    DFCAPACIDADE,
    DFCOLETASELETIVA,
    DFDESCRICAOSILO,
    DFIDSILO,
    DFNOMEFANTASIA,
  } = selectedSilo;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <ColumnItens>
        <RowItens>
          <ContainerItemModal>
            <label>Código</label>
            <div>
              <span>{DFIDSILO || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Unidade</label>
            <div>
              <span>{DFNOMEFANTASIA || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
        <RowItens>
          <ContainerItemModal>
            <label>Descrição</label>
            <div>
              <span>{DFDESCRICAOSILO || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Capacidade</label>
            <div>
              <span>{DFCAPACIDADE || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
        <RowItens>
          <ContainerItemModal>
            <label>Coleta Seletiva</label>
            <img src={returnCheckType(DFCOLETASELETIVA!)} />
          </ContainerItemModal>
        </RowItens>
      </ColumnItens>
    </ModalInfoTemplete>
  );
};
