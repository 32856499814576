import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Loading from 'react-loading';

import { ReactComponent as ArrowDown } from '../../../assets/ArrowDownWhite.svg';
import { ReactComponent as CollectIcon } from '../../../assets/newicons/COLETA.svg';
import { CollectToBond } from '../../../types/CollectToBond';
import { formatDateAndHour } from '../../../utils/date/formatDateAndHour';
import {
  SelectCollectToBondWithTankContainer,
  SelectCollectToBondWithTankComponent,
} from './styles';

interface ISelectCollectToBondWithTank {
  value: CollectToBond | undefined;
  setValue: Dispatch<SetStateAction<CollectToBond | undefined>>;
  collects: CollectToBond[];
  loadingCollectsToBond: boolean;
}

const SelectCollectToBondWithTank = ({
  setValue,
  value,
  collects,
  loadingCollectsToBond,
}: ISelectCollectToBondWithTank) => {
  const [openOption, setOpenOption] = useState<boolean>(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openOption) {
      setTimeout(() => {
        setShowContent(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 0);
    }
  }, [openOption]);

  const selectedCollect = (value: CollectToBond) => {
    if (value) {
      const { DFDATASAIDA, DFHORASAIDA, DFTOTALARMAZENADO } = value;
      return `${formatDateAndHour(
        DFDATASAIDA,
        DFHORASAIDA,
      )} : ${DFTOTALARMAZENADO} L`;
    }
    return 'Selecionar Coleta';
  };

  return (
    <SelectCollectToBondWithTankContainer {...{ openOption, showContent }}>
      <h2>Lista de Coletas</h2>
      <SelectCollectToBondWithTankComponent {...{ openOption, showContent }}>
        <CollectIcon />
        <div className="select-options-container">
          <span draggable="false">{selectedCollect(value!)}</span>
          <ArrowDown
            {...{ openOption }}
            fill="white"
            className="select"
            onClick={() => setOpenOption(!openOption)}
          />
        </div>
      </SelectCollectToBondWithTankComponent>
      <div
        className="list-options"
        style={{
          alignItems: `${loadingCollectsToBond ? 'center' : 'flex-start'}`,
          justifyContent: `${loadingCollectsToBond ? 'center' : 'flex-start'}`,
        }}
      >
        {loadingCollectsToBond ? (
          <Loading height={25} width={25} type="spin" />
        ) : (
          <>
            {collects.map((collect, index) => {
              const {
                DFDATASAIDA,
                DFHORASAIDA,
                DFTOTALARMAZENADO,
                DFQTDCOLETADA,
                DFPLACAVEICULO,
                DFNOMECARRETEIRO,
                DFIDCOLETA,
              } = collect;
              return (
                <div
                  className="collect-item"
                  onClick={() => {
                    setValue(collect!);
                    setOpenOption(!openOption);
                  }}
                  key={index}
                  style={{
                    borderBottom: `${
                      index + 1 < collects.length && '1px solid gray'
                    }`,
                  }}
                >
                  <div className="column">
                    <span>
                      <b>CÓDIGO: </b> {DFIDCOLETA}
                    </span>
                    <span>
                      <b>CARRETEIRO: </b> {DFNOMECARRETEIRO}
                    </span>
                    <span>
                      <b>PLACA VEÍCULO: </b>
                      {DFPLACAVEICULO}
                    </span>
                    <span>
                      <b>QTD. COLETADA DA COLETA: </b>
                      {DFTOTALARMAZENADO} Litros
                    </span>
                    <span>
                      <b>QTD. COLETADA DO TANQUE: </b>
                      {DFQTDCOLETADA} Litros
                    </span>
                  </div>
                  <div className="column">
                    <span>
                      <b>{formatDateAndHour(DFDATASAIDA, DFHORASAIDA)}</b>
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </SelectCollectToBondWithTankContainer>
  );
};

export { SelectCollectToBondWithTank };
