/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { MouseEvent, useEffect, useState } from 'react';
import Loading from 'react-loading';

import Close from '../../../assets/Close.svg';
import { useBondCollectContext } from '../../../context/ProviderPage/Vinculada';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { ContainerOverlayModal, ContentModal } from './styles';

interface IUnbondCollectItem {
  executeLoadCollectItensOfASelectedCollect: () => void;
}

export const UnbondCollectItem = ({
  executeLoadCollectItensOfASelectedCollect,
}: IUnbondCollectItem) => {
  const [loadAction, setLoadAction] = useState<boolean>(false);
  const { sideMenuWidth } = useSideMenuContext();
  const { setOpenInfo, openInfo, handleUnbondCollectItem } =
    useBondCollectContext();

  const verifyClose = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    const target = e.target as HTMLDivElement;
    if (target.id === 'container-overlay-modal') {
      setOpenInfo(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenInfo(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ContainerOverlayModal
      isOpen={openInfo}
      onClick={verifyClose}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal isOpen={openInfo} periodic={openInfo}>
        <div className="close">
          <h2>Desvincular tanque</h2>
          <div className="close-button-selected-image">
            <img src={Close} onClick={() => setOpenInfo(false)} />
          </div>
        </div>
        <p>
          Ao clicar em "Desvincular" as informações do vinculo do tanque com a
          coleta será removida. Deseja prosseguir com a operação?
        </p>
        <button
          className="bond-button"
          onClick={() =>
            handleUnbondCollectItem({
              setLoadAction,
              executeLoadCollectItensOfASelectedCollect,
            })
          }
        >
          {loadAction ? (
            <Loading width={25} height={25} type="spin" />
          ) : (
            'Desvincular'
          )}
        </button>
      </ContentModal>
    </ContainerOverlayModal>
  );
};
