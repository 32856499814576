import { useEffect, useState } from 'react';

import Closed from '../../assets/Close.svg';
import { usePageContext } from '../../context/usePage';
import { useSideMenuContext } from '../../context/useSideMenu';
import { Option } from '../Panel/SideMenu/MenuOptions/Option';
import {
  collectOptions,
  singleOptions,
  managementOptions,
  tankerOptions,
} from '../Panel/SideMenu/MenuOptions/options';
import { MenuMobileContainer } from './styles';

const MenuMobile = () => {
  const { selectedPage, setSelectedPage } = usePageContext();
  const { sideMenuIsOpenMobile, setSideMenuIsOpenMobile, setSideMenuIsOpen } =
    useSideMenuContext();
  const [mobileAnimation, setMobileAnimation] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 650) {
        setMobileAnimation(false);
        setSideMenuIsOpenMobile(false);
        setSideMenuIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (sideMenuIsOpenMobile) {
      setMobileAnimation(true);
    }
  }, [sideMenuIsOpenMobile]);

  const handleCloseModal = () => {
    setMobileAnimation(false);
    setSideMenuIsOpenMobile(false);
  };

  return (
    <MenuMobileContainer mobileAnimation={mobileAnimation}>
      <img src={Closed} className="close-icon" onClick={handleCloseModal} />
      <div className="list-options">
        {singleOptions.map((item, index) => {
          const { option, title, toPath } = item;
          return (
            <Option
              key={index}
              action={() => setSelectedPage({ option })}
              option={option}
              title={title}
              toPath={toPath}
              selected={selectedPage}
            />
          );
        })}
        {collectOptions.map((item, index) => {
          const { option, title, toPath } = item;
          return (
            <Option
              key={index}
              action={() => setSelectedPage({ option })}
              option={option}
              title={title}
              toPath={toPath}
              selected={selectedPage}
            />
          );
        })}
        {tankerOptions.map((item, index) => {
          const { option, title, toPath } = item;
          return (
            <Option
              key={index}
              action={() => setSelectedPage({ option })}
              option={option}
              title={title}
              toPath={toPath}
              selected={selectedPage}
            />
          );
        })}
        {managementOptions.map((item, index) => {
          const { option, title, toPath } = item;
          return (
            <Option
              key={index}
              action={() => setSelectedPage({ option })}
              option={option}
              title={title}
              toPath={toPath}
              selected={selectedPage}
            />
          );
        })}
      </div>
    </MenuMobileContainer>
  );
};

export { MenuMobile };
