import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Tank } from '../../../types/Tank';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterTank: boolean;
  openSideModalFilterLinkedTankAction: () => void;

  DFIDTANQUE: string;
  setDFIDTANQUE: Dispatch<SetStateAction<string>>;
  DFDESCTANQUE: string;
  setDFDESCTANQUE: Dispatch<SetStateAction<string>>;
  DFIDLINHA: string;
  setDFIDLINHA: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFCOLETASELETIVA: string;
  setDFCOLETASELETIVA: Dispatch<SetStateAction<string>>;
  DFLOGIN: string;
  setDFLOGIN: Dispatch<SetStateAction<string>>;
  DFIMEI: string;
  setDFIMEI: Dispatch<SetStateAction<string>>;
  DFDATALOGIN: string;
  setDFDATALOGIN: Dispatch<SetStateAction<string>>;
  DFHORALOGIN: string;
  setDFHORALOGIN: Dispatch<SetStateAction<string>>;
  filterLinkedTankAction: () => void;
  cleanAllInputsAction: () => void;
  loadLinkedTankAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedTank: Tank;
  setSelectedTank: Dispatch<SetStateAction<Tank>>;

  tank: Tank[];
  setTank: Dispatch<SetStateAction<Tank[]>>;

  loadingLinkedTank: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const LinkedTankContextProvider = ({ children }: IContextProps) => {
  const [DFIDTANQUE, setDFIDTANQUE] = useState<string>('');
  const [DFDESCTANQUE, setDFDESCTANQUE] = useState<string>('');
  const [DFIDLINHA, setDFIDLINHA] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFCOLETASELETIVA, setDFCOLETASELETIVA] = useState<string>('');
  const [DFLOGIN, setDFLOGIN] = useState<string>('');
  const [DFIMEI, setDFIMEI] = useState<string>('S');
  const [DFDATALOGIN, setDFDATALOGIN] = useState<string>('');
  const [DFHORALOGIN, setDFHORALOGIN] = useState<string>('');

  const [loadingLinkedTank, setLoadingLinkedTank] = useState<boolean>(false);
  const [openSideModalFilterTank, setOpenSideModalFilterTank] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedTank, setSelectedTank] = useState<Tank>({});
  const [tank, setTank] = useState<Tank[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);

  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDTANQUE = `DFIDTANQUE=${DFIDTANQUE}`;
  const DESCTANQUE = `DFDESCTANQUE=${DFDESCTANQUE}`;
  const IDLINHA = `DFIDLINHA=${DFIDLINHA}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const COLETASELETIVA = `DFCOLETASELETIVA=${DFCOLETASELETIVA}`;
  const LOGIN = `DFLOGIN=${DFLOGIN}`;
  const IMEI = `DFIMEI=${DFIMEI ? 'true' : ''}`;
  const DATALOGIN = `DFDATALOGIN=${DFDATALOGIN}`;
  const HORALOGIN = `DFHORALOGIN=${DFHORALOGIN}`;

  const openSideModalFilterLinkedTankAction = () => {
    setOpenSideModalFilterTank(!openSideModalFilterTank);
  };

  useEffect(() => {
    if (hasClick) {
      setLoadingLinkedTank(true);
      META_COLLECT_API.get(
        `/tanque?${Limit}&${Offset}&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}&${LOGIN}&${IMEI}&${DATALOGIN}&${HORALOGIN}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setTank(response.data.tanques);
          setQuantity(response.data.quantidade);
          setLoadingLinkedTank(false);
          setHasClick(false);
        })
        .catch(() => {
          setLoadingLinkedTank(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadLinkedTankActionWithParams = () => {
    setLoadingLinkedTank(true);
    META_COLLECT_API.get(`/tanque?${Limit}&offset=0&${IMEI}`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setTank(response.data.tanques);
        setQuantity(response.data.quantidade);
        setLoadingLinkedTank(false);
        setStart(1);
      })
      .catch(() => {
        setLoadingLinkedTank(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFATIVO('');
    setDFCOLETASELETIVA('');
    setDFDESCTANQUE('');
    setDFIDLINHA('');
    setDFIDTANQUE('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterLinkedTankAction();
    loadLinkedTankActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterLinkedTankAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterLinkedTankAction();
    setLoadingLinkedTank(true);
    META_COLLECT_API.get(
      `/tanque?${Limit}&offset=0&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}&${LOGIN}&${IMEI}&${DATALOGIN}&${HORALOGIN}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setTank(response.data.tanques);
        setQuantity(response.data.quantidade);
        setLoadingLinkedTank(false);
        setStart(1);
      })
      .catch(() => {
        setLoadingLinkedTank(false);
        setStart(1);
      });
  };

  const loadLinkedTankAction = () => {
    setLoadingLinkedTank(true);
    loadLinkedTankActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'tanquesVinculado') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFATIVO,
        DFCOLETASELETIVA,
        DFDESCTANQUE,
        DFIDLINHA,
        DFIDTANQUE,
        filterLinkedTankAction,
        loadLinkedTankAction,
        limit,
        loadingLinkedTank,
        offset,
        openInfo,
        openSideModalFilterTank,
        openSideModalFilterLinkedTankAction,
        page,
        quantity,
        selectedTank,
        setDFATIVO,
        setDFCOLETASELETIVA,
        setDFDESCTANQUE,
        setDFIDLINHA,
        setDFIDTANQUE,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setSelectedTank,
        setTank,
        tank,
        start,
        setStart,
        DFDATALOGIN,
        DFHORALOGIN,
        DFIMEI,
        DFLOGIN,
        setDFDATALOGIN,
        setDFHORALOGIN,
        setDFIMEI,
        setDFLOGIN,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useLinkedTankContext() {
  const context = useContext(Context);
  return context;
}

export { LinkedTankContextProvider, useLinkedTankContext };
