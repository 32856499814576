import { useRegionContext } from '../../../context/ProviderPage/Regiao';
import { Region } from '../../../types/Region';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListRegions {
  data: Region[];
}

export const ListRegions = ({ data }: IListRegions) => {
  const { setOpenInfo, setSelectedRegion } = useRegionContext();

  const handleSelectRegion = (region: Region) => {
    setSelectedRegion(region);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Código</th>
            <th className="list-th">Descrição</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((region, index) => {
            const { DFDESCREGIONAL, DFIDREGIONAL } = region;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSelectRegion(region)}
              >
                <td className="list-td-small">
                  {DFIDREGIONAL || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFDESCREGIONAL || 'Não informado'}
                </td>
                <td className="list-td-small">
                  <ListButton
                    {...{
                      action: () => handleSelectRegion(region),
                      icon: 'search',
                    }}
                  />
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
