import { useEffect } from 'react';

import { useExcludedCollectContext } from '../../../context/ProviderPage/Excluida';
import { FilterInput } from '../../Inputs/FilterInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterExcludedCollect = () => {
  const {
    openSideModalFilterExcludedCollect,
    openSideModalFilterExcludedCollectAction,
    cleanAllInputsAction,
    filterExcludedCollectAction,
    DFNOMECARRETEIRO,
    setDFNOMECARRETEIRO,
    DFNOMELINHA,
    setDFNOMELINHA,
    DFDATAEXCLUSAOCOLETA,
    setDFDATAEXCLUSAOCOLETA,
    DFDESCREGIONAL,
    setDFDESCREGIONAL,
    DFIDCOLETA,
    setDFIDCOLETA,
  } = useExcludedCollectContext();

  useEffect(() => {
    if (Number(DFIDCOLETA) < 0) {
      setDFIDCOLETA('0');
    }
  }, [DFIDCOLETA]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterExcludedCollect}
      onchange={openSideModalFilterExcludedCollectAction}
      title="Filtrar Vinculada"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterExcludedCollectAction}
    >
      <FilterInput
        onChange={setDFIDCOLETA}
        value={DFIDCOLETA}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMECARRETEIRO}
        value={DFNOMECARRETEIRO}
        title="Carreteiro"
        type="text"
      />
      <FilterInput
        onChange={setDFDESCREGIONAL}
        value={DFDESCREGIONAL}
        title="Região"
        type="text"
      />
      <FilterInput
        onChange={setDFNOMELINHA}
        value={DFNOMELINHA}
        title="Linha"
        type="text"
      />
      <FilterInput
        onChange={setDFDATAEXCLUSAOCOLETA}
        value={DFDATAEXCLUSAOCOLETA}
        title="Data Coleta"
        type="date"
      />
    </SideMenuFilterTemplete>
  );
};
