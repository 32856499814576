import {
  Dispatch,
  InputHTMLAttributes,
  ReactNode,
  SetStateAction,
} from 'react';

import { FormAuthInputContainer } from './styles';

interface IFormAuthInput extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  icon: ReactNode;
}

const FormAuthInput = ({ setValue, value, icon, ...props }: IFormAuthInput) => {
  return (
    <FormAuthInputContainer>
      {icon}
      <input
        {...{ value, onChange: e => setValue(e.target.value), ...props }}
      />
    </FormAuthInputContainer>
  );
};

export { FormAuthInput };
