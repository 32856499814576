import styled from 'styled-components';

const ListDevicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  grid-gap: 10px;
  /* align-self: flex-start; */
  /* overflow-y: auto; */
`;

export { ListDevicesContainer };
