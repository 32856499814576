import { useEffect } from 'react';

import { useDischargeContext } from '../../../context/ProviderPage/Descarga';
import { FilterInput } from '../../Inputs/FilterInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterDischargeCollect = () => {
  const {
    openSideModalFilterDischargeCollect,
    openSideModalFilterDischargeCollectAction,
    cleanAllInputsAction,
    filterDischargeCollectAction,
    DFNOMECARRETEIRO,
    setDFNOMECARRETEIRO,
    DFORDEMCOLETA,
    setDFORDEMCOLETA,
    DFNOMELINHA,
    setDFNOMELINHA,
    DFPLACAVEICULO,
    setDFPLACAVEICULO,
    DFDATACOLETA,
    setDFDATACOLETA,
    DFDATASAIDA,
    setDFDATASAIDA,
    DFTOTALARMAZENADO,
    setDFTOTALARMAZENADO,
    DFDATAPROGRAMADA,
    setDFDATAPROGRAMADA,
  } = useDischargeContext();

  useEffect(() => {
    if (Number(DFORDEMCOLETA) < 0) {
      setDFORDEMCOLETA('0');
    }
  }, [DFORDEMCOLETA]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterDischargeCollect}
      onchange={openSideModalFilterDischargeCollectAction}
      title="Filtrar Descarga"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterDischargeCollectAction}
    >
      <FilterInput
        onChange={setDFORDEMCOLETA}
        value={DFORDEMCOLETA}
        title="Ordem Coleta"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMECARRETEIRO}
        value={DFNOMECARRETEIRO}
        title="Carreteiro"
        type="text"
      />
      <FilterInput
        onChange={setDFTOTALARMAZENADO}
        value={DFTOTALARMAZENADO}
        title="Total em litros"
        type="number"
      />
      <FilterInput
        onChange={setDFPLACAVEICULO}
        value={DFPLACAVEICULO}
        title="Veículo"
        type="text"
      />
      <FilterInput
        onChange={setDFNOMELINHA}
        value={DFNOMELINHA}
        title="Linha"
        type="text"
      />
      <FilterInput
        onChange={setDFDATAPROGRAMADA}
        value={DFDATAPROGRAMADA}
        title="Data Programada"
        type="date"
      />
      <FilterInput
        onChange={setDFDATASAIDA}
        value={DFDATASAIDA}
        title="Data Saída"
        type="date"
      />
      <FilterInput
        onChange={setDFDATACOLETA}
        value={DFDATACOLETA}
        title="Data Coleta"
        type="date"
      />
    </SideMenuFilterTemplete>
  );
};
