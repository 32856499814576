import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Unity } from '../../../types/Unity';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterUnities: boolean;
  openSideModalFilterUnitiesAction: () => void;

  DFIDUNIDADE: string;
  setDFIDUNIDADE: Dispatch<SetStateAction<string>>;
  DFRAZSOCUNIDADE: string;
  setDFRAZSOCUNIDADE: Dispatch<SetStateAction<string>>;
  DFCNPJCPFCEI: string;
  setDFCNPJCPFCEI: Dispatch<SetStateAction<string>>;
  DFNOMEFANTASIA: string;
  setDFNOMEFANTASIA: Dispatch<SetStateAction<string>>;

  filterUnitiesAction: () => void;
  cleanAllInputsAction: () => void;
  loadUnitiesAction: () => void;

  openInfoUnity: boolean;
  setOpenInfoUnity: Dispatch<SetStateAction<boolean>>;

  selectedUnity: Unity;
  setSelectedUnity: Dispatch<SetStateAction<Unity>>;

  unities: Unity[];
  setUnities: Dispatch<SetStateAction<Unity[]>>;

  loadingUnities: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const UnityContextProvider = ({ children }: IContextProps) => {
  const [DFIDUNIDADE, setDFIDUNIDADE] = useState<string>('');
  const [DFRAZSOCUNIDADE, setDFRAZSOCUNIDADE] = useState<string>('');
  const [DFCNPJCPFCEI, setDFCNPJCPFCEI] = useState<string>('');
  const [DFNOMEFANTASIA, setDFNOMEFANTASIA] = useState<string>('');

  const [loadingUnities, setIsLoadingUnities] = useState<boolean>(false);
  const [openSideModalFilterUnities, setOpenSideModalFilterUnities] =
    useState<boolean>(false);
  const [openInfoUnity, setOpenInfoUnity] = useState<boolean>(false);

  const [selectedUnity, setSelectedUnity] = useState<Unity>({});
  const [unities, setUnities] = useState<Unity[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDUNIDADE = `DFIDUNIDADE=${DFIDUNIDADE}`;
  const RAZSOCUNIDADE = `DFRAZSOCUNIDADE=${DFRAZSOCUNIDADE}`;
  const CNPJCPFCEI = `DFCNPJCPFCEI=${DFCNPJCPFCEI.replace(/[^0-9]/g, '')}`;
  const NOMEFANTASIA = `DFNOMEFANTASIA=${DFNOMEFANTASIA}`;

  const openSideModalFilterUnitiesAction = () => {
    setOpenSideModalFilterUnities(!openSideModalFilterUnities);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingUnities(true);
      META_COLLECT_API.get(
        `/unidade?${Limit}&${Offset}&${IDUNIDADE}&${RAZSOCUNIDADE}&${CNPJCPFCEI}&${NOMEFANTASIA}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setUnities(response.data.unidades);
          setQuantity(response.data.quantidade);
          setIsLoadingUnities(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingUnities(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingUnities(true);
    META_COLLECT_API.get(`/unidade?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setUnities(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingUnities(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingUnities(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDUNIDADE('');
    setDFRAZSOCUNIDADE('');
    setDFCNPJCPFCEI('');
    setDFNOMEFANTASIA('');
    setOpenInfoUnity(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterUnitiesAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterUnitiesAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterUnitiesAction();
    setIsLoadingUnities(true);
    META_COLLECT_API.get(
      `/unidade?${Limit}&offset=0&${IDUNIDADE}&${RAZSOCUNIDADE}&${CNPJCPFCEI}&${NOMEFANTASIA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setUnities(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingUnities(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingUnities(false);
        setStart(1);
      });
  };

  const loadUnitiesAction = () => {
    setIsLoadingUnities(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'unidade') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        openSideModalFilterUnities,
        openSideModalFilterUnitiesAction,
        DFCNPJCPFCEI,
        DFIDUNIDADE,
        DFNOMEFANTASIA,
        DFRAZSOCUNIDADE,
        setDFCNPJCPFCEI,
        setDFIDUNIDADE,
        setDFNOMEFANTASIA,
        setDFRAZSOCUNIDADE,
        cleanAllInputsAction,
        filterUnitiesAction,
        openInfoUnity,
        setOpenInfoUnity,
        selectedUnity,
        setSelectedUnity,
        setUnities,
        unities,
        loadingUnities,
        loadUnitiesAction,
        limit,
        offset,
        page,
        quantity,
        setHasClick,
        setOfsset,
        setPage,
        setStart,
        start,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useUnityContext() {
  const context = useContext(Context);
  return context;
}

export { UnityContextProvider, useUnityContext };
