import React from 'react';

import Modal1 from './Model1';
import Modal2 from './Model2';

const ClosedCollectPrint: React.FC = () => {
  return (
    <>
      <Modal1 />
      {/* <Modal2 /> */}
    </>
  );
};

export default ClosedCollectPrint;
