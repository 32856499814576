import { transparentize } from 'polished';
import styled from 'styled-components';

interface IRegisterCardContainer {
  image: string;
}

export const RegisterCardContainer = styled.div<IRegisterCardContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.GREY_50};
  width: 230px;
  height: 260px;
  padding: 0.6rem;
  border-radius: 0.2rem;
  margin-bottom: 2.6rem;
  transition: 0.3s all;

  .enlarge {
    background-image: ${({ image }) => `url(${image})`};
    width: 100%;
    height: 170px;
    background-size: cover;
    cursor: pointer;
    div {
      width: 100%;
      height: 100%;
      transition: 0.3s all;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: ${({ theme }) =>
          transparentize(0.5, theme.COLORS.BLACK_1000)};
        img {
          width: 30px;
          height: 30px;
        }
      }
      img {
        width: 0;
        height: 0;
        transition: 0.1s ease-in-out;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      margin-left: 0.4rem;
      color: ${({ theme }) => theme.COLORS.GREY_20};
      transition: 0.3s all;
      font-size: 0.9rem;
      @media (max-width: 550px) {
        font-size: 0.7rem;
      }
      /* @media (max-width: 400px) {
        font-size: 0.59rem;
      } */
    }
  }
`;
