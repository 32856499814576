import { SelecteRegister } from '../../../types/SelectedMarker';

interface IValidExistsMarker {
  markers: SelecteRegister[];
}

function checkPriority(
  DFTIPOITEMCOLETA: string,
  DFTIPOREGISTRO: string,
): number {
  const sequences = [
    ['S', 'C'],
    ['S', 'N'],
    ['S', 'O'],
    ['C', 'C'],
    ['C', 'N'],
    ['C', 'O'],
  ];
  for (let i = 0; i < sequences.length; i += 1) {
    const [tipoItemColeta, tipoRegistro] = sequences[i];
    if (
      DFTIPOITEMCOLETA === tipoItemColeta &&
      DFTIPOREGISTRO === tipoRegistro
    ) {
      return i;
    }
  }
  return sequences.length;
}

function validExistsMarker({ markers }: IValidExistsMarker) {
  const filteredMarkers: SelecteRegister[] = [];
  const existingMarkers: { [key: string]: SelecteRegister } = {};
  for (let i = 0; i < markers.length; i += 1) {
    const marker = markers[i];
    const { DFIDITEMCOLETA, DFTIPOITEMCOLETA, DFTIPOREGISTRO, DFLOCALIZACAO } =
      marker;
    let shouldAddRecord = true;
    if (!DFLOCALIZACAO || !DFLOCALIZACAO.coordinates) {
      shouldAddRecord = false;
    } else {
      const existingMarker = DFIDITEMCOLETA
        ? existingMarkers[DFIDITEMCOLETA]
        : false;
      if (existingMarker) {
        const {
          DFTIPOITEMCOLETA: existingTipoItemColeta,
          DFTIPOREGISTRO: existingTipoRegistro,
        } = existingMarker;
        const priority = checkPriority(
          existingTipoItemColeta,
          existingTipoRegistro,
        );
        const newPriority = checkPriority(DFTIPOITEMCOLETA, DFTIPOREGISTRO);
        if (newPriority < priority) {
          const existingIndex = filteredMarkers.indexOf(existingMarker);
          filteredMarkers.splice(existingIndex, 1);
          delete existingMarkers[DFIDITEMCOLETA];
        } else {
          shouldAddRecord = false;
        }
      }
      if (shouldAddRecord) {
        filteredMarkers.push(marker);
        existingMarkers[DFIDITEMCOLETA] = marker;
      }
    }
  }
  return filteredMarkers;
}

export { validExistsMarker };
