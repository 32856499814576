/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import Close from '../../../assets/Close.svg';
import { Container } from './styles';

interface IFilterInputProps {
  title: any;
  type: any;
  value?: any | number;
  onChange: Dispatch<SetStateAction<any | any>>;
  min?: number;
  placeholder?: string;
}

const FilterInput = ({
  min,
  title,
  type,
  value,
  onChange,
  placeholder,
}: IFilterInputProps) => {
  const cleanInputsAction = () => {
    onChange('');
  };
  return (
    <Container>
      <label>{title}</label>
      <div className="container-filter-input">
        <input
          className="form-control"
          placeholder={placeholder}
          min={min}
          type={type}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        <img onClick={cleanInputsAction} src={Close} />
      </div>
    </Container>
  );
};

export { FilterInput };
