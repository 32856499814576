import { Dispatch, SetStateAction } from 'react';

import { CustomSwitch } from '../../../CustomSwitch';
import { PanelConfigurationOptionContainer } from './styles';

interface IPanelConfigurationOption {
  label: string;
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

const PanelConfigurationOption = ({
  label,
  setValue,
  value,
}: IPanelConfigurationOption) => {
  const action = () => {
    setValue(!value);
  };
  return (
    <PanelConfigurationOptionContainer>
      <label>{label}</label>
      <div>
        <CustomSwitch {...{ isOpen: value, action }} />
      </div>
    </PanelConfigurationOptionContainer>
  );
};

export { PanelConfigurationOption };
