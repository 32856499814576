import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IFormAuthSelectContainer {
  openOption: boolean;
  showContent: boolean;
}
interface IFormAuthSelectComponent {
  openOption: boolean;
  istransform?: boolean;
}

export const FormAuthSelectContainer = styled.div<IFormAuthSelectContainer>`
  width: 100%;
  margin-bottom: 1rem;

  .list-options {
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: ${({ openOption }) => (openOption ? `120px` : '0px')};
    background-color: ${({ theme }) =>
      transparentize(0.35, theme.COLORS.GREEN_30)};
    border-radius: 0 0 0.6rem 0.6rem;
    transition: 0.3s all;

    span {
      padding: 0.6rem;
      color: ${({ theme }) => theme.COLORS.WHITE};
      cursor: pointer;
      transition: 0.3s all;
      display: ${({ showContent }) => (showContent ? 'flex' : 'none')};
      align-items: center;
      &:hover {
        background-color: ${({ theme }) =>
          transparentize(0.8, theme.COLORS.GREY_50)};
      }
    }
  }
`;

export const FormAuthSelectComponent = styled.div<IFormAuthSelectComponent>`
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  max-height: 70px;
  border-radius: 0.6rem;
  ${({ openOption }) =>
    openOption &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  padding: 1rem;
  background-color: #238b95;
  transition: 0.3s ease-out;
  z-index: 99;
  position: relative;
  ${({ istransform, openOption }) =>
    istransform &&
    css`
      &:hover {
        transform: ${!openOption && 'scale(1.03)'};
      }
    `}

  svg {
    transition: 0.3s all;
    width: 25px;
    height: 25px;
  }

  .select-options-container {
    transition: 0.3s all;
    width: 90%;
    height: 100%;
    border: 0;
    /* border-left: 1px solid white; */
    padding: 1rem;
    background-color: transparent;
    outline: none;
    color: ${({ theme }) => theme.COLORS.WHITE};
    align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .select {
      cursor: pointer;
      position: relative;
      left: 20px;
      transform: ${({ openOption }) =>
        openOption ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
    svg {
      transition: 0.3s all;
      width: 15px;
      height: 15px;
    }
    p {
      font-size: 1rem;
      color: ${({ theme }) => transparentize(0.6, theme.COLORS.WHITE)};
    }
    @media (max-width: 750px) {
      font-size: 0.8rem;
    }
  }
`;
