/* eslint-disable no-nested-ternary */
import { Buffer } from 'buffer';
import { Dispatch, SetStateAction } from 'react';

import Enlarge from '../../../assets/Enlarge.svg';
import { Register } from '../../../types/Register';
import { formatDate } from '../../../utils/date/formatDate';
import { RegisterCardContainer } from './styles';

interface ILaboratoryCard {
  register: Register;
  // isOpen: boolean;
  // setIsOpen: Dispatch<SetStateAction<boolean>>;
  // setSelectedRegister: Dispatch<SetStateAction<LaboratoryCollectItem | null>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

export const LaboratoryCard = ({
  register,
  setIsOpen,
  setSelectedRegister,
}: ILaboratoryCard) => {
  const { DFDATAREGISTRO, DFTIPOREGISTRO, DFREGISTROIMAGEM, DFHORAREGISTRO } =
    register;
  const buffer =
    DFREGISTROIMAGEM && Buffer.from(DFREGISTROIMAGEM!.data).toString();
  return (
    <RegisterCardContainer image={`data:image/jpeg;base64,${buffer}`}>
      <div
        onClick={() => {
          setIsOpen(true);
          setSelectedRegister(register);
        }}
        className="enlarge"
      >
        <div>
          <img src={Enlarge} />
        </div>
      </div>
      <div className="column">
        <span className="type">
          <b>Tipo: </b>
          {`${
            DFTIPOREGISTRO === 'T'
              ? 'Teperatura'
              : DFTIPOREGISTRO === 'Q'
              ? 'Qualidade'
              : 'Ocorrência'
          }`}
        </span>
        <span className="date">
          <b>Data: </b>
          {formatDate(DFDATAREGISTRO)}
        </span>
        <span className="date">
          <b>Hora: </b>
          {DFHORAREGISTRO}
        </span>
      </div>
    </RegisterCardContainer>
  );
};
