import { useRegionContext } from '../../../context/ProviderPage/Regiao';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import {
  ColumnItens,
  ContainerItemModal,
  RowItens,
} from '../ModalInfoTemplete/styles';

export const InfoRegion = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedRegion } = useRegionContext();
  const { DFDESCREGIONAL, DFIDREGIONAL } = selectedRegion;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <ColumnItens>
        <RowItens>
          <ContainerItemModal>
            <label>Código</label>
            <div>
              <span>{DFIDREGIONAL || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Descrição</label>
            <div>
              <span>{DFDESCREGIONAL || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
      </ColumnItens>
    </ModalInfoTemplete>
  );
};
