import { useEffect } from 'react';

import { useLineContext } from '../../../context/ProviderPage/Linha';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterLines = () => {
  const {
    openSideModalFilterLine,
    openSideModalFilterLineAction,
    cleanAllInputsAction,
    filterLineAction,
    DFIDLINHA,
    setDFIDLINHA,
    DFNOMELINHA,
    setDFNOMELINHA,
    DFATIVO,
    setDFATIVO,
    DFIDREGIONAL,
    setDFIDREGIONAL,
    DFIDCARRETEIRO,
    setDFIDCARRETEIRO,
    DFIDUNIDADE,
    setDFIDUNIDADE,
  } = useLineContext();

  useEffect(() => {
    if (Number(DFIDLINHA) < 0) {
      setDFIDUNIDADE('0');
    }
  }, [DFIDLINHA]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterLine}
      onchange={openSideModalFilterLineAction}
      title="Filtrar Linha"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterLineAction}
    >
      <FilterSelectInput onChange={setDFATIVO} value={DFATIVO} title="Ativo" />
      <FilterInput
        onChange={setDFIDLINHA}
        value={DFIDLINHA}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMELINHA}
        value={DFNOMELINHA}
        title="Linha"
        type="text"
      />
      <FilterInput
        onChange={setDFIDREGIONAL}
        value={DFIDREGIONAL}
        title="Região"
        type="text"
      />
      <FilterInput
        onChange={setDFIDUNIDADE}
        value={DFIDUNIDADE}
        title="Unidade"
        type="text"
      />
      <FilterInput
        onChange={setDFIDCARRETEIRO}
        value={DFIDCARRETEIRO}
        title="Carreteiro"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
