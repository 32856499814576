import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Property } from '../../../types/Property';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterProperty: boolean;
  openSideModalFilterPropertyAction: () => void;

  DFIDPROPRIEDADE: string;
  setDFIDPROPRIEDADE: Dispatch<SetStateAction<string>>;
  DFMATRICULA: string;
  setDFMATRICULA: Dispatch<SetStateAction<string>>;
  DFCNPJCPFCEI: string;
  setDFCNPJCPFCEI: Dispatch<SetStateAction<string>>;
  DFNOMEPRODUTOR: string;
  setDFNOMEPRODUTOR: Dispatch<SetStateAction<string>>;
  DFNOMEPROPRIEDADE: string;
  setDFNOMEPROPRIEDADE: Dispatch<SetStateAction<string>>;
  DFNOMEMUNICIPIO: string;
  setDFNOMEMUNICIPIO: Dispatch<SetStateAction<string>>;
  DFIDSIGLAUF: string;
  setDFIDSIGLAUF: Dispatch<SetStateAction<string>>;
  DFLOGRADOURO: string;
  setDFLOGRADOURO: Dispatch<SetStateAction<string>>;
  DFNUMERO: string;
  setDFNUMERO: Dispatch<SetStateAction<string>>;
  DFATIVOINATIVO: string;
  setDFATIVOINATIVO: Dispatch<SetStateAction<string>>;

  filterPropertyAction: () => void;
  cleanAllInputsAction: () => void;
  loadPropertyAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedProperty: Property;
  setSelectedProperty: Dispatch<SetStateAction<Property>>;

  property: Property[];
  setProperty: Dispatch<SetStateAction<Property[]>>;

  loadingProperty: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const PropertyContextProvider = ({ children }: IContextProps) => {
  const [DFIDPROPRIEDADE, setDFIDPROPRIEDADE] = useState<string>('');
  const [DFMATRICULA, setDFMATRICULA] = useState<string>('');
  const [DFCNPJCPFCEI, setDFCNPJCPFCEI] = useState<string>('');
  const [DFNOMEPRODUTOR, setDFNOMEPRODUTOR] = useState<string>('');
  const [DFNOMEPROPRIEDADE, setDFNOMEPROPRIEDADE] = useState<string>('');
  const [DFNOMEMUNICIPIO, setDFNOMEMUNICIPIO] = useState<string>('');
  const [DFIDSIGLAUF, setDFIDSIGLAUF] = useState<string>('');
  const [DFLOGRADOURO, setDFLOGRADOURO] = useState<string>('');
  const [DFNUMERO, setDFNUMERO] = useState<string>('');
  const [DFATIVOINATIVO, setDFATIVOINATIVO] = useState<string>('');

  const [loadingProperty, setIsLoadingRegion] = useState<boolean>(false);
  const [openSideModalFilterProperty, setOpenSideModalFilterRegion] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedProperty, setSelectedProperty] = useState<Property>({});
  const [property, setProperty] = useState<Property[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const [start, setStart] = useState(1);

  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();

  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDPROPRIEDADE = `DFIDPROPRIEDADE=${DFIDPROPRIEDADE}`;
  const MATRICULA = `DFMATRICULA=${DFMATRICULA}`;
  const CNPJCPFCEI = `DFCNPJCPFCEI=${DFCNPJCPFCEI.replace(/[^0-9]/g, '')}`;
  const NOMEPRODUTOR = `DFNOMEPRODUTOR=${DFNOMEPRODUTOR}`;
  const NOMEPROPRIEDADE = `DFNOMEPROPRIEDADE=${DFNOMEPROPRIEDADE}`;
  const NOMEMUNICIPIO = `DFNOMEMUNICIPIO=${DFNOMEMUNICIPIO}`;
  const IDSIGLAUF = `DFIDSIGLAUF=${DFIDSIGLAUF}`;
  const LOGRADOURO = `DFLOGRADOURO=${DFLOGRADOURO}`;
  const NUMERO = `DFNUMERO=${DFNUMERO}`;
  const ATIVOINATIVO = `DFATIVOINATIVO=${DFATIVOINATIVO}`;

  const openSideModalFilterPropertyAction = () => {
    setOpenSideModalFilterRegion(!openSideModalFilterProperty);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingRegion(true);
      META_COLLECT_API.get(
        `/propriedade?${Limit}&${Offset}&${IDPROPRIEDADE}&${MATRICULA}&${CNPJCPFCEI}&${NOMEPRODUTOR}&${NOMEPROPRIEDADE}&${NOMEMUNICIPIO}&${IDSIGLAUF}&${LOGRADOURO}&${NUMERO}&${ATIVOINATIVO}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setProperty(response.data.propriedades);
          setQuantity(response.data.quantidade);
          setIsLoadingRegion(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingRegion(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingRegion(true);
    META_COLLECT_API.get(`/propriedade?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setProperty(response.data.propriedades);
        setQuantity(response.data.quantidade);
        setIsLoadingRegion(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingRegion(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDPROPRIEDADE('');
    setDFMATRICULA('');
    setDFCNPJCPFCEI('');
    setDFNOMEPRODUTOR('');
    setDFNOMEPROPRIEDADE('');
    setDFNOMEMUNICIPIO('');
    setDFIDSIGLAUF('');
    setDFLOGRADOURO('');
    setDFNUMERO('');
    setDFATIVOINATIVO('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterPropertyAction();
    loadUnitsActionWithParams();
    setStart(1);
    setPage(1);
  };

  const filterPropertyAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterPropertyAction();
    setIsLoadingRegion(true);
    META_COLLECT_API.get(
      `/propriedade?${Limit}&offset=0&${IDPROPRIEDADE}&${MATRICULA}&${CNPJCPFCEI}&${NOMEPRODUTOR}&${NOMEPROPRIEDADE}&${NOMEMUNICIPIO}&${IDSIGLAUF}&${LOGRADOURO}&${NUMERO}&${ATIVOINATIVO}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setProperty(response.data.propriedades);
        setQuantity(response.data.quantidade);
        setIsLoadingRegion(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingRegion(false);
        setStart(1);
      });
  };

  const loadPropertyAction = () => {
    setIsLoadingRegion(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'propriedade') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        filterPropertyAction,
        loadPropertyAction,
        limit,
        loadingProperty,
        offset,
        openInfo,
        openSideModalFilterProperty,
        openSideModalFilterPropertyAction,
        page,
        quantity,
        selectedProperty,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setProperty,
        setSelectedProperty,
        DFATIVOINATIVO,
        DFCNPJCPFCEI,
        DFIDPROPRIEDADE,
        DFIDSIGLAUF,
        DFLOGRADOURO,
        DFMATRICULA,
        DFNOMEMUNICIPIO,
        DFNOMEPRODUTOR,
        DFNOMEPROPRIEDADE,
        DFNUMERO,
        property,
        setDFATIVOINATIVO,
        setDFCNPJCPFCEI,
        setDFIDPROPRIEDADE,
        setDFIDSIGLAUF,
        setDFLOGRADOURO,
        setDFMATRICULA,
        setDFNOMEMUNICIPIO,
        setDFNOMEPRODUTOR,
        setDFNOMEPROPRIEDADE,
        setDFNUMERO,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function usePropertyContext() {
  const context = useContext(Context);
  return context;
}

export { PropertyContextProvider, usePropertyContext };
