import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { Unity } from '../../../types/Unity';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListUnities {
  data: Unity[];
}

export const ListUnities = ({ data }: IListUnities) => {
  const { setOpenInfoUnity, setSelectedUnity } = useUnityContext();

  const handleSecetUnity = (unity: Unity) => {
    setSelectedUnity(unity);
    setOpenInfoUnity(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Código</th>
            <th className="list-th">Razão social</th>
            <th className="list-th">Nome fantasia</th>
            <th className="list-th">CNPJ/CPF</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((unity, index) => {
            const {
              DFCNPJCPFCEI,
              DFIDUNIDADE,
              DFNOMEFANTASIA,
              DFRAZSOCUNIDADE,
            } = unity;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSecetUnity(unity)}
              >
                <td className="list-td-small">
                  {DFIDUNIDADE || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFRAZSOCUNIDADE || 'Não informado'}
                </td>
                <td style={{ whiteSpace: 'pre' }} className="list-td-large">
                  {DFNOMEFANTASIA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}
                </td>
                <td className="list-td-small">
                  <div>
                    <ListButton
                      {...{
                        action: () => handleSecetUnity(unity),
                        icon: 'search',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
