import moment from 'moment';

const deformatDate = (date: string) => {
  if (date) {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    return formatedDate;
  }
  return '';
};

export { deformatDate };
