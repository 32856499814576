/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import ArrowDown from '../../../assets/ArrowDown.svg';
import Close from '../../../assets/Close.svg';
import { Container } from './styles';

interface IFilterInputProps {
  title: any;
  value?: any | number;
  onChange: Dispatch<SetStateAction<any | any>>;
  placeholder?: string;
}

const FilterSelectInput = ({ title, value, onChange }: IFilterInputProps) => {
  const cleanInputsAction = () => {
    onChange('');
  };

  const [openOption, setOpenOption] = useState<boolean>(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openOption) {
      setTimeout(() => {
        setShowContent(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 0);
    }
  }, [openOption]);

  const selecOption = (option: string) => {
    onChange(option);
    setOpenOption(false);
  };

  return (
    <Container openOption={openOption} timedOpenOption={showContent}>
      <label>{title}</label>
      <div className="container-filter-input">
        <div className="filter-select">
          <span>
            {`${
              value === 'S' ? 'Sim' : value === 'N' ? 'Não' : 'Selecionar opção'
            }`}
          </span>
          <img
            className="button-open-options"
            src={ArrowDown}
            onClick={() => setOpenOption(!openOption)}
          />
        </div>
        <img className="button-clear" onClick={cleanInputsAction} src={Close} />
      </div>
      <div className="list-options">
        <span onClick={() => selecOption('S')}>Sim</span>
        <span onClick={() => selecOption('N')}>Não</span>
      </div>
    </Container>
  );
};

export { FilterSelectInput };
