import { useState } from 'react';

import { PageHeader } from '../../../../components/PageHeader';
import { ContainerPage, PageContent } from '../../../../styles/global.styles';
import { ReleaseCollectTankProducer } from '../../../../types/ReleaseCollect';
import { ListReleaseCollectSelectedTankProducers } from './components/ListReleaseCollectSelectedTankProducers';
import { ListSelectedReleasedCollectTanks } from './components/ListSelectedReleasedCollectTanks';

const SelectedReleasedCollect = () => {
  const [selectTank, setSelectTank] = useState<ReleaseCollectTankProducer>({});

  return (
    <>
      <PageHeader pageIcon={{ option: 'liberar' }} pageTitle="A liberar" />
      <ContainerPage>
        <PageContent>
          {selectTank.DFIDITEMCOLETA ? (
            <ListReleaseCollectSelectedTankProducers
              {...{
                setSelectTank,
                producers: selectTank.DFPRODUTORESDACOLETA!,
              }}
            />
          ) : (
            <ListSelectedReleasedCollectTanks {...{ setSelectTank }} />
          )}
        </PageContent>
      </ContainerPage>
    </>
  );
};

export default SelectedReleasedCollect;
