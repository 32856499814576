import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { StorageBondToCollect } from '../../../types/StorageBondToCollect';
import { TankRelease } from '../../../types/TankRelease';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface ILoadStorageProducers {
  idtank: string;
  setStorageProducer: Dispatch<SetStateAction<StorageBondToCollect[]>>;
}

interface IContext {
  openSideModalFilterTankToRelease: boolean;
  openSideModalFilterTankToReleaseAction: () => void;

  DFIDTANQUE: string;
  setDFIDTANQUE: Dispatch<SetStateAction<string>>;
  DFDESCTANQUE: string;
  setDFDESCTANQUE: Dispatch<SetStateAction<string>>;
  DFIDLINHA: string;
  setDFIDLINHA: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFCOLETASELETIVA: string;
  setDFCOLETASELETIVA: Dispatch<SetStateAction<string>>;

  filterTankToReleaseAction: () => void;
  cleanAllInputsAction: () => void;
  loadTankToReleaseAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedTankToRelease: TankRelease | undefined;
  setSelectedTankToRelease: Dispatch<SetStateAction<TankRelease | undefined>>;

  tankToRelease: TankRelease[];
  setTankToRelease: Dispatch<SetStateAction<TankRelease[]>>;

  loadingTankToRelease: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;

  selectedStorageProducerToRelease: StorageBondToCollect | undefined;
  setSelectedStorageProducerToRelease: Dispatch<
    SetStateAction<StorageBondToCollect | undefined>
  >;
  loadingStorageProducer: boolean;
  loadStorageProducers: ({
    idtank,
    setStorageProducer,
  }: ILoadStorageProducers) => void;
}

const Context = createContext({} as IContext);

const TankToReleaseContextProvider = ({ children }: IContextProps) => {
  const [DFIDTANQUE, setDFIDTANQUE] = useState<string>('');
  const [DFDESCTANQUE, setDFDESCTANQUE] = useState<string>('');
  const [DFIDLINHA, setDFIDLINHA] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFCOLETASELETIVA, setDFCOLETASELETIVA] = useState<string>('');

  const [loadingTankToRelease, setIsLoadingTankToRelease] =
    useState<boolean>(true);
  const [loadingStorageProducer, setLoadingStorageProducer] =
    useState<boolean>(true);
  const [openSideModalFilterTankToRelease, setOpenSideModalFilterTank] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [selectedTankToRelease, setSelectedTankToRelease] = useState<
    TankRelease | undefined
  >();
  const [
    selectedStorageProducerToRelease,
    setSelectedStorageProducerToRelease,
  ] = useState<StorageBondToCollect | undefined>(undefined);
  const [tankToRelease, setTankToRelease] = useState<TankRelease[]>([]);
  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDTANQUE = `DFIDTANQUE=${DFIDTANQUE}`;
  const DESCTANQUE = `DFDESCTANQUE=${DFDESCTANQUE}`;
  const IDLINHA = `DFIDLINHA=${DFIDLINHA}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const COLETASELETIVA = `DFCOLETASELETIVA=${DFCOLETASELETIVA}`;

  const openSideModalFilterTankToReleaseAction = () => {
    setOpenSideModalFilterTank(!openSideModalFilterTankToRelease);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingTankToRelease(true);
      META_COLLECT_API.get(
        `/tanque/a-liberar?${Limit}&${Offset}&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setTankToRelease(response.data.tanques);
          setQuantity(response.data.quantidade);
          setIsLoadingTankToRelease(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingTankToRelease(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadTankActionWithParams = () => {
    setIsLoadingTankToRelease(true);
    META_COLLECT_API.get(`/tanque/a-liberar?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setTankToRelease(response.data.tanques);
        setQuantity(response.data.quantidade);
        setIsLoadingTankToRelease(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingTankToRelease(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFATIVO('');
    setDFCOLETASELETIVA('');
    setDFDESCTANQUE('');
    setDFIDLINHA('');
    setDFIDTANQUE('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterTankToReleaseAction();
    loadTankActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterTankToReleaseAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterTankToReleaseAction();
    setIsLoadingTankToRelease(true);
    META_COLLECT_API.get(
      `/tanque/a-liberar?${Limit}&offset=0&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setTankToRelease(response.data.tanques);
        setQuantity(response.data.quantidade);
        setIsLoadingTankToRelease(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingTankToRelease(false);
        setStart(1);
      });
  };

  const loadTankToReleaseAction = () => {
    setIsLoadingTankToRelease(true);
    loadTankActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'vincular') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  const loadStorageProducers = ({
    idtank,
    setStorageProducer,
  }: ILoadStorageProducers) => {
    if (idtank) {
      setLoadingStorageProducer(true);
      META_COLLECT_API.get(
        `/tanque/a-liberar/armazenamentos?DFIDTANQUE=${idtank}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(res => {
          setStorageProducer(res.data.armazenamentos);
          setLoadingStorageProducer(false);
        })
        .catch(() => {
          setLoadingStorageProducer(false);
        });
    }
  };

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFATIVO,
        DFCOLETASELETIVA,
        DFDESCTANQUE,
        DFIDLINHA,
        DFIDTANQUE,
        filterTankToReleaseAction,
        loadTankToReleaseAction,
        limit,
        loadingTankToRelease,
        offset,
        openInfo,
        openSideModalFilterTankToRelease,
        openSideModalFilterTankToReleaseAction,
        page,
        quantity,
        selectedTankToRelease,
        setSelectedTankToRelease,
        setDFATIVO,
        setDFCOLETASELETIVA,
        setDFDESCTANQUE,
        setDFIDLINHA,
        setDFIDTANQUE,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setTankToRelease,
        tankToRelease,
        start,
        setStart,
        selectedStorageProducerToRelease,
        setSelectedStorageProducerToRelease,
        loadingStorageProducer,
        loadStorageProducers,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useTankToReleaseContextProvider() {
  const context = useContext(Context);
  return context;
}

export { TankToReleaseContextProvider, useTankToReleaseContextProvider };
