import styled from 'styled-components';

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    text-align: center;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }
`;
