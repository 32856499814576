import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { DischargeSilo } from '../../../types/DischargeSilo';
import { MouthTank } from '../../../types/MouthTank';
import { User } from '../../../types/User';
import { compareTwoDates } from '../../../utils/date/compareTwoDates';
import { getCurrentDate } from '../../../utils/date/getCurrentDate';
import { getCurrentDateTime } from '../../../utils/date/getCurrentDateTime';

interface IHandleDeschargeCollet {
  discharge: {
    path: string;
    startDate: string;
    startHour: string;
    endDate: string;
    endHour: string;
    setLoadingDischargeCollect: Dispatch<SetStateAction<boolean>>;
    dischargeSilo: DischargeSilo[];
    tankMouths: MouthTank[];
    DFIDCOLETA: number;
    LATITUDE: number;
    LONGITUDE: number;
    vehicleLeak: string;
    externalCleaning: string;
    wagonerCleaning: string;
    observation: string;
    acidityTemperature: string;
    acidityStart: string;
    acidityEnd: string;
    alkalineTemperature: string;
    alkalineStart: string;
    alkalineEnd: string;
    DFIDUSUARIO: number;
    acidityStartHour: string;
    acidityEndHour: string;
    alkalineStartHour: string;
    alkalineEndHour: string;
    totalPlataform: string;
    vehicleArrival: string;
    user: User;
  };
}

interface IExecuteCountTotalPlataform {
  dischargeSilo: DischargeSilo[];
  setTotalPlataform: Dispatch<SetStateAction<string>>;
}

const stopLoadingAndNotify = (
  setLoadingDischargeCollect: Dispatch<SetStateAction<boolean>>,
) => {
  setLoadingDischargeCollect(false);
  return toast.error(
    'Ocorreu um erro inesperado, tente novamente dentro de instantes!',
  );
};

const handleDeschargeCollet = ({ discharge }: IHandleDeschargeCollet) => {
  const {
    path,
    endDate,
    endHour,
    startDate,
    startHour,
    setLoadingDischargeCollect,
    dischargeSilo,
    tankMouths,
    DFIDCOLETA,
    LATITUDE,
    LONGITUDE,
    vehicleLeak,
    externalCleaning,
    wagonerCleaning,
    observation,
    acidityTemperature,
    acidityStart,
    acidityEnd,
    alkalineTemperature,
    alkalineStart,
    alkalineEnd,
    DFIDUSUARIO,
    acidityEndHour,
    acidityStartHour,
    alkalineEndHour,
    alkalineStartHour,
    totalPlataform,
    vehicleArrival,
    user,
  } = discharge;
  setLoadingDischargeCollect(true);
  const { DFCONFIGURACAOMOTORISTA, DFCONFIGURACAOCIP } = user!;

  if (DFCONFIGURACAOMOTORISTA === 'S') {
    if (
      !vehicleArrival ||
      !vehicleLeak ||
      !externalCleaning ||
      !wagonerCleaning
    ) {
      setLoadingDischargeCollect(false);
      return toast.error(
        'Atenção, você precisa preencher os campos das informações do motorista!',
      );
    }
  }

  if (DFCONFIGURACAOCIP === 'S') {
    if (
      !acidityTemperature ||
      !acidityStart ||
      !acidityEnd ||
      !alkalineTemperature ||
      !alkalineStart ||
      !alkalineEnd ||
      !acidityEndHour ||
      !acidityStartHour ||
      !alkalineEndHour ||
      !alkalineStartHour
    ) {
      setLoadingDischargeCollect(false);
      return toast.error(
        'Atenção, você precisa preencher os campos das informações do CIP!',
      );
    }
    const validAcidityDate = compareTwoDates({
      firstDate: acidityStart,
      firstTime: acidityStartHour,
      secondDate: acidityEnd,
      secondTime: acidityEndHour,
    });
    if (validAcidityDate.status === true) {
      setLoadingDischargeCollect(false);
      return toast.error(validAcidityDate.message);
    }
    const validAlkalineDate = compareTwoDates({
      firstDate: alkalineStart,
      firstTime: alkalineStartHour,
      secondDate: alkalineEnd,
      secondTime: alkalineEndHour,
    });
    if (validAlkalineDate.status === true) {
      setLoadingDischargeCollect(false);
      return toast.error(validAlkalineDate.message);
    }
  }

  if (!startDate || !startHour || !endDate || !endHour) {
    setLoadingDischargeCollect(false);
    return toast.error(
      'Atenção, você precisa preencher os campos das informações da Descarga!',
    );
  }

  const validDischargeDate = compareTwoDates({
    firstDate: startDate,
    firstTime: startHour,
    secondDate: endDate,
    secondTime: endHour,
  });
  if (validDischargeDate.status === true) {
    setLoadingDischargeCollect(false);
    return toast.error(validDischargeDate.message);
  }

  if (dischargeSilo.length === 0) {
    setLoadingDischargeCollect(false);
    return toast.error('Atenção, você não possui nenhum silo para descarga!');
  }

  let CTDFTEMPERATURA = 0;
  for (const mouth in tankMouths) {
    if (tankMouths[mouth].DFTEMPERATURA) {
      CTDFTEMPERATURA += 1;
    }
  }
  if (CTDFTEMPERATURA === 0) {
    setLoadingDischargeCollect(false);
    return toast.error(
      'Atenção, você não informou nenhuma temperatura das bocas!',
    );
  }

  const headers = {
    headers: {
      path,
    },
  };

  META_COLLECT_API.post(
    '/descarga/criar',
    [
      {
        DFVAZAMENTO: vehicleLeak || null,
        DFLIMPEZAEXTERNA: externalCleaning || null,
        DFHIGIENEMOTORISTA: wagonerCleaning || null,
        DFOBSERVACAO: observation || null,
        DFTEMPERATURAACIDEZ: acidityTemperature || null,
        DFDATAINICIOACIDEZ: acidityStart || null,
        DFDATAFIMACIDEZ: acidityEnd || null,
        DFTEMPERATURAALCALINA: alkalineTemperature || null,
        DFDATAINICIOALCALINA: alkalineStart || null,
        DFDATAFIMALCALINA: alkalineEnd || null,
        DFHORAFIMALCALINA: alkalineEndHour || null,
        DFHORAINICIOALCALINA: alkalineStartHour || null,
        DFHORAFIMACIDEZ: acidityEndHour || null,
        DFHORAINICIOACIDEZ: acidityStartHour || null,
        DFHORACHEGADA: vehicleArrival || null,
        DFIDUSUARIO,
        DFIDCOLETA,
        DFPLATAFORMA: totalPlataform,
      },
    ],
    {
      headers: {
        path,
      },
    },
  )
    .then(res => {
      for (const id in res.data.ids) {
        const DFIDDESCARGA = res.data.ids[id];
        tankMouths.forEach(objt => {
          objt.DFIDDESCARGA = DFIDDESCARGA;
        });
        META_COLLECT_API.post('/descarga/boca/criar', tankMouths, headers)
          .then(() => {
            const formatedDischargeSilo = [];
            for (const item in dischargeSilo) {
              const { DFIDCOLETA, date, hour, silo, volume } =
                dischargeSilo[item];
              const { DFIDSILO } = silo;
              formatedDischargeSilo.push({
                DFIDCOLETA,
                DFDATADESCARGA: date,
                DFIDSILO,
                DFHORADESCARGA: hour,
                DFVOLUME: volume,
                DFIDDESCARGA,
              });
            }
            META_COLLECT_API.post(
              '/descarga/silo/criar',
              formatedDischargeSilo,
              headers,
            )
              .then(() => {
                META_COLLECT_API.patch(
                  '/coleta/descarregar/painel',
                  {
                    DFIDCOLETA,
                    DFSTATUS: 'F',
                    DFDATAINICIODESCARGA: startDate,
                    DFDATAFIMDESCARGA: endDate,
                    DFHORAINICIODESCARGA: startHour,
                    DFHORAFIMDESCARGA: endHour,
                  },
                  {
                    headers: {
                      path,
                    },
                  },
                )
                  .then(() => {
                    META_COLLECT_API.post(
                      '/registro/painel/criar',
                      {
                        DFIDCOLETA,
                        DFDATAREGISTRO: getCurrentDate(),
                        DFHORAREGISTRO: getCurrentDateTime(),
                        DFTIPOREGISTRO: 'F',
                        DFOBSERVACAO: 'Fim da coleta',
                        DFLONGITUDE: LONGITUDE,
                        DFLATITUDE: LATITUDE,
                      },
                      headers,
                    )
                      .then(() => {
                        setLoadingDischargeCollect(false);
                        window.history.back();
                        return toast.success('Descarga realizada com sucesso!');
                      })
                      .catch(() => {
                        stopLoadingAndNotify(setLoadingDischargeCollect);
                      });
                  })
                  .catch(() => {
                    stopLoadingAndNotify(setLoadingDischargeCollect);
                  });
              })
              .catch(() => {
                stopLoadingAndNotify(setLoadingDischargeCollect);
              });
          })
          .catch(() => {
            stopLoadingAndNotify(setLoadingDischargeCollect);
          });
      }
    })
    .catch(() => {
      stopLoadingAndNotify(setLoadingDischargeCollect);
    });
  return null;
};

const executeCountTotalPlataform = ({
  dischargeSilo,
  setTotalPlataform,
}: IExecuteCountTotalPlataform) => {
  const totalPlataform = dischargeSilo.reduce((acumulador, silo) => {
    return acumulador + silo.volume;
  }, 0);
  setTotalPlataform(totalPlataform.toString());
};

export { handleDeschargeCollet, executeCountTotalPlataform };
