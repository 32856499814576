import styled from 'styled-components';

export const CloseAndOpenMenuMobileContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_30};
  display: none;
  transition: 0.3s all;
  img {
    transition: 0.3s all;
  }
  @media (max-width: 650px) {
    display: flex;
  }
  @media (max-width: 300px) {
    width: 25px;
    height: 25px;
    img {
      width: 13px;
      height: 13px;
    }
  }
`;
