import { useState } from 'react';
import { LiaClipboardListSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Calendar } from '../../../assets/collectcard/calendar.svg';
import { ReactComponent as Line } from '../../../assets/collectcard/line.svg';
import { ReactComponent as Milk } from '../../../assets/collectcard/milk.svg';
import { ReactComponent as Vehicle } from '../../../assets/collectcard/vehicle.svg';
import { ReactComponent as Wagoner } from '../../../assets/collectcard/wagoner.svg';
import { useCollectContext } from '../../../context/ProviderPage/Coleta';
import { usePageContext } from '../../../context/usePage';
import { OpenCollect } from '../../../types/OpenCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { Counter } from '../../Contador';
import { OpenCollectCardContainer, CollectMilkInformation } from './styles';

const OpenCollectCard = (collect: OpenCollect) => {
  const {
    DFIDCOLETA,
    DFORDEMCOLETA,
    DFNOMECARRETEIRO,
    DFPLACAVEICULO,
    DFNOMELINHA,
    DFDATAPROGRAMADA,
    DFDATACOLETA,
    DFTOTALARMAZENADO,
    DFCAPACIDADETOTALVEICULO,
  } = collect;
  const [activeMeter, setActiveMeter] = useState(false);
  const FDFTOTALARMAZENADO = Number(DFTOTALARMAZENADO) || 0;
  const resultMeter =
    (FDFTOTALARMAZENADO / Number(DFCAPACIDADETOTALVEICULO)) * 100;
  const meter = resultMeter > 100 ? 100 : resultMeter;
  const navigation = useNavigate();
  const { version } = usePageContext();
  const { setCloseCollect } = useCollectContext();

  setTimeout(() => {
    setActiveMeter(true);
  }, 100);

  return (
    <OpenCollectCardContainer>
      <div className="card-title">
        <Wagoner />
        <h1>{DFNOMECARRETEIRO}</h1>
      </div>
      <div className="collect-item-row">
        <div className="item-row-medium-with-border">
          <LiaClipboardListSolid />
          <span>Ordem Coleta: {DFORDEMCOLETA}</span>
        </div>
        <div className="item-row-medium">
          <Vehicle />
          <span>Veículo: {DFPLACAVEICULO}</span>
        </div>
      </div>
      <div className="collect-item-row">
        <div className="item-row">
          <Line />
          <span>Linha: {DFNOMELINHA}</span>
        </div>
      </div>
      <div className="collect-item-row">
        <div className="item-row-medium-with-border">
          <Calendar />
          <span>Data Programada: {formatDate(DFDATAPROGRAMADA)}</span>
        </div>
        <div className="item-row-medium">
          <Calendar />
          <span>Data Coleta: {formatDate(DFDATACOLETA)}</span>
        </div>
      </div>
      <CollectMilkInformation {...{ meter, activeMeter }}>
        <div className="milk-title-information ">
          <Milk />
          <span>Quantidade coletada</span>
        </div>
        <div className="item-column">
          <div className="item-row">
            <span>
              <b>Coletado: </b>
              {/* <span>{FDFTOTALARMAZENADO}</span> */}
              <Counter finalNumber={Number(FDFTOTALARMAZENADO)} />
            </span>
            <span>
              <b>Total: </b>
              {/* <span>{DFCAPACIDADETOTALVEICULO}</span> */}
              <Counter finalNumber={Number(DFCAPACIDADETOTALVEICULO)} />
            </span>
          </div>
          <div className="progress-milk-content">
            <div className="progress-milk-meter" />
          </div>
        </div>
      </CollectMilkInformation>
      <footer className="card-footer">
        <button
          className="visualize"
          onClick={() =>
            navigation(`/coleta/${DFIDCOLETA}/acompanhar${version}`)
          }
        >
          Acompanhar
        </button>
        <button
          className="close-collect"
          onClick={() =>
            setCloseCollect({
              isOpen: true,
              collect,
            })
          }
        >
          Encerrar
        </button>
      </footer>
    </OpenCollectCardContainer>
  );
};

export { OpenCollectCard };
