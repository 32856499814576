import styled from 'styled-components';

export const ListLaboratoryItensContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 270px 270px 270px 270px;
  flex-flow: row wrap;
  overflow-y: auto;
  padding: 0 3rem;
  transition: 0.3s all;

  @media (max-width: 750px) {
    flex-flow: column;
    padding: 0 0.6rem;
  }
`;
