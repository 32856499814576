import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppVersion } from '../../../../../types/AppVersion';
import Marca from '../../../assets/copy1.png';
import { usePageContext } from '../../../context/usePage';
import { ContainerMeta } from './styles';

export const Redirect: FC<AppVersion> = ({ version }) => {
  const navigate = useNavigate();
  const { setVersion } = usePageContext();
  useEffect(() => {
    setVersion(version);
    navigate(`/coleta${version}`);
  }, []);

  return (
    <ContainerMeta>
      <a href="https://www.webmeta.com.br" target="_blank">
        <img src={Marca} />
      </a>
    </ContainerMeta>
  );
};
