import { Dispatch, SetStateAction } from 'react';

import { ContainerSmallButtonIcon } from './styles';

interface ICloseAndOpenMenu {
  size: number;
  icon?: string;
  value: boolean;
  action: Dispatch<SetStateAction<boolean>>;
}

const CloseAndOpenMenu = ({ size, icon, action, value }: ICloseAndOpenMenu) => {
  return (
    <ContainerSmallButtonIcon
      {...{
        onClick: () => action(!value),
        size,
        value,
      }}
    >
      {icon && <img src={icon} />}
      {/* <ButtonTextLight>
        {value === false ? 'Recolher' : 'Abrir'}
      </ButtonTextLight> */}
    </ContainerSmallButtonIcon>
  );
};

export { CloseAndOpenMenu };
