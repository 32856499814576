import { darken, transparentize } from 'polished';
import styled from 'styled-components';

interface IModal {
  open: boolean;
  width?: number;
}
interface IShowModal {
  showContentFilter?: boolean;
}

export const Model = {
  Container: styled.div<IModal>`
    height: 100%;
    width: 100%;
    z-index: ${({ open }) => (open ? 120 : -1)};
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    background-color: ${({ open, theme }) =>
      open && transparentize(0.4, theme.COLORS.BLACK_1000)};
  `,

  Secondary: styled.div<IModal>`
    /* width: ${({ open }) => (open ? '20%' : 0)};
    background-color: ${darken(0.15, '#cdcdcd')};
    height: calc(20% - 30px);
    transition: 0.3s all; */
    width: 80%;
    height: 100%;
    background-color: grey;
    overflow-y: auto;
  `,

  List: styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  `,

  Paper: styled.div`
    align-self: center;
    width: 65%;
    height: 1200px;
    margin-top: 30px;
    background-color: white;
    page-break-before: always; /* Adicione uma quebra de página antes de cada Paper */
  `,

  Header: styled.div`
    border: 3px solid black;
    width: 90%;
    height: 200px;
  `,
};

export const PrintStyles = styled.div`
  @media print {
    /* Add print-specific styles here */
    ${Model.Header} {
      border: 3px solid black;
    }
  }
`;
