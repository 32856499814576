/* eslint-disable no-nested-ternary */
import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface ICollectMilkInformation {
  meter: number;
  activeMeter: boolean;
}

const OpenCollectCardContainer = styled.div`
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  /* min-height: 300px; */
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
  svg {
    width: 15px;
    height: 15px;
    margin-right: 0.4rem;
  }
  span {
    font-size: 0.8rem;
  }
  .card-footer {
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .visualize {
      background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    }
    .close-collect {
      background-color: ${({ theme }) => theme.COLORS.METER_66};
    }
    button {
      transition: 0.3s all;
      border: 0;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.7rem;
      color: white;
      font-weight: 600;
      font-size: 0.8rem;
      margin-left: 0.2rem;
      svg {
        color: white;
      }
      span {
        color: white;
        font-weight: 600;
        font-size: 0.8rem;
        margin-left: 0.2rem;
      }
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .card-title {
    min-height: 55px;
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.COLORS.GREY_50)};
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      font-size: 0.9rem;
      color: ${({ theme }) => darken(0.45, theme.COLORS.GREY_50)};
    }
  }

  .collect-item-row {
    min-height: 35px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};

    .item-row {
      padding: 0.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .item-row-medium-with-border {
      padding: 0.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
      border-right: 1px solid
        ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
    }
    .item-row-medium {
      padding: 0.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
    }
  }
  .collect-item-footer {
  }
`;

const CollectMilkInformation = styled.div<ICollectMilkInformation>`
  min-height: 35px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.4, theme.COLORS.GREY_50)};
  .item-row {
    width: 100%;
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .item-column {
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
  }

  .milk-title-information {
    width: 100%;
    padding: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.COLORS.GREY_50)};
  }

  .progress-milk-content {
    transition: 0.3s all;
    height: 20px;
    width: 100%;
    /* padding: 2px; */
    border-radius: 10px;
    border: 2px solid
      ${({ theme }) => transparentize(0.4, theme.COLORS.GREEN_30)};
    background-color: ${({ theme }) =>
      transparentize(0.4, theme.COLORS.GREEN_30)};
    .progress-milk-meter {
      height: 100%;
      transition: 0.9s all;
      ${({ meter, activeMeter }) =>
        meter &&
        css`
          transition: 1s all;
          border-radius: 10px;
          width: ${activeMeter ? meter : 0}%;
        `}
      background-color:  ${({ theme }) => theme.COLORS.WHITE};
    }
  }
  .milk-total {
    align-self: flex-end;
  }
`;

export { CollectMilkInformation, OpenCollectCardContainer };
