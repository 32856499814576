import { useExcludedCollectContext } from '../../../context/ProviderPage/Excluida';
import { formatDate } from '../../../utils/date/formatDate';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import {
  ContainerFullItemModal,
  ContainerItemModal,
  RowItens,
} from '../ModalInfoTemplete/styles';

export const InfoExcludedCollect = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedExcludedCollect } = useExcludedCollectContext();
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {selectedExcludedCollect && (
        <>
          <RowItens>
            <ContainerItemModal>
              <label>Código</label>
              <div>
                <span>
                  {selectedExcludedCollect.DFIDCOLETA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Carreteiro</label>
              <div>
                <span>
                  {selectedExcludedCollect.DFNOMECARRETEIRO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Região</label>
              <div>
                <span>
                  {selectedExcludedCollect.DFDESCREGIONAL || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Linha</label>
              <div>
                <span>
                  {selectedExcludedCollect.DFNOMELINHA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Data Coleta</label>
              <div>
                <span>
                  {formatDate(selectedExcludedCollect.DFDATAEXCLUSAOCOLETA) ||
                    'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerFullItemModal>
              <label>Data Coleta</label>
              <div>
                <span>
                  {selectedExcludedCollect.DFJUSTIFICATIVA || 'Não informado'}
                </span>
              </div>
            </ContainerFullItemModal>
          </RowItens>
        </>
      )}
    </ModalInfoTemplete>
  );
};
