import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoUnity = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedUnity } = useUnityContext();
  const { DFCNPJCPFCEI, DFIDUNIDADE, DFNOMEFANTASIA, DFRAZSOCUNIDADE } =
    selectedUnity;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <RowItens>
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>{DFIDUNIDADE || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Razão social</label>
          <div>
            <span>{DFRAZSOCUNIDADE || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Nome fantasia</label>
          <div>
            <span>{DFNOMEFANTASIA || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>CNPJ/CPF</label>
          <div>
            <span>{formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
    </ModalInfoTemplete>
  );
};
