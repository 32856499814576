import { useVehicleContext } from '../../../context/ProviderPage/Veiculo';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoVehicle = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedVehicle } = useVehicleContext();
  const { DFATIVO, DFDESCVEICULO, DFIDVEICULO, DFPLACAVEICULO } =
    selectedVehicle;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <RowItens>
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>{DFIDVEICULO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Placa</label>
          <div>
            <span>{DFPLACAVEICULO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Descrição</label>
          <div>
            <span>{DFDESCVEICULO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Ativo</label>
          <img src={returnCheckType(DFATIVO!)} />
        </ContainerItemModal>
      </RowItens>
    </ModalInfoTemplete>
  );
};
