/* eslint-disable one-var */
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { OpenCollect } from '../../../types/OpenCollect';
import { getCurrentDate } from '../../../utils/date/getCurrentDate';
import { getCurrentDateTime } from '../../../utils/date/getCurrentDateTime';

interface IHandleCloseCollect {
  collect: OpenCollect | null;
  setLoadingAndNotify: Dispatch<SetStateAction<boolean>>;
  path: string;
  loadCollectWithInterval: () => void;
  closeModal: () => void;
}

const handleCloseCollect = ({
  collect,
  setLoadingAndNotify,
  path,
  loadCollectWithInterval,
  closeModal,
}: IHandleCloseCollect) => {
  let DFLATITUDE: number, DFLONGITUDE: number;
  setLoadingAndNotify(true);

  const error = () => {
    setLoadingAndNotify(false);
    return toast.error(
      'Houve um erro durante a operação, tente novamente dentro de instantes!',
    );
  };

  const headers = {
    headers: {
      path,
    },
  };

  const { DFIDCOLETA } = collect! || 0;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        DFLATITUDE = latitude;
        DFLONGITUDE = longitude;
      },
      () => {
        return toast.error(
          'Atenção, você precisa permitir o GPS para que a descarga possa ser registrada!',
        );
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
    );
    setTimeout(() => {
      setLoadingAndNotify(false);
    }, 2000);
    META_COLLECT_API.patch(
      '/coleta/descarregar',
      {
        DFIDCOLETA,
        DFSTATUS: 'F',
        DFDATAINICIODESCARGA: getCurrentDate(),
        DFDATAFIMDESCARGA: getCurrentDate(),
        DFHORAINICIODESCARGA: getCurrentDateTime(),
        DFHORAFIMDESCARGA: getCurrentDateTime(),
      },
      headers,
    )
      .then(() => {
        META_COLLECT_API.post(
          '/registro/painel/criar',
          {
            DFIDCOLETA,
            DFDATAREGISTRO: getCurrentDate(),
            DFHORAREGISTRO: getCurrentDateTime(),
            DFTIPOREGISTRO: 'F',
            DFOBSERVACAO: 'Fim da coleta',
            DFLONGITUDE,
            DFLATITUDE,
          },
          headers,
        )
          .then(() => {
            loadCollectWithInterval();
            closeModal();
            setLoadingAndNotify(false);
            return toast.success('Coleta encerrada com sucesso!');
          })
          .catch(() => {
            error();
          });
      })
      .catch(() => {
        error();
      });
  } else {
    error();
  }
};

export { handleCloseCollect };
