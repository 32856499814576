/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
import { useEffect, useState, useRef, useCallback } from 'react';
import MapBox, {
  Marker,
  MapRef,
  FullscreenControl,
  NavigationControl,
  Popup,
  Layer,
  Source,
} from 'react-map-gl';

import { META_COLLECT_API } from '../../../api';
import { ReactComponent as ArrowDown } from '../../../assets/ArrowDown.svg';
import { ReactComponent as Distance } from '../../../assets/distance.svg';
import { ReactComponent as Flag } from '../../../assets/Flag.svg';
import { ReactComponent as Milk } from '../../../assets/milkcheckedSecondary.svg';
import { ReactComponent as Speed } from '../../../assets/speed.svg';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { MapContainer } from '../../../styles/global.styles';
import { Collect, MapCollect } from '../../../types/Collect';
import { CollectStopRecord } from '../../../types/CollectStopRecord';
import { ISelectedMapPoint } from '../../../types/SelectedMapPoint';
import { SelecteRegister } from '../../../types/SelectedMarker';
import { calculateDistanceTraveled } from '../../../utils/calculateDistanceTraveled';
import { returnMarkerColor } from '../../../utils/returnMarkerColor';
import { FooterItem } from './FooterItem';
import { mapFooterOptions } from './options';
import { SelectedMarker } from './SelectedMarker';
import { validExistsMarker } from './services';
import { StopSelectedMarker } from './StopSelectedMarker';
import { ContainerCollectInformation, ContainerFooter, NavigateToCollectContainer } from './styles';

interface IMap {
  DFIDCOLETA?: number;
}

const Map = ({ DFIDCOLETA }: IMap) => {
  const mapboxAccessToken =
    'pk.eyJ1IjoibWV0YXRlY25vbG9naWEiLCJhIjoiY2xibm40b2F1MDFrbjNucXYxOTdzbjA3OSJ9.6UDcTkPvVt6S14eLPrZ1wQ';
  const [locations, setLocations] = useState<SelecteRegister[]>();
  const [stopLocations, setStopLocations] = useState<CollectStopRecord[]>();
  const [selectedRegister, setSelectedRegister] = useState<SelecteRegister>();
  const [trajectoryData, setTrajectoryData] = useState<
    number[][]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [popupCoords, setPopupCoords] = useState<ISelectedMapPoint | null>();
  const [selectedStopRegister, setSelectedStopRegister] = useState<CollectStopRecord>();
  const [stopdPopupCoords, setStopPopupCoords] = useState<ISelectedMapPoint | null>();
  const [totalDistance, setTotalDistance] = useState<number>(0);
  const mapRef = useRef<MapRef>(null);
  const { path } = useSigninContext();
  const [userLocation, setUserLocation] = useState<ISelectedMapPoint>({
    latitude: 0,
    longitude: 0,
  });
  const [collect, setCollect] = useState<MapCollect>();

  useEffect(() => {
    if (path !== "") {
      META_COLLECT_API.get(`/coleta/painel/mapa?DFIDCOLETA=${DFIDCOLETA}`,{
        headers: {
          path,
        }
       }).then(({data})=>{
        setCollect(data);
       })
      META_COLLECT_API.get(`/registro/localizacao?DFIDCOLETA=${DFIDCOLETA}`, {
        headers: {
          path,
        },
      }).then(({ data }) => {
        let markers: SelecteRegister[] = data.registros;
        setLocations(validExistsMarker({ markers }));
        META_COLLECT_API.get(`/registro-parada-coleta?DFIDCOLETA=${DFIDCOLETA}`, {
          headers: {
            path,
          },
        })
          .then((res) => {
            const stopRegisters: CollectStopRecord[] = res.data.collectStop;
            setStopLocations(stopRegisters);
            setLoading(false);
            META_COLLECT_API.get(
              `/registro/localizacao/linha?DFIDCOLETA=${DFIDCOLETA}`,
              {
                headers: {
                  path,
                },
              },
            )
              .then(response => {
                const registers: SelecteRegister[] = response.data.registros;
                let markers: number[][] = [];
                for (let i = 0; i < registers.length; i += 1) {
                  const register = registers[i];
                  if (register.DFLOCALIZACAO && register.DFLOCALIZACAO.coordinates) {
                    markers.push(register.DFLOCALIZACAO.coordinates);
                  }
                }
                setTotalDistance(calculateDistanceTraveled(markers));
                setTrajectoryData(markers);
              })
              .catch(() => null);
          })
          .catch(() => {
            setLoading(false)
          })
      }).catch(() => {
        setLoading(false)
      })
    }
  }, [path]);


  const onSelectCity = useCallback(
    ({ longitude, latitude }: ISelectedMapPoint) => {
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        zoom: 18,
      });
    },
    [],
  );

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setUserLocation({
            latitude,
            longitude,
          });
        },
        () => null,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation) {
      setTimeout(() => {
        const { latitude, longitude } = userLocation;
        onSelectCity({ latitude, longitude });
      }, 300);
    }
  }, [userLocation]);

  const handleNavigateToStartCollect = () => {
    if (trajectoryData.length > 0) {
      setTimeout(() => {
        onSelectCity({
          latitude: trajectoryData[0][1],
          longitude: trajectoryData[0][0],
        });
      }, 2000);
    }
  }

  useEffect(() => {
    handleNavigateToStartCollect();
  }, [trajectoryData]);

  const Footer = () => {
    const [openMapDescription, setOpenMapDescription] = useState<boolean>(false);
    return (
      <ContainerFooter {...{ open: openMapDescription }}>
        <div className='title-footer'>
          <h1>Legenda</h1>
          <ArrowDown
            className="button-open-options"
            onClick={() => setOpenMapDescription(!openMapDescription)}
          />
        </div>
        <div className='container-footer-grid'>
          {mapFooterOptions.map((item, index) => (
            <FooterItem data={item} key={index} />
          ))}
        </div>
      </ContainerFooter>
    )
  }

  const NavigateToCollect = () => {
    return (
      <NavigateToCollectContainer onClick={() => handleNavigateToStartCollect()}>
        <Flag width={20} height={20} />
      </NavigateToCollectContainer>
    )
  }

  const CollectInfo = () => {
    const {DFVELOCIDADEMEDIA, DFVELOCIDADEMINIMA, DFVELOCIDADEMAXIMA, DFTOTALARMAZENADO, DFPORCENTAGEMCOLETADO} = collect!;
    const [openMapDescription, setOpenMapDescription] = useState<boolean>(false);
    return (
      <ContainerCollectInformation {...{ open: openMapDescription }}>
        <div className='title-footer'>
          <h1>Informações da coleta</h1>
          <ArrowDown
            className="button-open-options"
            onClick={() => setOpenMapDescription(!openMapDescription)}
          />
        </div>
        <div className='container-footer-grid'>
          <div className='line' />
          <div className='item'>
            <div className='column'>
              <h1>Quantidade coletada </h1>
              <span>{DFTOTALARMAZENADO || 0}L</span>
              <div className='progress-empty'>
                <div className='current-collect' style={{width: `${Number(DFPORCENTAGEMCOLETADO)}%`}} />
              </div>
              <div className='row' style={{marginTop: 4}}>
                <Milk width={20} height={20}  style={{marginRight: 2}}/>
                <span>{DFPORCENTAGEMCOLETADO}%</span>
              </div>
            </div>
          </div>
          <div className='line' />
          <div className='item'>
            <div className='row'>
              <Distance width={20} height={20} style={{ marginRight: 6 }} />
              <div className='column'>
                <h1>Distancia estimada </h1>
                <span>{totalDistance.toFixed(2)} Km</span>
              </div>
            </div>
          </div>
          <div className='line' />
          <div className='item'>
            <div className='row'>
              <Speed width={20} height={20} style={{ marginRight: 6 }} />
              <div className='column'>
                <h1>Velocidade máxima </h1>
                <span>{DFVELOCIDADEMAXIMA||0}</span>
              </div>
            </div>
          </div>
          <div className='line' />
          <div className='item'>
            <div className='row'>
              <Speed width={20} height={20} style={{ marginRight: 6 }} />
              <div className='column'>
                <h1>Velocidade média </h1>
                <span>{DFVELOCIDADEMEDIA||0}</span>
              </div>
            </div>
          </div>
          <div className='line' />
          <div className='item'>
            <div className='row'>
              <Speed width={20} height={20} style={{ marginRight: 6 }} />
              <div className='column'>
                <h1>Velocidade mínima </h1>
                <span>{DFVELOCIDADEMINIMA||0}</span>
              </div>
            </div>
          </div>
        </div>
      </ContainerCollectInformation>
    )
  }

  return (
    <>
      {!loading && (
        <>
          <MapContainer>
            <MapBox
              ref={mapRef}
              style={{ display: 'flex' }}
              initialViewState={{ zoom: 12 }}
              mapboxAccessToken={mapboxAccessToken}
              mapStyle="mapbox://styles/mapbox/streets-v12"
            >
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <NavigateToCollect />
              <Footer />
              <CollectInfo />
              {popupCoords && (
                <Popup
                  maxWidth='280px'
                  anchor="top"
                  longitude={popupCoords.longitude}
                  latitude={popupCoords.latitude}
                  onClose={() => setPopupCoords(null)}
                >
                  <SelectedMarker data={selectedRegister} />
                </Popup>
              )}
              {stopdPopupCoords && (
                <Popup
                  maxWidth='280px'
                  anchor="top"
                  longitude={stopdPopupCoords.longitude}
                  latitude={stopdPopupCoords.latitude}
                  onClose={() => setStopPopupCoords(null)}
                >
                  <StopSelectedMarker data={selectedStopRegister} />
                </Popup>
              )}
              {locations &&
                locations.map((marker, index) => (
                  <Marker
                    key={`marker-${index}`}
                    latitude={marker.DFLOCALIZACAO.coordinates[1]}
                    longitude={marker.DFLOCALIZACAO.coordinates[0]}
                    anchor="center"
                    color={String(
                      returnMarkerColor({
                        DFTIPOITEMCOLETA: marker.DFTIPOITEMCOLETA,
                        DFTIPOREGISTRO: marker.DFTIPOREGISTRO,
                      }),
                    )}
                    onClick={e => {
                      e.originalEvent.stopPropagation();
                      setSelectedRegister(marker);
                      setPopupCoords({
                        latitude: marker.DFLOCALIZACAO.coordinates[1],
                        longitude: marker.DFLOCALIZACAO.coordinates[0],
                      });
                      setStopPopupCoords(null);
                    }}
                  />
                ))}
              {stopLocations &&
                stopLocations.map((marker, index) => (
                  <Marker
                    key={`marker-${index}`}
                    latitude={marker.DFLOCALIZACAO.coordinates[1]}
                    longitude={marker.DFLOCALIZACAO.coordinates[0]}
                    anchor="center"
                    color={"#964b00"}
                    onClick={e => {
                      e.originalEvent.stopPropagation();
                      setSelectedStopRegister(marker);
                      setStopPopupCoords({
                        latitude: marker.DFLOCALIZACAO.coordinates[1],
                        longitude: marker.DFLOCALIZACAO.coordinates[0],
                      });
                      setPopupCoords(null)
                    }}
                  />
                ))}
              <Source
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'LineString',
                        coordinates: trajectoryData,
                      },
                    },
                  ],
                }}
              >
                <Layer
                  type="line"
                  source="trajectoryData"
                  layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                  }}
                  paint={{
                    'line-color': '#bdbdbd',
                    'line-width': 8,
                  }}
                />
              </Source>
            </MapBox>
          </MapContainer>
        </>
      )}
    </>
  );
};

export { Map };