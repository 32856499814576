import { useEffect } from 'react';

import { useScheduleContext } from '../../../context/ProviderPage/Programacao';
import { FilterInput } from '../../Inputs/FilterInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterScheduleCollect = () => {
  const {
    openSideModalFilterScheduleCollect,
    openSideModalFilterScheduleCollectAction,
    cleanAllInputsAction,
    filterScheduleCollectAction,
    DFNOMECARRETEIRO,
    setDFNOMECARRETEIRO,
    DFORDEMCOLETA,
    setDFORDEMCOLETA,
    DFNOMELINHA,
    setDFNOMELINHA,
    DFPLACAVEICULO,
    setDFPLACAVEICULO,
    DFDATAPROGRAMADA,
    setDFDATAPROGRAMADA,
  } = useScheduleContext();

  useEffect(() => {
    if (Number(DFORDEMCOLETA) < 0) {
      setDFORDEMCOLETA('0');
    }
  }, [DFORDEMCOLETA]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterScheduleCollect}
      onchange={openSideModalFilterScheduleCollectAction}
      title="Filtrar Programada"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterScheduleCollectAction}
    >
      <FilterInput
        onChange={setDFORDEMCOLETA}
        value={DFORDEMCOLETA}
        title="Ordem Coleta"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMECARRETEIRO}
        value={DFNOMECARRETEIRO}
        title="Carreteiro"
        type="text"
      />
      <FilterInput
        onChange={setDFPLACAVEICULO}
        value={DFPLACAVEICULO}
        title="Veículo"
        type="text"
      />
      <FilterInput
        onChange={setDFNOMELINHA}
        value={DFNOMELINHA}
        title="Linha"
        type="text"
      />
      <FilterInput
        onChange={setDFDATAPROGRAMADA}
        value={DFDATAPROGRAMADA}
        title="Data Programada"
        type="date"
      />
    </SideMenuFilterTemplete>
  );
};
