/* eslint-disable @typescript-eslint/no-explicit-any */
import { CollectItemLaboratoryItem } from '../../../../../../components/CollectItemLaboratoryItem';
import { useBondCollectContext } from '../../../../../../context/ProviderPage/Vinculada';
import { CollectItem } from '../../../../../../types/CollectItem';
import { ListSelectedReleasedCollectTanksContainer } from './styles';

interface IListSelectedReleasedCollectItens {
  data: CollectItem[];
}

const ListSelectedReleasedCollectItens = ({
  data,
}: IListSelectedReleasedCollectItens) => {
  const { setOpenInfo, setSelectedUnbondCollectItem } = useBondCollectContext();

  return (
    <ListSelectedReleasedCollectTanksContainer>
      {data.map((item, index) => {
        return (
          <CollectItemLaboratoryItem
            action={() => {
              setOpenInfo(true);
              setSelectedUnbondCollectItem(item);
            }}
            key={index}
            data={item as any}
          />
        );
      })}
    </ListSelectedReleasedCollectTanksContainer>
  );
};

export { ListSelectedReleasedCollectItens };
