import { FC } from 'react';

import PDFMAPA from '../../PDF/MAPADEVIAGEM';
import { AppVersion } from '../../types/AppVersion';
import AppProvider from './context';
import { FilterProvider } from './context/ProviderPage';
import { Routes } from './routes';

const V1: FC<AppVersion> = ({ version }) => {
  return (
    <AppProvider>
      <FilterProvider>
        <PDFMAPA />
        <Routes {...{ version }} />
      </FilterProvider>
    </AppProvider>
  );
};

export { V1 };
