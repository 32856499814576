import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppVersion } from '../../../../../types/AppVersion';

const RedirectToSignin: FC<AppVersion> = ({ version }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${version}`);
  }, []);

  return <></>;
};

export { RedirectToSignin };
