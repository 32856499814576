import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { DischargeCollect } from '../../../types/DischargeCollect';
import { deformatDate } from '../../../utils/date/deformatDate';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterDischargeCollect: boolean;
  openSideModalFilterDischargeCollectAction: () => void;

  DFNOMECARRETEIRO: string;
  setDFNOMECARRETEIRO: Dispatch<SetStateAction<string>>;
  DFORDEMCOLETA: string;
  setDFORDEMCOLETA: Dispatch<SetStateAction<string>>;
  DFNOMELINHA: string;
  setDFNOMELINHA: Dispatch<SetStateAction<string>>;
  DFPLACAVEICULO: string;
  setDFPLACAVEICULO: Dispatch<SetStateAction<string>>;
  DFDATACOLETA: string;
  setDFDATACOLETA: Dispatch<SetStateAction<string>>;
  DFDATASAIDA: string;
  setDFDATASAIDA: Dispatch<SetStateAction<string>>;
  DFIDCOLETA: string;
  setDFIDCOLETA: Dispatch<SetStateAction<string>>;
  DFBLOQUEIOERP: string;
  setDFBLOQUEIOERP: Dispatch<SetStateAction<string>>;
  DFTOTALARMAZENADO: string;
  setDFTOTALARMAZENADO: Dispatch<SetStateAction<string>>;
  DFDATAPROGRAMADA: string;
  setDFDATAPROGRAMADA: Dispatch<SetStateAction<string>>;

  filterDischargeCollectAction: () => void;
  cleanAllInputsAction: () => void;
  loaddischargeAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedDischargeCollect: DischargeCollect | undefined;
  setSelectedDischargeCollect: Dispatch<
    SetStateAction<DischargeCollect | undefined>
  >;

  dischargeCollect: DischargeCollect[];
  setDischargeCollect: Dispatch<SetStateAction<DischargeCollect[]>>;

  loadingDischargeCollect: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const DischargeContextProvider = ({ children }: IContextProps) => {
  const [DFNOMECARRETEIRO, setDFNOMECARRETEIRO] = useState<string>('');
  const [DFORDEMCOLETA, setDFORDEMCOLETA] = useState<string>('');
  const [DFNOMELINHA, setDFNOMELINHA] = useState<string>('');
  const [DFPLACAVEICULO, setDFPLACAVEICULO] = useState<string>('');
  const [DFDATACOLETA, setDFDATACOLETA] = useState<string>('');
  const [DFDATASAIDA, setDFDATASAIDA] = useState<string>('');
  const [DFIDCOLETA, setDFIDCOLETA] = useState<string>('');
  const [DFBLOQUEIOERP, setDFBLOQUEIOERP] = useState<string>('');
  const [DFTOTALARMAZENADO, setDFTOTALARMAZENADO] = useState<string>('');
  const [DFDATAPROGRAMADA, setDFDATAPROGRAMADA] = useState<string>('');

  const [loadingDischargeCollect, setIsLoadingDischargeCollect] =
    useState<boolean>(false);
  const [
    openSideModalFilterDischargeCollect,
    setOpenSideModalFilterDischargeCollect,
  ] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedDischargeCollect, setSelectedDischargeCollect] = useState<
    DischargeCollect | undefined
  >();
  const [dischargeCollect, setDischargeCollect] = useState<DischargeCollect[]>(
    [],
  );

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path, user } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);

  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const NOMECARRETEIRO = `DFNOMECARRETEIRO=${DFNOMECARRETEIRO}`;
  const ORDEMCOLETA = `DFORDEMCOLETA=${DFORDEMCOLETA}`;
  const NOMELINHA = `DFNOMELINHA=${DFNOMELINHA}`;
  const PLACAVEICULO = `DFPLACAVEICULO=${DFPLACAVEICULO}`;
  const DATACOLETA = `DFDATACOLETA=${deformatDate(DFDATACOLETA)}`;
  const DATASAIDA = `DFDATASAIDA=${deformatDate(DFDATASAIDA)}`;
  const IDCOLETA = `DFIDCOLETA=${DFIDCOLETA}`;
  const STATUS = `DFSTATUS=D`;
  const BLOQUEIO = `DFBLOQUEIOERP=${DFBLOQUEIOERP}`;
  const TOTALARMAZENADO = `DFTOTALARMAZENADO=${DFTOTALARMAZENADO}`;
  const DATAPROGRAMADA = `DFDATAPROGRAMADA=${deformatDate(DFDATAPROGRAMADA)}`;
  const IDUNIDADE = user?.DFIDUNIDADE
    ? `&DFIDUNIDADE=${user?.DFIDUNIDADE}`
    : '';

  const openSideModalFilterDischargeCollectAction = () => {
    setOpenSideModalFilterDischargeCollect(
      !openSideModalFilterDischargeCollect,
    );
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingDischargeCollect(true);
      META_COLLECT_API.get(
        `/coleta/descarga?${Limit}&${Offset}&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setDischargeCollect(response.data.coletas);
          setQuantity(response.data.quantidade);
          setIsLoadingDischargeCollect(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingDischargeCollect(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingDischargeCollect(true);
    META_COLLECT_API.get(
      `/coleta/descarga?${Limit}&offset=0&${STATUS}${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setDischargeCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingDischargeCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingDischargeCollect(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFNOMECARRETEIRO('');
    setDFORDEMCOLETA('');
    setDFNOMELINHA('');
    setDFPLACAVEICULO('');
    setDFDATACOLETA('');
    setDFDATASAIDA('');
    setOpenInfo(false);
    setDFBLOQUEIOERP('');
    setDFTOTALARMAZENADO('');
    setDFDATAPROGRAMADA('');
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterDischargeCollectAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterDischargeCollectAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterDischargeCollectAction();
    setIsLoadingDischargeCollect(true);
    META_COLLECT_API.get(
      `/coleta/descarga?${Limit}&offset=0&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setDischargeCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingDischargeCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingDischargeCollect(false);
        setStart(1);
      });
  };

  const loaddischargeAction = () => {
    setIsLoadingDischargeCollect(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'programacao') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (user?.DFIDUNIDADE) {
      loaddischargeAction();
    }
  }, [user]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFORDEMCOLETA,
        filterDischargeCollectAction,
        loaddischargeAction,
        limit,
        loadingDischargeCollect,
        offset,
        openInfo,
        openSideModalFilterDischargeCollect,
        openSideModalFilterDischargeCollectAction,
        page,
        quantity,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setDischargeCollect,
        DFDATACOLETA,
        DFDATASAIDA,
        DFNOMECARRETEIRO,
        DFNOMELINHA,
        DFPLACAVEICULO,
        DFIDCOLETA,
        dischargeCollect,
        selectedDischargeCollect,
        setDFDATACOLETA,
        setDFDATASAIDA,
        setDFNOMECARRETEIRO,
        setDFNOMELINHA,
        setDFORDEMCOLETA,
        setDFPLACAVEICULO,
        setSelectedDischargeCollect,
        setDFIDCOLETA,
        start,
        setStart,
        DFBLOQUEIOERP,
        DFTOTALARMAZENADO,
        setDFBLOQUEIOERP,
        setDFTOTALARMAZENADO,
        DFDATAPROGRAMADA,
        setDFDATAPROGRAMADA,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useDischargeContext() {
  const context = useContext(Context);
  return context;
}

export { DischargeContextProvider, useDischargeContext };
