import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListTanks } from '../../../components/List/ListTanks';
import { LoadingData } from '../../../components/LoadingData';
import { InfoTank } from '../../../components/Modal/InfoTank';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useTankContext } from '../../../context/ProviderPage/Tanque';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Tanque = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingTank,
    loadTankAction,
    tank,
    openSideModalFilterTankAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useTankContext();

  useEffect(() => {
    setSelectedPage({ option: 'tanque' });
    loadTankAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'tanque' }}
        pageTitle="Tanque"
        filterAction={openSideModalFilterTankAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingTank === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingTank ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {tank.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum tanque foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListTanks data={tank} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: tank.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoTank
          option="tanque"
          titleModal="Tanque selecionado"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Tanque };
