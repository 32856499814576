import styled from 'styled-components';

export const ListTankMouthsContainer = styled.div`
  width: 100%;
  max-height: 230px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ListTankMouthsItem = styled.div`
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  height: 210px;
  margin-right: 1rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  h2 {
    font-size: 1rem;
  }
`;
