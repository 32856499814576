import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListUnities } from '../../../components/List/ListUnities';
import { LoadingData } from '../../../components/LoadingData';
import { InfoUnity } from '../../../components/Modal/InfoUnity';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Unidade = () => {
  const { setSelectedPage } = usePageContext();
  const {
    loadingUnities,
    loadUnitiesAction,
    unities,
    openSideModalFilterUnitiesAction,
    openInfoUnity,
    setOpenInfoUnity,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useUnityContext();
  useEffect(() => {
    setSelectedPage({ option: 'unidade' });
    loadUnitiesAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'unidade' }}
        pageTitle="Unidade"
        filterAction={openSideModalFilterUnitiesAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingUnities === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingUnities ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {unities.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma unidade foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListUnities data={unities} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: unities.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoUnity
          option="unidade"
          titleModal="Unidade selecionada"
          isOpen={openInfoUnity}
          closeModal={setOpenInfoUnity}
        />
      </ContainerPage>
    </>
  );
};

export { Unidade };
