import { transparentize } from 'polished';
import styled from 'styled-components';

const ListReleaseCollectSelectedTankProducersContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const ListReleaseCollectSelectedTankProducersContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 49.6% 49.6%;
  flex-flow: row wrap;
  overflow-y: auto;
  justify-content: space-between;
  padding: 0 1rem;
  transition: 0.3s all;
  margin-top: 1rem;

  @media (max-width: 900px) {
    flex-flow: column;
    padding: 0 0.6rem;
    grid-template-columns: 100%;
  }
`;

const ProducerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.GREY_50};
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-color: ${({ theme }) => transparentize(0.55, theme.COLORS.GREY)};
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  .producer-body {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    width: 100%;
    .producer-row {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding-top: 0.4rem;
      margin-top: 0.4rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .producer-id {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          margin-left: 0.2rem;
          font-size: 1rem;
        }
      }
    }
  }
`;

export {
  ListReleaseCollectSelectedTankProducersContainer,
  ListReleaseCollectSelectedTankProducersContent,
  ProducerItem,
};
