/* eslint-disable consistent-return */
import {
  IClosedProperty,
  IPrintClosedCollect,
} from '../../version/v1/types/PrintClosedCollect';

const selectTankOwner = (DFPRODUTORES: IClosedProperty[]) => {
  const owner = DFPRODUTORES.find(item => item.DFPROPRIETARIO === 'S');
  return owner;
};

const countCollectedTanks = (
  printClosedCollect: IPrintClosedCollect[] | undefined,
) => {
  if (printClosedCollect) {
    const quantity = printClosedCollect!.filter(item => item.DFQTDCOLETADA > 0);
    return quantity.length > 1
      ? `${quantity.length} COLETAS REALIZADAS`
      : '1 COLETA REALIZADA';
  }
  return '';
};

const getVolume = (
  printClosedCollect: IPrintClosedCollect[] | undefined,
): number => {
  if (printClosedCollect) {
    const count = printClosedCollect.reduce((acc, item) => {
      return acc + item.DFQTDCOLETADA;
    }, 0);

    return count;
  }
  return 0;
};

const getDifference = (
  printClosedCollect: IPrintClosedCollect[] | undefined,
  DFPLATAFORMA: number | undefined,
): number => {
  if (printClosedCollect && DFPLATAFORMA) {
    const count = printClosedCollect.reduce((acc, item) => {
      return acc + item.DFQTDCOLETADA;
    }, 0);

    return (count - DFPLATAFORMA) * -1;
  }
  return 0;
};

export { selectTankOwner, countCollectedTanks, getVolume, getDifference };

// const CollectInfoRowTop = ({ field, value }: ICollectInfoRow) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         borderBottom: '1px solid gray',
//         borderTop: '1px solid grey',
//       }}
//     >
//       <span
//         style={{
//           backgroundColor: '#E5E5E5',
//           margin: 0,
//           padding: 0,
//           marginRight: 6,
//         }}
//       >
//         {field}
//       </span>
//       <span>{value}</span>
//     </div>
//   );
// };

// const CollectInfoRow = ({ field, value }: ICollectInfoRow) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         borderBottom: '1px solid gray',
//       }}
//     >
//       <span
//         style={{
//           backgroundColor: '#E5E5E5',
//           margin: 0,
//           padding: 0,
//           marginRight: 6,
//         }}
//       >
//         {field}
//       </span>
//       <span>{value}</span>
//     </div>
//   );
// };

// const CollectInfoRowEnd = ({ field, value }: ICollectInfoRow) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         borderBottom: '1px solid gray',
//       }}
//     >
//       <span
//         style={{
//           backgroundColor: '#E5E5E5',
//           margin: 0,
//           padding: 0,
//         }}
//       >
//         {field}
//       </span>
//       <span>{value}</span>
//     </div>
//   );
// };

// const CollectInfoRowEndTop = ({ field, value }: ICollectInfoRow) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         borderBottom: '1px solid gray',
//         borderTop: '1px solid grey',
//       }}
//     >
//       <span
//         style={{
//           backgroundColor: '#E5E5E5',
//           margin: 0,
//           padding: 0,
//         }}
//       >
//         {field}
//       </span>
//       <span>{value}</span>
//     </div>
//   );
// };

// const NormalCollect = () => {
//   return (
//     <table
//       style={{
//         width: '100%',
//         fontFamily: 'Inter',
//         fontSize: 12,
//         margin: 0,
//         padding: 0,
//         boxSizing: 'border-box',
//         borderSpacing: 0,
//         borderCollapse: 'collapse',
//         border: 'none',
//       }}
//     >
//       <thead>
//         <tr>
//           <th style={{ textAlign: 'center' }}>HORA</th>
//           <th style={{ textAlign: 'start', width: '35%' }}>VISITA</th>
//           <th style={{ textAlign: 'start', width: '35%' }}></th>
//           <th style={{ textAlign: 'end' }}>VOLUME(L)</th>
//         </tr>
//       </thead>

//       <tbody>
//         {printClosedCollect.map((item, index) => {
//           const {
//             DFBOCAS,
//             DFTEMPERATURA,
//             DFREGUAATRAS,
//             DFREGUAFRENTE,
//             DFHORACOLETA,
//             DFPRODUTORES,
//             DFQTDCOLETADA,
//             DFCOLETOULACRE,
//             DFCOLETOUAMOSTRA,
//             DFALIZAROL,
//           } = item;
//           return (
//             <tr key={index}>
//               <td
// style={{
//   textAlign: 'center',
//   justifyContent: 'center',
//   display: 'flex',
//   border: 'none',
//   borderTop: '1px solid grey',
// }}
//               >
//                 {DFHORACOLETA}
//               </td>
//               <td
//                 style={{
//                   border: 0,
//                   margin: 0,
//                   padding: 0,
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 <div>
//                   <CollectInfoRowTop
//                     {...{
//                       field: 'PRODUTOR',
//                       value: DFHORACOLETA,
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'FAZENDA',
//                       value: 'CHACARA MARANATA',
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'TANQUE',
//                       value: '1104-10786/01',
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'BOCAS',
//                       value: `${DFBOCAS ? DFBOCAS.length : 0}`,
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'RÉGUA 1',
//                       value: `${DFREGUAFRENTE ? `${DFREGUAFRENTE} cm` : '-'}`,
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'RÉGUA 2',
//                       value: `${DFREGUAATRAS ? `${DFREGUAATRAS} cm` : '-'}`,
//                     }}
//                   />

//                   <CollectInfoRow
//                     {...{
//                       field: 'AMOSTRA',
//                       value: `${DFCOLETOUAMOSTRA || '-'}`,
//                     }}
//                   />
//                 </div>
//               </td>

//               <td
//                 style={{
//                   border: 0,
//                   margin: 0,
//                   padding: 0,
//                   boxSizing: 'border-box',
//                 }}
//               >
//                 <div>
//                   <CollectInfoRowEndTop
//                     {...{
//                       field: 'CÓDIGO',
//                       value: '10786',
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'CÓDIGO',
//                       value: '10786/01',
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'COMUNITÁRIO',
//                       value: `${
//                         DFPRODUTORES && DFPRODUTORES.length > 1
//                           ? 'SIM'
//                           : 'NÃO'
//                       }`,
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'TEMPERATURA',
//                       value: `${DFTEMPERATURA ? `${DFTEMPERATURA} °C` : '-'}`,
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'TEMPERATURA TERMÔMETRO',
//                       value: '-',
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'ALIZAROL',
//                       value: `${DFALIZAROL || '-'}`,
//                     }}
//                   />

//                   <CollectInfoRowEnd
//                     {...{
//                       field: 'LACRE',
//                       value: `${DFCOLETOULACRE || '-'}`,
//                     }}
//                   />
//                 </div>
//               </td>
//               <td
//                 style={{
//                   textAlign: 'end',
//                   justifyContent: 'flex-end',
//                   display: 'flex',
//                   borderTop: '1px solid grey',
//                 }}
//               >
//                 {DFQTDCOLETADA ? `${DFQTDCOLETADA} Lt` : '0 Lt'}
//               </td>
//             </tr>
//           );
//         })}
//       </tbody>
//     </table>
//   );
// };
