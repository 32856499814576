import { ReactNode, useEffect, useState } from 'react';

import Close from '../../../assets/Close.svg';
import Filter from '../../../assets/Filter.svg';
import {
  ContainerModalFilter,
  ContainerSideModalFilterExemple,
  FilterColumn,
  FilterRowButtons,
  HeaderFilter,
  NotShowContent,
  OverlayModalFilter,
} from './styles';

interface ISideMenuFilterTemplete {
  isOpen: boolean;
  onchange: () => void;
  title: string;
  children: ReactNode;
  cleanAllInputsAction: () => void;
  filterAction: () => void;
}

export const SideMenuFilterTemplete = ({
  isOpen,
  onchange,
  title,
  children,
  cleanAllInputsAction,
  filterAction,
}: ISideMenuFilterTemplete) => {
  const ValidIsOpen = () => {
    if (isOpen) {
      onchange();
    }
  };

  const [showContentFilter, setShowContentFilter] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowContentFilter(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContentFilter(false);
      }, 0);
    }
  }, [isOpen]);

  return (
    <ContainerSideModalFilterExemple isOpen={isOpen}>
      <OverlayModalFilter onClick={ValidIsOpen} />
      <ContainerModalFilter isOpen={isOpen}>
        <NotShowContent showContentFilter={showContentFilter}>
          <HeaderFilter>
            <div className="header-filter-title">
              <img src={Filter} />
              <span>{title}</span>
            </div>
            <img
              className="header-filter-close-button"
              src={Close}
              onClick={onchange}
            />
          </HeaderFilter>
          <FilterColumn>{children}</FilterColumn>
          <FilterRowButtons>
            <button
              className="filterButtonModalTransparent"
              onClick={cleanAllInputsAction}
            >
              Limpar filtros
            </button>
            <button className="filterButtonModal" onClick={filterAction}>
              Aplicar filtros
            </button>
          </FilterRowButtons>
        </NotShowContent>
      </ContainerModalFilter>
    </ContainerSideModalFilterExemple>
  );
};
