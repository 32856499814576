import { Dispatch, SetStateAction } from 'react';

import { META_COLLECT_API } from '../../../api';
import { Collect } from '../../../types/Collect';

type HandleUpdateClosedCollect = {
  collect?: Collect;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  path: string;
};

const handleUpdateClosedCollect = async ({
  collect,
  setIsOpen,
  isOpen,
  path,
}: HandleUpdateClosedCollect) => {
  setIsOpen(!isOpen);
  await META_COLLECT_API.put(
    '/coleta/atualizar/painel',
    {
      DFIDCOLETA: collect?.DFIDCOLETA,
      DFBLOQUEIOERP: isOpen ? 'S' : 'N',
    },
    {
      headers: {
        path,
      },
    },
  );
};

export { handleUpdateClosedCollect };
