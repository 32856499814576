import { useEffect } from 'react';

import { usePropertyContext } from '../../../context/ProviderPage/Propriedade';
import { formatInputCPFAndCNPJ } from '../../../utils/cnpjOrCpf/formatInpuCPFAndCNPJ';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterProperties = () => {
  const {
    openSideModalFilterProperty,
    openSideModalFilterPropertyAction,
    cleanAllInputsAction,
    filterPropertyAction,
    DFIDPROPRIEDADE,
    setDFIDPROPRIEDADE,
    DFMATRICULA,
    setDFMATRICULA,
    DFCNPJCPFCEI,
    setDFCNPJCPFCEI,
    DFNOMEPRODUTOR,
    setDFNOMEPRODUTOR,
    DFNOMEPROPRIEDADE,
    setDFNOMEPROPRIEDADE,
    DFNOMEMUNICIPIO,
    setDFNOMEMUNICIPIO,
    DFIDSIGLAUF,
    setDFIDSIGLAUF,
    DFLOGRADOURO,
    setDFLOGRADOURO,
    DFNUMERO,
    setDFNUMERO,
    DFATIVOINATIVO,
    setDFATIVOINATIVO,
  } = usePropertyContext();

  useEffect(() => {
    if (Number(DFIDPROPRIEDADE) < 0) {
      setDFIDPROPRIEDADE('0');
    }
  }, [DFIDPROPRIEDADE]);

  const formatCPFAndCNPJ = (value: string) => {
    setDFCNPJCPFCEI(formatInputCPFAndCNPJ(value));
  };

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterProperty}
      onchange={openSideModalFilterPropertyAction}
      title="Filtrar Propriedade"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterPropertyAction}
    >
      <FilterSelectInput
        onChange={setDFATIVOINATIVO}
        value={DFATIVOINATIVO}
        title="Ativo"
      />
      <FilterInput
        onChange={setDFMATRICULA}
        value={DFMATRICULA}
        title="Matrícula"
        type="text"
      />
      {/* <FilterInput
        onChange={setDFIDPROPRIEDADE}
        value={DFIDPROPRIEDADE}
        title="Código"
        type="number"
        min={0}
      /> */}
      <FilterInput
        onChange={setDFNOMEPROPRIEDADE}
        value={DFNOMEPROPRIEDADE}
        title="Propriedade"
        type="text"
      />

      <FilterInput
        onChange={formatCPFAndCNPJ}
        value={DFCNPJCPFCEI}
        title="CNPJ/CPF"
        type="text"
      />
      <FilterInput
        onChange={setDFNUMERO}
        value={DFNUMERO}
        title="Número"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMEPRODUTOR}
        value={DFNOMEPRODUTOR}
        title="Produtor"
        type="text"
      />
      <FilterInput
        onChange={setDFNOMEMUNICIPIO}
        value={DFNOMEMUNICIPIO}
        title="Município"
        type="text"
      />
      <FilterInput
        onChange={setDFIDSIGLAUF}
        value={DFIDSIGLAUF}
        title="UF"
        type="text"
      />
      <FilterInput
        onChange={setDFLOGRADOURO}
        value={DFLOGRADOURO}
        title="Logradouro"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
