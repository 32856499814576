import { ReactNode } from 'react';

import { WagonerContextProvider } from './Carreteiro';
import { CollectContextProvider } from './Coleta';
import { ConfigurationContextProvider } from './Configuration';
import { DischargeContextProvider } from './Descarga';
import { DeviceContextProvider } from './Device';
import { ClosedCollectContextProvider } from './Encerrada';
import { ExcludedCollectContextProvider } from './Excluida';
import { ReleasedCollectContextProvider } from './Liberar';
import { LineContextProvider } from './Linha';
import { ScheduleContextProvider } from './Programacao';
import { PropertyContextProvider } from './Propriedade';
import { RegionContextProvider } from './Regiao';
import { SiloContextProvider } from './Silo';
import { TankContextProvider } from './Tanque';
import { LinkedTankContextProvider } from './TanquesVinculado';
import { UnityContextProvider } from './Unidade';
import { VehicleContextProvider } from './Veiculo';
import { BondCollectContextProvider } from './Vinculada';
import { TankToReleaseContextProvider } from './Vincular';

interface IAppProvider {
  children: ReactNode;
}

const FilterProvider = ({ children }: IAppProvider) => {
  return (
    <UnityContextProvider>
      <SiloContextProvider>
        <RegionContextProvider>
          <LineContextProvider>
            <TankContextProvider>
              <PropertyContextProvider>
                <VehicleContextProvider>
                  <ScheduleContextProvider>
                    <CollectContextProvider>
                      <WagonerContextProvider>
                        <DischargeContextProvider>
                          <ClosedCollectContextProvider>
                            <DeviceContextProvider>
                              <ConfigurationContextProvider>
                                <ReleasedCollectContextProvider>
                                  <TankToReleaseContextProvider>
                                    <BondCollectContextProvider>
                                      <ExcludedCollectContextProvider>
                                        <LinkedTankContextProvider>
                                          {children}
                                        </LinkedTankContextProvider>
                                      </ExcludedCollectContextProvider>
                                    </BondCollectContextProvider>
                                  </TankToReleaseContextProvider>
                                </ReleasedCollectContextProvider>
                              </ConfigurationContextProvider>
                            </DeviceContextProvider>
                          </ClosedCollectContextProvider>
                        </DischargeContextProvider>
                      </WagonerContextProvider>
                    </CollectContextProvider>
                  </ScheduleContextProvider>
                </VehicleContextProvider>
              </PropertyContextProvider>
            </TankContextProvider>
          </LineContextProvider>
        </RegionContextProvider>
      </SiloContextProvider>
    </UnityContextProvider>
  );
};

export { FilterProvider };
