/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface IFooterItemContainer {
  icon: 'NC' | 'CP' | 'DC' | 'CF' | 'IC' | 'CS' | 'PP';
}

export const FooterItemContainer = styled.div<IFooterItemContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.1rem;

  .icon {
    margin-right: 0.1rem;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    border: 1px solid white;
    background-color: ${({ icon, theme }) =>
      icon === 'IC'
        ? theme.COLORS.IC
        : icon === 'CF'
        ? theme.COLORS.CF
        : icon === 'CP'
        ? theme.COLORS.CP
        : icon === 'DC'
        ? theme.COLORS.DC
        : icon === 'NC'
        ? theme.COLORS.NC
        : icon === 'CS'
        ? theme.COLORS.CS
        : icon === 'PP'
        ? theme.COLORS.PP
        : ''};
  }
  span {
    transition: 0.3s all;
    color: ${({ theme }) => theme.COLORS.BLACK_1000};
    /* @media (max-width: 1000px) {
      font-size: 0.8rem;
    }
    @media (max-width: 680px) {
      font-size: 0.6rem;
    } */
  }
`;
