import { Dispatch, SetStateAction } from 'react';

import { ReactComponent as ID } from '../../../../../../assets/id.svg';
import { ReactComponent as Producer } from '../../../../../../assets/Producer.svg';
import { BackButton } from '../../../../../../components/Buttons/BackButton';
import {
  ReleaseCollectTank,
  ReleaseCollectTankProducer,
} from '../../../../../../types/ReleaseCollect';
import {
  ListReleaseCollectSelectedTankProducersContainer,
  ListReleaseCollectSelectedTankProducersContent,
  ProducerItem,
} from './styles';

interface IListReleaseCollectSelectedTankProducers {
  producers: ReleaseCollectTank[];
  setSelectTank: Dispatch<SetStateAction<ReleaseCollectTankProducer>>;
}

const ListReleaseCollectSelectedTankProducers = ({
  producers,
  setSelectTank,
}: IListReleaseCollectSelectedTankProducers) => {
  return (
    <ListReleaseCollectSelectedTankProducersContainer>
      <BackButton action={() => setSelectTank({})} title="Voltar" />
      <ListReleaseCollectSelectedTankProducersContent>
        {producers.map((item, index) => {
          const {
            DFMATRICULA,
            DFNOMEPRODUTOR,
            DFNOMEPROPRIEDADE,
            DFQTDENTRADA,
          } = item;
          return (
            <ProducerItem key={index}>
              <Producer width={25} height={25} />
              <div className="producer-body">
                <h4>{DFNOMEPRODUTOR}</h4>
                <h4>{DFNOMEPROPRIEDADE}</h4>
                <div className="producer-row">
                  <span>
                    <b>Quantidade coletada: </b>
                    {DFQTDENTRADA} Litros
                  </span>
                  <div className="producer-id">
                    <ID />
                    <span>{DFMATRICULA}</span>
                  </div>
                </div>
              </div>
            </ProducerItem>
          );
        })}
      </ListReleaseCollectSelectedTankProducersContent>
    </ListReleaseCollectSelectedTankProducersContainer>
  );
};

export { ListReleaseCollectSelectedTankProducers };
