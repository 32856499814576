/* eslint-disable no-bitwise */
import React, { ReactNode } from 'react';

import { ReactComponent as Icon } from '../../assets/farm.svg';
import { useClosedCollectContext } from '../../version/v1/context/ProviderPage/Encerrada';
import { IClosedProperty } from '../../version/v1/types/PrintClosedCollect';
import { formatDateAndHour } from '../../version/v1/utils/date/formatDateAndHour';
import { getCurrentDate } from '../../version/v1/utils/date/getCurrentDate';
import { getCurrentDateTime } from '../../version/v1/utils/date/getCurrentDateTime';
import {
  countCollectedTanks,
  getDifference,
  getVolume,
  selectTankOwner,
} from './service';

const PDFMAPA: React.FC = () => {
  const { printClosedCollect, selectedClosedCollect } =
    useClosedCollectContext();

  const mouthH5 = (value: string) => {
    return (
      <h5
        style={{
          fontSize: 12,
          fontFamily: 'Inter',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        }}
      >
        {value}
      </h5>
    );
  };

  const tH5 = (value: string) => {
    return (
      <h5
        style={{
          borderBottom: '1px solid grey',
          fontFamily: 'Inter',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        }}
      >
        {value}
      </h5>
    );
  };

  const option = (field: string, value: string) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '33.33%',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0,0,0,0.07)',
        }}
      >
        <h5
          style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
          }}
        >
          {field}
        </h5>
        <span
          style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            fontSize: 12,
          }}
        >
          &nbsp;{value}
        </span>
      </div>
    );
  };

  const Header = () => {
    return printClosedCollect ? (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          alignItems: 'center',
          paddingTop: 8,
          paddingBottom: 8,
          fontFamily: 'Inter',
          fontSize: 12,
        }}
      >
        <div
          style={{
            width: '99%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            borderBottom: '1px solid grey',
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        >
          <b>MAPA DE VIAGEM</b>
          <span>
            {formatDateAndHour(getCurrentDate(), getCurrentDateTime())}
          </span>
        </div>
        <div
          style={{
            width: '99%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            borderBottom: '1px solid grey',
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        >
          <div>
            <b>EMPRESA: </b>
            <span>{printClosedCollect.coleta.DFRAZSOCUNIDADE}</span>
          </div>
          <div>
            <b>CNPJ: </b>
            <span>{printClosedCollect.coleta.DFCNPJCPFCEI}</span>
          </div>
        </div>
        <div
          style={{
            width: '99%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            borderBottom: '1px solid grey',
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        >
          <div>
            <b>ROTA: </b>
            <span>{printClosedCollect.coleta.DFDESCREGIONAL}</span>
          </div>
          <div>
            <b>LINHA: </b>
            <span>{selectedClosedCollect?.DFNOMELINHA}</span>
          </div>
        </div>
        <div
          style={{
            width: '99%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            borderBottom: '1px solid grey',
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        >
          <div>
            <b>COLETOR: </b>
            <span>{selectedClosedCollect?.DFNOMECARRETEIRO}</span>
          </div>
          <div>
            <b>VEÍCULO: </b>
            <span>{selectedClosedCollect?.DFPLACAVEICULO}</span>
          </div>
        </div>
        <div
          style={{
            width: '99%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        >
          <div>
            <b>ABERTURA: </b>
            <span>
              {formatDateAndHour(
                selectedClosedCollect?.DFDATASAIDA,
                selectedClosedCollect?.DFHORASAIDA,
              )}
            </span>
          </div>
          <div>
            <b>FECHAMENTO: </b>
            <span>
              {formatDateAndHour(
                selectedClosedCollect?.DFDATACOLETA,
                selectedClosedCollect?.DFHORACOLETA,
              )}
            </span>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const Odometer = () => {
    return (
      <div
        style={{
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        }}
      >
        {tH5('DESLOCAMENTO')}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderBottom: '1px solid grey',
            width: '100%',
            fontFamily: 'Inter',
          }}
        >
          {option(
            'Odômetro Inicial',
            `${printClosedCollect?.coleta.DFKMINICIAL || 0} Km`,
          )}
          {option(
            'Odômetro Final',
            `${printClosedCollect?.coleta.DFKMFINAL || 0} Km`,
          )}
          {option(
            'Distância',
            `${printClosedCollect?.DFDISTANCIAPERCORRIDA || 0} Km`,
          )}
          {/* {option(
            'Distância',
            `${
              ((printClosedCollect?.coleta.DFKMINICIAL || 0) -
                (printClosedCollect?.coleta.DFKMFINAL || 0)) *
              -1
            } Km`,
          )} */}
        </div>
      </div>
    );
  };

  const Tracking = () => {
    return (
      <div
        style={{
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        }}
      >
        {tH5('Rastreamento')}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderBottom: '1px solid grey',
            width: '100%',
            fontFamily: 'Inter',
          }}
        >
          {option(
            'Velocidade máxima',
            `${
              printClosedCollect?.DFVELOCIDADEMAXIMA
                ? `${printClosedCollect.DFVELOCIDADEMAXIMA}`
                : ''
            }`,
          )}

          {option(
            'Velocidade média',
            `${
              printClosedCollect?.DFVELOCIDADEMEDIA
                ? `${printClosedCollect.DFVELOCIDADEMEDIA}`
                : ''
            }`,
          )}

          {option(
            'Velocidade mínima',
            `${
              printClosedCollect?.DFVELOCIDADEMINIMA
                ? `${printClosedCollect.DFVELOCIDADEMINIMA}`
                : ''
            }`,
          )}
        </div>
      </div>
    );
  };

  const Mouths = () => {
    if (printClosedCollect) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
          }}
        >
          {tH5('ARMAZENAMENTO NO VEÍCULO')}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '1px solid grey',
              justifyContent: 'space-between',
            }}
          >
            {mouthH5('BOCA')}
            {mouthH5('VOLUME(L)')}
          </div>
          {printClosedCollect?.bocas.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  fontFamily: 'Inter',
                  borderBottom: '1px solid grey',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: 'Inter',
                  }}
                >
                  {item.DFBOCA}
                </span>
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: 'Inter',
                  }}
                >
                  {item.DFVOLUMETOTAL}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const CollectOwner = (owner: IClosedProperty) => {
    return (
      <div
        style={{
          padding: 3,
        }}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Icon
            width={20}
            height={20}
            style={{
              marginRight: 6,
            }}
          />
          {owner?.DFNOMEPROPRIEDADE || '-'}
        </span>
      </div>
    );
  };

  const renderTankProducers = (producers: IClosedProperty[]) => {
    return (
      <table
        style={{
          width: '100%',
          fontFamily: 'Inter',
          fontSize: 12,
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          borderSpacing: 0,
          borderCollapse: 'collapse',
          border: 'none',
        }}
      >
        <thead>
          <tr>
            <th style={{ width: '85%', textAlign: 'left' }}>PRODUTOR</th>
            <th style={{ width: '15%', textAlign: 'end' }}>INFORMADO(L)</th>
          </tr>
        </thead>
        <tbody>
          <>
            {producers &&
              producers.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      {item?.DFNOMEPROPRIEDADE || ''}
                    </td>
                    <td
                      style={{
                        textAlign: 'end',
                      }}
                    >
                      {item?.DFPRODUTORESCOLETA &&
                      item.DFPRODUTORESCOLETA.length > 0
                        ? item.DFPRODUTORESCOLETA[0]?.DFQTDENTRADA
                        : 0}
                    </td>
                  </tr>
                );
              })}
          </>
        </tbody>
      </table>
    );
  };

  interface ITD {
    children: ReactNode;
  }
  const TD = ({ children }: ITD) => {
    return (
      <td
        style={{
          borderTop: '1px solid gray',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        }}
      >
        {children}
      </td>
    );
  };

  const Collect = () => {
    return (
      <table
        style={{
          width: '100%',
          fontFamily: 'Inter',
          fontSize: 12,
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          borderSpacing: 0,
          borderCollapse: 'collapse',
          border: 'none',
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>HORA</th>
            <th style={{ textAlign: 'start', width: '80%' }}>
              TANQUE COLETADO
            </th>
            <th style={{ textAlign: 'end' }}>VOLUME(L)</th>
          </tr>
        </thead>

        <tbody>
          {printClosedCollect?.itensColeta &&
            printClosedCollect?.itensColeta.map((item, index) => {
              const { DFQTDCOLETADA, DFHORACOLETA, DFPRODUTORES } = item;
              const owner = selectTankOwner(DFPRODUTORES);
              return (
                DFQTDCOLETADA > 0 && (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 1
                          ? 'rgba(0,0,0,0.07)'
                          : 'rgba(0,0,0,0.05)',
                    }}
                  >
                    <td
                      style={{
                        borderTop: '1px solid gray',
                        textAlign: 'center',
                      }}
                    >
                      {DFHORACOLETA ? DFHORACOLETA.slice(0, 5) : 0}
                    </td>
                    <TD>
                      <CollectOwner {...owner!} />
                      {renderTankProducers(DFPRODUTORES)}
                    </TD>
                    <td
                      style={{ borderTop: '1px solid gray', textAlign: 'end' }}
                    >
                      {DFQTDCOLETADA ? `${DFQTDCOLETADA}` : ''}
                    </td>
                  </tr>
                )
              );
            })}
        </tbody>
      </table>
    );
  };

  const FooterCollect = () => {
    return (
      <>
        <h5
          style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderBottom: '1px solid grey',
            borderTop: '1px solid grey',
            width: '100%',
            fontFamily: 'Inter',
          }}
        >
          {countCollectedTanks(printClosedCollect?.itensColeta)}
        </h5>
        <table
          style={{
            width: '100%',
            fontFamily: 'Inter',
            fontSize: 12,
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderSpacing: 0,
            borderCollapse: 'collapse',
            border: 'none',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'center',
                  borderBottom: '1px solid gray',
                }}
              >
                TOTAL COLETADO
              </th>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'center',
                  borderBottom: '1px solid gray',
                }}
              >
                TOTAL DESCARREGADO
              </th>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'center',
                  borderBottom: '1px solid gray',
                }}
              >
                DIFERENÇA
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }}>
                {getVolume(printClosedCollect?.itensColeta) || 0}L
              </td>
              <td style={{ textAlign: 'center' }}>
                {printClosedCollect?.coleta.DFPLATAFORMA || 0}L
              </td>
              <td style={{ textAlign: 'center' }}>
                {getDifference(
                  printClosedCollect?.itensColeta,
                  printClosedCollect?.coleta.DFPLATAFORMA,
                ) || 0}
                L
              </td>
            </tr>
          </tbody>
        </table>
        <h5
          style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderTop: '1px solid grey',
            width: '100%',
            fontFamily: 'Inter',
          }}
        >
          DETALHES DA DESCARGA
        </h5>
        <table
          style={{
            width: '100%',
            fontFamily: 'Inter',
            fontSize: 12,
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            borderSpacing: 0,
            borderCollapse: 'collapse',
            border: 'none',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'left',
                  borderTop: '1px solid gray',
                }}
              >
                DATA
              </th>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'left',
                  borderTop: '1px solid gray',
                }}
              >
                LOCAL
              </th>
              <th
                style={{
                  width: '33.33%',
                  textAlign: 'end',
                  borderTop: '1px solid gray',
                }}
              >
                QUANTIDADE(L)
              </th>
            </tr>
          </thead>
          <tbody>
            {printClosedCollect?.coleta.DFSILOS &&
              printClosedCollect?.coleta.DFSILOS.map((item, index) => {
                const {
                  DFDATADESCARGA,
                  DFHORADESCARGA,
                  DFVOLUME,
                  DFDESCRICAOSILO,
                } = item;
                return (
                  <tr key={index}>
                    <td
                      style={{ textAlign: 'left', borderTop: '1px solid gray' }}
                    >
                      {formatDateAndHour(DFDATADESCARGA, DFHORADESCARGA)}
                    </td>
                    <td
                      style={{ textAlign: 'left', borderTop: '1px solid gray' }}
                    >
                      {DFDESCRICAOSILO}
                    </td>
                    <td
                      style={{ textAlign: 'end', borderTop: '1px solid gray' }}
                    >
                      {DFVOLUME}L
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  };

  const Signature = () => {
    return (
      <div style={{ marginTop: 3598, width: '100%', fontFamily: 'Inter' }}>
        <p>
          Eu,<b>{selectedClosedCollect?.DFNOMECARRETEIRO}</b>, declaro
          formalmente a veracidade das informações contidas neste relatório, que
          abrange as coletas e descargas referentes à viagem de número 1172497.
        </p>

        <p>
          O término da referida viagem foi oficializado por meio da
          sincronização de dados do aplicativo Meta Collect, na versão
          <b> 1.0.0</b>, com o encerramento devidamente registrado em{' '}
          {formatDateAndHour(
            selectedClosedCollect?.DFDATACOLETA,
            selectedClosedCollect?.DFHORACOLETA,
          )}
          . A partir desse ponto, destaco que o agente de coleta não detém mais
          a prerrogativa de efetuar quaisquer alterações nos registros, sendo
          essa responsabilidade exclusiva dos administradores da plataforma, os
          quais têm a autoridade para modificar os dados conforme suas
          credenciais de acesso.
        </p>

        <p>
          Por meio desta assinatura, atesto, de maneira formal e legítima, a
          autenticidade das informações contidas neste relatório, assegurando
          sua validade e confiabilidade.
        </p>

        <div style={{ width: '100%', fontFamily: 'Inter' }}>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              marginTop: 40,
            }}
          >
            <span style={{ marginBottom: 20 }}>
              _____________________________________________________
            </span>
            <span>{selectedClosedCollect?.DFNOMECARRETEIRO}</span>
          </div>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              marginTop: 40,
            }}
          >
            <span style={{ marginBottom: 20 }}>
              _____________________________________________________
            </span>
            <span>RESPONSÁVEL PELA DESCARGA</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <body
      id="template"
      style={{
        zIndex: -100000000,
        display: 'none',
      }}
    >
      <Header />
      <Odometer />
      <Tracking />
      <Mouths />
      <Collect />
      <FooterCollect />
      <Signature />
    </body>
  );
};

export default PDFMAPA;
