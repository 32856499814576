import { Dispatch, SetStateAction, useState } from 'react';

import { useConfiguraionContext } from '../../../context/ProviderPage/Configuration';
import { CustomSwitch } from '../../CustomSwitch';
import { IModal, ModalConfiguration } from './ModalConfiguration';
import {
  ColumnItens,
  ContainerItemMediumModal,
} from './ModalConfiguration/styles';

interface IModifyValue {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

export const InfoConfiguration = ({
  closeModal,
  titleModal,
  option,
}: IModal) => {
  const { openCreateNewConfiguration, onCreateNewConfiguration } =
    useConfiguraionContext();

  const [DFDISTRIBUIR, setDFDISTRIBUIR] = useState(false);
  // const [DFQUALIDADE, setDFQUALIDADE] = useState(false);
  const [DFREGUAFRENTE, setDFREGUAFRENTE] = useState(false);
  const [DFREGUAATRAS, setDFREGUAATRAS] = useState(false);
  const [DFTERMOMENTRO, setDFTERMOMENTRO] = useState(false);
  const [DFLIBERARCOLETA, setDFLIBERARCOLETA] = useState(false);
  const [DFEXAMESENSORIAL, setDFEXAMESENSORIAL] = useState(false);
  const [DFEXAMEALIZAROL, setDFEXAMEALIZAROL] = useState(false);
  const [DFPASSOUNOALIZAROL, setDFPASSOUNOALIZAROL] = useState(false);
  const [DFFOTOSDEAMOSTRA, setDFFOTOSDEAMOSTRA] = useState(false);
  const [DFFOTOSDATEMPERATURA, setDFFOTOSDATEMPERATURA] = useState(false);
  const [DFAMOSTRADEQUALIDE, setDFAMOSTRADEQUALIDE] = useState(false);
  const [DFAMOSTRA, setDFAMOSTRA] = useState(false);
  const [DFLACRE, setDFLACRE] = useState(false);

  const config = {
    DFDISTRIBUIR: DFDISTRIBUIR ? 'S' : 'N',
    // DFQUALIDADE: DFQUALIDADE ? 'S' : 'N',
    DFQUALIDADE: 'S',
    DFREGUAFRENTE: DFREGUAFRENTE ? 'S' : 'N',
    DFREGUAATRAS: DFREGUAATRAS ? 'S' : 'N',
    DFTERMOMENTRO: DFTERMOMENTRO ? 'S' : 'N',
    DFLIBERARCOLETA: DFLIBERARCOLETA ? 'S' : 'N',
    DFEXAMESENSORIAL: DFEXAMESENSORIAL ? 'S' : 'N',
    DFEXAMEALIZAROL: DFEXAMEALIZAROL ? 'S' : 'N',
    DFPASSOUNOALIZAROL: DFPASSOUNOALIZAROL ? 'S' : 'N',
    DFAMOSTRADEQUALIDE: DFAMOSTRADEQUALIDE ? 'S' : 'N',
    DFFOTOSDEAMOSTRA: DFFOTOSDEAMOSTRA ? 'S' : 'N',
    DFFOTOSDATEMPERATURA: DFFOTOSDATEMPERATURA ? 'S' : 'N',
    DFAMOSTRA: DFAMOSTRA ? 'S' : 'N',
    DFLACRE: DFLACRE ? 'S' : 'N',
  };

  const modifyValue = ({ setValue, value }: IModifyValue) => {
    setValue(!value);
  };

  return (
    <ModalConfiguration
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={openCreateNewConfiguration}
      medium={true}
    >
      <ColumnItens>
        <ContainerItemMediumModal>
          <span>Forçar Medição da régua da frontal: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFREGUAFRENTE,
                setValue: setDFREGUAFRENTE,
              })
            }
            isOpen={DFREGUAFRENTE}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar Medição da régua trazeira: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFREGUAATRAS,
                setValue: setDFREGUAATRAS,
              })
            }
            isOpen={DFREGUAATRAS}
          />
        </ContainerItemMediumModal>
        {/* <ContainerItemMediumModal>
          <span>Forçar envio de imagem de qualidade: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFQUALIDADE,
                setValue: setDFQUALIDADE,
              })
            }
            isOpen={DFQUALIDADE}
          />
        </ContainerItemMediumModal> */}

        <ContainerItemMediumModal>
          <span>Forçar distribuição do leite: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFDISTRIBUIR,
                setValue: setDFDISTRIBUIR,
              })
            }
            isOpen={DFDISTRIBUIR}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar contraprova de tempertura: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFTERMOMENTRO,
                setValue: setDFTERMOMENTRO,
              })
            }
            isOpen={DFTERMOMENTRO}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar liberação da coleta: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFLIBERARCOLETA,
                setValue: setDFLIBERARCOLETA,
              })
            }
            isOpen={DFLIBERARCOLETA}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de exame sensorial: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFEXAMESENSORIAL,
                setValue: setDFEXAMESENSORIAL,
              })
            }
            isOpen={DFEXAMESENSORIAL}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de exame alizarol: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFEXAMEALIZAROL,
                setValue: setDFEXAMEALIZAROL,
              })
            }
            isOpen={DFEXAMEALIZAROL}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de passou no alizarol: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFPASSOUNOALIZAROL,
                setValue: setDFPASSOUNOALIZAROL,
              })
            }
            isOpen={DFPASSOUNOALIZAROL}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de amostra de qualidade: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFAMOSTRADEQUALIDE,
                setValue: setDFAMOSTRADEQUALIDE,
              })
            }
            isOpen={DFAMOSTRADEQUALIDE}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de fotos da amostra: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFFOTOSDEAMOSTRA,
                setValue: setDFFOTOSDEAMOSTRA,
              })
            }
            isOpen={DFFOTOSDEAMOSTRA}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio de fotos da temeperatura: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFFOTOSDATEMPERATURA,
                setValue: setDFFOTOSDATEMPERATURA,
              })
            }
            isOpen={DFFOTOSDATEMPERATURA}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio da amostra: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFAMOSTRA,
                setValue: setDFAMOSTRA,
              })
            }
            isOpen={DFAMOSTRA}
          />
        </ContainerItemMediumModal>

        <ContainerItemMediumModal>
          <span>Forçar envio do lacre: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFLACRE,
                setValue: setDFLACRE,
              })
            }
            isOpen={DFLACRE}
          />
        </ContainerItemMediumModal>

        <button
          className="btn-action-button"
          onClick={() => onCreateNewConfiguration({ config })}
        >
          Salvar
        </button>
      </ColumnItens>
    </ModalConfiguration>
  );
};
