import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListOpenCollects } from '../../../components/List/ListOpenCollects';
import { LoadingData } from '../../../components/LoadingData';
import { ConfirmCloseCollect } from '../../../components/Modal/ConfirmCloseCollect';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useCollectContext } from '../../../context/ProviderPage/Coleta';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Coleta = () => {
  const { setSelectedPage } = usePageContext();
  const {
    loadingCollect,
    loadCollectWithInterval,
    collect,
    openSideModalFilterCollectAction,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useCollectContext();

  useEffect(() => {
    setSelectedPage({ option: 'coleta' });
  }, []);

  useEffect(() => {
    loadCollectWithInterval();
    const interval = setInterval(loadCollectWithInterval, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'coleta' }}
        pageTitle="Coleta"
        filterAction={openSideModalFilterCollectAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingCollect === true ? 'center' : 'space-between'
            }`,
            overflowY: 'auto',
          }}
        >
          {loadingCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {collect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma coleta foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListOpenCollects data={collect} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: collect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <ConfirmCloseCollect />
      </ContainerPage>
    </>
  );
};

export { Coleta };
