import { useLineContext } from '../../../context/ProviderPage/Linha';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import {
  ColumnItens,
  ContainerItemModal,
  RowItens,
} from '../ModalInfoTemplete/styles';

export const InfoLine = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedLine } = useLineContext();
  const {
    DFATIVO,
    DFDESCREGIONAL,
    DFNOMECARRETEIRO,
    DFIDLINHA,
    DFNOMEFANTASIA,
    DFNOMELINHA,
  } = selectedLine;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <ColumnItens>
        <RowItens>
          <ContainerItemModal>
            <label>Código</label>
            <div>
              <span>{DFIDLINHA || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Linha </label>
            <div>
              <span>{DFNOMELINHA || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
        <RowItens>
          <ContainerItemModal>
            <label>Região</label>
            <div>
              <span>{DFDESCREGIONAL || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Unidade</label>
            <div>
              <span>{DFNOMEFANTASIA || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
        <RowItens>
          <ContainerItemModal>
            <label>Carreteiro</label>
            <div>
              <span>{DFNOMECARRETEIRO || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Ativo</label>
            <img src={returnCheckType(DFATIVO!)} />
          </ContainerItemModal>
        </RowItens>
      </ColumnItens>
    </ModalInfoTemplete>
  );
};
