import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Vehicle } from '../../../types/Vehicle';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterVehicle: boolean;
  openSideModalFilterVehicleAction: () => void;

  DFIDVEICULO: string;
  setDFIDVEICULO: Dispatch<SetStateAction<string>>;
  DFDESCVEICULO: string;
  setDFDESCVEICULO: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFPLACAVEICULO: string;
  setDFPLACAVEICULO: Dispatch<SetStateAction<string>>;

  filterVehicleAction: () => void;
  cleanAllInputsAction: () => void;
  loadVehicleAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedVehicle: Vehicle;
  setSelectedVehicle: Dispatch<SetStateAction<Vehicle>>;

  vehicle: Vehicle[];
  setVehicle: Dispatch<SetStateAction<Vehicle[]>>;

  loadingVehicle: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const VehicleContextProvider = ({ children }: IContextProps) => {
  const [DFIDVEICULO, setDFIDVEICULO] = useState<string>('');
  const [DFDESCVEICULO, setDFDESCVEICULO] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFPLACAVEICULO, setDFPLACAVEICULO] = useState<string>('');

  const [loadingVehicle, setIsLoadingVehicle] = useState<boolean>(false);
  const [openSideModalFilterVehicle, setOpenSideModalFilterVehicle] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>({});
  const [vehicle, setVehicle] = useState<Vehicle[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);

  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDVEICULO = `DFIDVEICULO=${DFIDVEICULO}`;
  const DESCVEICULO = `DFDESCVEICULO=${DFDESCVEICULO}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const PLACAVEICULO = `DFPLACAVEICULO=${DFPLACAVEICULO}`;

  const openSideModalFilterVehicleAction = () => {
    setOpenSideModalFilterVehicle(!openSideModalFilterVehicle);
  };
  useEffect(() => {
    if (hasClick) {
      setIsLoadingVehicle(true);
      META_COLLECT_API.get(
        `/veiculo?${Limit}&${Offset}&${IDVEICULO}&${DESCVEICULO}&${ATIVO}&${PLACAVEICULO}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setVehicle(response.data.unidades);
          setQuantity(response.data.quantidade);
          setIsLoadingVehicle(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingVehicle(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingVehicle(true);
    META_COLLECT_API.get(`/veiculo?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setVehicle(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingVehicle(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingVehicle(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDVEICULO('');
    setDFATIVO('');
    setDFPLACAVEICULO('');
    setDFDESCVEICULO('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterVehicleAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterVehicleAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterVehicleAction();
    setIsLoadingVehicle(true);
    META_COLLECT_API.get(
      `/veiculo?${Limit}&offset=0&${IDVEICULO}&${DESCVEICULO}&${ATIVO}&${PLACAVEICULO}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setVehicle(response.data.unidades);
        setQuantity(response.data.quantidade);
        setIsLoadingVehicle(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingVehicle(false);
        setStart(1);
      });
  };

  const loadVehicleAction = () => {
    setIsLoadingVehicle(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'caminhao') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        filterVehicleAction,
        loadVehicleAction,
        limit,
        loadingVehicle,
        offset,
        openInfo,
        openSideModalFilterVehicleAction,
        page,
        quantity,
        vehicle,
        selectedVehicle,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setVehicle,
        setSelectedVehicle,
        DFATIVO,
        DFDESCVEICULO,
        DFIDVEICULO,
        DFPLACAVEICULO,
        openSideModalFilterVehicle,
        setDFATIVO,
        setDFDESCVEICULO,
        setDFIDVEICULO,
        setDFPLACAVEICULO,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useVehicleContext() {
  const context = useContext(Context);
  return context;
}

export { VehicleContextProvider, useVehicleContext };
