const formatReceiveSeconds = (seconds: number) => {
  const timeUnits = [
    { unit: 'ano', divisor: 31536000 },
    { unit: 'mês', divisor: 2419200 },
    { unit: 'semana', divisor: 604800 },
    { unit: 'dia', divisor: 86400 },
    { unit: 'hora', divisor: 3600 },
    { unit: 'minuto', divisor: 60 },
    { unit: 'segundo', divisor: 1 },
  ];

  const timeStrings = [];

  for (const unit of timeUnits) {
    const value = Math.floor(seconds / unit.divisor);
    if (value > 0) {
      timeStrings.push(
        `${value} ${unit.unit}${
          value !== 1 ? `${unit.unit === 'mês' ? 'es' : 's'} ` : ''
        }`,
      );
      seconds %= unit.divisor;
    }
  }

  if (timeStrings.length === 0) {
    return 'menos de um segundo';
  }

  return timeStrings.join(' ');
};

export { formatReceiveSeconds };

// console.log(formatReceiveSeconds(45));
// console.log(formatReceiveSeconds(75));
// console.log(formatReceiveSeconds(3660));
// console.log(formatReceiveSeconds(3665));
// console.log(formatReceiveSeconds(86400));
// console.log(formatReceiveSeconds(879600));
// console.log(formatReceiveSeconds(31536000));
// console.log(formatReceiveSeconds(9831536000));
