import { useEffect } from 'react';

import { useSiloContext } from '../../../context/ProviderPage/Silo';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterSilos = () => {
  const {
    openSideModalFilterSilo,
    openSideModalFilterSiloAction,
    DFIDUNIDADE,
    setDFIDUNIDADE,
    cleanAllInputsAction,
    filterSiloAction,
    DFIDSILO,
    setDFIDSILO,
    DFDESCRICAOSILO,
    setDFDESCRICAOSILO,
    DFCAPACIDADE,
    setDFCAPACIDADE,
    DFCOLETASELETIVA,
    setDFCOLETASELETIVA,
  } = useSiloContext();

  useEffect(() => {
    if (Number(DFIDUNIDADE) < 0) {
      setDFIDUNIDADE('0');
    }
    if (Number(DFIDSILO) < 0) {
      setDFIDSILO('0');
    }
    if (Number(DFCAPACIDADE) < 0) {
      setDFCAPACIDADE('0');
    }
  }, [DFIDUNIDADE, DFIDSILO, DFCAPACIDADE]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterSilo}
      onchange={openSideModalFilterSiloAction}
      title="Filtrar Silo"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterSiloAction}
    >
      <FilterInput
        onChange={setDFIDSILO}
        value={DFIDSILO}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFIDUNIDADE}
        value={DFIDUNIDADE}
        title="Unidade"
        type="text"
      />
      <FilterInput
        onChange={setDFDESCRICAOSILO}
        value={DFDESCRICAOSILO}
        title="Descrição"
        type="text"
      />
      <FilterInput
        onChange={setDFCAPACIDADE}
        value={DFCAPACIDADE}
        title="Capacidade"
        type="number"
        min={0}
      />
      <FilterSelectInput
        onChange={setDFCOLETASELETIVA}
        value={DFCOLETASELETIVA}
        title="Coleta Seletiva"
      />
    </SideMenuFilterTemplete>
  );
};
