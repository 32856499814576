import { MouthTank } from '../../../types/MouthTank';
import { CustomLabelWithInput } from '../../CustomLabelWithInput';
import { ListTankMouthsContainer, ListTankMouthsItem } from './styles';

interface IListTankMouths {
  data: MouthTank[];
  handleUpdateTankMouth: (id: number, field: string, value: string) => void;
}

const ListTankMouths = ({ data, handleUpdateTankMouth }: IListTankMouths) => {
  return (
    <ListTankMouthsContainer>
      {data.map(({ DFBOCA, DFLACRE, DFTEMPERATURA }) => {
        return (
          <ListTankMouthsItem key={DFBOCA}>
            <h2>Boca {DFBOCA}</h2>
            <CustomLabelWithInput
              label="Temperatura"
              value={DFTEMPERATURA!}
              handleUpdateTankMouth={handleUpdateTankMouth}
              placeholder="Temperatura(ºC)"
              id={Number(DFBOCA)}
              field="DFTEMPERATURA"
            />
            <CustomLabelWithInput
              label="Lacre"
              value={DFLACRE!}
              handleUpdateTankMouth={handleUpdateTankMouth}
              placeholder="Lacre"
              id={Number(DFBOCA)}
              field="DFLACRE"
            />
          </ListTankMouthsItem>
        );
      })}
    </ListTankMouthsContainer>
  );
};

export { ListTankMouths };
