import { FilterBondCollect } from './FilterBondCollect';
import { FilterClosedCollect } from './FilterClosedCollect';
import { FilterDischargeCollect } from './FilterDischargeCollect';
import { FilterExcludedCollect } from './FilterExcludedCollect';
import { FilterLines } from './FilterLines';
import { FilterOpenedCollect } from './FilterOpenedCollect';
import { FilterProperties } from './FilterProperties';
import { FilterRegions } from './FilterRegions';
import { FilterReleasedCollect } from './FilterReleasedCollect';
import { FilterScheduleCollect } from './FilterScheduledCollect';
import { FilterSilos } from './FilterSilos';
import { FilterTanks } from './FilterTanks';
import { FilterUnities } from './FilterUnities';
import { FilterVehicles } from './FilterVehicles';
import { FilterWagoner } from './FilterWagoner';

export const RenderAllFilters = () => {
  return (
    <>
      <FilterUnities />
      <FilterSilos />
      <FilterRegions />
      <FilterLines />
      <FilterTanks />
      <FilterProperties />
      <FilterWagoner />
      <FilterVehicles />
      <FilterScheduleCollect />
      <FilterOpenedCollect />
      <FilterDischargeCollect />
      <FilterClosedCollect />
      <FilterReleasedCollect />
      <FilterBondCollect />
      <FilterExcludedCollect />
    </>
  );
};
