const calculateDistanceTraveled = (locations: number[][]): number => {
  if (locations.length < 2) {
    return 0;
  }

  const earthRadius = 6371;
  let totalDistance = 0;

  for (let i = 1; i < locations.length; i += 1) {
    const lat1 = locations[i - 1][1];
    const lon1 = locations[i - 1][0];
    const lat2 = locations[i][1];
    const lon2 = locations[i][0];

    const lat1Rad = (lat1 * Math.PI) / 180;
    const lon1Rad = (lon1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;
    const lon2Rad = (lon2 * Math.PI) / 180;

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1Rad) *
        Math.cos(lat2Rad) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    totalDistance += distance;
  }

  return totalDistance;
};

// Example usage:
// const locations: Geometry[] = [
//   { type: 'Point', coordinates: [-74.006, 40.7128] }, // New York
//   { type: 'Point', coordinates: [-118.2437, 34.0522] }, // Los Angeles
//   { type: 'Point', coordinates: [-0.1278, 51.5074] }, // London
// ];

// const distanceTraveled = calculateDistanceTraveled(locations);
// console.log(`Total estimated distance: ${distanceTraveled.toFixed(2)} km`);

export { calculateDistanceTraveled };
