/* eslint-disable no-return-assign */
/* eslint-disable no-promise-executor-return */
import qrcode from 'qrcode';
import { toast } from 'react-toastify';

import themes from '../../../../../themes';
import { Tank } from '../../../types/Tank';

const generateQRCode = async (tank: Tank) => {
  try {
    const current = tank;
    const canvas = document.createElement('canvas');
    await qrcode.toCanvas(canvas, JSON.stringify(current));
    const dataUrl = canvas.toDataURL('image/png');
    const { DFDESCTANQUE } = tank;
    const logo = `        
      <div class="logo-container">
        <img alt="logo-empresa" class="logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png"/>
      </div>
    `;
    const htmlContent = `
      <html>
        <head>
          <style>
          body, h5, span {
            font-family: 'Montserrat', sans-serif;
          }
          body {
            display: grid; 
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
          }
          .card {
            border: 0.4px solid black;
            height: 335px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            page-break-inside: avoid;
            position: relative;
          }
          .card-content{
            display: flex;
            flex-direction: column;
            margin: 0;
          }
          .card-title {
            color: ${themes.COLORS.GREEN_DARK};
            font-weight: bold;
            margin: 0;
            margin-top: 6px;
          }
          img {
            align-self: center;
            width: 82%;
            height: auto;
            margin: 0;
          }
          .card-footer {
            width: 100%;
          }      
          .logo-container {
            position: absolute;
            bottom: 0px; 
            right: 10px;
            margin-bottom: 6px;
          } 
          .logo {
            width: 70px; 
            height: 100%;
          }
          span{
            margin: 0;
          }
          @media print {
            body {
              background-color: white;
            }
            .card {
              border: 0.4px solid black;
              background-color: white !important; 
            }
          }
        </style>
        
          <title>Meta Collect</title>
        </head>
        <body>
          <div class="card">
            <div class="card-content"> 
              <h5 class="card-title">IDENTIFICAÇÃO DE TANQUE</h5>
              <img src="${dataUrl}" alt="${DFDESCTANQUE}" />
              <span>${DFDESCTANQUE}</span>
            </div>
            <div class="card-footer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 400"><path fill=${themes.COLORS.GREEN_DARK} fill-opacity="1" d="M0,192L80,202.7C160,213,400,235,480,202.7C640,171,800,85,960,69.3C1120,53,1280,107,1360,133.3L1440,160L1440,400L1360,400C1280,400,1120,400,960,400C800,400,640,400,480,400C400,400,160,400,80,400L0,400Z"></path></svg>
            </div>
          </div>
        </body>
      </html>
    `;

    let printWindow = window.open(
      '',
      '_blank',
      'width=700, height=700',
    ) as Window;

    if (!printWindow) {
      toast.error('Falha ao abrir janela de impressão');
    }
    printWindow.document.write(htmlContent);
    const images = printWindow.document.querySelectorAll('img');
    const promises = Array.from(images).map(
      img => new Promise(resolve => (img.onload = resolve)),
    );
    await Promise.all(promises);
    printWindow.document.close();
    printWindow.print();
  } catch (error) {
    toast.error(
      'Ocorreu um erro inesperado ao carregar as informações do tanque, tente novamente dentro de instantes!',
    );
  }
  return false;
};
export { generateQRCode };
