/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { BackButton } from '../../../../components/Buttons/BackButton';
import { ListTanksToReleaseWithCollet } from '../../../../components/List/ListTanksToReleaseWithCollet';
import { LoadingData } from '../../../../components/LoadingData';
import { BondStorageProducerWithCollect } from '../../../../components/Modal/BondStorageProducerWithCollect';
import { PageHeader } from '../../../../components/PageHeader';
import { useTankToReleaseContextProvider } from '../../../../context/ProviderPage/Vincular';
import { usePageContext } from '../../../../context/usePage';
import { ContainerPage, PageContent } from '../../../../styles/global.styles';
import { StorageBondToCollect } from '../../../../types/StorageBondToCollect';

const SelectedTankToRelease = () => {
  const {
    setOpenInfo,
    openInfo,
    loadingStorageProducer,
    loadStorageProducers,
  } = useTankToReleaseContextProvider();
  const { tankid } = useParams();
  const { version, setSelectedPage } = usePageContext();

  const [storageProducer, setStorageProducer] = useState<
    StorageBondToCollect[]
  >([]);

  const loadStorages = () => {
    setOpenInfo(false);
    loadStorageProducers({ idtank: tankid!, setStorageProducer });
  };

  useEffect(() => {
    if (tankid) {
      setOpenInfo(false);
      setSelectedPage({ option: 'vincular' });
      loadStorageProducers({ idtank: tankid!, setStorageProducer });
    }
  }, [tankid]);

  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'vincular' }}
        pageTitle="Vincular"
        hasFilter={false}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: loadingStorageProducer ? 'center' : 'flex-start',
          }}
        >
          {loadingStorageProducer ? (
            <LoadingData title="Carregando dados" />
          ) : (
            <>
              <BackButton
                title="Voltar"
                action={() => navigate(`/vincular${version}`)}
              />
              <ListTanksToReleaseWithCollet storages={storageProducer} />
            </>
          )}
        </PageContent>
      </ContainerPage>
      <BondStorageProducerWithCollect
        {...{
          closeModal: setOpenInfo,
          isOpen: openInfo,
          DFIDTANQUE: tankid!,
          loadStorages,
        }}
      />
    </>
  );
};

export { SelectedTankToRelease };
