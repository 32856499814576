import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { META_COLLECT_API } from '../../../api';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { Register } from '../../../types/Register';
import { TankAndProperty } from '../../../types/TankAndProperty';
import { BackButton } from '../../Buttons/BackButton';
import { ListRegister } from '../../List/ListRegister';
import { SelectedRegisterContainer } from './styles';

interface ISelectedRegister {
  selectedItem: TankAndProperty;
  setSelectedItem: Dispatch<SetStateAction<TankAndProperty | null>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const SelectedRegister = ({
  selectedItem,
  setSelectedItem,
  isOpen,
  setIsOpen,
  setSelectedRegister,
}: ISelectedRegister) => {
  const [loading, setLoading] = useState<boolean>();
  const [registers, setRegisters] = useState<Register[]>([]);

  const { path } = useSigninContext();

  useEffect(() => {
    setLoading(true);
    META_COLLECT_API.get(
      `/registro?DFIDITEMCOLETA=${selectedItem.DFIDITEMCOLETA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setRegisters(response.data.registros);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <SelectedRegisterContainer>
      <BackButton action={() => setSelectedItem(null)} title="Voltar" />
      {/* <ContainerListSelectedRegister> */}
      <ListRegister
        loading={loading!}
        registers={registers}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setSelectedRegister={setSelectedRegister}
      />
      {/* </ContainerListSelectedRegister> */}
    </SelectedRegisterContainer>
  );
};

export { SelectedRegister };
