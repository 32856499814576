import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import {
  sideMenuParams,
  componentsHeight,
} from '../../../utils/sideMenuParams';

const { CLOSED_WIDTH, OPEN_WIDTH } = sideMenuParams;
const { FIRST_HEADER } = componentsHeight;
interface IContainerModal {
  width?: number;
  isOpen: boolean;
}
interface IContentModal {
  isOpen: boolean;
  periodic: boolean;
}

export const ContainerOverlayModal = styled.div<IContainerModal>`
  transition: 0.3s all;
  z-index: ${({ isOpen }) => (isOpen ? 20 : -1)};
  ${({ width }) =>
    width === CLOSED_WIDTH
      ? css`
          width: calc(100vw - ${CLOSED_WIDTH}px);
        `
      : css`
          width: calc(100vw - ${OPEN_WIDTH}px);
        `};
  @media (max-width: 650px) {
    width: 100%;
  }
  height: calc(100% - ${FIRST_HEADER}px);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: ${({ isOpen, theme }) =>
    isOpen && transparentize(0.4, theme.COLORS.BLACK_1000)};

  .inform-option {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .row-inform-silo-volume {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      color: ${({ theme }) => theme.COLORS.GREY};
      font-size: 1rem;
      font-weight: 700;
    }

    input {
      border: 0px;
      width: 100%;
      height: 60px;
      outline: 0;
      border-radius: 0.6rem;
      padding: 0 1.6rem;
      font-size: 1rem;
      background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
    }
    button {
      margin-top: 1rem;
      border-radius: 0.6rem;
      font-size: 1rem;
      font-weight: bold;
      border: 0px;
      padding: 1rem;
      height: 60px;
      color: ${({ theme }) => theme.COLORS.WHITE};
      background-color: ${({ theme }) => theme.COLORS.GREEN_DARK};
      align-self: flex-end;
      transition: 0.3s all;
      &:hover {
        background-color: ${({ theme }) =>
          transparentize(0.05, theme.COLORS.GREEN_DARK)};
      }
    }
  }
`;

export const ContentModal = styled.div<IContentModal>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: 0.3s all;
  min-width: ${({ isOpen }) => (isOpen ? '65%' : '0')};
  min-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
  max-height: 430px;
  border-radius: 0.6rem;
  background-color: ${({ isOpen, theme }) => isOpen && theme.COLORS.WHITE};

  @media (max-width: 820px) {
    max-height: 400px;
  }
  @media (max-width: 400px) {
    max-height: 260px;
  }
  @media (max-width: 650px) {
    width: 90%;
  }

  .close {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
  }

  .close-button-selected-image {
    background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin: 0.4rem;
    img {
      width: 25px;
      height: 25px;
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .not-showed-modal {
    transition: 0.3s all;
    padding: 1rem;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 7px;
      height: 1px;
    }

    img:first-of-type {
      transition: 0.3s all;
      width: 400px;
      height: 400px;
      @media (max-width: 820px) {
        max-width: 300px;
        max-height: 300px;
      }

      @media (max-width: 400px) {
        max-width: 220px;
        max-height: 220px;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 1rem;
    }

    P {
      max-width: 400px;
    }
  }
`;
