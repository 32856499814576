import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListDevices } from '../../../components/List/ListDevices';
import { LoadingData } from '../../../components/LoadingData';
import { PageHeader } from '../../../components/PageHeader';
import { useDeviceContext } from '../../../context/ProviderPage/Device';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Devices = () => {
  const { setSelectedPage } = usePageContext();

  const { devices, loadingDevices, loadDevicesAction } = useDeviceContext();

  useEffect(() => {
    setSelectedPage({ option: 'dispositivo' });
    loadDevicesAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'dispositivo' }}
        pageTitle="Dispositivos"
        hasFilter={false}
      />

      <ContainerPage>
        <PageContent
          style={{
            overflowY: 'auto',
            justifyContent: `${
              loadingDevices === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingDevices ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {devices.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma descarga foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <ListDevices {...{ devices }} />
              )}
            </>
          )}
        </PageContent>
      </ContainerPage>
    </>
  );
};

export { Devices };
