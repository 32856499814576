import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListBondCollect } from '../../../components/List/ListBondCollect';
import { LoadingData } from '../../../components/LoadingData';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useBondCollectContext } from '../../../context/ProviderPage/Vinculada';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Vinculada = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingBondCollect,
    bondCollect,
    openSideModalFilterBondCollectAction,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
    loaddingBondCollectAction,
  } = useBondCollectContext();

  const { user } = useSigninContext();

  useEffect(() => {
    if (user) {
      setSelectedPage({ option: 'vinculada' });
      loaddingBondCollectAction();
    }
  }, [user]);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'vinculada' }}
        pageTitle="Vinculada"
        filterAction={openSideModalFilterBondCollectAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingBondCollect === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingBondCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {bondCollect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma descarga foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListBondCollect data={bondCollect} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: bondCollect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
      </ContainerPage>
    </>
  );
};

export { Vinculada };
