export const formatFullDateWithDateAndTime = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  // const seconds = String(dateObj.getSeconds()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}`;
  return formattedDate;
};
