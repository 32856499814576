/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Close from '../../../assets/Close.svg';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { DischargeSilo } from '../../../types/DischargeSilo';
import { Silo } from '../../../types/Silo';
import { OptionSelecetInput } from '../../Inputs/OptionSelecetInput';
import { ContainerOverlayModal, ContentModal } from './styles';

export interface IModal {
  isOpen?: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  silos: Silo[];
  createdDischargeCollectSilo: Silo;
  setCreatedDischargeCollectSilo: Dispatch<SetStateAction<Silo>>;
  handleCreateNewDischargeSilo: (silo: DischargeSilo) => void;
  volume: number | null;
  setVolume: Dispatch<SetStateAction<number | null>>;
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  hour: string;
  setHour: Dispatch<SetStateAction<string>>;
  DFIDCOLETA: string;
}

export const CreateNewSiloInCollectDischarge = ({
  closeModal,
  isOpen,
  createdDischargeCollectSilo,
  setCreatedDischargeCollectSilo,
  silos,
  handleCreateNewDischargeSilo,
  volume,
  setVolume,
  date,
  hour,
  setDate,
  setHour,
  DFIDCOLETA,
}: IModal) => {
  const { sideMenuWidth } = useSideMenuContext();

  const verifyClose = (e: any) => {
    if (e.target.id === 'container-overlay-modal') {
      closeModal(false);
    }
  };

  const [showContentModal, setShowContentModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowContentModal(true);
      }, 0);
    } else {
      setTimeout(() => {
        setShowContentModal(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ContainerOverlayModal
      isOpen={isOpen!}
      onClick={verifyClose}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal isOpen={isOpen!} periodic={showContentModal}>
        <div className="close">
          <div className="close-button-selected-image">
            <img src={Close} onClick={() => closeModal(true)} />
          </div>
          <OptionSelecetInput
            {...{
              title: 'Selecionar silo',
              setCreatedDischargeCollectSilo,
              placeholder: 'Selecionar silo',
              silos,
              value: createdDischargeCollectSilo.DFDESCRICAOSILO,
            }}
          />
          {createdDischargeCollectSilo && (
            <div className="row-inform-silo-volume">
              <div className="inform-option">
                <label>Volume</label>
                <input
                  value={volume || ''}
                  onChange={e => setVolume(Number(e.target.value))}
                  placeholder="Informar volume:"
                />
              </div>
              <div className="inform-option">
                <label>Data</label>
                <input
                  value={date || ''}
                  onChange={e => setDate(e.target.value)}
                  type="date"
                />
              </div>
              <div className="inform-option">
                <label>Hora</label>
                <input
                  value={hour || ''}
                  onChange={e => setHour(e.target.value)}
                  type="time"
                />
              </div>
              <button
                onClick={() =>
                  handleCreateNewDischargeSilo({
                    silo: createdDischargeCollectSilo,
                    volume: volume!,
                    date,
                    hour,
                    DFIDCOLETA,
                  })
                }
              >
                Informar volume
              </button>
            </div>
          )}
        </div>
      </ContentModal>
    </ContainerOverlayModal>
  );
};
