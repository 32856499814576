/* eslint-disable consistent-return */
const returnType = (
  DFTIPOREGISTRO:
    | 'A'
    | 'D'
    | 'F'
    | 'C'
    | 'N'
    | 'G'
    | 'Q'
    | 'R'
    | 'T'
    | 'O'
    | 'P'
    | null,
) => {
  switch (DFTIPOREGISTRO) {
    case 'A':
      return 'Inicio da coleta';
    case 'D':
      return 'Descarga da coleta';
    case 'F':
      return 'Fim da coleta';
    case 'C':
      return 'Coleta em propriedade';
    case 'N':
      return 'Não coletado';
    case 'G':
      return 'Geolocalização da coleta';
    case 'Q':
      return 'Qualidade de leite';
    case 'R':
      return 'Régua do leite';
    case 'T':
      return 'Temperatura do leite';
    case 'O':
      return 'Ocorrência em propriedade';
    case 'P':
      return 'Parada Prolongada';
    default:
      return null;
  }
};

const returnRegisterType = (
  DFTIPOREGISTRO:
    | 'A'
    | 'D'
    | 'F'
    | 'C'
    | 'N'
    | 'G'
    | 'Q'
    | 'R'
    | 'T'
    | 'O'
    | 'P'
    | null,
  DFTIPOITEMCOLETA?: 'C' | 'N' | 'S',
) => {
  if (DFTIPOREGISTRO && !DFTIPOITEMCOLETA) {
    return returnType(DFTIPOREGISTRO);
  }
  if (DFTIPOREGISTRO && DFTIPOITEMCOLETA) {
    if (DFTIPOREGISTRO === 'C' && DFTIPOITEMCOLETA === 'C') {
      return 'Coleta em propriedade';
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'C') {
      return 'Coleta em andamento';
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'C') {
      return 'Ocorrência em propriedade';
    }
    if (DFTIPOREGISTRO === 'C' && DFTIPOITEMCOLETA === 'S') {
      return 'Socorro em propriedade';
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'S') {
      return 'Socorro em andamento';
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'S') {
      return 'Ocorrência em propriedade';
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'N') {
      return 'Ocorrência em propriedade';
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'N') {
      return 'Coleta em andamento';
    }
  }
};

export { returnRegisterType, returnType };
