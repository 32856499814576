import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { sideMenuParams } from '../utils/sideMenuParams';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  sideMenuWidth: number | undefined;
  setSideMenuWidth: Dispatch<SetStateAction<number>>;
  sideMenuIsOpen: boolean;
  setSideMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  sideMenuIsOpenMobile: boolean;
  setSideMenuIsOpenMobile: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext({} as IContext);

const SideMenuContextProvider = ({ children }: IContextProps) => {
  const { OPEN_WIDTH } = sideMenuParams;
  const [sideMenuWidth, setSideMenuWidth] = useState<number>(OPEN_WIDTH);
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<boolean>(false);
  const [sideMenuIsOpenMobile, setSideMenuIsOpenMobile] =
    useState<boolean>(false);

  return (
    <Context.Provider
      value={{
        sideMenuWidth,
        setSideMenuWidth,
        sideMenuIsOpen,
        setSideMenuIsOpen,
        setSideMenuIsOpenMobile,
        sideMenuIsOpenMobile,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useSideMenuContext() {
  const context = useContext(Context);
  return context;
}

export { SideMenuContextProvider, useSideMenuContext };
