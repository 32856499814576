import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { OpenCollect } from '../../../types/OpenCollect';
import { deformatDate } from '../../../utils/date/deformatDate';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface ICloseCollect {
  isOpen: boolean;
  collect: OpenCollect | null;
}

interface IContext {
  openSideModalFilterScheduleCollect: boolean;
  openSideModalFilterCollectAction: () => void;

  DFNOMECARRETEIRO: string;
  setDFNOMECARRETEIRO: Dispatch<SetStateAction<string>>;
  DFORDEMCOLETA: string;
  setDFORDEMCOLETA: Dispatch<SetStateAction<string>>;
  DFNOMELINHA: string;
  setDFNOMELINHA: Dispatch<SetStateAction<string>>;
  DFPLACAVEICULO: string;
  setDFPLACAVEICULO: Dispatch<SetStateAction<string>>;
  DFDATACOLETA: string;
  setDFDATACOLETA: Dispatch<SetStateAction<string>>;
  DFDATASAIDA: string;
  setDFDATASAIDA: Dispatch<SetStateAction<string>>;
  DFIDCOLETA: string;
  setDFIDCOLETA: Dispatch<SetStateAction<string>>;
  DFBLOQUEIOERP: string;
  setDFBLOQUEIOERP: Dispatch<SetStateAction<string>>;
  DFTOTALARMAZENADO: string;
  setDFTOTALARMAZENADO: Dispatch<SetStateAction<string>>;
  DFDATAPROGRAMADA: string;
  setDFDATAPROGRAMADA: Dispatch<SetStateAction<string>>;

  filterCollectAction: () => void;
  cleanAllInputsAction: () => void;
  loadCollectAction: () => void;
  loadCollectWithInterval: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedCollect: OpenCollect | undefined;
  setSelectedCollect: Dispatch<SetStateAction<OpenCollect | undefined>>;

  collect: OpenCollect[];
  setCollect: Dispatch<SetStateAction<OpenCollect[]>>;

  loadingCollect: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
  closeCollect: ICloseCollect;
  setCloseCollect: Dispatch<SetStateAction<ICloseCollect>>;
}

const Context = createContext({} as IContext);

const CollectContextProvider = ({ children }: IContextProps) => {
  const [DFNOMECARRETEIRO, setDFNOMECARRETEIRO] = useState<string>('');
  const [DFORDEMCOLETA, setDFORDEMCOLETA] = useState<string>('');
  const [DFNOMELINHA, setDFNOMELINHA] = useState<string>('');
  const [DFPLACAVEICULO, setDFPLACAVEICULO] = useState<string>('');
  const [DFDATACOLETA, setDFDATACOLETA] = useState<string>('');
  const [DFDATASAIDA, setDFDATASAIDA] = useState<string>('');
  const [DFIDCOLETA, setDFIDCOLETA] = useState<string>('');
  const [DFBLOQUEIOERP, setDFBLOQUEIOERP] = useState<string>('');
  const [DFTOTALARMAZENADO, setDFTOTALARMAZENADO] = useState<string>('');
  const [DFDATAPROGRAMADA, setDFDATAPROGRAMADA] = useState<string>('');
  const [loadingCollect, setIsLoadingCollect] = useState<boolean>(false);
  const [openSideModalFilterScheduleCollect, setOpenSideModalFilterCollect] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedCollect, setSelectedCollect] = useState<
    OpenCollect | undefined
  >();
  const [collect, setCollect] = useState<OpenCollect[]>([]);
  const [closeCollect, setCloseCollect] = useState<ICloseCollect>({
    isOpen: false,
    collect: null,
  });

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path, user } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const NOMECARRETEIRO = `DFNOMECARRETEIRO=${DFNOMECARRETEIRO}`;
  const ORDEMCOLETA = `DFORDEMCOLETA=${DFORDEMCOLETA}`;
  const NOMELINHA = `DFNOMELINHA=${DFNOMELINHA}`;
  const PLACAVEICULO = `DFPLACAVEICULO=${DFPLACAVEICULO}`;
  const DATACOLETA = `DFDATACOLETA=${deformatDate(DFDATACOLETA)}`;
  const DATASAIDA = `DFDATASAIDA=${deformatDate(DFDATASAIDA)}`;
  const IDCOLETA = `DFIDCOLETA=${DFIDCOLETA}`;
  const STATUS = `DFSTATUS=A`;
  const BLOQUEIO = `DFBLOQUEIOERP=${DFBLOQUEIOERP}`;
  const TOTALARMAZENADO = `DFTOTALARMAZENADO=${DFTOTALARMAZENADO}`;
  const DATAPROGRAMADA = `DFDATAPROGRAMADA=${deformatDate(DFDATAPROGRAMADA)}`;
  const IDUNIDADE = user?.DFIDUNIDADE
    ? `&DFIDUNIDADE=${user?.DFIDUNIDADE}`
    : '';

  const openSideModalFilterCollectAction = () => {
    setOpenSideModalFilterCollect(!openSideModalFilterScheduleCollect);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingCollect(true);
      META_COLLECT_API.get(
        `/coleta?${Limit}&${Offset}&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setCollect(response.data.coletas);
          setQuantity(response.data.quantidade);
          setIsLoadingCollect(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingCollect(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingCollect(true);
    META_COLLECT_API.get(`/coleta?${Limit}&offset=0&${STATUS}${IDUNIDADE}`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingCollect(false);
        setStart(1);
      });
  };

  const loadCollectWithInterval = () => {
    setIsLoadingCollect(true);
    META_COLLECT_API.get(`/coleta?${Limit}&offset=0&${STATUS}${IDUNIDADE}`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingCollect(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFNOMECARRETEIRO('');
    setDFORDEMCOLETA('');
    setDFNOMELINHA('');
    setDFPLACAVEICULO('');
    setDFDATACOLETA('');
    setDFDATASAIDA('');
    setOpenInfo(false);
    // setDFBLOQUEIOERP('');
    setDFDATAPROGRAMADA('');
    setDFTOTALARMAZENADO('');
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterCollectAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterCollectAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterCollectAction();
    setIsLoadingCollect(true);
    META_COLLECT_API.get(
      `/coleta?${Limit}&offset=0&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingCollect(false);
        setStart(1);
      });
  };

  const loadCollectAction = () => {
    setIsLoadingCollect(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'coleta') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (user?.DFIDUNIDADE) {
      loadCollectAction();
    }
  }, [user]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFORDEMCOLETA,
        filterCollectAction,
        loadCollectAction,
        limit,
        loadingCollect,
        offset,
        openInfo,
        openSideModalFilterScheduleCollect,
        openSideModalFilterCollectAction,
        page,
        quantity,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setCollect,
        DFDATACOLETA,
        DFDATASAIDA,
        DFNOMECARRETEIRO,
        DFNOMELINHA,
        DFPLACAVEICULO,
        DFIDCOLETA,
        collect,
        selectedCollect,
        setDFDATACOLETA,
        setDFDATASAIDA,
        setDFNOMECARRETEIRO,
        setDFNOMELINHA,
        setDFORDEMCOLETA,
        setDFPLACAVEICULO,
        setSelectedCollect,
        setDFIDCOLETA,
        setStart,
        start,
        DFBLOQUEIOERP,
        DFTOTALARMAZENADO,
        setDFBLOQUEIOERP,
        setDFTOTALARMAZENADO,
        DFDATAPROGRAMADA,
        setDFDATAPROGRAMADA,
        loadCollectWithInterval,
        closeCollect,
        setCloseCollect,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useCollectContext() {
  const context = useContext(Context);
  return context;
}

export { CollectContextProvider, useCollectContext };
