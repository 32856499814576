import { ReactComponent as Bond } from '../../../assets/bond.svg';
import { ReactComponent as Milk } from '../../../assets/milk.svg';
import { useTankToReleaseContextProvider } from '../../../context/ProviderPage/Vincular';
import { StorageBondToCollect } from '../../../types/StorageBondToCollect';
import { formatFullDateWithDateAndTime } from '../../../utils/date/formatFullDateWithDateAndTime';
import {
  ListTankToReleaseItem,
  ListTanksToReleaseWithColletContainer,
} from './styles';

interface IListTanksToReleaseWithCollet {
  storages: StorageBondToCollect[];
}

const ListTanksToReleaseWithCollet = ({
  storages,
}: IListTanksToReleaseWithCollet) => {
  const { setOpenInfo, setSelectedStorageProducerToRelease } =
    useTankToReleaseContextProvider();

  return (
    <ListTanksToReleaseWithColletContainer>
      {storages.map((item, index) => {
        const { DFARMAZENAMENTOS, DFDATAEHORA, DFTOTALINFORMADO } = item;
        return (
          <ListTankToReleaseItem
            key={index}
            onClick={() => {
              setSelectedStorageProducerToRelease(item);
              setOpenInfo(true);
            }}
          >
            <Milk width={35} height={35} />
            <div className="row-item-to-release">
              <span>
                <b>Data da coleta: </b>
                {'\n'}
                {formatFullDateWithDateAndTime(DFDATAEHORA)}
              </span>
              <span>
                <b>Total coletado: </b>
                {'\n'}
                {DFTOTALINFORMADO} L
              </span>
              <span>
                <b>Total de armazenamentos: </b>
                {'\n'}
                {DFARMAZENAMENTOS.length}
              </span>
              <button className="button-bond">
                <Bond className="bond-icon" />
                Vincular
              </button>
            </div>
          </ListTankToReleaseItem>
        );
      })}
    </ListTanksToReleaseWithColletContainer>
  );
};

export { ListTanksToReleaseWithCollet };
