import { Link } from 'react-router-dom';

import { usePageContext } from '../../../context/usePage';
import { DischargeCollect } from '../../../types/DischargeCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListDischargeCollects {
  data: DischargeCollect[];
}

export const ListDischargeCollects = ({ data }: IListDischargeCollects) => {
  const { version } = usePageContext();

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ordem Coleta</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Total em litros</th>
            <th className="list-th">Veículo</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Data Programada</th>
            <th className="list-th">Data Saída</th>
            <th className="list-th">Data Coleta</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((collect, index) => {
            const {
              DFDATACOLETA,
              DFDATASAIDA,
              DFIDCOLETA,
              DFNOMECARRETEIRO,
              DFNOMELINHA,
              DFORDEMCOLETA,
              DFPLACAVEICULO,
              DFTOTALARMAZENADO,
              DFDATAPROGRAMADA,
            } = collect;
            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-small">
                  {DFORDEMCOLETA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFTOTALARMAZENADO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {formatDate(DFDATAPROGRAMADA)}
                </td>
                <td className="list-td-small">{formatDate(DFDATASAIDA)}</td>
                <td className="list-td-small">{formatDate(DFDATACOLETA)}</td>
                <td className="list-td-small">
                  <div>
                    <Link to={`/plataforma/${DFIDCOLETA}/acompanhar${version}`}>
                      <ListButton
                        {...{
                          action: () => null,
                          icon: 'arrown-down',
                        }}
                      />
                    </Link>
                    <Link to={`/plataforma/${DFIDCOLETA}${version}`}>
                      <ListButton
                        {...{
                          action: () => null,
                          icon: 'discharge',
                        }}
                      />
                    </Link>
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
