import { Dispatch, SetStateAction } from 'react';

import Lab from '../../assets/collectOptions/lab.svg';
import Map from '../../assets/collectOptions/map.svg';
import Register from '../../assets/collectOptions/Register.svg';
import Statistic from '../../assets/collectOptions/statistic.svg';
import { useSideMenuContext } from '../../context/useSideMenu';
import { MapOption } from '../../types/MapOption';
import { CollectOption, HeaderCollectOptions } from './styles';

interface ICollectOptions {
  setSelectedOption: Dispatch<SetStateAction<MapOption>>;
  selectedOption: MapOption;
}

const CollectOptions = ({
  setSelectedOption,
  selectedOption,
}: ICollectOptions) => {
  const { setSideMenuIsOpen } = useSideMenuContext();

  const options: MapOption[] = [
    { name: 'statistic', title: 'Estatísticas', id: 1 },
    { name: 'map', title: 'Localização', id: 2 },
    { name: 'register', title: 'Registros', id: 3 },
    { name: 'lab', title: 'Laboratório', id: 4 },
  ];

  const returnOptionIcon = (name: string) => {
    switch (name) {
      case 'map':
        return <img src={Map} />;
      case 'statistic':
        return <img src={Statistic} />;
      case 'register':
        return <img src={Register} />;
      case 'lab':
        return <img src={Lab} />;
      default:
        return null;
    }
  };

  const handleSelectOption = (option: MapOption) => {
    if (option.id === 2) {
      setSideMenuIsOpen(true);
      setSelectedOption(option);
    } else {
      setSelectedOption(option);
    }
  };

  return (
    <HeaderCollectOptions>
      {options.map(option => {
        const { id, name, title } = option;
        return (
          <CollectOption
            selectedOption={selectedOption}
            option={option}
            key={id}
            onClick={() => handleSelectOption(option)}
          >
            {returnOptionIcon(name)}
            <span>{title}</span>
            <div />
          </CollectOption>
        );
      })}
    </HeaderCollectOptions>
  );
};

export { CollectOptions };
