import { Dispatch, SetStateAction } from 'react';

import { LaboratoryCollectItem } from '../../../types/LaboratoryCollectItem';
import { CollectItemLaboratoryItem } from '../../CollectItemLaboratoryItem';
import { ContainerLaboratory } from './styles';

interface IListColllectLaboratory {
  data: LaboratoryCollectItem[];
  loading: boolean;
  setSelectedItem: Dispatch<SetStateAction<LaboratoryCollectItem | null>>;
}

const ListColllectLaboratory = ({
  data,
  loading,
  setSelectedItem,
}: IListColllectLaboratory) => {
  return (
    <ContainerLaboratory
      style={{
        justifyContent: `${!loading && data.length > 0 && 'space-between'}`,
      }}
    >
      {data.map((item, index) => {
        return (
          <CollectItemLaboratoryItem
            key={index}
            action={setSelectedItem}
            data={item}
          />
        );
      })}
    </ContainerLaboratory>
  );
};

export { ListColllectLaboratory };
