/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { useTankContext } from '../../../context/ProviderPage/Tanque';
import { Tank } from '../../../types/Tank';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { EmptyButton } from '../../Buttons/ListButton/styles';
import { AnimateTr, ContainerListPage } from '../styles';
import { generateQRCode } from './services';

interface IListTanks {
  data: Tank[];
}

export const ListTanks = ({ data }: IListTanks) => {
  const { setOpenInfo, setSelectedTank } = useTankContext();
  const verifyClose = (e: any, tank: Tank) => {
    if (e.target.id !== 'QRCODE') {
      setOpenInfo(true);
      setSelectedTank(tank);
      return false;
    }
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Coleta Seletiva</th>
            <th className="list-th">Código</th>
            <th className="list-th">Descrição</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Produtores</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((tank, index) => {
            const {
              DFATIVO,
              DFCOLETASELETIVA,
              DFDESCTANQUE,
              DFIDTANQUE,
              DFNOMELINHA,
              DFQUANTIDADEDEVINCULOS,
            } = tank;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={e => verifyClose(e, tank)}
              >
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFCOLETASELETIVA!)}
                  />
                </td>
                <td className="list-td-small">
                  {DFIDTANQUE || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCTANQUE || 'Não informado'}
                </td>

                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFQUANTIDADEDEVINCULOS || '0'}
                </td>
                <td className="list-td-small">
                  <div>
                    <ListButton
                      id="select-tank"
                      {...{
                        action: (e: any) => verifyClose(e, tank),
                        icon: 'search',
                      }}
                    />
                    {DFATIVO === 'S' ? (
                      <ListButton
                        id="QRCODE"
                        {...{
                          action: () => generateQRCode(tank),
                          icon: 'qrcode',
                        }}
                      />
                    ) : (
                      <EmptyButton />
                    )}
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
