import { darken } from 'polished';
import styled from 'styled-components';

interface IContainerSmallButtonIcon {
  size: number;
  value: boolean;
}

export const ContainerSmallButtonIcon = styled.div<IContainerSmallButtonIcon>`
  height: ${({ size }) => size}px;
  border-radius: 25px;
  min-width: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.COLORS.GREEN_30};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  transform: ${({ value }) => (value ? 'rotate(-180deg)' : 'rotate(0deg)')};
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.COLORS.GREEN_30)};
  }
  @media (max-width: 650px) {
    display: none;
  }

  img {
    /* margin-right: 0.6rem; */
    width: 25px;
    height: 25px;
  }
`;
