import { useEffect, useState } from 'react';

import { usePageContext } from '../../../../../context/usePage';
import { useSideMenuContext } from '../../../../../context/useSideMenu';
import { IOptionMenu } from '../../../../../types/OptionMenu';
import { returnIcon } from '../../../../../utils/returnIcon';
import { ContainerOption } from './styles';

const Option = ({ option, title, toPath, action, selected }: IOptionMenu) => {
  const { sideMenuIsOpen } = useSideMenuContext();
  const [closeLi, setCloseLi] = useState(false);
  const { version } = usePageContext();

  useEffect(() => {
    setTimeout(() => {
      setCloseLi(sideMenuIsOpen);
    }, 140);
  }, [sideMenuIsOpen]);

  return (
    <ContainerOption
      sidemenu={closeLi ? 'open' : 'closed'}
      option={option}
      selected={selected}
      onClick={action}
      to={`${toPath}${version}`}
    >
      <div className="icon-view">
        <img src={returnIcon({ option })} />
      </div>
      <li>{title}</li>
    </ContainerOption>
  );
};

export { Option };
