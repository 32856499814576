import styled from 'styled-components';

export const ListBondTankAndPropertyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 49.6% 49.6%;
  flex-flow: row wrap;
  overflow-y: auto;
  padding: 0 1rem;
  justify-content: center;
  padding: 0 1rem;
  transition: 0.3s all;

  @media (max-width: 900px) {
    flex-flow: column;
    padding: 0 0.6rem;
    grid-template-columns: 100%;
  }
`;
