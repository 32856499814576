import styled from 'styled-components';

interface IPaginatorContainer {
  page: number;
  maxPage: number;
  quantity: number;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  .logo-app {
    display: none;
    width: 130px;
    transition: 0.3s all;
    @media (max-width: 960px) {
      width: 120px;
    }
    @media (max-width: 780px) {
      width: 90px;
    }
    @media (max-width: 600px) {
      width: 60px;
    }
  }
`;

export const PaginatorContainer = styled.div<IPaginatorContainer>`
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 960px) {
    width: 120px;
  }
  @media (max-width: 780px) {
    width: 90px;
  }
  @media (max-width: 600px) {
    width: 60px;
  }
  .paginator-right-button {
    cursor: ${({ page }) => page > 1 && 'pointer'};
    opacity: ${({ page }) => (page === 1 ? 0.3 : 1)};
  }
  .paginator-left-button {
    cursor: ${({ page, maxPage }) => page < maxPage && 'pointer'};
    opacity: ${({ page, maxPage }) =>
      page === maxPage || maxPage === 0 ? 0.3 : 1};
  }
  .itens-quantity-page {
    font-weight: 1rem;
    @media (max-width: 960px) {
      font-weight: 0.8rem;
    }
    @media (max-width: 780px) {
      font-weight: 0.6rem;
    }
    @media (max-width: 600px) {
      font-weight: 0.4rem;
    }
  }
`;
