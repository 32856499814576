import { useScheduleContext } from '../../../context/ProviderPage/Programacao';
import { formatDate } from '../../../utils/date/formatDate';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoScheduledCollect = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedScheduleCollect } = useScheduleContext();
  // const {
  //   DFDATAPROGRAMADA,
  //   DFNOMECARRETEIRO,
  //   DFNOMELINHA,
  //   DFORDEMCOLETA,
  //   DFPLACAVEICULO,
  // } = selectedScheduleCollect!;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {selectedScheduleCollect && (
        <>
          <RowItens>
            <ContainerItemModal>
              <label>Ordem Coleta</label>
              <div>
                <span>
                  {selectedScheduleCollect!.DFORDEMCOLETA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Carreteiro</label>
              <div>
                <span>
                  {selectedScheduleCollect!.DFNOMECARRETEIRO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Veículo</label>
              <div>
                <span>
                  {selectedScheduleCollect!.DFPLACAVEICULO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Linha</label>
              <div>
                <span>
                  {selectedScheduleCollect!.DFNOMELINHA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Data Programada</label>
              <div>
                <span>
                  {formatDate(selectedScheduleCollect!.DFDATAPROGRAMADA) ||
                    'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
        </>
      )}
    </ModalInfoTemplete>
  );
};
