import Sad from '../../assets/sad.svg';
import { DontExistsItemContainer } from './styles';

interface IDontExistsItem {
  title: string;
}

const DontExistsItem = ({ title }: IDontExistsItem) => {
  return (
    <DontExistsItemContainer>
      <img src={Sad} />
      <span>{title}</span>
    </DontExistsItemContainer>
  );
};

export { DontExistsItem };
