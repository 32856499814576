/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '../../../../../../components/Buttons/BackButton';
import { CollectItemLaboratoryItem } from '../../../../../../components/CollectItemLaboratoryItem';
import { LoadingData } from '../../../../../../components/LoadingData';
import { usePageContext } from '../../../../../../context/usePage';
import { ReleaseCollectTankProducer } from '../../../../../../types/ReleaseCollect';
import { loadReleasedCollectTanks } from './services';
import {
  ListSelectedReleasedCollectTanksContainer,
  ListSelectedReleasedCollectTanksContent,
} from './styles';

interface IListSelectedReleasedCollectTanks {
  setSelectTank: Dispatch<SetStateAction<ReleaseCollectTankProducer>>;
}

const ListSelectedReleasedCollectTanks = ({
  setSelectTank,
}: IListSelectedReleasedCollectTanks) => {
  const [tanks, setTank] = useState<ReleaseCollectTankProducer[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const route = useParams();
  const { version } = usePageContext();

  useEffect(() => {
    if (route.collectionOrder) {
      loadReleasedCollectTanks({
        setTank,
        setLoading,
        collectionOrder: Number(route.collectionOrder),
      });
    }
  }, [route]);

  return (
    <ListSelectedReleasedCollectTanksContainer
      style={{
        alignItems: `${!loading && 'flex-start'}`,
        justifyContent: `${!loading && 'flex-start'}`,
      }}
    >
      {loading ? (
        <LoadingData title="Buscando dados" />
      ) : (
        <>
          <BackButton
            action={() => navigate(`/liberar${version}`)}
            title="Voltar"
          />
          {tanks.length > 0 ? (
            <ListSelectedReleasedCollectTanksContent
              style={{
                justifyContent: `${
                  !loading && tanks.length > 0 && 'space-between'
                }`,
              }}
            >
              {tanks.map((item, index) => {
                return (
                  <CollectItemLaboratoryItem
                    key={index}
                    action={() => setSelectTank(item)}
                    data={item as any}
                  />
                );
              })}
            </ListSelectedReleasedCollectTanksContent>
          ) : (
            <></>
          )}
        </>
      )}
    </ListSelectedReleasedCollectTanksContainer>
  );
};

export { ListSelectedReleasedCollectTanks };
