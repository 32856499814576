import React, { useEffect, useState } from 'react';
import Loading from 'react-loading';

import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useCollectContext } from '../../../context/ProviderPage/Coleta';
import { ReleaseCollectTankProducer } from '../../../types/ReleaseCollect';
import { CollectItemLaboratoryItem } from '../../CollectItemLaboratoryItem';
import { ModalInfoTemplete } from '../ModalInfoTemplete';
import { ColumnItens } from '../ModalInfoTemplete/styles';
import { ModalLargeInfoTemplete } from '../ModalLargeInfoTemplete';
import { handleDischargeCollect, loadReleasedCollectTanks } from './services';
import { ContainerLaboratory } from './styles';

const ConfirmDischargeCollect: React.FC = () => {
  const { dischargeCollect, setDischargeCollect, loadCollectWithInterval } =
    useCollectContext();
  const { path } = useSigninContext();
  const { isOpen } = dischargeCollect;
  const [loadingAndNotify, setLoadingAndNotify] = useState(false);
  const [tanks, setTank] = useState<ReleaseCollectTankProducer[]>([]);
  const [loading, setLoading] = useState(true);

  const closeModal = () => {
    setDischargeCollect({
      isOpen: false,
      collect: null,
      id: null,
    });
  };

  useEffect(() => {
    if (dischargeCollect.collect) {
      loadReleasedCollectTanks({
        setTank,
        setLoading,
        collectionOrder: Number(dischargeCollect.collect.DFIDCOLETA),
        path,
      });
    }
  }, [dischargeCollect]);

  return (
    <ModalLargeInfoTemplete
      {...{
        closeModal,
        isOpen,
        option: 'coleta',
        titleModal: 'Descarregar Coleta',
      }}
    >
      <ColumnItens>
        <p>
          Antes de descarregar a coleta, confirme sua decisão, pois isso
          impactará diretamente no processo. Tem total certeza de que deseja
          proceder com o descarregamento neste momento?
        </p>
        <ContainerLaboratory>
          {tanks.map((item, index) => {
            return (
              <CollectItemLaboratoryItem
                key={index}
                action={() => null}
                data={item as any}
              />
            );
          })}
        </ContainerLaboratory>
        <div className="row-medium-buttons">
          <button className="cancel" onClick={() => closeModal()}>
            Cancelar
          </button>
          <button
            disabled={loadingAndNotify}
            className="success"
            onClick={() =>
              handleDischargeCollect({
                collect: dischargeCollect.collect,
                setLoadingAndNotify,
                path,
                loadCollectWithInterval,
                closeModal,
              })
            }
          >
            Confirmar
            {loadingAndNotify && (
              <Loading width={25} height={25} color="white" type="spin" />
            )}
          </button>
        </div>
      </ColumnItens>
    </ModalLargeInfoTemplete>
  );
};

export { ConfirmDischargeCollect };
