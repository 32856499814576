/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
const formatInputNumberPositiveAndNegative = (value: string) => {
  value = value.replace(/[^\d.-]/g, '');
  value = value.replace(/^-+/g, '-');
  value = value.replace(/(?<=\d)-/g, '');
  value = value.replace(/(\.\d*\.|\.{2,})/g, '.');
  const floatValue = parseFloat(value);
  if (!isNaN(floatValue)) {
    if (Math.max(floatValue) > 7) {
      return '7';
    }
    if (Math.min(floatValue) < -7) {
      return '-7';
    }
  }
  return value;
};

export { formatInputNumberPositiveAndNegative };
