import { IListCollectData } from '../../../types/ListCollectData';
import { OpenCollectCard } from '../../Cards/OpenCollectCard';
import { ListOpenCollectesContainer } from './styles';

export const ListOpenCollects = ({ data }: IListCollectData) => {
  return (
    <ListOpenCollectesContainer>
      {data.map((collect, key) => {
        return <OpenCollectCard {...collect} {...{ key }} />;
      })}
    </ListOpenCollectesContainer>
  );
};
