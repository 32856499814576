/* eslint-disable no-useless-escape */
const returnUrlWithHttp = (url: string) => {
  const firstReplace = url.replace(/^https?:\/\/([^\/]+)\/?.*/, '$1');
  if (firstReplace) {
    return firstReplace;
  }
  const secondReplace = url.replace(/^http?:\/\/([^\/]+)\/?.*/, '$1');
  if (secondReplace) {
    return secondReplace;
  }
  return '';
};

export { returnUrlWithHttp };
