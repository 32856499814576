import { CollectStopRecord } from '../../../../types/CollectStopRecord';
import { formatDate } from '../../../../utils/date/formatDate';
import { formatReceiveSeconds } from '../../../../utils/formatReceiveSeconds';

interface IStopSelectedMarker {
  data?: CollectStopRecord;
}

const StopSelectedMarker = ({ data }: IStopSelectedMarker) => {
  const {
    DFFIMDATAPARADA,
    DFFIMHORAPARADA,
    DFINICIODATAPARADA,
    DFINICIOHORAPARADA,
    DFLOCALIZACAO,
    DFSEGUNDOSPARADO,
    DFDESCRICAO,
  } = data!;

  return (
    <div>
      <b>{DFDESCRICAO}</b>
      {DFINICIODATAPARADA && (
        <p>
          <b>Data e Hora inicio:</b> {formatDate(DFINICIODATAPARADA)}
          &nbsp; às {DFINICIOHORAPARADA}
        </p>
      )}
      {DFFIMDATAPARADA && (
        <p>
          <b>Data e Hora fim:</b> {formatDate(DFFIMDATAPARADA)}
          &nbsp; às {DFFIMHORAPARADA}
        </p>
      )}
      {DFSEGUNDOSPARADO && (
        <p>
          <b>Duração: </b>
          {formatReceiveSeconds(DFSEGUNDOSPARADO)}
        </p>
      )}
      {DFLOCALIZACAO && (
        <p>
          <b>Localização:</b> {DFLOCALIZACAO.coordinates[1]},
          {DFLOCALIZACAO.coordinates[0]}
        </p>
      )}
    </div>
  );
};

export { StopSelectedMarker };
