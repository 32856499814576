import { useEffect } from 'react';

import NewConfig from '../../../assets/newconfig.svg';
import { CustomButtonWithIcon } from '../../../components/Buttons/CustomButtonWithIcon';
import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListConfigurations } from '../../../components/List/ListConfiguration';
import { LoadingData } from '../../../components/LoadingData';
import { InfoConfiguration } from '../../../components/Modal/InfoConfiguration';
import { PageHeader } from '../../../components/PageHeader';
import { useConfiguraionContext } from '../../../context/ProviderPage/Configuration';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Configuracoes = () => {
  const { setSelectedPage } = usePageContext();
  const {
    loadConfigurationsAction,
    configurations,
    loadingConfigurations,
    openCreateNewConfiguration,
    openAndCloseCreateNewConfiguration,
  } = useConfiguraionContext();

  useEffect(() => {
    setSelectedPage({ option: 'configuracoes' });
    loadConfigurationsAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'configuracoes' }}
        pageTitle="Configurações do App"
        hasFilter={false}
        button={
          <CustomButtonWithIcon
            {...{
              width: 180,
              action: openAndCloseCreateNewConfiguration,
              icon: NewConfig,
              title: 'Nova Configuração',
            }}
          />
        }
      />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: 'flex-start',
          }}
        >
          {loadingConfigurations ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {configurations.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma configuração disponível no momento" />
                </DontExistsItemFlex>
              ) : (
                <ListConfigurations data={configurations} />
              )}
            </>
          )}
        </PageContent>
        <InfoConfiguration
          {...{
            isOpen: openCreateNewConfiguration,
            closeModal: openAndCloseCreateNewConfiguration,
            option: 'configuracoes',
            titleModal: 'Nova Configuração',
          }}
        />
      </ContainerPage>
    </>
  );
};

export { Configuracoes };
