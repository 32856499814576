import styled from 'styled-components';

import { componentsHeight } from '../../../utils/sideMenuParams';

const { FIRST_HEADER } = componentsHeight;

export interface ICustomNav {
  width?: number;
}

export const CustomMenuContainer = styled.div`
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  display: flex;
`;

export const CustomNav = styled.div<ICustomNav>`
  height: 100%;
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s all;
  background-color: ${({ theme }) => theme.COLORS.GREEN_100};
  /* -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75); */
  @media (max-width: 650px) {
    display: none;
  }
`;

export const CustomHeader = styled.div`
  width: 100%;
  height: ${FIRST_HEADER}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s all;

  @media (max-width: 650px) {
    /* flex-direction: column; */
  }

  .first-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    padding: 1rem;
    transition: 0.3s all;
    @media (max-width: 600px) {
      width: 180px;
    }
    @media (max-width: 480px) {
      /* width: 50px; */
    }
  }
  .content-display-user {
    width: calc(100% - 200px);
    @media (max-width: 600px) {
      width: calc(100% - 180);
    }
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 650px) {
      justify-content: flex-end;
    }
  }

  .logo {
    width: 100px;
    transition: 0.3s all;
    @media (max-width: 750px) {
      width: 90px;
    }
    @media (max-width: 650px) {
      display: none;
    }
  }
`;
