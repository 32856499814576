import React, { useState } from 'react';
import Loading from 'react-loading';

import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useCollectContext } from '../../../context/ProviderPage/Coleta';
import { ModalInfoTemplete } from '../ModalInfoTemplete';
import { ColumnItens } from '../ModalInfoTemplete/styles';
import { handleCloseCollect } from './services';

const ConfirmCloseCollect: React.FC = () => {
  const { closeCollect, setCloseCollect, loadCollectWithInterval } =
    useCollectContext();
  const { path } = useSigninContext();
  const { isOpen } = closeCollect;
  const [loadingAndNotify, setLoadingAndNotify] = useState(false);

  const closeModal = () => {
    setCloseCollect({
      isOpen: false,
      collect: null,
    });
  };

  return (
    <ModalInfoTemplete
      {...{
        closeModal,
        isOpen,
        option: 'coleta',
        titleModal: 'Encerrar Coleta',
      }}
    >
      <ColumnItens>
        <p>
          Antes de encerrar a coleta de leite, confirme sua decisão, pois isso
          impactará diretamente no processo. Tem total certeza de que deseja
          proceder com o encerramento neste momento?
        </p>
        <div className="row-medium-buttons">
          <button className="cancel" onClick={() => closeModal()}>
            Cancelar
          </button>
          <button
            disabled={loadingAndNotify}
            className="success"
            onClick={() =>
              handleCloseCollect({
                collect: closeCollect.collect,
                setLoadingAndNotify,
                path,
                loadCollectWithInterval,
                closeModal,
              })
            }
          >
            Confirmar
            {loadingAndNotify && (
              <Loading width={25} height={25} color="white" type="spin" />
            )}
          </button>
        </div>
      </ColumnItens>
    </ModalInfoTemplete>
  );
};

export { ConfirmCloseCollect };
