import { CustomLabelWithInputContaner } from './styles';

interface ICustomLabelWithInput {
  id: number;
  label: string;
  placeholder: string;
  value: string;
  field: string;
  handleUpdateTankMouth: (id: number, field: string, value: string) => void;
  type?: React.HTMLInputTypeAttribute;
}

const CustomLabelWithInput = ({
  label,
  handleUpdateTankMouth,
  value,
  placeholder,
  id,
  field,
  type,
}: ICustomLabelWithInput) => {
  return (
    <CustomLabelWithInputContaner>
      <label>
        <b>{label}</b>
      </label>
      <input
        {...{ placeholder, value, type }}
        onChange={({ target }) =>
          handleUpdateTankMouth(id, field, target.value)
        }
      />
    </CustomLabelWithInputContaner>
  );
};

export { CustomLabelWithInput };
