import { SelecteRegister } from '../../../../types/SelectedMarker';
import { formatDate } from '../../../../utils/date/formatDate';
import { returnRegisterType } from '../../../../utils/returnRegisterType';

interface ISelectedMarker {
  data?: SelecteRegister;
}

const SelectedMarker = ({ data }: ISelectedMarker) => {
  const {
    DFDATAREGISTRO,
    DFHORACOLETA,
    DFHORAREGISTRO,
    DFLOCALIZACAO,
    DFQTDCOLETADA,
    DFTEMPERATURA,
    DFTIPOREGISTRO,
    DFDESCTANQUE,
    DFTIPOITEMCOLETA,
  } = data!;
  return (
    <>
      <b>{returnRegisterType(DFTIPOREGISTRO, DFTIPOITEMCOLETA)}</b>
      {DFDESCTANQUE && (
        <p>
          <b>Tanque:</b> {DFDESCTANQUE}
        </p>
      )}
      {DFDATAREGISTRO && (
        <p>
          <b>Data e Hora:</b> {formatDate(DFDATAREGISTRO)}
          &nbsp; às {DFHORACOLETA || DFHORAREGISTRO}
        </p>
      )}
      {DFQTDCOLETADA && (
        <p>
          <b>Volume:</b> {DFQTDCOLETADA}L
        </p>
      )}
      {DFQTDCOLETADA && (
        <p>
          <b>Temperatura:</b> {DFTEMPERATURA}°C
        </p>
      )}
      {DFLOCALIZACAO && (
        <p>
          <b>Localização:</b> {DFLOCALIZACAO.coordinates[1]},
          {DFLOCALIZACAO.coordinates[0]}
        </p>
      )}
    </>
  );
};

export { SelectedMarker };
