import { useVehicleContext } from '../../../context/ProviderPage/Veiculo';
import { Vehicle } from '../../../types/Vehicle';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListVehicles {
  data: Vehicle[];
}

export const ListVehicles = ({ data }: IListVehicles) => {
  const { setOpenInfo, setSelectedVehicle } = useVehicleContext();

  const handleSelectVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Código</th>
            <th className="list-th">Placa</th>
            <th className="list-th">Descrição</th>
            <th className="list-th">Bocas</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((vehicle, index) => {
            const {
              DFATIVO,
              DFDESCVEICULO,
              DFIDVEICULO,
              DFPLACAVEICULO,
              DFQUANTIDADEBOCAS,
            } = vehicle;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSelectVehicle(vehicle)}
              >
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  {DFIDVEICULO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCVEICULO || 'Não informado'}
                </td>
                <td className="list-td-small">{DFQUANTIDADEBOCAS || '0'}</td>
                <td className="list-td-small">
                  <ListButton
                    {...{
                      action: () => handleSelectVehicle(vehicle),
                      icon: 'search',
                    }}
                  />
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
