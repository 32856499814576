interface ISideMenuParams {
  OPEN_WIDTH: number;
  CLOSED_WIDTH: number;
}
interface IComponentsHeight {
  FIRST_HEADER: number;
  SECOND_HEADER: number;
  SELECTED_COLLECT_HEADER: number;
}

const sideMenuParams: ISideMenuParams = {
  OPEN_WIDTH: 200,
  CLOSED_WIDTH: 90,
};

const componentsHeight: IComponentsHeight = {
  FIRST_HEADER: 60,
  SECOND_HEADER: 70,
  SELECTED_COLLECT_HEADER: 75,
};

export { sideMenuParams, componentsHeight };
