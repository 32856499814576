import { Dispatch, SetStateAction, useState } from 'react';

import { useConfiguraionContext } from '../../../context/ProviderPage/Configuration';
import { CustomSwitch } from '../../CustomSwitch';
import { IModal, ModalConfiguration } from './ModalConfiguration';
import {
  ColumnItens,
  ContainerItemMediumModal,
} from './ModalConfiguration/styles';

interface IModifyValue {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

export const InfoConfiguration = ({
  closeModal,
  titleModal,
  option,
}: IModal) => {
  const { openCreateNewConfiguration, onCreateNewConfiguration } =
    useConfiguraionContext();

  const [DFDISTRIBUIR, setDFDISTRIBUIR] = useState(false);
  const [DFQUALIDADE, setDFQUALIDADE] = useState(false);
  const [DFREGUAATRAS, setDFREGUAATRAS] = useState(false);
  const [DFTERMOMENTRO, setDFTERMOMENTRO] = useState(false);
  const [DFLIBERARCOLETA, setDFLIBERARCOLETA] = useState(false);

  const config = {
    DFDISTRIBUIR: DFDISTRIBUIR ? 'S' : 'N',
    DFQUALIDADE: DFQUALIDADE ? 'S' : 'N',
    DFREGUAATRAS: DFREGUAATRAS ? 'S' : 'N',
    DFTERMOMENTRO: DFTERMOMENTRO ? 'S' : 'N',
    DFLIBERARCOLETA: DFLIBERARCOLETA ? 'S' : 'N',
  };

  const modifyValue = ({ setValue, value }: IModifyValue) => {
    setValue(!value);
  };

  return (
    <ModalConfiguration
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={openCreateNewConfiguration}
      medium={true}
    >
      <ColumnItens>
        <ContainerItemMediumModal>
          <span>Forçar Medição da régua trazeira: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFREGUAATRAS,
                setValue: setDFREGUAATRAS,
              })
            }
            isOpen={DFREGUAATRAS}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar envio de imagem de qualidade: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFQUALIDADE,
                setValue: setDFQUALIDADE,
              })
            }
            isOpen={DFQUALIDADE}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar distribuição do leite: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFDISTRIBUIR,
                setValue: setDFDISTRIBUIR,
              })
            }
            isOpen={DFDISTRIBUIR}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar contraprova de tempertura: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFTERMOMENTRO,
                setValue: setDFTERMOMENTRO,
              })
            }
            isOpen={DFTERMOMENTRO}
          />
        </ContainerItemMediumModal>
        <ContainerItemMediumModal>
          <span>Forçar liberação da coleta: </span>
          <CustomSwitch
            action={() =>
              modifyValue({
                value: DFLIBERARCOLETA,
                setValue: setDFLIBERARCOLETA,
              })
            }
            isOpen={DFLIBERARCOLETA}
          />
        </ContainerItemMediumModal>
        <button
          className="btn-action-button"
          onClick={() => onCreateNewConfiguration({ config })}
        >
          Salvar
        </button>
      </ColumnItens>
    </ModalConfiguration>
  );
};
