import { FooterItemContainer } from './styles';

export interface IFooterItem {
  title: string;
  icon: 'NC' | 'CP' | 'DC' | 'CF' | 'IC' | 'CS' | 'PP';
}
interface IDataFooterItem {
  data: IFooterItem;
}

const FooterItem = ({ data }: IDataFooterItem) => {
  const { icon, title } = data;
  return (
    <FooterItemContainer icon={icon}>
      <div className="icon" />
      <span>{title}</span>
    </FooterItemContainer>
  );
};

export { FooterItem };
