import { useEffect, useState } from 'react';

import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { PageContent } from '../../../styles/global.styles';
import { Statistic } from '../../../types/Statistic';
import { StatisticsTimeLine } from '../../StatisticsTimeLine';
import { loadStatistics } from './services';

interface IStatistic {
  DFIDCOLETA: number;
}

const CollectStatistic = ({ DFIDCOLETA }: IStatistic) => {
  const [statistics, setStatistics] = useState<Statistic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { path } = useSigninContext();

  useEffect(() => {
    loadStatistics({ DFIDCOLETA, path, setLoading, setStatistics });
  }, []);

  return (
    <PageContent>
      <StatisticsTimeLine statistics={statistics} loading={loading!} />
    </PageContent>
  );
};

export { CollectStatistic };
