import { transparentize } from 'polished';
import styled from 'styled-components';

import { MapOption } from '../../types/MapOption';

interface ICollectOption {
  option: MapOption;
  selectedOption: MapOption;
}

export const HeaderCollectOptions = styled.div`
  height: 100%;
  width: 70px;
  background-color: ${({ theme }) =>
    transparentize(0.12, theme.COLORS.GREEN_100)};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 550px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const CollectOption = styled.div<ICollectOption>`
  height: 65px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  background-color: ${({ theme, option, selectedOption }) =>
    option.id === selectedOption.id &&
    transparentize(0.75, theme.COLORS.BLACK_1000)};
  cursor: pointer;
  img {
    width: 20px;
    height: 25px;
  }
  span {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: 0.5rem;
  }
  &:hover {
    background-color: ${({ theme, option, selectedOption }) =>
      option.id !== selectedOption.id &&
      transparentize(0.91, theme.COLORS.BLACK_1000)};
  }
`;
