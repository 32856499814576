import { IFooterItem } from './FooterItem';

const mapFooterOptions: IFooterItem[] = [
  { icon: 'IC', title: 'Inicío da coleta' },
  { icon: 'NC', title: 'Não coletada' },
  { icon: 'DC', title: 'Descarga da coleta' },
  { icon: 'CS', title: 'Socorro em propriedade' },
  { icon: 'CP', title: 'Coleta em propriedade' },
  { icon: 'CF', title: 'Fim da coleta' },
  { icon: 'PP', title: 'Parada prolongada' },
];

export { mapFooterOptions };
