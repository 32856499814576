import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { META_COLLECT_API } from '../../../api';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { LaboratoryCollectItem } from '../../../types/LaboratoryCollectItem';
import { Register } from '../../../types/Register';
import { BackButton } from '../../Buttons/BackButton';
import { ListLaboratoryItens } from '../../List/ListLaboratoryItens';
import {
  ListCollectItemLaboratoryContainer,
  SelectedCollectItemLaboratoryContainer,
} from './styles';

interface ISelectedCollectItemLaboratory {
  selectedItem: LaboratoryCollectItem;
  setSelectedItem: Dispatch<SetStateAction<LaboratoryCollectItem | null>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const SelectedCollectItemLaboratory = ({
  setSelectedItem,
  selectedItem,
  setIsOpen,
  setSelectedRegister,
}: ISelectedCollectItemLaboratory) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [collectItens, setCollectItens] = useState<Register[]>([]);
  const { path } = useSigninContext();

  useEffect(() => {
    META_COLLECT_API.get(
      `/registro/laboratorio?DFIDITEMCOLETA=${selectedItem.DFIDITEMCOLETA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setCollectItens(response.data.registros);
        setLoading(false);
      })
      .catch(error => {
        setCollectItens(error);
        setLoading(false);
      });
  }, []);

  return (
    <SelectedCollectItemLaboratoryContainer>
      <BackButton action={() => setSelectedItem(null)} title="Voltar" />
      <ListCollectItemLaboratoryContainer>
        <ListLaboratoryItens
          loading={loading}
          registers={collectItens}
          setSelectedRegister={setSelectedRegister}
          setIsOpen={setIsOpen}
        />
      </ListCollectItemLaboratoryContainer>
    </SelectedCollectItemLaboratoryContainer>
  );
};

export { SelectedCollectItemLaboratory };
