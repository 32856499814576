import { ReactNode } from 'react';

import { SigninContextProvider } from './Signin';

interface IAppProvider {
  children: ReactNode;
}

const AuthPagerovider = ({ children }: IAppProvider) => {
  return <SigninContextProvider>{children}</SigninContextProvider>;
};

export { AuthPagerovider };
