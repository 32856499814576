import { usePropertyContext } from '../../../context/ProviderPage/Propriedade';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoProperty = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedProperty } = usePropertyContext();
  const {
    DFATIVOINATIVO,
    DFCNPJCPFCEI,
    DFIDSIGLAUF,
    DFLOGRADOURO,
    DFMATRICULA,
    DFNOMEMUNICIPIO,
    DFNOMEPRODUTOR,
    DFNOMEPROPRIEDADE,
    DFNUMERO,
  } = selectedProperty;

  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <RowItens>
        <ContainerItemModal>
          <label>Matrícula</label>
          <div>
            <span>{DFMATRICULA || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Propriedade</label>
          <div>
            <span>{DFNOMEPROPRIEDADE || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Produtor</label>
          <div>
            <span>{DFNOMEPRODUTOR || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Número</label>
          <div>
            <span>{DFNUMERO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>CNPJ/CPF</label>
          <div>
            <span>{formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Muncípio</label>
          <div>
            <span>{DFNOMEMUNICIPIO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>UF</label>
          <div>
            <span>{DFIDSIGLAUF || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Logradouro</label>
          <div>
            <span>{DFLOGRADOURO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Ativo</label>
          <img src={returnCheckType(DFATIVOINATIVO!)} />
        </ContainerItemModal>
        <ContainerItemModal>
          {/* <label>Código</label>
          <div>
            <span>{DFIDPROPRIEDADE || 'Não informado'}</span>
          </div> */}
        </ContainerItemModal>
      </RowItens>
    </ModalInfoTemplete>
  );
};
