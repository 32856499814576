/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import {
  sideMenuParams,
  componentsHeight,
} from '../../../../utils/sideMenuParams';

const { CLOSED_WIDTH, OPEN_WIDTH } = sideMenuParams;
const { FIRST_HEADER, SECOND_HEADER } = componentsHeight;
interface IContainerModal {
  width?: number;
  isOpen: boolean;
}
interface IContentModal {
  isOpen: boolean;
  periodic: boolean;
  medium?: boolean;
}

export const ContainerOverlayModal = styled.div<IContainerModal>`
  transition: 0.3s all;
  z-index: ${({ isOpen }) => (isOpen ? 20 : -1)};
  ${({ width }) =>
    width === CLOSED_WIDTH
      ? css`
          width: calc(100vw - ${CLOSED_WIDTH}px);
        `
      : css`
          width: calc(100vw - ${OPEN_WIDTH}px);
        `};
  @media (max-width: 650px) {
    width: 100%;
  }
  height: calc(100vh - ${FIRST_HEADER + SECOND_HEADER}px);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: ${({ isOpen, theme }) =>
    isOpen && transparentize(0.4, theme.COLORS.BLACK_1000)};
`;

export const ContentInfo = styled.div`
  display: flex;
  width: 100%;
  padding: 0.8rem;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 320px;
`;

export const ContentModal = styled.div<IContentModal>`
  display: flex;
  transition: 0.3s all;
  min-width: ${({ isOpen, medium }) => medium && isOpen ? '50%' : isOpen ? '70%' : '0'};
  min-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
  max-height: 80%;
  border-radius: 1rem;
  background-color: ${({ isOpen, theme }) => isOpen && theme.COLORS.WHITE};
  @media (max-height: 400px) {
    min-height: 40%;
  }

  .btn-action-button{
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    width: 100%;
    height: 50px;
    border-radius: 0.45rem;
    font-size: 0.8rem;
    font-weight: bold;
    transition: 0.3s all;
    color: ${({ theme }) => theme.COLORS.WHITE};
    &:hover {
      filter: brightness(0.8);
      /* color: ${({ theme }) => transparentize(0.45, theme.COLORS.GREEN_DARK)};
      border: 1px solid
        ${({ theme }) => transparentize(0.45, theme.COLORS.GREEN_DARK)};
        background-color: transparent; */
    }
  }

  .not-showed-modal {
    transition: 0.3s all;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;

    header {
      height: 80px;
      border-radius: 1rem 1rem 0 0;
      background-color: ${({ theme }) => theme.COLORS.GREEN_30};
      width: 100%;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 30px;
        height: 30px;
        @media (max-width: 850px) {
          width: 20px;
          height: 20px;
        }
        @media (max-height: 450px) {
          width: 20px;
          height: 20px;
        }
      }

      span {
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: ${({ theme }) => theme.COLORS.WHITE};
        @media (max-width: 850px) {
          font-size: 0.7rem;
        }
      }

      .header-filter-close-button {
        cursor: pointer;
        width: 35px;
        height: 35px;
        padding: 8px;
        border-radius: 35px;
        &:hover {
          background-color: ${({ theme }) =>
            transparentize(0.9, theme.COLORS.BLACK_1000)};
        }
      }
    }
  }
`;

export const ColumnItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const RowItens = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const ContainerItemMediumModal = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 0.5rem;
  /* background-color: red; */
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
    span {
      transition: 0.3s all;
      font-size: 1rem;
      font-weight: 700;
      color: ${({ theme }) => theme.COLORS.GREY};
      margin-right: 1rem;
      @media (max-width: 850px) {
        font-size: 0.8rem;
      }
    }
 
`;

export const ContainerFullItemModal = styled.div`
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  img {
    margin-top: 0.4rem;
    width: 15px;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
  label {
    color: ${({ theme }) => theme.COLORS.GREY};
    font-size: 0.7rem;
    font-weight: 700;
  }
  div {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 50px;
    padding: 0 1.6rem;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};

    span {
      transition: 0.3s all;
      font-size: 0.75rem;
      font-weight: 700;
      color: ${({ theme }) => theme.COLORS.GREY};
      @media (max-width: 850px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export const ContainerItemModal = styled.div`
  width: 48%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  img {
    margin-top: 0.4rem;
    width: 15px;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
  label {
    color: ${({ theme }) => theme.COLORS.GREY};
    font-size: 0.7rem;
    font-weight: 700;
  }
  div {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 50px;
    padding: 0 1.6rem;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};

    span {
      transition: 0.3s all;
      font-size: 0.75rem;
      font-weight: 700;
      color: ${({ theme }) => theme.COLORS.GREY};
      @media (max-width: 850px) {
        font-size: 0.65rem;
      }
    }
  }
`;

export const ContainerCheck = styled.div`
  width: 48%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }
  img {
    margin-top: 0.4rem;
    width: 15px;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
  label {
    color: ${({ theme }) => theme.COLORS.GREY};
    font-size: 0.7rem;
    font-weight: 700;
  }
`;
