/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { ReactComponent as Close } from '../../../assets/Close.svg';
import { ReactComponent as IsActive } from '../../../assets/isactive.svg';
import { ReactComponent as UnityIcon } from '../../../assets/newicons/FABRICA.svg';
import { ReactComponent as Search } from '../../../assets/searchmodal.svg';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { usePageContext } from '../../../context/usePage';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { Unity } from '../../../types/Unity';
import { executeFilterUnities } from './services';
import { ModalToSelectNewUnityToWorkContainer, UnityListItem } from './styles';

const ModalToSelectNewUnityToWork = () => {
  const {
    openModalToSelectUnity,
    openAndCloseModalToSelectUnity,
    setOpenModalToSelectUnity,
  } = usePageContext();
  const { sideMenuWidth } = useSideMenuContext();
  const { user, handleUpdateUnity } = useSigninContext();
  const { loadUnitiesAction, unities } = useUnityContext();
  const [search, setSearch] = useState('');
  const [receiveUnities, setReceiveUnities] = useState<Unity[]>(unities);

  useEffect(() => {
    loadUnitiesAction();
  }, []);

  useEffect(() => {
    setReceiveUnities(unities);
  }, [unities]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenModalToSelectUnity(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const verifyClose = (e: any) => {
    if (e.target.id === 'container-overlay-modal') {
      setOpenModalToSelectUnity(false);
    }
  };

  const RenderHeader = () => {
    return (
      <header>
        <div>
          <UnityIcon />
          <span>Selecionar Unidade</span>
        </div>
        <Close
          className="header-filter-close-button"
          onClick={openAndCloseModalToSelectUnity}
        />
      </header>
    );
  };

  const isSelected = (current: Unity) => {
    if (current.DFIDUNIDADE === user?.DFIDUNIDADE) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    executeFilterUnities({ search, unities, setReceiveUnities });
  }, [search]);

  return (
    <ModalToSelectNewUnityToWorkContainer
      {...{
        open: openModalToSelectUnity,
        side: sideMenuWidth!,
      }}
      id="container-overlay-modal"
      onClick={verifyClose}
    >
      <div className="modal-select-unity-content">
        <RenderHeader />
        <div className="body-modal">
          <div className="modal-select-unity-list-title">
            <input
              placeholder="Pesquisar unidade"
              {...{
                value: search,
                onChange: e => setSearch(e.target.value),
              }}
            />
            <Search />
          </div>
          <div className="modal-select-unity-list">
            {receiveUnities.map((current, key) => {
              const selected = isSelected(current);
              return (
                <UnityListItem
                  {...{ selected, key }}
                  onClick={() => handleUpdateUnity(current)}
                >
                  <span>{current.DFNOMEFANTASIA}</span>
                  {selected && (
                    <div className="selected-box">
                      <span>Selecionado</span>
                      <IsActive />
                    </div>
                  )}
                </UnityListItem>
              );
            })}
          </div>
        </div>
      </div>
    </ModalToSelectNewUnityToWorkContainer>
  );
};

export { ModalToSelectNewUnityToWork };
