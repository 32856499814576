import { useState } from 'react';
import Load from 'react-loading';

import { ListPanelConfiguration } from '../../../components/List/ListPanelConfiguration';
import { PageHeader } from '../../../components/PageHeader';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { ContainerPage, PageContent } from '../../../styles/global.styles';
import { Unity } from '../../../types/Unity';
import { returnTrueOrFalse } from '../../../utils/returnTrueOrFalse';

const PanelConfigurations = () => {
  const { handleUpdateConfigurations, user } = useSigninContext();
  const { DFCONFIGURACAOCIP, DFCONFIGURACAOMOTORISTA } = user!;
  const [cip, setCip] = useState(returnTrueOrFalse(DFCONFIGURACAOCIP!));
  const [motorist, setMotorist] = useState(
    returnTrueOrFalse(DFCONFIGURACAOMOTORISTA!),
  );
  const [loading, setLoading] = useState(false);
  const [unity, setUnity] = useState<Unity>({});
  const configs = { cip, motorist, unity, setLoading };
  return (
    <>
      <PageHeader
        pageIcon={{ option: 'configuracoesdopainel' }}
        pageTitle="Configurações do painel"
        hasFilter={false}
      />
      <ContainerPage>
        <PageContent
          {...{
            style: {
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              overflowY: 'auto',
              padding: 0,
            },
          }}
        >
          <ListPanelConfiguration
            {...{ cip, setCip, motorist, setMotorist, setUnity, unity }}
          />
          <div style={{ padding: 16, paddingTop: 0 }}>
            <button
              onClick={() => handleUpdateConfigurations(configs)}
              className="full-confirm-button"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Load height={14} width={14} type="spin" />
                  <span>Salvando</span>
                </>
              ) : (
                <>
                  <span>Salvar Alterações</span>
                </>
              )}
            </button>
          </div>
        </PageContent>
      </ContainerPage>
    </>
  );
};

export { PanelConfigurations };
