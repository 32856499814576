import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { BackButton } from '../../../../components/Buttons/BackButton';
import { DontExistsItem } from '../../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../../components/DontExistsItem/styles';
import { LoadingData } from '../../../../components/LoadingData';
import { UnbondCollectItem } from '../../../../components/Modal/UnbondCollectItem';
import { PageHeader } from '../../../../components/PageHeader';
import { useBondCollectContext } from '../../../../context/ProviderPage/Vinculada';
import { usePageContext } from '../../../../context/usePage';
import { ContainerPage, PageContent } from '../../../../styles/global.styles';
import { CollectItem } from '../../../../types/CollectItem';
import { ListSelectedReleasedCollectItens } from './components/ListSelectedReleasedCollectItens';

const SelectedBondCollect = () => {
  const route = useParams();
  const navigate = useNavigate();
  const [collectItens, setCollectItens] = useState<CollectItem[]>([]);
  const [loadCollectItens, setLoadCollectItens] = useState<boolean>(false);
  const { loadCollectItensOfASelectedCollect } = useBondCollectContext();
  const { version } = usePageContext();

  const executeLoadCollectItensOfASelectedCollect = () => {
    loadCollectItensOfASelectedCollect({
      currentCollectId: route.collectionOrder!,
      setCollectItens,
      setLoadCollectItens,
    });
  };

  useEffect(() => {
    if (route.collectionOrder) {
      executeLoadCollectItensOfASelectedCollect();
    }
  }, [route]);

  return (
    <>
      <PageHeader pageIcon={{ option: 'vinculada' }} pageTitle="Vinculada" />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadCollectItens === false ? 'normal' : 'center'
            }`,
          }}
        >
          {loadCollectItens ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {collectItens.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma descarga foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <BackButton
                    title="Voltar"
                    action={() => navigate(`/vinculada${version}`)}
                  />
                  <ListSelectedReleasedCollectItens data={collectItens} />
                </>
              )}
            </>
          )}
        </PageContent>
      </ContainerPage>
      <UnbondCollectItem
        {...{
          executeLoadCollectItensOfASelectedCollect: () => {
            navigate(`/vinculada${version}`);
          },
        }}
      />
    </>
  );
};

export { SelectedBondCollect };
