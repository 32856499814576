import { transparentize } from 'polished';
import styled from 'styled-components';

interface IMenuMobileContainer {
  mobileAnimation: boolean;
}

export const MenuMobileContainer = styled.div<IMenuMobileContainer>`
  height: ${({ mobileAnimation }) => (mobileAnimation ? '100vh' : 0)};
  width: ${({ mobileAnimation }) => (mobileAnimation ? '100%' : 0)};
  background-color: ${({ theme, mobileAnimation }) =>
    mobileAnimation
      ? transparentize(0.1, theme.COLORS.GREEN_100)
      : 'transparent'};
  z-index: ${({ mobileAnimation }) => (mobileAnimation ? 100 : -1)};
  position: absolute;
  display: none;
  padding: 5.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s all;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .list-options {
    display: ${({ mobileAnimation }) => (mobileAnimation ? 'normal' : 'none')};
  }

  .close-icon {
    position: fixed;
    right: 0;
    top: 0;
    margin: 2rem;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
`;
