import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Tank } from '../../../types/Tank';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterTank: boolean;
  openSideModalFilterTankAction: () => void;

  DFIDTANQUE: string;
  setDFIDTANQUE: Dispatch<SetStateAction<string>>;
  DFDESCTANQUE: string;
  setDFDESCTANQUE: Dispatch<SetStateAction<string>>;
  DFIDLINHA: string;
  setDFIDLINHA: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFCOLETASELETIVA: string;
  setDFCOLETASELETIVA: Dispatch<SetStateAction<string>>;

  filterTankAction: () => void;
  cleanAllInputsAction: () => void;
  loadTankAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedTank: Tank;
  setSelectedTank: Dispatch<SetStateAction<Tank>>;

  tank: Tank[];
  setTank: Dispatch<SetStateAction<Tank[]>>;

  loadingTank: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const TankContextProvider = ({ children }: IContextProps) => {
  const [DFIDTANQUE, setDFIDTANQUE] = useState<string>('');
  const [DFDESCTANQUE, setDFDESCTANQUE] = useState<string>('');
  const [DFIDLINHA, setDFIDLINHA] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFCOLETASELETIVA, setDFCOLETASELETIVA] = useState<string>('');

  const [loadingTank, setIsLoadingTank] = useState<boolean>(false);
  const [openSideModalFilterTank, setOpenSideModalFilterTank] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedTank, setSelectedTank] = useState<Tank>({});
  const [tank, setTank] = useState<Tank[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);

  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDTANQUE = `DFIDTANQUE=${DFIDTANQUE}`;
  const DESCTANQUE = `DFDESCTANQUE=${DFDESCTANQUE}`;
  const IDLINHA = `DFIDLINHA=${DFIDLINHA}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const COLETASELETIVA = `DFCOLETASELETIVA=${DFCOLETASELETIVA}`;

  const openSideModalFilterTankAction = () => {
    setOpenSideModalFilterTank(!openSideModalFilterTank);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingTank(true);
      META_COLLECT_API.get(
        `/tanque?${Limit}&${Offset}&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setTank(response.data.tanques);
          setQuantity(response.data.quantidade);
          setIsLoadingTank(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingTank(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadTankActionWithParams = () => {
    setIsLoadingTank(true);
    META_COLLECT_API.get(`/tanque?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setTank(response.data.tanques);
        setQuantity(response.data.quantidade);
        setIsLoadingTank(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingTank(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFATIVO('');
    setDFCOLETASELETIVA('');
    setDFDESCTANQUE('');
    setDFIDLINHA('');
    setDFIDTANQUE('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterTankAction();
    loadTankActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterTankAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterTankAction();
    setIsLoadingTank(true);
    META_COLLECT_API.get(
      `/tanque?${Limit}&offset=0&${IDTANQUE}&${DESCTANQUE}&${IDLINHA}&${ATIVO}&${COLETASELETIVA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setTank(response.data.tanques);
        setQuantity(response.data.quantidade);
        setIsLoadingTank(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingTank(false);
        setStart(1);
      });
  };

  const loadTankAction = () => {
    setIsLoadingTank(true);
    loadTankActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'tanque') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFATIVO,
        DFCOLETASELETIVA,
        DFDESCTANQUE,
        DFIDLINHA,
        DFIDTANQUE,
        filterTankAction,
        loadTankAction,
        limit,
        loadingTank,
        offset,
        openInfo,
        openSideModalFilterTank,
        openSideModalFilterTankAction,
        page,
        quantity,
        selectedTank,
        setDFATIVO,
        setDFCOLETASELETIVA,
        setDFDESCTANQUE,
        setDFIDLINHA,
        setDFIDTANQUE,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setSelectedTank,
        setTank,
        tank,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useTankContext() {
  const context = useContext(Context);
  return context;
}

export { TankContextProvider, useTankContext };
