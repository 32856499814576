import { transparentize } from 'polished';
import styled from 'styled-components';

export const FormAuthInputContainer = styled.div`
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  height: 70px;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) =>
    transparentize(0.6, theme.COLORS.GREEN_30)};
  transition: 0.3s ease-out;
  &:hover {
    transform: scale(1.03);
  }

  svg {
    transition: 0.3s all;
    width: 25px;
    height: 25px;
  }

  input {
    transition: 0.3s all;
    width: 90%;
    height: 100%;
    border: 0;
    /* border-left: 1px solid white; */
    padding: 1rem;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    color: ${({ theme }) => theme.COLORS.WHITE};
    &::placeholder {
      color: ${({ theme }) => transparentize(0.5, theme.COLORS.WHITE)};
    }
    @media (max-width: 750px) {
      font-size: 0.8rem;
    }
  }
`;
