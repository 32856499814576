import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { Option } from '../types/Option';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  selectedPage: Option;
  setSelectedPage: Dispatch<SetStateAction<Option>>;
  openModalToSelectUnity: boolean;
  setOpenModalToSelectUnity: Dispatch<SetStateAction<boolean>>;
  openAndCloseModalToSelectUnity: () => void;
  version: string;
  setVersion: Dispatch<SetStateAction<string>>;
}

const Context = createContext({} as IContext);

const PageContextProvider = ({ children }: IContextProps) => {
  const [selectedPage, setSelectedPage] = useState<Option>({
    option: undefined,
  });
  const [version, setVersion] = useState<string>('');
  const [openModalToSelectUnity, setOpenModalToSelectUnity] =
    useState<boolean>(false);

  const openAndCloseModalToSelectUnity = () => {
    setOpenModalToSelectUnity(!openModalToSelectUnity);
  };

  return (
    <Context.Provider
      value={{
        selectedPage,
        setSelectedPage,
        openModalToSelectUnity,
        setOpenModalToSelectUnity,
        openAndCloseModalToSelectUnity,
        setVersion,
        version,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function usePageContext() {
  const context = useContext(Context);
  return context;
}

export { PageContextProvider, usePageContext };
