import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListClosedCollects } from '../../../components/List/ListClosedCollect';
import { LoadingData } from '../../../components/LoadingData';
import { InfoClosedCollect } from '../../../components/Modal/InfoClosedCollect';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useClosedCollectContext } from '../../../context/ProviderPage/Encerrada';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Encerradas = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingClosedCollect,
    loadClosedCollectAction,
    closedCollect,
    openSideModalFilterClosedCollectAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useClosedCollectContext();

  useEffect(() => {
    setSelectedPage({ option: 'encerrada' });
    loadClosedCollectAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'encerrada' }}
        pageTitle="Encerrada"
        filterAction={openSideModalFilterClosedCollectAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingClosedCollect === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingClosedCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {closedCollect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma coleta foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListClosedCollects data={closedCollect} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: closedCollect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoClosedCollect
          option="encerrada"
          titleModal="Encerrada selecionada"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Encerradas };
