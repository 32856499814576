import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IAnimateTr {
  index: number;
}

export const ContainerListPage = styled.div`
  display: flex;
  /* min-width: 65%; */
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  overflow: auto;

  table tr {
    &:hover {
      background-color: ${({ theme }) =>
        transparentize(0.7, theme.COLORS.GREY)};
    }
  }

  table {
    border-collapse: collapse;
  }
  table th {
    padding: 1rem;
    background-color: ${({ theme }) => theme.COLORS.GREEN_20};
    max-width: 150px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    /* z-index: 0; */
  }
  .list-th {
    max-width: 300px;
  }
  .first-table-th {
    max-width: 100px;
    border-top-left-radius: 0.6rem;
  }
  .last-table-th {
    max-width: 100px;
    border-top-right-radius: 0.6rem;
  }
  .last-table-th-medium {
    max-width: 100px;
    border-top-right-radius: 0.6rem;
  }

  td {
    /* white-space: pre; */
    text-align: center;
    padding: 0.4rem 2rem;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.COLORS.GREY};
    border-bottom: 1px solid ${({ theme }) => darken(0.25, theme.COLORS.WHITE)};
    img {
      cursor: pointer;
    }
  }
  .list-td-extra-small {
    min-width: 100px;
    max-width: 150px;
    .icon-check {
      width: 15px;
    }
  }
  .list-td-small {
    min-width: 150px;
    max-width: 300px;
    .icon-check {
      width: 15px;
    }
    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .list-td-medium {
    min-width: 200px;
    max-width: 240px;
    word-wrap: break-word;
  }
  .list-td-large {
    min-width: 300px;
    max-width: 300px;
    word-wrap: break-word;
  }
  .list-td-option {
    display: flex;
    min-width: 100px;
    max-width: 200px;
  }
  table thead {
    position: sticky;
    inset-block-start: 0;
    background-color: #ddd;
    z-index: 1;
  }
`;

export const AnimateTr = styled.tr<IAnimateTr>`
  cursor: pointer;
  ${({ index }) =>
    index % 2 === 0
      ? css`
          background-color: ${({ theme }) =>
            transparentize(0.9, theme.COLORS.GREY)};
        `
      : css`
          background-color: ${({ theme }) =>
            transparentize(0.85, theme.COLORS.GREY)};
        `}
  transition: all 0.4s ease-out;
  animation: fadeIn 0.6s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      /* transform: translateX(100px); */
    }
    100% {
      opacity: 1;
      /* transform: translateX(0); */
    }
  }

  .small-button {
    border: 0;
    padding: 0.3rem;
    background-color: darkgrey;
    /* width: 25px;
    height: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    transition: 0.1s all;
    margin: 0 0.25rem;
    svg {
      margin-left: 6px;
    }
    :active {
      filter: brightness(0.9);
    }
  }

  .option-item {
    background-color: ${({ theme }) => transparentize(0.3, theme.COLORS.GREY)};
    border-radius: 3px;
    padding: 0.3rem;
    margin: 0 6px;
    width: 30px;
    height: 30px;
  }

  .rc-switch {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
  }
`;
