import Menu from '../../../assets/menumobile.svg';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { CloseAndOpenMenuMobileContainer } from './styles';

const CloseAndOpenMenuMobile = () => {
  const { setSideMenuIsOpenMobile } = useSideMenuContext();

  return (
    <CloseAndOpenMenuMobileContainer
      onClick={() => setSideMenuIsOpenMobile(true)}
    >
      <img src={Menu} />
    </CloseAndOpenMenuMobileContainer>
  );
};

export { CloseAndOpenMenuMobile };
