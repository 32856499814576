import React, { useEffect, useState } from 'react';

interface ICounterProps {
  finalNumber: number;
}

const Counter: React.FC<ICounterProps> = ({ finalNumber }) => {
  const [count, setCount] = useState(0);
  const velocity = 1;
  useEffect(() => {
    const interval = setInterval(() => {
      if (count < finalNumber) {
        setCount(count + 100);
      } else {
        clearInterval(interval);
      }
    }, velocity);
    if (count > finalNumber) {
      setCount(finalNumber);
    }
    return () => clearInterval(interval);
  }, [count, finalNumber, velocity]);

  return <>{count}</>;
};

export { Counter };
