import React, { useEffect, useState } from 'react';

import { useClosedCollectContext } from '../../../../context/ProviderPage/Encerrada';
import { useSideMenuContext } from '../../../../context/useSideMenu';
import { Model, PrintStyles } from './styles';

const Model1: React.FC = () => {
  const { Container, Secondary, Paper, List, Header } = Model;
  const [showContentFilter, setShowContentFilter] = useState(false);
  const { sideMenuWidth } = useSideMenuContext();
  const { setOpenFirstModel, openFirstModel } = useClosedCollectContext();

  useEffect(() => {
    if (openFirstModel) {
      setTimeout(() => {
        setShowContentFilter(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContentFilter(false);
      }, 0);
    }
  }, [openFirstModel]);

  const handlePrint = () => {
    const printContent = document.getElementById('print-content');
    if (printContent) {
      const printWindow = window.open('', '_blank');
      printWindow?.document.write(
        '<html><head><title>Print</title></head><body>',
      );
      printWindow?.document.write(printContent.innerHTML);
      printWindow?.document.write('</body></html>');
      printWindow?.document.close();
      printWindow?.print();
    }
  };

  return (
    <>
      <Container
        {...{ open: showContentFilter, width: sideMenuWidth }}
        onClick={() => setOpenFirstModel(false)}
      >
        <Secondary {...{ open: showContentFilter }}>
          <List id="print-content">
            <Paper>
              <Header>
                <h1>Teste</h1>
              </Header>
            </Paper>
            <Paper>
              <h1>oi</h1>
            </Paper>
            <Paper>
              <h1>oi</h1>
            </Paper>
          </List>
        </Secondary>
        <button onClick={handlePrint}>Imprimir</button>
      </Container>
    </>
  );
};

export default Model1;
