import { useEffect } from 'react';

import { useTankContext } from '../../../context/ProviderPage/Tanque';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterTanks = () => {
  const {
    cleanAllInputsAction,
    DFIDTANQUE,
    setDFIDTANQUE,
    DFDESCTANQUE,
    setDFDESCTANQUE,
    DFIDLINHA,
    setDFIDLINHA,
    DFATIVO,
    setDFATIVO,
    DFCOLETASELETIVA,
    setDFCOLETASELETIVA,
    openSideModalFilterTank,
    openSideModalFilterTankAction,
    filterTankAction,
  } = useTankContext();

  useEffect(() => {
    if (Number(DFIDTANQUE) < 0) {
      setDFIDTANQUE('0');
    }
  }, [DFIDTANQUE]);

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterTank}
      onchange={openSideModalFilterTankAction}
      title="Filtrar Tanque"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterTankAction}
    >
      <FilterSelectInput onChange={setDFATIVO} value={DFATIVO} title="Ativo" />
      <FilterInput
        onChange={setDFIDTANQUE}
        value={DFIDTANQUE}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFDESCTANQUE}
        value={DFDESCTANQUE}
        title="Descrição"
        type="text"
      />
      <FilterSelectInput
        onChange={setDFCOLETASELETIVA}
        value={DFCOLETASELETIVA}
        title="Coleta Seletiva"
      />
      <FilterInput
        onChange={setDFIDLINHA}
        value={DFIDLINHA}
        title="Linha"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
