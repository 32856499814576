import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListDischargeCollects } from '../../../components/List/ListDischargeCollects';
import { LoadingData } from '../../../components/LoadingData';
import { InfoRegion } from '../../../components/Modal/InfoRegion';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useDischargeContext } from '../../../context/ProviderPage/Descarga';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Descarga = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loaddischargeAction,
    loadingDischargeCollect,
    dischargeCollect,
    openSideModalFilterDischargeCollectAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useDischargeContext();

  useEffect(() => {
    setSelectedPage({ option: 'descarga' });
    loaddischargeAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'descarga' }}
        pageTitle="Plataforma"
        filterAction={openSideModalFilterDischargeCollectAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingDischargeCollect === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingDischargeCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {dischargeCollect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma descarga foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListDischargeCollects data={dischargeCollect} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: dischargeCollect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoRegion
          option="coleta"
          titleModal="Coleta selecionada"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Descarga };
