/* eslint-disable prefer-const */
import moment from 'moment';

const formatDateAndHour = (date?: string, hour?: string) => {
  let formatedDate;
  let formatedHour;
  if (!date || !hour) {
    return 'Data não informada';
  }
  formatedDate = moment(date).format('DD/MM/YYYY');
  formatedHour = hour.slice(0, 5);
  if (formatedDate === 'Invalid date' || formatedHour.length <= 4) return '--';
  return `${formatedDate} às ${formatedHour}`;
};
export { formatDateAndHour };
