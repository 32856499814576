import themes from '../../../themes';

interface IReturnCardStatisticColor {
  DFTIPOITEMCOLETA?: 'N' | 'C' | 'S';
  DFTIPOREGISTRO?:
    | 'N'
    | 'A'
    | 'D'
    | 'F'
    | 'C'
    | 'G'
    | 'Q'
    | 'R'
    | 'T'
    | 'O'
    | 'P';
}

const returnCardStatisticColor = ({
  DFTIPOITEMCOLETA,
  DFTIPOREGISTRO,
}: IReturnCardStatisticColor): string => {
  if (DFTIPOREGISTRO && DFTIPOITEMCOLETA) {
    if (DFTIPOREGISTRO === 'C' && DFTIPOITEMCOLETA === 'C') {
      return themes.COLORS.CP;
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'C') {
      return themes.COLORS.NC;
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'C') {
      return themes.COLORS.NC;
    }
    if (DFTIPOREGISTRO === 'C' && DFTIPOITEMCOLETA === 'S') {
      return themes.COLORS.CS;
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'S') {
      return themes.COLORS.CS;
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'S') {
      return themes.COLORS.NC;
    }
    if (DFTIPOREGISTRO === 'O' && DFTIPOITEMCOLETA === 'N') {
      return themes.COLORS.NC;
    }
    if (DFTIPOREGISTRO === 'N' && DFTIPOITEMCOLETA === 'N') {
      return themes.COLORS.DC;
    }
  }
  switch (DFTIPOREGISTRO) {
    case 'A':
      return themes.COLORS.IC;
    case 'D':
      return themes.COLORS.DC;
    case 'F':
      return themes.COLORS.CF;
    case 'Q':
      return themes.COLORS.IC;
    case 'P':
      return themes.COLORS.PP;
    default:
      return themes.COLORS.NI;
  }
};

export { returnCardStatisticColor };
