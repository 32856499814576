import { darken } from 'polished';
import styled from 'styled-components';

export const ListPanelConfigurationContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .panel-configuration-title {
    width: 100%;
    padding: 1rem;
    background-color: ${({ theme }) => theme.COLORS.GREEN_100};
    border-top: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    h1 {
      font-size: 1.3rem;
      /* color: ${({ theme }) => darken(0.45, theme.COLORS.GREY_50)}; */
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }
`;
