import { ReactComponent as Unity } from '../../../../assets/newicons/FABRICA.svg';
import { useSigninContext } from '../../../../context/AuthProviderPages/Signin';
import { SelectNewUnityToWorkContainer } from './styles';

interface ISelectNewUnityToWork {
  openAndCloseModalToSelectUnity: () => void;
}

const SelectNewUnityToWork = ({
  openAndCloseModalToSelectUnity,
}: ISelectNewUnityToWork) => {
  const { user } = useSigninContext();
  return (
    <SelectNewUnityToWorkContainer
      onClick={() => openAndCloseModalToSelectUnity()}
    >
      <Unity />
      <span>{user?.DFNOMEFANTASIA}</span>
    </SelectNewUnityToWorkContainer>
  );
};

export { SelectNewUnityToWork };
