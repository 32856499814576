import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListExcludedCollects } from '../../../components/List/ListExcludedCollect';
import { LoadingData } from '../../../components/LoadingData';
import { InfoExcludedCollect } from '../../../components/Modal/InfoExcludedCollect';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useExcludedCollectContext } from '../../../context/ProviderPage/Excluida';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Excluida = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadExcludedCollectAction,
    loadingExcludedCollect,
    excludedCollect,
    openSideModalFilterExcludedCollectAction,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
    openInfo,
    setOpenInfo,
  } = useExcludedCollectContext();

  useEffect(() => {
    setSelectedPage({ option: 'excluida' });
    loadExcludedCollectAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'excluida' }}
        pageTitle="Excluida"
        filterAction={openSideModalFilterExcludedCollectAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingExcludedCollect === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingExcludedCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {excludedCollect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma região foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListExcludedCollects data={excludedCollect} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: excludedCollect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoExcludedCollect
          {...{
            option: 'excluida',
            titleModal: 'Coleta Eexcluida selecionada',
            isOpen: openInfo,
            closeModal: setOpenInfo,
          }}
        />
      </ContainerPage>
    </>
  );
};

export { Excluida };
