/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-expressions */
import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactMapGL from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import { App } from './App';

ReactMapGL; // This is a dependency of react-map-gl even if you didn't explicitly install it
// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
