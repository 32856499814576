import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IDropdownMenu {
  openDropdownMenu: boolean;
}

export const ContainerConectedUser = styled.div`
  height: 100%;
  /* width: calc(100% - 270px); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* background-color: red; */

  .content_conected_user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    @media (max-width: 650px) {
      display: none;
    }
    span {
      @media (max-width: 750px) {
        font-size: 0.7rem;
      }
      @media (max-width: 300px) {
        font-size: 0.6rem;
      }
    }
  }
  .content_user {
    margin-left: 1rem;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    transition: 0.3s all;
    @media (max-width: 650px) {
      display: none;
    }
  }
`;

export const UnityLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-left: 1rem;
  background-size: cover;
`;

export const DropdownMenu = styled.div<IDropdownMenu>`
  display: flex;
  justify-content: center;
  img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  .open-menu {
    margin-right: 11rem;
    display: flex;
    flex-direction: column;
    transition: 0.25s all;
    position: absolute;
    height: ${({ openDropdownMenu }) =>
      openDropdownMenu === true ? '150px' : '0px'};
    width: ${({ openDropdownMenu }) =>
      openDropdownMenu === true ? '150px' : '0px'};
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    -webkit-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.34);
    position: absolute;

    .menu-item {
      width: 100%;
      transition: 0.3s all;
      background-color: ${({ theme }) => theme.COLORS.WHITE};
      cursor: pointer;
      display: none;
      ${({ openDropdownMenu }) =>
        openDropdownMenu &&
        css`
          display: flex;
          min-height: 35px;
          padding: 1rem;
        `};
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: ${({ theme }) => darken(0.05, theme.COLORS.WHITE)};
      }
      span {
        text-align: center;
        align-self: center;
        display: ${({ openDropdownMenu }) =>
          openDropdownMenu === true ? 'flex' : 'none'};
      }
    }
  }
`;
