import { ReactNode, createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { Device, ReleaseBondDevice } from '../../../types/Device';
import { useSigninContext } from '../../AuthProviderPages/Signin';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  loadingDevices: boolean;
  devices: Device[];
  loadDevicesAction: () => void;
  releaseBondDevice: ({
    device,
    setLoadingOperation,
  }: ReleaseBondDevice) => void;
  deleteBondDevice: ({
    device,
    setLoadingOperation,
  }: ReleaseBondDevice) => void;
}

const Context = createContext({} as IContext);

const DeviceContextProvider = ({ children }: IContextProps) => {
  const [loadingDevices, setLoadingDevices] = useState(true);
  const { path } = useSigninContext();

  const [devices, setDevices] = useState<Device[]>([]);

  const loadDevicesAction = () => {
    META_COLLECT_API.get('/dispositivo', {
      headers: {
        path,
      },
    })
      .then(({ data }) => {
        setDevices(data.devices);
        setLoadingDevices(false);
      })
      .catch(() => {
        setLoadingDevices(false);
      });
  };

  const releaseBondDevice = ({
    device,
    setLoadingOperation,
  }: ReleaseBondDevice) => {
    setLoadingOperation(true);
    const { DFIMEI, DFIDCARRETEIRO, DFIDVINCULODISPOSITIVO } = device;
    const deviceRelease = {
      DFIMEI,
      DFIDCARRETEIRO,
      DFIDVINCULODISPOSITIVO,
    };

    if (!DFIDCARRETEIRO) {
      toast.info(
        'Você não pode realizar esta operação, pois este dispositivo não possui um carreteiro vinculado a ele',
        {
          className: 'class-info',
        },
      );
      setLoadingOperation(false);
    } else {
      META_COLLECT_API.patch('/dispositivo/painel/liberar', deviceRelease, {
        headers: {
          path,
        },
      })
        .then(res => {
          toast.success(res.data.message, {
            className: 'class-success',
          });
          loadDevicesAction();
          setLoadingOperation(false);
        })
        .catch(req => {
          toast.error(req.response.data.error, {
            className: 'class-error',
          });
          setLoadingOperation(false);
        });
    }
  };

  const deleteBondDevice = ({
    device,
    setLoadingOperation,
  }: ReleaseBondDevice) => {
    setLoadingOperation(true);
    const { DFIMEI, DFIDCARRETEIRO, DFIDVINCULODISPOSITIVO } = device;
    const deviceRelease = {
      DFIMEI,
      DFIDCARRETEIRO,
      DFIDVINCULODISPOSITIVO,
    };

    META_COLLECT_API.post('/dispositivo/painel/remover', deviceRelease, {
      headers: {
        path,
      },
    })
      .then(res => {
        toast.success(res.data.message, {
          className: 'class-success',
        });
        loadDevicesAction();
        setLoadingOperation(false);
      })
      .catch(req => {
        toast.error(req.response.data.error, {
          className: 'class-error',
        });
        setLoadingOperation(false);
      });
  };

  return (
    <Context.Provider
      value={{
        loadingDevices,
        devices,
        loadDevicesAction,
        releaseBondDevice,
        deleteBondDevice,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useDeviceContext() {
  const context = useContext(Context);
  return context;
}

export { DeviceContextProvider, useDeviceContext };
