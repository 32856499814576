import { darken, transparentize } from 'polished';
import styled from 'styled-components';

interface INewSiloButton {
  color: string;
}

export const NewSiloButton = styled.button<INewSiloButton>`
  min-width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 0;
  border-radius: 0.3rem;
  align-self: flex-end;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.3s all;
  color: ${({ theme }) => theme.COLORS.WHITE};
  background-color: ${({ color }) => color};
  &:hover {
    background-color: ${({ color }) => transparentize(0.2, color)};
  }
`;

export const DeschargeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  input,
  select {
    width: 220px;
    height: 48px;
    outline: 3px;
    padding: 0 0.4rem;
    border-radius: 0.4rem;
    background-color: transparent;
    border: 1px solid ${({ theme }) => transparentize(0.9, theme.COLORS.GREY)};
  }

  .row-input {
    display: flex;
    flex-direction: row;
  }
  .column-input {
    display: flex;
    flex-direction: column;
  }
  .column-input {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
  }
  .row-buttons {
    display: flex;
    flex-direction: row;
  }
  label {
    color: ${({ theme }) => darken(0.05, theme.COLORS.GREY)};
  }
  h2 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => darken(0.1, theme.COLORS.GREY)};
  }

  .line {
    width: 100%;
    padding: 0.5px;
    margin: 1rem 0;
    background-color: ${({ theme }) => transparentize(0.9, theme.COLORS.GREY)};
  }
`;
