import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { ReactComponent as ArrowDown } from '../../../assets/ArrowDownWhite.svg';
import { Unity } from '../../../types/Unity';
import { FormAuthSelectContainer, FormAuthSelectComponent } from './styles';

interface IFormAuthSelect {
  value: Unity;
  setValue: Dispatch<SetStateAction<Unity>>;
  icon: ReactNode;
  unities: Unity[];
  istransform?: boolean;
}

const FormAuthSelect = ({
  setValue,
  value,
  unities,
  icon,
  istransform,
}: IFormAuthSelect) => {
  const [openOption, setOpenOption] = useState<boolean>(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openOption) {
      setTimeout(() => {
        setShowContent(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 0);
    }
  }, [openOption]);

  const selectedUnityCut = (value: string) => {
    if (value) {
      if (value.length > 24) {
        return `${value.slice(0, 24)}...`;
      }
      return value;
    }
    return 'Selecionar Unidade';
  };

  return (
    <FormAuthSelectContainer {...{ openOption, showContent }}>
      <FormAuthSelectComponent {...{ openOption, showContent, istransform }}>
        {icon}
        <div className="select-options-container">
          <span draggable="false">
            {selectedUnityCut(value.DFNOMEFANTASIA!)}
          </span>
          <ArrowDown
            {...{ openOption }}
            fill="white"
            className="select"
            onClick={() => setOpenOption(!openOption)}
          />
        </div>
      </FormAuthSelectComponent>
      <div className="list-options">
        {unities.map((unity, index) => (
          <span
            key={index}
            onClick={() => {
              setValue(unity);
              setOpenOption(!openOption);
            }}
          >
            {unity.DFNOMEFANTASIA}
          </span>
        ))}
      </div>
    </FormAuthSelectContainer>
  );
};

export { FormAuthSelect };
