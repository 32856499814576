import styled from 'styled-components';

const ListSelectedReleasedCollectTanksContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 49.6% 49.6%;
  flex-flow: row wrap;
  overflow-y: auto;
  justify-content: space-between;
  padding: 0 1rem;
  transition: 0.3s all;
  margin-top: 1rem;

  @media (max-width: 900px) {
    flex-flow: column;
    padding: 0 0.6rem;
    grid-template-columns: 100%;
  }
`;

export { ListSelectedReleasedCollectTanksContainer };
