import { useExcludedCollectContext } from '../../../context/ProviderPage/Excluida';
import { ListExcludedCollect } from '../../../types/ExcludedCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

export const ListExcludedCollects = ({ data }: ListExcludedCollect) => {
  const { setOpenInfo, setSelectedExcludedCollect } =
    useExcludedCollectContext();
  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Código</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Região</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Data Coleta</th>
            <th className="list-th">Justificativa</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((collect, index) => {
            const {
              DFIDCOLETA,
              DFDESCREGIONAL,
              DFNOMECARRETEIRO,
              DFNOMELINHA,
              DFDATAEXCLUSAOCOLETA,
              DFJUSTIFICATIVA,
            } = collect;

            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-small">
                  {DFIDCOLETA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCREGIONAL || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {formatDate(DFDATAEXCLUSAOCOLETA) || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFJUSTIFICATIVA.length > 65
                    ? `${DFJUSTIFICATIVA.slice(0, 65)}...`
                    : DFJUSTIFICATIVA || 'Não informado'}
                </td>
                <td className="list-td-small">
                  <div>
                    <ListButton
                      {...{
                        action: () => {
                          setSelectedExcludedCollect(collect);
                          setOpenInfo(true);
                        },
                        icon: 'search',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
