import { Dispatch, SetStateAction, useState } from 'react';

import ArrowLeft from '../../assets/paginator/ArrowLeft.svg';
import ArrowRight from '../../assets/paginator/ArrowRight.svg';
import { Container, PaginatorContainer } from './styles';

interface IPaginator {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  quantity: number;
  quantityShowed?: number;
  limitItens: number;
  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start?: number;
  setStart?: Dispatch<SetStateAction<number>>;
}

const Paginator = ({
  page,
  quantity,
  quantityShowed,
  setPage,
  setOfsset,
  limitItens,
  offset,
  setHasClick,
  setStart,
  start,
}: IPaginator) => {
  const [maxPage] = useState(Math.ceil(quantity / limitItens));

  return (
    <Container>
      {/* <div className="logo-app" /> */}
      <PaginatorContainer quantity={quantity} page={page} maxPage={maxPage}>
        <img
          src={ArrowRight}
          className="paginator-right-button"
          onClick={() => {
            if (page > 1) {
              setHasClick(true);
              setOfsset(offset - limitItens);
              setPage(page - 1);
              setStart!(start! - limitItens);
            }
          }}
        />
        <span className="itens-quantity-page">
          {start}-{limitItens * page > quantity ? quantity : limitItens * page}
        </span>
        <img
          src={ArrowLeft}
          className="paginator-left-button"
          onClick={() => {
            if (page < maxPage) {
              setHasClick(true);
              setOfsset(offset + limitItens);
              setPage(page + 1);
              setStart!(start! + quantityShowed!);
            }
          }}
        />
      </PaginatorContainer>
      {/* <a href="https://www.webmeta.com.br" target="_blank">
        <img className="logo-app" src={Marca} />
      </a> */}
    </Container>
  );
};

export { Paginator };
