interface ICompareTwoDates {
  firstDate: string;
  secondDate: string;
  firstTime: string;
  secondTime: string;
}
interface IParseDateAndTimeToFullDate {
  date: string;
  time: string;
}

const parseDateAndTimeToFullDate = ({
  date,
  time,
}: IParseDateAndTimeToFullDate) => {
  return new Date(`${date}T${time}`);
};

const compareTwoDates = ({
  firstDate,
  secondDate,
  firstTime,
  secondTime,
}: ICompareTwoDates) => {
  const newFirstDate = parseDateAndTimeToFullDate({
    date: firstDate,
    time: firstTime,
  });
  const newSecondDate = parseDateAndTimeToFullDate({
    date: secondDate,
    time: secondTime,
  });

  if (newFirstDate.getTime() > newSecondDate.getTime()) {
    return {
      status: true,
      message: 'Os datas informadas, são entre perídos divergentes',
    };
  }
  return {
    status: false,
    message: 'A data esta dentro dos conformes',
  };
};

export { compareTwoDates };
