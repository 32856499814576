import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { V1 } from './v1';
import { V2 } from './v2';

interface ISelectAppVersion {
  version: string;
}

const searchValidVersion = (version: string) => {
  if (version.includes('/v1')) {
    return '/v1';
  }
  if (version.includes('/v2')) {
    return '/v2';
  }
  if (version === '/') {
    window.location.href = '/v1';
    return '/v1';
  }
  return '/v1';
};

const SelectAppVersion: FC<ISelectAppVersion> = ({ version }) => {
  if (version === '/') {
    window.location.href = '/v1';
  }
  const fixVersion = searchValidVersion(version);
  const validVersion = () => {
    switch (fixVersion) {
      case '/v1':
        return <V1 {...{ version: fixVersion }} />;
      case '/v2':
        return <V2 {...{ version: fixVersion }} />;
      default:
        return <V1 {...{ version: fixVersion }} />;
    }
  };

  return <BrowserRouter>{validVersion()}</BrowserRouter>;
};

export { SelectAppVersion };
