import { darken } from 'polished';
import styled from 'styled-components';

interface IContainerSmallButtonIcon {
  width: number;
}

export const ContainerSmallButtonIcon = styled.div<IContainerSmallButtonIcon>`
  height: 50px;
  border-radius: 25px;
  width: ${({ width }) => width}px;
  background-color: ${({ theme }) => theme.COLORS.GREEN_30};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.COLORS.GREEN_30)};
  }

  img {
    margin-right: 0.6rem;
    width: 15px;
    height: 15px;
    transition: 0.3s all;
  }
  @media (max-width: 650px) {
    span {
      display: none;
    }
    width: 50px;
    img {
      margin: 0;
    }
  }
`;
