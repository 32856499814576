import { usePropertyContext } from '../../../context/ProviderPage/Propriedade';
import { Property } from '../../../types/Property';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListProperty {
  data: Property[];
}

export const ListProperty = ({ data }: IListProperty) => {
  const { setOpenInfo, setSelectedProperty } = usePropertyContext();

  const handleSelectProperty = (property: Property) => {
    setSelectedProperty(property);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Matrícula</th>
            {/* <th className="list-th">Código</th> */}
            {/* <th className="list-th">Nome</th> */}
            <th className="list-th">Propriedade</th>
            <th className="list-th">CNPJ/CPF</th>
            <th className="list-th">Número</th>
            <th className="list-th">Produtor</th>
            <th className="list-th">Município</th>
            <th className="list-th">UF</th>
            <th className="list-th">Logradouro</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((property, index) => {
            const {
              DFATIVOINATIVO,
              DFCNPJCPFCEI,
              DFIDSIGLAUF,
              DFLOGRADOURO,
              DFMATRICULA,
              DFNOMEMUNICIPIO,
              DFNOMEPRODUTOR,
              DFNOMEPROPRIEDADE,
              DFNUMERO,
            } = property;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSelectProperty(property)}
              >
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFATIVOINATIVO!)}
                  />
                </td>
                <td className="list-td-small">
                  {DFMATRICULA || 'Não informado'}
                </td>
                {/* <td className="list-td-small">
                  {DFIDPROPRIEDADE || 'Não informado'}
                </td> */}
                <td className="list-td-large">
                  {DFNOMEPROPRIEDADE || 'Não informado'}
                </td>

                <td className="list-td-medium">
                  {formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {DFNUMERO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMEPRODUTOR || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {DFNOMEMUNICIPIO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFIDSIGLAUF || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {DFLOGRADOURO || 'Não informado'}
                </td>
                <td className="list-td-option">
                  <ListButton
                    {...{
                      action: () => handleSelectProperty(property),
                      icon: 'search',
                    }}
                  />
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
