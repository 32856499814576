import { Dispatch, SetStateAction } from 'react';

import { META_COLLECT_API } from '../../../api';
import { CollectRegister } from '../../../types/CollectRegister';
import { Statistic } from '../../../types/Statistic';
import { StopedCollectRegister } from '../../../types/StopedCollectRegister';
import { formatReceiveSeconds } from '../../../utils/formatReceiveSeconds';

interface ILoadStatistics {
  setLoading: Dispatch<SetStateAction<boolean>>;
  path: string;
  DFIDCOLETA: number;
  setStatistics: Dispatch<SetStateAction<Statistic[]>>;
}

interface IFormateTimeLineInfo {
  stoped: StopedCollectRegister[];
  registers: CollectRegister[];
  setStatistics: Dispatch<SetStateAction<Statistic[]>>;
}

const checkaRegisterDifference = (
  currentRegister: Statistic[],
  index: number,
) => {
  if (index === currentRegister.length - 1) {
    return '';
  }
  const d1 = currentRegister[index].DFDATA;
  const h1 = currentRegister[index].DFHORA;
  const d2 = currentRegister[index + 1].DFDATA;
  const h2 = currentRegister[index + 1].DFHORA;
  if (!d1 || !d2 || !h1 || !h2) {
    return '';
  }
  const fulld1 = new Date(`${d1} ${h1}`);
  const fulld2 = new Date(`${d2} ${h2}`);
  return formatReceiveSeconds((fulld2.getTime() - fulld1.getTime()) / 1000);
};

const formatStopRegister = ({
  registers,
  stoped,
  setStatistics,
}: IFormateTimeLineInfo) => {
  const formatRegisters = registers.map(item => {
    return {
      ...item,
      DFTIPO: 'R',
      DFDATA: item.DFDATAREGISTRO,
      DFHORA: item.DFHORAREGISTRO,
    };
  });
  const formatStoped = stoped.map(item => {
    return {
      ...item,
      DFTIPO: 'S',
      DFDATA: item.DFFIMDATAPARADA,
      DFHORA: item.DFFIMHORAPARADA,
    };
  });
  const currentRegister = [...formatRegisters, ...formatStoped];

  const compareDates = (a: any, b: any) => {
    const getDate = (item: any) => {
      const date = item.DFDATAREGISTRO || item.DFFIMDATAPARADA;
      const time = item.DFHORAREGISTRO || item.DFFIMHORAPARADA;
      if (date && time) {
        return new Date(`${date} ${time}`);
      }
      if (date) {
        return new Date(date);
      }
      return null;
    };
    const dateA = getDate(a);
    const dateB = getDate(b);
    if (dateA === null || dateB === null) {
      return 0;
    }
    return dateA.getTime() - dateB.getTime();
  };

  const updatedRegister = currentRegister.map((item, index) => {
    return {
      ...item,
      DFDIFERENCAEMSEGUNDOS: checkaRegisterDifference(currentRegister, index),
    };
  });

  setStatistics(updatedRegister.sort(compareDates));
};

const loadStatistics = async ({
  setLoading,
  path,
  DFIDCOLETA,
  setStatistics,
}: ILoadStatistics) => {
  setLoading(true);
  META_COLLECT_API.get(`/registro/estatisticas?DFIDCOLETA=${DFIDCOLETA}`, {
    headers: {
      path,
    },
  })
    .then(response => {
      META_COLLECT_API.get(`/registro-parada-coleta?DFIDCOLETA=${DFIDCOLETA}`, {
        headers: {
          path,
        },
      })
        .then(({ data }) => {
          formatStopRegister({
            registers: response.data.registros,
            stoped: data.collectStop,
            setStatistics,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })
    .catch(() => {
      setLoading(false);
    });
};

export { loadStatistics };
