import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { ExcludedCollect } from '../../../types/ExcludedCollect';
import { deformatDate } from '../../../utils/date/deformatDate';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterExcludedCollect: boolean;
  openSideModalFilterExcludedCollectAction: () => void;

  DFIDCOLETA: string;
  setDFIDCOLETA: Dispatch<SetStateAction<string>>;
  DFDATAEXCLUSAOCOLETA: string;
  setDFDATAEXCLUSAOCOLETA: Dispatch<SetStateAction<string>>;
  DFNOMECARRETEIRO: string;
  setDFNOMECARRETEIRO: Dispatch<SetStateAction<string>>;
  DFHORAEXCLUSAOCOLETA: string;
  setDFHORAEXCLUSAOCOLETA: Dispatch<SetStateAction<string>>;
  DFNOMELINHA: string;
  setDFNOMELINHA: Dispatch<SetStateAction<string>>;
  DFDESCREGIONAL: string;
  setDFDESCREGIONAL: Dispatch<SetStateAction<string>>;

  filterExcludedCollectAction: () => void;
  cleanAllInputsAction: () => void;
  loadExcludedCollectAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedExcludedCollect: ExcludedCollect | undefined;
  setSelectedExcludedCollect: Dispatch<
    SetStateAction<ExcludedCollect | undefined>
  >;

  excludedCollect: ExcludedCollect[];
  setExcludedCollect: Dispatch<SetStateAction<ExcludedCollect[]>>;

  loadingExcludedCollect: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as IContext);

const ExcludedCollectContextProvider = ({ children }: IContextProps) => {
  const [DFIDCOLETA, setDFIDCOLETA] = useState<string>('');
  const [DFDATAEXCLUSAOCOLETA, setDFDATAEXCLUSAOCOLETA] = useState<string>('');
  const [DFHORAEXCLUSAOCOLETA, setDFHORAEXCLUSAOCOLETA] = useState<string>('');
  const [DFNOMECARRETEIRO, setDFNOMECARRETEIRO] = useState<string>('');
  const [DFNOMELINHA, setDFNOMELINHA] = useState<string>('');
  const [DFDESCREGIONAL, setDFDESCREGIONAL] = useState<string>('');

  const [loadingExcludedCollect, setIsLoadingExcludedCollect] =
    useState<boolean>(false);
  const [
    openSideModalFilterExcludedCollect,
    setOpenSideModalFilterExcludedCollect,
  ] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedExcludedCollect, setSelectedExcludedCollect] = useState<
    ExcludedCollect | undefined
  >();
  const [excludedCollect, setExcludedCollect] = useState<ExcludedCollect[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDCOLETA = `DFIDCOLETA=${DFIDCOLETA}`;
  const DATAEXCLUSAOCOLETA = `DFDATAEXCLUSAOCOLETA=${deformatDate(
    DFDATAEXCLUSAOCOLETA,
  )}`;
  const HORAEXCLUSAOCOLETA = `DFHORAEXCLUSAOCOLETA=${DFHORAEXCLUSAOCOLETA}`;
  const NOMECARRETEIRO = `DFNOMECARRETEIRO=${DFNOMECARRETEIRO}`;
  const NOMELINHA = `DFNOMELINHA=${DFNOMELINHA}`;
  const DESCREGIONAL = `DFDESCREGIONAL=${DFDESCREGIONAL}`;

  const openSideModalFilterExcludedCollectAction = () => {
    setOpenSideModalFilterExcludedCollect(!openSideModalFilterExcludedCollect);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingExcludedCollect(true);
      META_COLLECT_API.get(
        `/coleta-deletada?${Limit}&${Offset}&${IDCOLETA}&${DATAEXCLUSAOCOLETA}&${HORAEXCLUSAOCOLETA}&${NOMECARRETEIRO}&${NOMELINHA}&${DESCREGIONAL}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setExcludedCollect(response.data.registers);
          setQuantity(response.data.quantidade);
          setIsLoadingExcludedCollect(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingExcludedCollect(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingExcludedCollect(true);
    META_COLLECT_API.get(`/coleta-deletada?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setExcludedCollect(response.data.registers);
        setQuantity(response.data.quantidade);
        setIsLoadingExcludedCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingExcludedCollect(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFDESCREGIONAL('');
    setDFDATAEXCLUSAOCOLETA('');
    setDFHORAEXCLUSAOCOLETA('');
    setDFIDCOLETA('');
    setDFNOMECARRETEIRO('');
    setDFNOMELINHA('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterExcludedCollectAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterExcludedCollectAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterExcludedCollectAction();
    setIsLoadingExcludedCollect(true);
    META_COLLECT_API.get(
      `/coleta-deletada?${Limit}&offset=0&${IDCOLETA}&${DATAEXCLUSAOCOLETA}&${HORAEXCLUSAOCOLETA}&${NOMECARRETEIRO}&${NOMELINHA}&${DESCREGIONAL}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setExcludedCollect(response.data.registers);
        setQuantity(response.data.quantidade);
        setIsLoadingExcludedCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingExcludedCollect(false);
        setStart(1);
      });
  };

  const loadExcludedCollectAction = () => {
    setIsLoadingExcludedCollect(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'regiao') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFDESCREGIONAL,
        filterExcludedCollectAction,
        loadExcludedCollectAction,
        limit,
        loadingExcludedCollect,
        offset,
        openInfo,
        openSideModalFilterExcludedCollect,
        openSideModalFilterExcludedCollectAction,
        page,
        quantity,
        excludedCollect,
        selectedExcludedCollect,
        setDFDESCREGIONAL,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setExcludedCollect,
        setSelectedExcludedCollect,
        setStart,
        start,
        DFDATAEXCLUSAOCOLETA,
        setDFDATAEXCLUSAOCOLETA,
        DFHORAEXCLUSAOCOLETA,
        DFNOMECARRETEIRO,
        DFNOMELINHA,
        setDFHORAEXCLUSAOCOLETA,
        setDFNOMECARRETEIRO,
        setDFNOMELINHA,
        DFIDCOLETA,
        setDFIDCOLETA,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useExcludedCollectContext() {
  const context = useContext(Context);
  return context;
}

export { ExcludedCollectContextProvider, useExcludedCollectContext };
