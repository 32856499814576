import { darken } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

import { ICustomNav } from '../components/Panel/SideMenu/styles';
import { sideMenuParams, componentsHeight } from '../utils/sideMenuParams';

const { CLOSED_WIDTH, OPEN_WIDTH } = sideMenuParams;
const { FIRST_HEADER, SECOND_HEADER } = componentsHeight;

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root{ 
    --toastify-icon-color-info: #FFC85A;
    --toastify-icon-color-success: #12855F;
    --toastify-icon-color-warning: #FFF;
    --toastify-icon-color-error: #6E2727;

    --toastify-color-progress-info: #FFC85A;
    --toastify-color-progress-success: #12855F;
    --toastify-color-progress-warning: #FFF;
    --toastify-color-progress-error:#6E2727;
  }
  .class-success{
    color: #12855F;
  }
  .class-error{
    color: #6E2727;
  }
  .class-info{
    color: #614C22;
  }

  ::-webkit-scrollbar {
    width: 15px;
  }
 
  ::-webkit-scrollbar-corner 
  {
    background: transparent; 
  }

  ::-webkit-scrollbar-track {
    background: #aaa; 
    border-radius: 0.6rem;
  }
 
  ::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 10px; 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ccc; 
  }

  input, html{
    font-family: 'Inter', sans-serif;
  }

  button{
    cursor: pointer;
  }

  .ReactModal__Overlay {
    opacity: 0;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition-duration: 0.25s;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transition-duration: 0.25s;
  }
  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    padding-left: calc(10%);
    z-index: 5;
    position: absolute;
  }
  .react-modal-content {
    height: 600px;
    width: 600px; 
    outline: 0;
    background-color: white;
  }

 

  .filter-modal-content{
      width: 600px; 
      height: 400px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0.6rem;
      position: fixed;
      transition: 0.3s all;
      margin-left: 280px;    

      @media(max-width: 900px){
        width: 500px; 
      }
      @media(max-width: 810px){
        width: 450px; 
      }
      @media(max-width: 700px){
        width: 350px; 
      }
      @media(max-width: 650px){
        width: 400px; 
      }
      @media(max-width: 500px){
        width: 300px; 
      }
      @media(max-width: 400px){
        width: 280px; 
      }
    }
  .filter-modal-content-closed{
      width: 600px; 
      height: 400px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0.6rem;
      position: fixed;
      transition: 0.3s all;
      margin-left: 90px; 
      @media(max-width: 900px){
        width: 500px; 
      }
      @media(max-width: 810px){
        width: 450px; 
      }
      @media(max-width: 700px){
        width: 350px; 
      }
      @media(max-width: 650px){
        width: 400px; 
      }
      @media(max-width: 500px){
        width: 300px; 
      }
      @media(max-width: 400px){
        width: 280px; 
      }
    }
  
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Column = styled.div<ICustomNav>`
  width: 100%;
  transition: 0.3s all;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonTextLight = styled.span`
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: 0.85rem;
  font-weight: bold;
`;

export const ContainerErrorPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 400px;
    /* background-size: contain; */
    transition: 0.3s all;
    @media (max-height: 600px) {
      width: 350px;
    }
    @media (max-height: 500px) {
      width: 300px;
    }
    @media (max-height: 400px) {
      width: 230px;
    }
    @media (max-width: 1020px) {
      width: 500px;
    }
    @media (max-width: 600px) {
      width: 350px;
    }
    @media (max-width: 340px) {
      width: 260px;
    }
    @media (max-height: 700px) {
      width: 260px;
    }
    @media (max-height: 600px) {
      width: 210px;
    }
  }

  span {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.COLORS.GREEN_100};
    transition: 0.3s all;

    @media (max-width: 1020px) {
      font-size: 2rem;
    }
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
    @media (max-width: 400px) {
      font-size: 1.1rem;
    }
  }

  p {
    text-align: center;
    font-size: 1rem;
    transition: 0.3s all;
  }

  button {
    margin-top: 2rem;
    width: 200px;
    height: 60px;
    border-radius: 30px;
    border: 0;
    font-size: 1.3rem;
    font-weight: bold;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    transition: 0.3s all;
    &:hover {
      background-color: ${({ theme }) => darken(0.04, theme.COLORS.GREEN_30)};
    }
    @media (max-height: 700px) {
      width: 150px;
      height: 45px;
      font-size: 0.8rem;
    }
    @media (max-height: 600px) {
      width: 100px;
      height: 30px;
      font-size: 0.6rem;
    }
  }
`;

export const ContainerPage = styled.div`
  width: 100%;
  height: calc(100% - ${SECOND_HEADER}px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
`;

export const ScrollContainerPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
  background-color: ${({ theme }) => darken(0.1, theme.COLORS.WHITE)};
`;

export const CollectRowContainer = styled.div`
  height: calc(100% - ${SECOND_HEADER}px);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const PageContent = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.COLORS.WHITE};

  .full-confirm-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    border: 0;
    border-radius: 3px;
    padding: 1rem;
    transition: 0.3s all;
    svg {
      margin-right: 0.2rem;
    }
    ${({ theme }) => css`
      color: ${theme.COLORS.WHITE};
      font-weight: bold;
      background-color: ${theme.COLORS.GREEN_30};
      &:hover {
        filter: brightness(0.9);
      }
    `};
  }
`;

export const MapContainer = styled.div`
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  border-radius: 0.6rem 0.6rem 0 0;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  transition: all 0.4s ease-out;
  animation: ease 8s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const PainelRow = styled.div`
  height: calc(100% - ${FIRST_HEADER}px);
  display: flex;
  flex-direction: row;
`;

export const PainelColumn = styled.div<ICustomNav>`
  ${({ width }) =>
    width === CLOSED_WIDTH
      ? css`
          width: calc(100vw - ${CLOSED_WIDTH}px);
        `
      : css`
          width: calc(100vw - ${OPEN_WIDTH}px);
        `};
  @media (max-width: 650px) {
    width: 100%;
  }
  transition: 0.3s all;
  height: calc(100% - ${SECOND_HEADER}px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;
