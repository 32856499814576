import styled from 'styled-components';

export const ContainerPageHeader = styled.div`
  width: 100%;
  height: 70px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.GREEN_100};
  /* -webkit-box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15); */
  transition: 0.3s all;

  .selected-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
    }
    h1 {
      margin-left: 1rem;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.COLORS.WHITE};
      /* font-weight: bold; */
    }
  }
`;
