/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import ArrowDown from '../../../assets/ArrowDown.svg';
import { Silo } from '../../../types/Silo';
import { Container } from './styles';

interface IOptionSelecetInputProps {
  title: any;
  value?: any | number;
  setCreatedDischargeCollectSilo: Dispatch<SetStateAction<Silo>>;
  silos: Silo[];
}

const OptionSelecetInput = ({
  title,
  value,
  setCreatedDischargeCollectSilo,
  silos,
}: IOptionSelecetInputProps) => {
  const [openOption, setOpenOption] = useState<boolean>(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (openOption) {
      setTimeout(() => {
        setShowContent(true);
      }, 100);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 0);
    }
  }, [openOption]);

  const selecOption = (option: Silo) => {
    setCreatedDischargeCollectSilo(option);
    setOpenOption(false);
  };

  return (
    <Container openOption={openOption} timedOpenOption={showContent}>
      <label>{title}</label>
      <div className="container-filter-input">
        <div className="filter-select">
          <span> {value || 'Selecionar silo'}</span>
          <img
            className="button-open-options"
            src={ArrowDown}
            onClick={() => setOpenOption(!openOption)}
          />
        </div>
      </div>
      <div className="list-options">
        {silos.map((silo, index) => (
          <span key={index} onClick={() => selecOption(silo)}>
            {silo.DFDESCRICAOSILO}
          </span>
        ))}
      </div>
    </Container>
  );
};

export { OptionSelecetInput };
