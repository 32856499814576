/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import Edit from '../../../assets/edit.svg';
import Search from '../../../assets/ListSearchIcon.svg';
import Discharge from '../../../assets/menuOptions/discharge.svg';
import Arrown from '../../../assets/OptionArrrow.svg';
import Print from '../../../assets/print.svg';
import Qrcode from '../../../assets/qrcode.svg';
import { ListButtonContainer } from './styles';

interface IListButton {
  icon:
    | 'search'
    | 'arrown-down'
    | 'discharge'
    | 'other'
    | 'edit'
    | 'qrcode'
    | 'print';
  action: (e: any) => void;
  id?: string;
  disabled?: boolean;
}

export const ListButton = ({ action, icon, id, disabled }: IListButton) => {
  return (
    <ListButtonContainer
      {...{
        id,
        disabled,
        onClick: action,
      }}
    >
      <img
        id={id}
        src={
          icon === 'search'
            ? Search
            : icon === 'arrown-down'
            ? Arrown
            : icon === 'discharge'
            ? Discharge
            : icon === 'edit'
            ? Edit
            : icon === 'qrcode'
            ? Qrcode
            : icon === 'print'
            ? Print
            : ''
        }
      />
    </ListButtonContainer>
  );
};
