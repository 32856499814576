import { Dispatch, SetStateAction } from 'react';

import { META_COLLECT_API } from '../../../../../../api';
import { ReleaseCollectTankProducer } from '../../../../../../types/ReleaseCollect';

interface ILoadReleasedCollectTanks {
  setTank: Dispatch<SetStateAction<ReleaseCollectTankProducer[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  collectionOrder: number;
}
const loadReleasedCollectTanks = ({
  setTank,
  setLoading,
  collectionOrder,
}: ILoadReleasedCollectTanks) => {
  META_COLLECT_API.get(`/itemcoleta/laboratorio?DFIDCOLETA=${collectionOrder}`)
    .then(res => {
      setTank(res.data.itens);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export { loadReleasedCollectTanks };
