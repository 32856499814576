import { transparentize } from 'polished';
import styled from 'styled-components';

interface IContainerModalFilter {
  isOpen: boolean;
}
interface IShowContentFilter {
  showContentFilter?: boolean;
}

export const ContainerSideModalFilterExemple = styled.div<IContainerModalFilter>`
  height: 100vh;
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 100 : -1)};
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transition: 0.3s all;
  background-color: ${({ isOpen, theme }) =>
    isOpen && transparentize(0.4, theme.COLORS.BLACK_1000)};
`;

export const OverlayModalFilter = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.3s all;
`;

export const ContainerModalFilter = styled.div<IContainerModalFilter>`
  width: ${({ isOpen }) => (isOpen ? '400px' : 0)};
  background-color: #fff;
  transition: 0.3s all;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  display: flex;
  flex-direction: column;
`;

export const NotShowContent = styled.div<IShowContentFilter>`
  transition: 0.3s all;
  height: 100%;
  display: flex;
  display: ${({ showContentFilter }) => (!showContentFilter ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderFilter = styled.header`
  width: 100%;
  height: 60px;
  padding: 1.3rem;
  border-top-left-radius: 0.8rem;
  background-color: ${({ theme }) => theme.COLORS.GREEN_30};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-filter-title {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 1rem;
      font-size: 1.3rem;
      font-weight: 700;
      color: ${({ theme }) => theme.COLORS.WHITE};
      transition: 0.3s all;
      @media (max-width: 700px) {
        font-size: 1rem;
      }
    }
  }

  .header-filter-close-button {
    cursor: pointer;
    width: 35px;
    height: 35px;
    padding: 8px;
    border-radius: 35px;
    transition: 0.3s all;
    &:hover {
      background-color: ${({ theme }) =>
        transparentize(0.9, theme.COLORS.BLACK_1000)};
    }
  }
`;

export const FilterColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FilterRowButtons = styled.div`
  display: flex;
  padding: 1rem 2rem;
  /* background-color: red; */
  transition: 0.3s all;
  @media (max-height: 500px) {
  }
  .filterButtonModal {
    width: 140px;
    height: 50px;
    border-radius: 0.45rem;
    font-size: 0.8rem;
    font-weight: bold;
    transition: 0.3s all;
    margin-left: 1rem;
    border: 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.GREEN_DARK};
    &:hover {
      background-color: ${({ theme }) =>
        transparentize(0.1, theme.COLORS.GREEN_DARK)};
    }
    @media (max-width: 700px) {
      width: 120px;
      font-size: 0.85rem;
    }
    @media (max-width: 500px) {
      width: 100px;
      font-size: 0.65rem;
      height: 50px;
    }
    @media (max-height: 500px) {
      height: 40px;
      font-size: 0.65rem;
    }
  }
  .filterButtonModalTransparent {
    background-color: transparent;
    color: ${({ theme }) => theme.COLORS.GREEN_DARK};
    border: 2px solid ${({ theme }) => theme.COLORS.GREEN_DARK};
    width: 140px;
    height: 50px;
    border-radius: 0.45rem;
    font-size: 0.8rem;
    font-weight: bold;
    transition: 0.3s all;
    &:hover {
      color: ${({ theme }) => transparentize(0.45, theme.COLORS.GREEN_DARK)};
      border: 2px solid
        ${({ theme }) => transparentize(0.45, theme.COLORS.GREEN_DARK)};
    }
    @media (max-width: 700px) {
      width: 120px;
      font-size: 0.85rem;
    }
    @media (max-width: 500px) {
      width: 100px;
      font-size: 0.65rem;
      height: 50px;
    }
    @media (max-height: 500px) {
      height: 40px;
      font-size: 0.65rem;
    }
  }
`;
