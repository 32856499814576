import styled from 'styled-components';

export const DontExistsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.3s all;

  img {
    width: 150px;
    transition: 0.3s all;
    @media (max-width: 390px) {
      width: 110px;
    }
  }

  span {
    text-align: center;
    color: #666666;
    font-weight: 700;
    font-size: 1.5rem;
    width: 45%;
    transition: 0.3s all;
    @media (max-width: 350px) {
      font-size: 1rem;
    }
    @media (max-width: 160px) {
      font-size: 0.8rem;
    }
  }
`;

export const DontExistsItemFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
