import { transparentize } from 'polished';
import styled from 'styled-components';

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: 0.3s all;
    border-radius: 50px;
    padding: 0.6rem;
    background-color: ${({ theme }) => theme.COLORS.GREEN_100};
    &:hover {
      background-color: ${({ theme }) =>
        transparentize(0.15, theme.COLORS.GREEN_100)};
    }
  }
  span {
    margin-left: 0.8rem;
    font-weight: bold;
    color: ${({ theme }) => theme.COLORS.GREEN_100};
  }
`;
