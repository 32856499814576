/* eslint-disable no-nested-ternary */
import { CollectRegister } from '../../types/CollectRegister';
import { Statistic } from '../../types/Statistic';
import { StopedCollectRegister } from '../../types/StopedCollectRegister';
import { formatDate } from '../../utils/date/formatDate';
import { formatReceiveSeconds } from '../../utils/formatReceiveSeconds';
import { returnRegisterType } from '../../utils/returnRegisterType';
import { DontExistsItem } from '../DontExistsItem';
import { DontExistsItemFlex } from '../DontExistsItem/styles';
import { LoadingData } from '../LoadingData';
import { renderIcon } from './renderIcon';
import {
  ContainerStatisticsTimeLine,
  TimeLineCard,
  TimeLineCurrentTime,
  TimeLineSpace,
  TimeLineSpaceContainer,
} from './styles';

interface IStatisticsTimeLine {
  statistics: Statistic[];
  loading: boolean;
}

interface ICardSpace {
  index: number;
  statistics: Statistic[];
}

const CardSpace = ({ index, statistics }: ICardSpace) => {
  const isRender = index < statistics.length - 1;
  return isRender ? (
    <TimeLineSpaceContainer>
      <TimeLineSpace />
      <TimeLineCurrentTime>
        <span>{statistics[index].DFDIFERENCAEMSEGUNDOS}</span>
      </TimeLineCurrentTime>
    </TimeLineSpaceContainer>
  ) : (
    <></>
  );
};

const StatisticsTimeLine = ({ statistics, loading }: IStatisticsTimeLine) => {
  const renderTimeLine = () => {
    return statistics.map((item, index) => {
      const { DFTIPO } = item;
      let current;
      if (DFTIPO === 'R') {
        current = item as CollectRegister;
        const {
          DFTIPOREGISTRO,
          DFTIPOITEMCOLETA,
          DFOBSERVACAO,
          DFDESCTANQUE,
          DFQTDCOLETADA,
          DFTEMPERATURA,
          DFDATAREGISTRO,
          DFHORAREGISTRO,
          DFHORACOLETA,
        } = current;
        return (
          <div key={index}>
            <TimeLineCard
              DFTIPOREGISTRO={DFTIPOREGISTRO!}
              DFTIPOITEMCOLETA={DFTIPOITEMCOLETA}
            >
              <div className="icon">
                <img src={renderIcon(DFTIPOREGISTRO!)} />
              </div>
              <div className="card-column">
                <span>
                  {returnRegisterType(DFTIPOREGISTRO, DFTIPOITEMCOLETA)}
                </span>
                <p>
                  <b>Observação: </b>
                  {DFOBSERVACAO || 'Não informada'}
                </p>
                {DFDESCTANQUE && (
                  <p>
                    <b>Tanque:</b> {DFDESCTANQUE}
                  </p>
                )}
                {DFTIPOREGISTRO !== 'O' && (
                  <>
                    {DFQTDCOLETADA && (
                      <p>
                        <b>Volume:</b> {DFQTDCOLETADA}L
                      </p>
                    )}
                    {DFTEMPERATURA && (
                      <p>
                        <b>Temperatura:</b> {DFTEMPERATURA}°
                      </p>
                    )}
                  </>
                )}
                {DFDATAREGISTRO && (
                  <p>
                    <b>Data e Hora:</b> {formatDate(DFDATAREGISTRO)}
                    &nbsp; às {DFHORACOLETA || DFHORAREGISTRO || '-'}
                  </p>
                )}
              </div>
            </TimeLineCard>
            <CardSpace {...{ index, statistics }} />
          </div>
        );
      }
      current = item as StopedCollectRegister;
      const {
        DFFIMDATAPARADA,
        DFFIMHORAPARADA,
        DFINICIODATAPARADA,
        DFINICIOHORAPARADA,
        DFSEGUNDOSPARADO,
      } = current;
      return (
        <div key={index}>
          <TimeLineCard key={index} DFTIPOREGISTRO={'P'}>
            <div className="icon">
              <img src={renderIcon('P')} />
            </div>
            <div className="card-column">
              <span>{returnRegisterType('P', undefined)}</span>
              <p>
                <b>Observação: </b> Carreteiro ficou parado entre {`\n`}
                <b>
                  {formatDate(DFINICIODATAPARADA)} às{' '}
                  {DFINICIOHORAPARADA || '-'}
                </b>
                <a> e </a>
                <b>
                  {formatDate(DFFIMDATAPARADA)} às {DFFIMHORAPARADA || '-'}
                </b>
                <a> durante {formatReceiveSeconds(DFSEGUNDOSPARADO)}</a>
              </p>
            </div>
          </TimeLineCard>
          <CardSpace {...{ index, statistics }} />
        </div>
      );
    });
  };

  return (
    <ContainerStatisticsTimeLine
      style={{
        alignItems: `${loading ? 'center' : 'normal'}`,
        justifyContent: `${loading ? 'center' : 'normal'}`,
      }}
    >
      {loading === true ? (
        <LoadingData title="Carregando dados!" />
      ) : statistics.length > 0 && loading === false ? (
        <>{renderTimeLine()}</>
      ) : (
        loading === false &&
        statistics.length === 0 && (
          <DontExistsItemFlex>
            <DontExistsItem title="Nenhum item foi encontrado!" />
          </DontExistsItemFlex>
        )
      )}
    </ContainerStatisticsTimeLine>
  );
};

export { StatisticsTimeLine };
