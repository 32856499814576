import { ReactNode, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BiBarcodeReader } from 'react-icons/bi';
import { CgSmartphoneChip } from 'react-icons/cg';
import {
  FaIdBadge,
  FaInfo,
  FaSyncAlt,
  FaClipboardList,
  FaEdit,
} from 'react-icons/fa';
import { GrLicense } from 'react-icons/gr';
import { MdInstallMobile } from 'react-icons/md';
import { SiApple, SiAndroid } from 'react-icons/si';
import { SlScreenSmartphone } from 'react-icons/sl';
import Load from 'react-loading';

import { useDeviceContext } from '../../../../context/ProviderPage/Device';
import { DataDevice } from '../../../../types/Device';
import { formatDateAndHour } from '../../../../utils/date/formatDateAndHour';
import { formatBytesToGigaBytes } from '../../../../utils/formatBytesToGigaBytes';
import { DeviceCardContainer, DeviceHardware } from './styles';

interface IRenderRowItem {
  label: string;
  value?: string;
  icon?: ReactNode;
}

const DeviceCard: React.FC<DataDevice> = ({ device }: DataDevice) => {
  const [activeMeter, setActiveMeter] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    DFCOLETAEMANDAMENTO,
    DFCPU,
    DFDATAINSTALACAO,
    DFDATAVINCULO,
    DFESPACOTOTAL,
    DFESPACOUTILIZADO,
    DFHORAINSTALACAO,
    DFHORAVINCULO,
    DFIDCARRETEIRO,
    DFIDLICENCA,
    // DFIDVINCULODISPOSITIVO,
    DFMEMORIARAM,
    DFMODELOAPARELHO,
    DFNOMECARRETEIRO,
    DFSO,
    DFVERSAOAPP,
    DFVERSAOSO,
    DFDATASINCRONIZACAO,
    DFHORASINCRONIZACAO,
    DFIMEI,
  } = device;
  const FDFMEMORIARAM = formatBytesToGigaBytes(DFMEMORIARAM);
  const FDFESPACOUTILIZADO = formatBytesToGigaBytes(DFESPACOUTILIZADO);
  const FDFESPACOTOTAL = formatBytesToGigaBytes(DFESPACOTOTAL);
  const meter = (Number(DFESPACOUTILIZADO) / Number(DFESPACOTOTAL)) * 100;
  const { releaseBondDevice, deleteBondDevice } = useDeviceContext();
  const selectedDevice = {
    setLoadingOperation,
    device,
  };
  const deleteDevice = {
    setLoadingOperation: setDeleteLoading,
    device,
  };

  setTimeout(() => {
    setActiveMeter(true);
  }, 100);

  const RenderRowItem = ({ icon, label, value }: IRenderRowItem) => {
    return (
      <div className="card-row">
        <div className="card-row-item">
          {icon}
          <span>{label}</span>
        </div>
        {value && <span>{value}</span>}
      </div>
    );
  };

  return (
    <DeviceCardContainer {...{ active: DFCOLETAEMANDAMENTO === 'S' }}>
      <div className="card-title">
        <h1>{DFNOMECARRETEIRO || 'Sem dispositivo'}</h1>
        <div className="badge">
          <div className="animation" />
        </div>
      </div>

      <RenderRowItem
        {...{
          label: 'Carreteiro',
          value: DFIDCARRETEIRO ? DFIDCARRETEIRO.toString() : '',
          icon: <FaIdBadge />,
        }}
      />

      <RenderRowItem
        {...{
          label: 'Licença',
          value: DFIDLICENCA ? DFIDLICENCA.toString() : '',
          icon: <GrLicense />,
        }}
      />

      <RenderRowItem
        {...{ label: 'Versão App', value: DFVERSAOAPP, icon: <FaInfo /> }}
      />

      <RenderRowItem
        {...{
          label: 'Data Instalação',
          value: `${formatDateAndHour(DFDATAINSTALACAO, DFHORAINSTALACAO)}`,
          icon: <MdInstallMobile />,
        }}
      />

      <RenderRowItem
        {...{
          label: 'Data Vinculo',
          value: `${formatDateAndHour(DFDATAVINCULO, DFHORAVINCULO)}`,
          icon: <FaClipboardList />,
        }}
      />

      <RenderRowItem
        {...{
          label: 'Data Sincronização',
          value: `${formatDateAndHour(
            DFDATASINCRONIZACAO,
            DFHORASINCRONIZACAO,
          )}`,
          icon: <FaSyncAlt />,
        }}
      />

      <div className="card-row" style={{ padding: 0 }}>
        <div className="card-row-item-medium-with-bar">
          <div className="card-row-item">
            {DFSO === 'Android' ? <SiAndroid /> : <SiApple />}
            <span>{DFSO === 'Android' ? 'Android' : 'IOS'}</span>
          </div>
          <span>{DFVERSAOSO}</span>
        </div>
        <div className="card-row-item-medium">
          <div className="card-row-item">
            <SlScreenSmartphone />
            <span>Modelo</span>
          </div>
          <span>{DFMODELOAPARELHO}</span>
        </div>
      </div>

      {/* <RenderRowItem
        {...{
          label: DFSO === 'Android' ? 'Android' : 'IOS',
          value: DFVERSAOSO,
          icon: DFSO === 'Android' ? <SiAndroid /> : <SiApple />,
        }}
      />

      <RenderRowItem
        {...{
          label: 'Modelo',
          value: DFMODELOAPARELHO,
          icon: <SlScreenSmartphone />,
        }}
      /> */}

      <RenderRowItem
        {...{
          label: 'Imei',
          value: DFIMEI || '',
          icon: <BiBarcodeReader />,
        }}
      />

      <DeviceHardware {...{ meter, activeMeter }}>
        <div className="card-row-item">
          <CgSmartphoneChip />
          <span>Hardware</span>
        </div>
        <div className="device-hardware-device-info">
          <span>{FDFESPACOUTILIZADO}GB utilizado</span>
          <span>de {FDFESPACOTOTAL}GB</span>
        </div>
        <div className="device-hardware-storage-size">
          <div className="device-hardware-storage-meter" />
        </div>
        <div className="device-hardware-device-info">
          <span>{FDFMEMORIARAM} RAM</span>
          <span>{DFCPU}Ghz CPU</span>
        </div>
      </DeviceHardware>

      <div className="card-footer">
        <button
          className="modify"
          onClick={() => releaseBondDevice(selectedDevice)}
          disabled={loadingOperation || deleteLoading}
        >
          {loadingOperation ? (
            <>
              <Load height={14} width={14} type="spin" />
              <span>Liberando</span>
            </>
          ) : (
            <>
              <FaEdit />
              <span>Liberar</span>
            </>
          )}
        </button>
        <button
          className="delete"
          onClick={() => deleteBondDevice(deleteDevice)}
          disabled={loadingOperation || deleteLoading}
        >
          {deleteLoading ? (
            <>
              <Load height={14} width={14} type="spin" />
              <span>Liberando</span>
            </>
          ) : (
            <>
              <AiFillDelete />
              <span>Remover</span>
            </>
          )}
        </button>
      </div>
    </DeviceCardContainer>
  );
};

export { DeviceCard };
