import { ListDevice } from '../../../types/Device';
import { DeviceCard } from './DeviceCard';
import { ListDevicesContainer } from './styles';

const ListDevices: React.FC<ListDevice> = ({ devices }: ListDevice) => {
  return (
    <ListDevicesContainer>
      {devices.map((device, key) => {
        return <DeviceCard {...{ device, key }} />;
      })}
    </ListDevicesContainer>
  );
};

export { ListDevices };
