import { transparentize } from 'polished';
import styled from 'styled-components';

export const ListTanksToReleaseWithColletContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 49.6% 49.6%;
  flex-flow: row wrap;
  overflow-y: auto;
  padding: 1rem;
  justify-content: space-between;
  transition: 0.3s all;

  @media (max-width: 900px) {
    flex-flow: column;
    padding: 0 0.6rem;
    grid-template-columns: 100%;
  }
`;

export const ListTankToReleaseItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.GREY_50};
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-color: ${({ theme }) => transparentize(0.55, theme.COLORS.GREY)};
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  .row-item-to-release {
    width: 100%;
    display: flex;
    flex-direction: column;
    .button-bond {
      border: 0;
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #2b2b2b;
      padding: 0.4rem 0.4rem;
      border-radius: 0.3rem;
      color: white;
      font-weight: bold;
      &:hover {
        filter: brightness(0.9);
      }
      .bond-icon {
        width: 20px;
        height: 20px;
        margin-right: 0.3rem;
      }
    }
  }
`;
