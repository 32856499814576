import DeleteIcon from '../../assets/delete.svg';
import SiloIcon from '../../assets/menuOptions/Silo.svg';
import { DischargeSilo } from '../../types/DischargeSilo';
import { Silo } from '../../types/Silo';
import { formatDate } from '../../utils/date/formatDate';
import { DischargeSiloItemContainer } from './styles';

interface IDischargeSiloItem {
  item: DischargeSilo;
  handleRemoveDischargeSilo: (silo: Silo) => void;
}

const DischargeSiloItem = ({
  item,
  handleRemoveDischargeSilo,
}: IDischargeSiloItem) => {
  const { date, hour, silo, volume } = item;
  const { DFDESCRICAOSILO } = silo;
  return (
    <DischargeSiloItemContainer>
      <div className="silo-background">
        <img src={SiloIcon} className="silo-icon" />
      </div>
      <div className="silo-description">
        <span>
          <b>Silo: </b>
          {DFDESCRICAOSILO}
        </span>
        <span>
          <b>Volume: </b>
          {volume}
        </span>
        <span>
          <b>Data: </b>
          {formatDate(date)} ás {hour}
        </span>
      </div>
      <img
        onClick={() => handleRemoveDischargeSilo(silo)}
        src={DeleteIcon}
        className="delete-button"
      />
    </DischargeSiloItemContainer>
  );
};

export { DischargeSiloItem };
