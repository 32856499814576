import Flag from '../../assets/Flag.svg';
import Tank from '../../assets/menuOptions/tank.svg';
import Stoped from '../../assets/stop.svg';

const renderIcon = (
  type: 'A' | 'D' | 'F' | 'C' | 'N' | 'G' | 'Q' | 'R' | 'T' | 'O' | 'P',
) => {
  switch (type) {
    case 'A':
      return Flag;
    case 'D':
      return Flag;
    case 'F':
      return Flag;
    case 'C':
      return Tank;
    case 'Q':
      return Tank;
    case 'R':
      return Tank;
    case 'T':
      return Tank;
    case 'O':
      return Tank;
    case 'N':
      return Tank;
    case 'P':
      return Stoped;
    default:
      return '';
  }
};

export { renderIcon };
