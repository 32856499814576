import { useEffect } from 'react';

import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { formatInputCPFAndCNPJ } from '../../../utils/cnpjOrCpf/formatInpuCPFAndCNPJ';
import { FilterInput } from '../../Inputs/FilterInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterUnities = () => {
  const {
    openSideModalFilterUnities,
    openSideModalFilterUnitiesAction,
    DFCNPJCPFCEI,
    DFIDUNIDADE,
    DFNOMEFANTASIA,
    DFRAZSOCUNIDADE,
    setDFCNPJCPFCEI,
    setDFIDUNIDADE,
    setDFNOMEFANTASIA,
    setDFRAZSOCUNIDADE,
    cleanAllInputsAction,
    filterUnitiesAction,
  } = useUnityContext();

  useEffect(() => {
    if (Number(DFIDUNIDADE) < 0) {
      setDFIDUNIDADE('0');
    }
  }, [DFIDUNIDADE]);

  const formatCPFAndCNPJ = (value: string) => {
    setDFCNPJCPFCEI(formatInputCPFAndCNPJ(value));
  };

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterUnities}
      onchange={openSideModalFilterUnitiesAction}
      title="Filtrar Unidade"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterUnitiesAction}
    >
      <FilterInput
        onChange={setDFIDUNIDADE}
        value={DFIDUNIDADE}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFRAZSOCUNIDADE}
        value={DFRAZSOCUNIDADE}
        title="Razão social"
        type="text"
      />
      <FilterInput
        onChange={setDFNOMEFANTASIA}
        value={DFNOMEFANTASIA}
        title="Nome fantasia"
        type="text"
      />
      <FilterInput
        onChange={formatCPFAndCNPJ}
        value={DFCNPJCPFCEI}
        title="CNPJ/CPF"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
