import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { META_COLLECT_API } from '../../../api';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { PageContent } from '../../../styles/global.styles';
import { Register } from '../../../types/Register';
import { TankAndProperty } from '../../../types/TankAndProperty';
import { DontExistsItem } from '../../DontExistsItem';
import { DontExistsItemFlex } from '../../DontExistsItem/styles';
import { ListBondTankAndProperty } from '../../List/ListBondTankAndProperty';
import { LoadingData } from '../../LoadingData';
import { SelectedRegister } from '../SelectedRegister';

interface IRegister {
  DFIDCOLETA?: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedRegister: Dispatch<SetStateAction<Register | undefined>>;
}

const BondTankAndProperty = ({
  DFIDCOLETA,
  isOpen,
  setIsOpen,
  setSelectedRegister,
}: IRegister) => {
  const [ITENSCOLETA, SETITENSCOLETA] = useState<TankAndProperty[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [selectedItem, setSelectedItem] = useState<TankAndProperty | null>(
    null,
  );
  const { path } = useSigninContext();

  useEffect(() => {
    setLoading(true);
    META_COLLECT_API.get(`/itemcoleta/registro?DFIDCOLETA=${DFIDCOLETA}`, {
      headers: {
        path,
      },
    })
      .then(res => {
        SETITENSCOLETA(res.data.itens);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <PageContent
      style={{
        justifyContent: `${loading === false ? 'normal' : 'center'}`,
      }}
    >
      {loading ? (
        <LoadingData title="Buscando dados" />
      ) : (
        <>
          {selectedItem ? (
            <SelectedRegister
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              setSelectedRegister={setSelectedRegister}
            />
          ) : (
            <>
              {ITENSCOLETA.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum item foi encontrado!" />{' '}
                </DontExistsItemFlex>
              ) : (
                <ListBondTankAndProperty
                  ITENSCOLETA={ITENSCOLETA}
                  setSelectedRegister={setSelectedRegister}
                  loading={loading}
                  setSelectedItem={setSelectedItem}
                />
              )}
            </>
          )}
        </>
      )}
    </PageContent>
  );
};

export { BondTankAndProperty };
