// import carter from '../assets/menuOptions/carter.svg';
// import collect from '../assets/menuOptions/collect.svg';
import Bond from '../assets/bond.svg';
import excluded from '../assets/excluded.svg';
import bondCollect from '../assets/menuOptions/bondCollect.svg';
import calendar from '../assets/menuOptions/calendar.svg';
import discharge from '../assets/menuOptions/discharge.svg';
// import truck from '../assets/menuOptions/truck.svg';
// import unity from '../assets/menuOptions/unity.svg';
import farm from '../assets/menuOptions/farm.svg';
import gear from '../assets/menuOptions/gear.svg';
import line from '../assets/menuOptions/line.svg';
import region from '../assets/menuOptions/map.svg';
import panelconfiguration from '../assets/menuOptions/panelconfiguration.svg';
import silo from '../assets/menuOptions/Silo.svg';
import smartphone from '../assets/menuOptions/smartphone.svg';
import tank from '../assets/menuOptions/tank.svg';
import tanquesVinculado from '../assets/menuOptions/tanquesVinculado.svg';
import Release from '../assets/menuOptions/unlock.svg';
import milkchecked from '../assets/milkchecked.svg';
import carter from '../assets/newicons/CARRETEIRO.svg';
import collect from '../assets/newicons/COLETA.svg';
// import discharge from '../assets/newicons/DESCARGA.svg';
import unity from '../assets/newicons/FABRICA.svg';
import truck from '../assets/newicons/VEICULO.svg';
import { Option } from '../types/Option';

const returnIcon = ({ option }: Option) => {
  const none = '';
  switch (option) {
    case 'unidade':
      return unity;
    case 'silo':
      return silo;
    case 'regiao':
      return region;
    case 'linha':
      return line;
    case 'tanque':
      return tank;
    case 'propriedade':
      return farm;
    case 'carreteiro':
      return carter;
    case 'caminhao':
      return truck;
    case 'programacao':
      return calendar;
    case 'coleta':
      return collect;
    case 'coleta-item':
      return collect;
    case 'configuracoes':
      return gear;
    case 'descarga':
      return discharge;
    case 'encerrada':
      return milkchecked;
    case 'dispositivo':
      return smartphone;
    case 'configuracoesdopainel':
      return panelconfiguration;
    case 'liberar':
      return Release;
    case 'vincular':
      return Bond;
    case 'vinculada':
      return bondCollect;
    case 'excluida':
      return excluded;
    case 'tanquesVinculado':
      return tanquesVinculado;
    default:
      return none;
  }
};

export { returnIcon };
