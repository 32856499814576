import { formatCnpj } from './formatCnpj';
import { formatCpf } from './formatCpf';

const formatFieldCpfOrCnpj = (text?: string) => {
  if (text && text.length <= 11) {
    return formatCpf(text);
  }
  return formatCnpj(text);
};

export { formatFieldCpfOrCnpj };
