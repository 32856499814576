import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IModalToSelectNewUnityToWorkContainer {
  open: boolean;
  side: number;
}

interface IUnityListItem {
  selected: boolean;
}

export const ModalToSelectNewUnityToWorkContainer = styled.div<IModalToSelectNewUnityToWorkContainer>`
  transition: 0.1s all;
  display: flex;
  padding-left: ${({ side }) => `${side}px`};
  z-index: -1;
  width: 100%;
  height: 100vh;
  /* padding-top: 6rem; */
  ${({ open }) =>
    open &&
    css`
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.5);
      /* padding-top: 0; */
    `}
  position: absolute;
  align-items: center;
  justify-content: center;
  @media (max-width: 650px) {
    padding: 0%;
  }
  .modal-select-unity-content {
    transition: 0.3s all;
    width: ${({ open }) => (open ? '60%' : '0')};
    height: ${({ open }) => (open ? '60%' : '0')};
    @media (max-width: 650px) {
      width: ${({ open }) => (open ? '80%' : '0')};
    }
    header {
      transition: 0.3s all;
      ${({ open }) =>
        !open
          ? css`
              display: none;
            `
          : css`
              display: flex;
            `}
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      width: 100%;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.COLORS.GREEN_100};
      div {
        transition: 0.3s all;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        transition: 0.3s all;
        width: 30px;
        height: 30px;
        @media (max-width: 850px) {
          width: 20px;
          height: 20px;
        }
      }

      span {
        transition: 0.3s all;
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: ${({ theme }) => theme.COLORS.WHITE};
        word-wrap: break-word;
        @media (max-width: 850px) {
          font-size: 0.7rem;
        }
      }

      .header-filter-close-button {
        transition: 0.3s all;
        cursor: pointer;
        width: 35px;
        height: 35px;
        padding: 8px;
        border-radius: 35px;
        &:hover {
          background-color: ${({ theme }) =>
            transparentize(0.9, theme.COLORS.BLACK_1000)};
        }
      }
    }

    .body-modal {
      transition: 0.3s all;
      height: 100%;
      width: 100%;
      padding: 1rem;
      background-color: ${({ theme }) => theme.COLORS.GREY_2};
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;

      .modal-select-unity-list-title {
        transition: 0.3s all;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 0.4rem;
        input {
          transition: 0.3s all;
          width: 100%;
          border: 0;
          outline: 0;
          margin-right: 0.4rem;
        }
      }
      .modal-select-unity-list {
        transition: 0.3s all;
        height: calc(100% - 4rem);
        width: 100%;
        display: flex;
        flex-direction: column;
        /* padding: 1rem; */
        overflow-y: auto;

        .unity-list-item {
        }
      }
    }
  }
`;

export const UnityListItem = styled.div<IUnityListItem>`
  transition: 0.3s all;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.4rem;
  background-color: ${({ theme, selected }) =>
    selected ? theme.COLORS.GREEN_100 : theme.COLORS.GREY_2};
  padding: 1.3rem;
  cursor: pointer;
  span {
    word-wrap: break-word;
    color: ${({ theme, selected }) =>
      !selected ? theme.COLORS.GREEN_100 : theme.COLORS.GREY_2};
  }
  .selected-box {
    height: 30px;
    border-radius: 5px;
    padding: 0 0.4rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span {
      word-wrap: break-word;
      font-size: 0.8rem;
      color: ${({ theme, selected }) =>
        selected ? theme.COLORS.GREEN_100 : theme.COLORS.GREY_2};
    }
    svg {
      margin-left: 0.2rem;
      transition: 0.3s all;
      width: 12px;
      height: 12px;
    }
  }
  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        filter: brightness(0.9);
      `};
  }
`;
