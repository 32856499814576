import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerMenuOptions {
  sideMenuIsClose: boolean;
}

export const ContainerMenuOptions = styled.div<IContainerMenuOptions>`
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s all;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .option-separator {
    ${({ sideMenuIsClose }) =>
      sideMenuIsClose &&
      css`
        display: none;
      `}
    width: 100%;
    margin-bottom: 0.4rem;
    border-top: 0.2px solid
      ${({ theme }) => transparentize(0.7, theme.COLORS.GREEN_20)};
    h3 {
      font-size: 1.2rem;
      margin: 0.4rem 0 0 0.6rem;
      font-weight: 300;
      color: ${({ theme }) => theme.COLORS.WHITE};
      font-size: 1rem;
      transition: 0.3s all;
    }
  }
`;
