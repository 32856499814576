/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { ReactNode, useEffect } from 'react';

import Closed from '../../../assets/closed.svg';
import Logo from '../../../assets/logo1.png';
import { usePageContext } from '../../../context/usePage';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { Column, PainelColumn, PainelRow } from '../../../styles/global.styles';
import { sideMenuParams } from '../../../utils/sideMenuParams';
import { CloseAndOpenMenu } from '../../Buttons/CloseAndOpenMenu';
import { CloseAndOpenMenuMobile } from '../../Buttons/CloseAndOpenMenuMobile';
import { ConectedUser } from './ConectedUser';
import { MenuOptions } from './MenuOptions';
import { SelectNewUnityToWork } from './SelectNewUnityToWork';
import { CustomNav, CustomMenuContainer, CustomHeader } from './styles';

interface ISideMenu {
  children?: ReactNode;
}

const SideMenu = ({ children }: ISideMenu) => {
  const { CLOSED_WIDTH, OPEN_WIDTH } = sideMenuParams;
  const { sideMenuWidth, setSideMenuWidth, setSideMenuIsOpen, sideMenuIsOpen } =
    useSideMenuContext();
  const { openAndCloseModalToSelectUnity } = usePageContext();

  useEffect(() => {
    if (sideMenuIsOpen) {
      setSideMenuWidth(CLOSED_WIDTH);
    } else {
      setSideMenuWidth(OPEN_WIDTH);
    }
  }, [sideMenuIsOpen]);

  return (
    <CustomMenuContainer>
      <Column width={sideMenuWidth}>
        <CustomHeader>
          <div className="first-item-header">
            <CloseAndOpenMenu
              value={sideMenuIsOpen}
              action={setSideMenuIsOpen}
              size={35}
              icon={Closed}
            />
            <CloseAndOpenMenuMobile />
            <img className="logo" src={Logo} />
          </div>
          <div className="content-display-user">
            <SelectNewUnityToWork {...{ openAndCloseModalToSelectUnity }} />
            <ConectedUser />
          </div>
        </CustomHeader>
        <PainelRow>
          <CustomNav width={sideMenuWidth}>
            <MenuOptions />
          </CustomNav>
          <PainelColumn width={sideMenuWidth!}>{children}</PainelColumn>
        </PainelRow>
      </Column>
    </CustomMenuContainer>
  );
};

export { SideMenu };
