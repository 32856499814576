import { useEffect } from 'react';

import { useWagonerContext } from '../../../context/ProviderPage/Carreteiro';
import { formatInputCPFAndCNPJ } from '../../../utils/cnpjOrCpf/formatInpuCPFAndCNPJ';
import { FilterInput } from '../../Inputs/FilterInput';
import { FilterSelectInput } from '../../Inputs/FilterSelectInput';
import { SideMenuFilterTemplete } from '../SideMenuFilterTemplete';

export const FilterWagoner = () => {
  const {
    openSideModalFilterWagonerAction,
    openSideModalFilterWagoners,
    cleanAllInputsAction,
    filterWagonersAction,
    DFCNPJCPFCEI,
    DFATIVO,
    DFIDCARRETEIRO,
    DFIDVEICULO,
    DFNOMECARRETEIRO,
    setDFCNPJCPFCEI,
    setDFATIVO,
    setDFIDCARRETEIRO,
    setDFIDVEICULO,
    setDFNOMECARRETEIRO,
  } = useWagonerContext();

  useEffect(() => {
    if (Number(DFIDVEICULO) < 0) {
      setDFIDVEICULO('0');
    }
    if (Number(DFIDCARRETEIRO) < 0) {
      setDFIDCARRETEIRO('0');
    }
  }, [DFIDCARRETEIRO, DFIDVEICULO]);

  const formatCPFAndCNPJ = (value: string) => {
    setDFCNPJCPFCEI(formatInputCPFAndCNPJ(value));
  };

  return (
    <SideMenuFilterTemplete
      isOpen={openSideModalFilterWagoners}
      onchange={openSideModalFilterWagonerAction}
      title="Filtrar Carreteiro"
      cleanAllInputsAction={cleanAllInputsAction}
      filterAction={filterWagonersAction}
    >
      <FilterSelectInput onChange={setDFATIVO} value={DFATIVO} title="Ativo" />
      <FilterInput
        onChange={setDFIDCARRETEIRO}
        value={DFIDCARRETEIRO}
        title="Código"
        type="number"
        min={0}
      />
      <FilterInput
        onChange={setDFNOMECARRETEIRO}
        value={DFNOMECARRETEIRO}
        title="Nome"
        type="text"
      />
      <FilterInput
        onChange={formatCPFAndCNPJ}
        value={DFCNPJCPFCEI}
        title="CNPJ/CPF"
        type="text"
      />
      <FilterInput
        onChange={setDFIDVEICULO}
        value={DFIDVEICULO}
        title="Veículo"
        type="text"
      />
    </SideMenuFilterTemplete>
  );
};
