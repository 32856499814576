import { useScheduleContext } from '../../../context/ProviderPage/Programacao';
import { IListCollectData } from '../../../types/ListCollectData';
import { ScheduleCollect } from '../../../types/ScheduleCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

export const ListScheduleCollects = ({ data }: IListCollectData) => {
  const { setOpenInfo, setSelectedScheduleCollect } = useScheduleContext();

  const handleseelectedScheduleCollect = (collect: ScheduleCollect) => {
    setSelectedScheduleCollect(collect);
    setOpenInfo(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ordem Coleta</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Veículo</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Data Programada</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((scheduleCollect, index) => {
            const {
              DFDATAPROGRAMADA,
              DFNOMECARRETEIRO,
              DFNOMELINHA,
              DFORDEMCOLETA,
              DFPLACAVEICULO,
            } = scheduleCollect;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleseelectedScheduleCollect(scheduleCollect)}
              >
                <td className="list-td-small">
                  {DFORDEMCOLETA || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {formatDate(DFDATAPROGRAMADA)}
                </td>
                <td className="list-td-smal">
                  <div>
                    <ListButton
                      {...{
                        action: () =>
                          handleseelectedScheduleCollect(scheduleCollect),
                        icon: 'search',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
