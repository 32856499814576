import styled from 'styled-components';

export const EmptyButton = styled.button`
  width: 25px;
  height: 25px;
  margin: 0 0.25rem;
  border: 0;
  background-color: transparent;
`;

export const ListButtonContainer = styled.button`
  border: 0;
  background-color: darkgrey;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  transition: 0.1s all;
  margin: 0 0.25rem;
  img {
    width: 15px;
    height: 15px;
  }
  :active {
    filter: brightness(0.9);
    img {
      width: 12px;
      height: 12px;
    }
  }
`;
