import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListRegions } from '../../../components/List/ListRegions';
import { LoadingData } from '../../../components/LoadingData';
import { InfoRegion } from '../../../components/Modal/InfoRegion';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useRegionContext } from '../../../context/ProviderPage/Regiao';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Regiao = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingRegion,
    loadRegionAction,
    region,
    openSideModalFilterRegionAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useRegionContext();

  useEffect(() => {
    setSelectedPage({ option: 'regiao' });
    loadRegionAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'regiao' }}
        pageTitle="Região"
        filterAction={openSideModalFilterRegionAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingRegion === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingRegion ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {region.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma região foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListRegions data={region} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: region.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoRegion
          option="regiao"
          titleModal="Região selecionada"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Regiao };
