import styled from 'styled-components';

export const ListOpenCollectesContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: grid;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px;
  ::-webkit-scrollbar {
    /* width: 5px; */
  }
`;
