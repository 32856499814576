import { useWagonerContext } from '../../../context/ProviderPage/Carreteiro';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoWagoner = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedWagoner } = useWagonerContext();
  const {
    DFATIVO,
    DFCNPJCPFCEI,
    DFIDCARRETEIRO,
    DFNOMECARRETEIRO,
    DFPLACAVEICULO,
  } = selectedWagoner;
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <RowItens>
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>{DFIDCARRETEIRO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Nome</label>
          <div>
            <span>{DFNOMECARRETEIRO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>CNPJ/CPF</label>
          <div>
            <span>{formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Veículo</label>
          <div>
            <span>{DFPLACAVEICULO || 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Ativo</label>
          <img src={returnCheckType(DFATIVO!)} />
        </ContainerItemModal>
      </RowItens>
    </ModalInfoTemplete>
  );
};
