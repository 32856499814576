import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Wagoner } from '../../../types/Wagoner';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterWagoners: boolean;
  openSideModalFilterWagonerAction: () => void;

  DFIDCARRETEIRO: string;
  setDFIDCARRETEIRO: Dispatch<SetStateAction<string>>;
  DFNOMECARRETEIRO: string;
  setDFNOMECARRETEIRO: Dispatch<SetStateAction<string>>;
  DFCNPJCPFCEI: string;
  setDFCNPJCPFCEI: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFIDVEICULO: string;
  setDFIDVEICULO: Dispatch<SetStateAction<string>>;

  filterWagonersAction: () => void;
  cleanAllInputsAction: () => void;
  loadWagonerAction: () => void;

  openInfoWagoner: boolean;
  setOpenInfoWagoner: Dispatch<SetStateAction<boolean>>;

  selectedWagoner: Wagoner;
  setSelectedWagoner: Dispatch<SetStateAction<Wagoner>>;

  wagoners: Wagoner[];
  setWagoners: Dispatch<SetStateAction<Wagoner[]>>;

  loadingWagoners: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
  executeCleanInputs: () => void;
}

const Context = createContext({} as IContext);

const WagonerContextProvider = ({ children }: IContextProps) => {
  const [DFIDCARRETEIRO, setDFIDCARRETEIRO] = useState<string>('');
  const [DFNOMECARRETEIRO, setDFNOMECARRETEIRO] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFCNPJCPFCEI, setDFCNPJCPFCEI] = useState<string>('');
  const [DFIDVEICULO, setDFIDVEICULO] = useState<string>('');

  const [loadingWagoners, setIsLoadingWagoners] = useState<boolean>(false);
  const [openSideModalFilterWagoners, setOpenSideModalFilterWagoners] =
    useState<boolean>(false);
  const [openInfoWagoner, setOpenInfoWagoner] = useState<boolean>(false);

  const [selectedWagoner, setSelectedWagoner] = useState<Wagoner>({});
  const [wagoners, setWagoners] = useState<Wagoner[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);

  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDCARRETEIRO = `DFIDCARRETEIRO=${DFIDCARRETEIRO}`;
  const NOMECARRETEIRO = `DFNOMECARRETEIRO=${DFNOMECARRETEIRO}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const CNPJCPFCEI = `DFCNPJCPFCEI=${DFCNPJCPFCEI.replace(/[^0-9]/g, '')}`;
  const IDVEICULO = `DFIDVEICULO=${DFIDVEICULO}`;

  const openSideModalFilterWagonerAction = () => {
    setOpenSideModalFilterWagoners(!openSideModalFilterWagoners);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingWagoners(true);
      META_COLLECT_API.get(
        `/carreteiro?${Limit}&${Offset}&${IDCARRETEIRO}&${NOMECARRETEIRO}&${ATIVO}&${CNPJCPFCEI}&${IDVEICULO}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setWagoners(response.data.carreteiros);
          setQuantity(response.data.quantidade);
          setIsLoadingWagoners(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingWagoners(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadWagonersActionWithParams = () => {
    setIsLoadingWagoners(true);
    META_COLLECT_API.get(`/carreteiro?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setWagoners(response.data.carreteiros);
        setQuantity(response.data.quantidade);
        setIsLoadingWagoners(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingWagoners(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDCARRETEIRO('');
    setDFCNPJCPFCEI('');
    setDFATIVO('');
    setDFNOMECARRETEIRO('');
    setDFIDVEICULO('');
    setOpenInfoWagoner(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterWagonerAction();
    loadWagonersActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterWagonersAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterWagonerAction();
    setIsLoadingWagoners(true);
    META_COLLECT_API.get(
      `/carreteiro?${Limit}&offset=0&${IDCARRETEIRO}&${NOMECARRETEIRO}&${ATIVO}&${CNPJCPFCEI}&${IDVEICULO}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setWagoners(response.data.carreteiros);
        setQuantity(response.data.quantidade);
        setIsLoadingWagoners(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingWagoners(false);
        setStart(1);
      });
  };

  const loadWagonerAction = () => {
    setIsLoadingWagoners(true);
    loadWagonersActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'carreteiro') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        DFCNPJCPFCEI,
        DFATIVO,
        DFIDCARRETEIRO,
        DFIDVEICULO,
        DFNOMECARRETEIRO,
        setDFCNPJCPFCEI,
        setDFATIVO,
        setDFIDCARRETEIRO,
        setDFIDVEICULO,
        setDFNOMECARRETEIRO,
        cleanAllInputsAction,
        filterWagonersAction,
        loadingWagoners,
        loadWagonerAction,
        openInfoWagoner,
        openSideModalFilterWagoners,
        openSideModalFilterWagonerAction,
        selectedWagoner,
        setOpenInfoWagoner,
        setSelectedWagoner,
        setWagoners,
        wagoners,
        setOfsset,
        limit,
        offset,
        page,
        quantity,
        setPage,
        setHasClick,
        executeCleanInputs,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useWagonerContext() {
  const context = useContext(Context);
  return context;
}

export { WagonerContextProvider, useWagonerContext };
