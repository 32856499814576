import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListSilos } from '../../../components/List/ListSilos';
import { LoadingData } from '../../../components/LoadingData';
import { InfoSilo } from '../../../components/Modal/InfoSilo';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useSiloContext } from '../../../context/ProviderPage/Silo';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Silo = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingSilo,
    loadSiloAction,
    silo,
    openSideModalFilterSiloAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useSiloContext();
  // const { sideMenuWidth } = useSideMenuContext();
  useEffect(() => {
    setSelectedPage({ option: 'silo' });
    loadSiloAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'silo' }}
        pageTitle="Silo"
        filterAction={openSideModalFilterSiloAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingSilo === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingSilo ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {silo.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum silo foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListSilos data={silo} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: silo.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoSilo
          option="silo"
          titleModal="Silo selecionado"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Silo };
