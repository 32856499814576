import { transparentize } from 'polished';
import styled from 'styled-components';

import {
  ITankListOptionContainer,
  returnColor,
} from '../TankListOption/styles';

export const CollectItemLaboratoryItemContainer = styled.div<ITankListOptionContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ DFTIPOITEMCOLETA }) =>
    transparentize(0.4, returnColor(DFTIPOITEMCOLETA))};
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  transition: 0.3s all;
  svg {
    width: 25px;
    height: 25px;
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.55, theme.COLORS.GREY)};
  }
  @media (max-width: 800px) {
    width: 100%;
  }

  .option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: 500;
      margin-left: 0.6rem;
    }
    margin-right: 0.6rem;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /* background-color: red; */
    margin-top: 0.1rem;
    span {
      margin-right: 0.6rem;
    }
    .temperature {
      height: 25px;
    }
  }
  .row-tank {
    display: flex;
    flex-direction: row;
    img {
      margin-right: 0.6rem;
    }
    span:first-of-type {
      font-weight: bold;
    }
  }
  .column-tank {
    display: flex;
    flex-direction: column;
  }
`;
