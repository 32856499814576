import { Dispatch, SetStateAction } from 'react';

import Id from '../../assets/TankItem/ID.svg';
import { ReactComponent as NormalTank } from '../../assets/TankItem/NormalTank.svg';
import { ReactComponent as IdMatricula } from '../../assets/TankItem/USER.svg';
import { ReactComponent as TemperaturePrecision } from '../../assets/temepraturePrecision.svg';
import { ReactComponent as Temperature } from '../../assets/temperature.svg';
import { LaboratoryCollectItem } from '../../types/LaboratoryCollectItem';
import { formatCentimeters } from '../../utils/formatCentimeters';
import { CollectItemLaboratoryItemContainer } from './styles';

interface ICollectItemLaboratoryItem {
  data: LaboratoryCollectItem;
  action: Dispatch<SetStateAction<LaboratoryCollectItem | null>>;
}

const CollectItemLaboratoryItem = ({
  data,
  action,
}: ICollectItemLaboratoryItem) => {
  const {
    DFNOMEPRODUTOR,
    DFIDTANQUE,
    DFDESCTANQUE,
    DFMATRICULA,
    DFTEMPERATURA,
    DFALIZAROL,
    DFTIPOALIZAROL,
    DFSENSORIAL,
    DFCOLETOUAMOSTRA,
    DFCOLETOULACRE,
    DFINFORMACAOPRODUTOR,
    DFREGUAATRAS,
    DFREGUAFRENTE,
    DFTERMOMENTRO,
    DFTIPOITEMCOLETA,
  } = data;

  return (
    <CollectItemLaboratoryItemContainer
      onClick={() => action(data)}
      {...{ DFTIPOITEMCOLETA }}
    >
      <div className="row-tank">
        <NormalTank />
        <div className="column-tank">
          {DFDESCTANQUE && <span>{DFDESCTANQUE}</span>}
          {DFNOMEPRODUTOR && <span>{DFNOMEPRODUTOR}</span>}
        </div>
      </div>
      <div className="row">
        {DFIDTANQUE && (
          <div className="option">
            <img src={Id} />
            <span>{DFIDTANQUE || 'Não informado'}</span>
          </div>
        )}
        {DFMATRICULA && (
          <div className="option">
            <IdMatricula />
            <span>{DFMATRICULA || 'Não informado'}</span>
          </div>
        )}
        {DFTEMPERATURA && (
          <div className="option">
            <Temperature />
            <span>{DFTEMPERATURA}°C</span>
          </div>
        )}
        {DFTERMOMENTRO && (
          <div className="option">
            <TemperaturePrecision />
            <span>{DFTERMOMENTRO}°C</span>
          </div>
        )}
      </div>

      <div className="row">
        {DFALIZAROL && (
          <span>
            <b>Alizarol: </b>
            {DFALIZAROL === 'S' ? 'Sim' : 'Não' || 'Não informado'}
          </span>
        )}
        {DFTIPOALIZAROL && (
          <span>
            <b>Tipo: </b>
            {DFTIPOALIZAROL}
          </span>
        )}
        {DFSENSORIAL && (
          <span>
            <b>Sensorial: </b>
            {DFSENSORIAL}
          </span>
        )}
      </div>

      <div className="row">
        {DFCOLETOUAMOSTRA && (
          <span>
            <b>Amostra: </b>
            {DFCOLETOUAMOSTRA}
          </span>
        )}
        {DFCOLETOULACRE && (
          <span>
            <b>Lacre: </b>
            {DFCOLETOULACRE}
          </span>
        )}
      </div>

      <div className="row">
        {DFREGUAFRENTE && (
          <span>
            <b>Régua 1: </b>
            {formatCentimeters(DFREGUAFRENTE)}
          </span>
        )}
        {DFREGUAATRAS && (
          <span>
            <b>Régua 2: </b>
            {formatCentimeters(DFREGUAATRAS)}
          </span>
        )}
      </div>

      <div className="row">
        {DFINFORMACAOPRODUTOR && (
          <span>
            <b>Produtores: </b>
            {DFINFORMACAOPRODUTOR}
          </span>
        )}
      </div>
    </CollectItemLaboratoryItemContainer>
  );
};

export { CollectItemLaboratoryItem };
