import { IOptionMenu } from '../../../../types/OptionMenu';

const singleOptions = [
  {
    option: 'unidade',
    title: 'Unidade',
    toPath: '/unidade',
  },
  {
    option: 'silo',
    title: 'Silo',
    toPath: '/silo',
  },
  {
    option: 'regiao',
    title: 'Região',
    toPath: '/regiao',
  },
  {
    option: 'linha',
    title: 'Linha',
    toPath: '/linha',
  },
  {
    option: 'tanque',
    title: 'Tanque',
    toPath: '/tanque',
  },
  {
    option: 'propriedade',
    title: 'Propriedade',
    toPath: '/propriedade',
  },
  {
    option: 'carreteiro',
    title: 'Carreteiro',
    toPath: '/carreteiro',
  },
  {
    option: 'caminhao',
    title: 'Veículo',
    toPath: '/veiculo',
  },
] as IOptionMenu[];

const collectOptions = [
  {
    option: 'coleta',
    title: 'Aberta',
    toPath: '/coleta',
  },
  {
    option: 'programacao',
    title: 'Programada',
    toPath: '/programacao',
  },
  {
    option: 'descarga',
    title: 'Plataforma',
    toPath: '/plataforma',
  },
  {
    option: 'encerrada',
    title: 'Encerrada',
    toPath: '/encerrada',
  },
  {
    option: 'excluida',
    title: 'Excluida',
    toPath: '/excluida',
  },
] as IOptionMenu[];

const tankerOptions = [
  {
    option: 'tanquesVinculado',
    title: 'Tanques Vinculado',
    toPath: '/tanques-vinculados',
  },
  {
    option: 'liberar',
    title: 'A liberar',
    toPath: '/liberar',
  },
  {
    option: 'vincular',
    title: 'Vincular',
    toPath: '/vincular',
  },
  {
    option: 'vinculada',
    title: 'Vinculada',
    toPath: '/vinculada',
  },
] as IOptionMenu[];

const managementOptions = [
  {
    option: 'configuracoesdopainel',
    title: 'Cnfg. do Painel',
    toPath: '/configuracoes-do-painel',
  },
  {
    option: 'configuracoes',
    title: 'Cnfg. do App ',
    toPath: '/configuracoes',
  },
  {
    option: 'dispositivo',
    title: 'Dispositivos',
    toPath: '/dispositivos',
  },
] as IOptionMenu[];

export { singleOptions, collectOptions, tankerOptions, managementOptions };
