import { useWagonerContext } from '../../../context/ProviderPage/Carreteiro';
import { Wagoner } from '../../../types/Wagoner';
import { formatFieldCpfOrCnpj } from '../../../utils/cnpjOrCpf/formatFieldCpfOrCnpj';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListWagoners {
  data: Wagoner[];
}

export const ListWagoners = ({ data }: IListWagoners) => {
  const { setOpenInfoWagoner, setSelectedWagoner } = useWagonerContext();

  const handleSelectWagoner = (wagoner: Wagoner) => {
    setSelectedWagoner(wagoner);
    setOpenInfoWagoner(true);
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Nome</th>
            <th className="list-th">Código</th>
            <th className="list-th">CNPJ/CPF</th>
            <th className="list-th">Veículo</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((wagoner, index) => {
            const {
              DFATIVO,
              DFCNPJCPFCEI,
              DFIDCARRETEIRO,
              DFPLACAVEICULO,
              DFNOMECARRETEIRO,
            } = wagoner;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={() => handleSelectWagoner(wagoner)}
              >
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  {DFIDCARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>

                <td className="list-td-large">
                  {formatFieldCpfOrCnpj(DFCNPJCPFCEI) || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>

                <td className="list-td-small">
                  <ListButton
                    {...{
                      action: () => handleSelectWagoner(wagoner),
                      icon: 'search',
                    }}
                  />
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
