import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListWagoners } from '../../../components/List/ListWagoners';
import { LoadingData } from '../../../components/LoadingData';
import { InfoWagoner } from '../../../components/Modal/InfoWagoner';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useWagonerContext } from '../../../context/ProviderPage/Carreteiro';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Carreteiro = () => {
  const { setSelectedPage } = usePageContext();
  const {
    wagoners,
    loadWagonerAction,
    openSideModalFilterWagonerAction,
    loadingWagoners,
    openInfoWagoner,
    setOpenInfoWagoner,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    executeCleanInputs,
    start,
    setStart,
  } = useWagonerContext();

  useEffect(() => {
    executeCleanInputs();
    setSelectedPage({ option: 'carreteiro' });
    loadWagonerAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'carreteiro' }}
        pageTitle="Carreteiro"
        filterAction={() => openSideModalFilterWagonerAction()}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingWagoners === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingWagoners ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {wagoners.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum carreteiro foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListWagoners data={wagoners} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: wagoners.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoWagoner
          closeModal={setOpenInfoWagoner}
          isOpen={openInfoWagoner}
          titleModal="Carreteiro selecionado"
          option="carreteiro"
        />
      </ContainerPage>
    </>
  );
};

export { Carreteiro };
