import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListProperty } from '../../../components/List/ListProperty';
import { LoadingData } from '../../../components/LoadingData';
import { InfoProperty } from '../../../components/Modal/InfoProperty';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { usePropertyContext } from '../../../context/ProviderPage/Propriedade';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Propriedade = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingProperty,
    loadPropertyAction,
    property,
    openSideModalFilterPropertyAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = usePropertyContext();

  useEffect(() => {
    setSelectedPage({ option: 'propriedade' });
    loadPropertyAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'propriedade' }}
        pageTitle="Propriedade"
        filterAction={openSideModalFilterPropertyAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingProperty === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingProperty ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {property.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma propriedade foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListProperty data={property} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: property.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoProperty
          option="propriedade"
          titleModal="Propriedade selecionada"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Propriedade };
