import styled from 'styled-components';

export const ConfirmCloseCollectContent = styled.div`
  max-width: 70%;
  .button-success {
    border: 0;
  }
  .button-cancel {
    border: 0;
  }
`;

export const ContainerLaboratory = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 49.6% 49.6%;
  flex-flow: row wrap;
  overflow-y: auto;
  justify-content: space-between;
  transition: 0.3s all;

  @media (max-width: 1200px) {
    flex-flow: column;
    padding: 0 0.6rem;
    grid-template-columns: 100%;
    width: 100%;
  }
`;
