import themes from '../../../themes';

interface IReturnMarkerColor {
  DFTIPOITEMCOLETA?: 'N' | 'C' | 'S';
  DFTIPOREGISTRO?: 'A' | 'D' | 'F' | 'C' | 'G' | 'Q' | 'R' | 'T' | 'O';
}

const returnMarkerColor = ({
  DFTIPOITEMCOLETA,
  DFTIPOREGISTRO,
}: IReturnMarkerColor): string => {
  if (DFTIPOITEMCOLETA && DFTIPOREGISTRO) {
    switch (DFTIPOITEMCOLETA) {
      case 'N':
        return themes.COLORS.NC;
      case 'C':
        return themes.COLORS.CP;
      case 'S':
        return themes.COLORS.CS;
      default:
        return '';
    }
  }
  if (DFTIPOREGISTRO) {
    switch (DFTIPOREGISTRO) {
      case 'A':
        return themes.COLORS.IC;
      case 'D':
        return themes.COLORS.DC;
      case 'F':
        return themes.COLORS.CF;
      default:
        return '';
    }
  }
  return '';
};

export { returnMarkerColor };
