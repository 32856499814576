import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { CollectToBond } from '../../../types/CollectToBond';
import { ReleasedCollect } from '../../../types/ReleasedCollect';
import { StorageBondToCollect } from '../../../types/StorageBondToCollect';
import { deformatDate } from '../../../utils/date/deformatDate';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface ILoadCollectsToBond {
  DFDATADACOLETA: string;
  DFIDTANQUE: string;
  setCollects: Dispatch<SetStateAction<CollectToBond[]>>;
  setLoadingCollectsToBond: Dispatch<SetStateAction<boolean>>;
}

interface IBondTankIntoCollect {
  collect: CollectToBond;
  selectedStorageProducerToRelease: StorageBondToCollect;
  loadStorages: () => void;
  setLoadAction: Dispatch<SetStateAction<boolean>>;
}

interface IContext {
  openSideModalFilterReleasedCollect: boolean;
  openSideModalFilterReleasedCollectAction: () => void;
  DFNOMECARRETEIRO: string;
  setDFNOMECARRETEIRO: Dispatch<SetStateAction<string>>;
  DFORDEMCOLETA: string;
  setDFORDEMCOLETA: Dispatch<SetStateAction<string>>;
  DFNOMELINHA: string;
  setDFNOMELINHA: Dispatch<SetStateAction<string>>;
  DFPLACAVEICULO: string;
  setDFPLACAVEICULO: Dispatch<SetStateAction<string>>;
  DFDATACOLETA: string;
  setDFDATACOLETA: Dispatch<SetStateAction<string>>;
  DFDATASAIDA: string;
  setDFDATASAIDA: Dispatch<SetStateAction<string>>;
  DFIDCOLETA: string;
  setDFIDCOLETA: Dispatch<SetStateAction<string>>;
  DFBLOQUEIOERP: string;
  setDFBLOQUEIOERP: Dispatch<SetStateAction<string>>;
  DFTOTALARMAZENADO: string;
  setDFTOTALARMAZENADO: Dispatch<SetStateAction<string>>;
  DFDATAPROGRAMADA: string;
  setDFDATAPROGRAMADA: Dispatch<SetStateAction<string>>;

  filterReleasedCollectAction: () => void;
  cleanAllInputsAction: () => void;
  loadReleasedCollectAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedReleasedCollect: ReleasedCollect | undefined;
  setSelectedReleasedCollect: Dispatch<
    SetStateAction<ReleasedCollect | undefined>
  >;

  releasedCollect: ReleasedCollect[];
  setReleasedCollectCollect: Dispatch<SetStateAction<ReleasedCollect[]>>;

  loadingReleasedCollect: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
  loadCollectsToBond: ({
    DFDATADACOLETA,
    setCollects,
    setLoadingCollectsToBond,
    DFIDTANQUE,
  }: ILoadCollectsToBond) => void;
  bondTankIntoCollect: ({
    collect,
    selectedStorageProducerToRelease,
    loadStorages,
    setLoadAction,
  }: IBondTankIntoCollect) => void;
}

const Context = createContext({} as IContext);

const ReleasedCollectContextProvider = ({ children }: IContextProps) => {
  const [DFNOMECARRETEIRO, setDFNOMECARRETEIRO] = useState<string>('');
  const [DFORDEMCOLETA, setDFORDEMCOLETA] = useState<string>('');
  const [DFNOMELINHA, setDFNOMELINHA] = useState<string>('');
  const [DFPLACAVEICULO, setDFPLACAVEICULO] = useState<string>('');
  const [DFDATACOLETA, setDFDATACOLETA] = useState<string>('');
  const [DFDATASAIDA, setDFDATASAIDA] = useState<string>('');
  const [DFIDCOLETA, setDFIDCOLETA] = useState<string>('');
  const [DFBLOQUEIOERP, setDFBLOQUEIOERP] = useState<string>('');
  const [DFTOTALARMAZENADO, setDFTOTALARMAZENADO] = useState<string>('');
  const [DFDATAPROGRAMADA, setDFDATAPROGRAMADA] = useState<string>('');

  const [loadingReleasedCollect, setIsLoadingReleasedCollect] =
    useState<boolean>(true);
  const [
    openSideModalFilterReleasedCollect,
    setOpenSideModalFilterReleasedCollect,
  ] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedReleasedCollect, setSelectedReleasedCollect] = useState<
    ReleasedCollect | undefined
  >();
  const [releasedCollect, setReleasedCollectCollect] = useState<
    ReleasedCollect[]
  >([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);
  const { path, user } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);

  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const NOMECARRETEIRO = `DFNOMECARRETEIRO=${DFNOMECARRETEIRO}`;
  const ORDEMCOLETA = `DFORDEMCOLETA=${DFORDEMCOLETA}`;
  const NOMELINHA = `DFNOMELINHA=${DFNOMELINHA}`;
  const PLACAVEICULO = `DFPLACAVEICULO=${DFPLACAVEICULO}`;
  const DATACOLETA = `DFDATACOLETA=${deformatDate(DFDATACOLETA)}`;
  const DATASAIDA = `DFDATASAIDA=${deformatDate(DFDATASAIDA)}`;
  const IDCOLETA = `DFIDCOLETA=${DFIDCOLETA}`;
  const STATUS = `DFSTATUS=D`;
  const BLOQUEIO = `DFBLOQUEIOERP=${DFBLOQUEIOERP}`;
  const TOTALARMAZENADO = `DFTOTALARMAZENADO=${DFTOTALARMAZENADO}`;
  const DATAPROGRAMADA = `DFDATAPROGRAMADA=${deformatDate(DFDATAPROGRAMADA)}`;
  const IDUNIDADE = user?.DFIDUNIDADE
    ? `&DFIDUNIDADE=${user?.DFIDUNIDADE}`
    : '';

  const openSideModalFilterReleasedCollectAction = () => {
    setOpenSideModalFilterReleasedCollect(!openSideModalFilterReleasedCollect);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingReleasedCollect(true);
      META_COLLECT_API.get(
        `/coleta/liberada?${Limit}&${Offset}&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setReleasedCollectCollect(response.data.coletas);
          setQuantity(response.data.quantidade);
          setIsLoadingReleasedCollect(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingReleasedCollect(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingReleasedCollect(true);
    META_COLLECT_API.get(
      `/coleta/liberada?${Limit}&offset=0&${STATUS}${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setReleasedCollectCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingReleasedCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingReleasedCollect(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFNOMECARRETEIRO('');
    setDFORDEMCOLETA('');
    setDFNOMELINHA('');
    setDFPLACAVEICULO('');
    setDFDATACOLETA('');
    setDFDATASAIDA('');
    setOpenInfo(false);
    setDFBLOQUEIOERP('');
    setDFTOTALARMAZENADO('');
    setDFDATAPROGRAMADA('');
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterReleasedCollectAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterReleasedCollectAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterReleasedCollectAction();
    setIsLoadingReleasedCollect(true);
    META_COLLECT_API.get(
      `/coleta/liberada?${Limit}&offset=0&${STATUS}&${NOMECARRETEIRO}&${ORDEMCOLETA}&${NOMELINHA}&${PLACAVEICULO}&${DATACOLETA}&${DATASAIDA}&${IDCOLETA}&${BLOQUEIO}&${TOTALARMAZENADO}&${DATAPROGRAMADA}${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setReleasedCollectCollect(response.data.coletas);
        setQuantity(response.data.quantidade);
        setIsLoadingReleasedCollect(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingReleasedCollect(false);
        setStart(1);
      });
  };

  const loadReleasedCollectAction = () => {
    setIsLoadingReleasedCollect(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'liberar') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (user?.DFIDUNIDADE) {
      loadReleasedCollectAction();
    }
  }, [user]);

  const loadCollectsToBond = ({
    DFDATADACOLETA,
    DFIDTANQUE,
    setCollects,
    setLoadingCollectsToBond,
  }: ILoadCollectsToBond) => {
    setCollects([]);
    setLoadingCollectsToBond(true);
    META_COLLECT_API.get(
      `/coleta/a-vincular?DFIDTANQUE=${DFIDTANQUE}&DFDATADACOLETA="${DFDATADACOLETA}"`,
      {
        headers: {
          path,
        },
      },
    )
      .then(res => {
        setCollects(res.data.coletas);
        setLoadingCollectsToBond(false);
      })
      .catch(() => {
        setLoadingCollectsToBond(false);
      });
  };

  const bondTankIntoCollect = async ({
    collect,
    selectedStorageProducerToRelease,
    loadStorages,
    setLoadAction,
  }: IBondTankIntoCollect) => {
    setLoadAction(true);
    META_COLLECT_API.post(
      'armazenamento-produtor/panel/vincular',
      {
        collect,
        selectedStorageProducerToRelease,
      },
      {
        headers: {
          path,
        },
      },
    )
      .then(() => {
        loadStorages();
        setLoadAction(false);
      })
      .catch(() => {
        toast.error('Aconteceu um erro inesperado!');
        setLoadAction(false);
      });
  };

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        DFORDEMCOLETA,
        filterReleasedCollectAction,
        loadReleasedCollectAction,
        limit,
        loadingReleasedCollect,
        offset,
        openInfo,
        openSideModalFilterReleasedCollect,
        openSideModalFilterReleasedCollectAction,
        page,
        quantity,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        setReleasedCollectCollect,
        DFDATACOLETA,
        DFDATASAIDA,
        DFNOMECARRETEIRO,
        DFNOMELINHA,
        DFPLACAVEICULO,
        DFIDCOLETA,
        releasedCollect,
        selectedReleasedCollect,
        setDFDATACOLETA,
        setDFDATASAIDA,
        setDFNOMECARRETEIRO,
        setDFNOMELINHA,
        setDFORDEMCOLETA,
        setDFPLACAVEICULO,
        setSelectedReleasedCollect,
        setDFIDCOLETA,
        start,
        setStart,
        DFBLOQUEIOERP,
        DFTOTALARMAZENADO,
        setDFBLOQUEIOERP,
        setDFTOTALARMAZENADO,
        DFDATAPROGRAMADA,
        setDFDATAPROGRAMADA,
        loadCollectsToBond,
        bondTankIntoCollect,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useReleasedCollectContext() {
  const context = useContext(Context);
  return context;
}

export { ReleasedCollectContextProvider, useReleasedCollectContext };
