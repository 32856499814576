import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import farm from '../../../assets/farm-min.jpg';

export const ContainerSignin = styled.div`
  /* background-image: url(${farm}); */
  /* background-size: cover; */
  /* background-color: ${({ theme }) => theme.GREEN_30}; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;

  .copyright {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    right: 0;
    bottom: 0;
    padding: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 650px) {
      align-items: center;
    }
    @media (max-width: 750px) {
      flex-direction: column;
    }
    img {
      transition: 0.3s all;
      width: 120px;
      @media (max-width: 650px) {
        width: 80px;
      }
      margin-bottom: 0.3rem;
    }

    span {
      transition: 0.3s all;
      color: #fff;
      font-size: 0.8rem;
      text-align: center;
      @media (max-width: 650px) {
        font-size: 0.6rem;
      }
    }
  }

  .marc {
    width: 60%;
    height: 90%;
    opacity: 0.4;
    right: 0;
    bottom: 0;
    position: fixed;
    transition: 0.3s all;
    @media (max-width: 750px) {
      width: 40%;
      height: 60%;
    }
  }

  .backgorund-signin-image {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    /* background-color: ${({ theme }) =>
      transparentize(0.7, theme.COLORS.GREEN_30)};
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px); */
    transition: 0.3s all;
  }

  form {
    animation: open 1s ease-in-out;
    @keyframes open {
      0% {
        transform: translate(0, -300px);
      }
      50% {
        transform: translate(0, 100px);
      }
      100% {
        transform: translate(0, 0px);
      }
    }
    z-index: 15;
    transition: 0.3s all;
    padding: 1rem;
    width: 860px;
    min-height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
    background: rgb(255, 255, 255, 0.6); */
    /* background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.6) 45%,
      rgba(255, 255, 255, 1) 100%
    ); */
    background-color: ${({ theme }) => theme.COLORS.GREEN_100};
    border-radius: 0.6rem;
    ${({ theme }) => css`
      box-shadow: 0px 0px 69px 24px
        ${transparentize(0.8, theme.COLORS.GREEN_100)};
    `};
    @media (max-width: 1000px) {
      width: 700px;
    }
    @media (max-width: 850px) {
      width: 600px;
    }

    @media (max-width: 650px) {
      width: 75%;
    }

    .form-ilustration {
      width: 250px;
      animation: floating 3s ease-in-out infinite;
      @media (max-width: 1000px) {
        width: 250px;
      }
      @media (max-width: 750px) {
        display: none;
      }
      @keyframes floating {
        0% {
          transform: translate(0, 0px);
        }
        50% {
          transform: translate(0, -25px);
        }
        100% {
          transform: translate(0, -0px);
        }
      }
    }

    .form-logo {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 750px) {
        display: none;
      }
    }

    .form-content {
      /* background-color: ${({ theme }) =>
        transparentize(0.94, theme.COLORS.GREEN_30)}; */
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 45%;
      padding: 0.5rem;
      transition: 0.3s all;

      @media (max-width: 750px) {
        width: 100%;
      }

      .form-input:focus {
        background-color: ${({ theme }) =>
          transparentize(0.3, theme.COLORS.GREEN_30)};
      }

      button {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 0.6rem;
        font-size: 1.1rem;
        font-weight: bold;
        color: ${({ theme }) => theme.COLORS.WHITE};
        background-color: ${({ theme }) => theme.COLORS.GREEN_30};
        transition: 0.3s ease-in-out;
        &:hover {
          filter: brightness(0.9);
          transform: scale(1.03);
        }
      }
    }
  }
`;
