import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Line } from '../../../types/Line';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

interface IContext {
  openSideModalFilterLine: boolean;
  openSideModalFilterLineAction: () => void;

  DFIDLINHA: string;
  setDFIDLINHA: Dispatch<SetStateAction<string>>;
  DFNOMELINHA: string;
  setDFNOMELINHA: Dispatch<SetStateAction<string>>;
  DFATIVO: string;
  setDFATIVO: Dispatch<SetStateAction<string>>;
  DFIDREGIONAL: string;
  setDFIDREGIONAL: Dispatch<SetStateAction<string>>;
  DFIDCARRETEIRO: string;
  setDFIDCARRETEIRO: Dispatch<SetStateAction<string>>;
  DFIDUNIDADE: string;
  setDFIDUNIDADE: Dispatch<SetStateAction<string>>;

  filterLineAction: () => void;
  cleanAllInputsAction: () => void;
  loadLineAction: () => void;

  openInfo: boolean;
  setOpenInfo: Dispatch<SetStateAction<boolean>>;

  selectedLine: Line;
  setSelectedLine: Dispatch<SetStateAction<Line>>;

  line: Line[];
  setLine: Dispatch<SetStateAction<Line[]>>;

  loadingLine: boolean;

  offset: number;
  setOfsset: Dispatch<SetStateAction<number>>;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  quantity: number;
  setHasClick: Dispatch<SetStateAction<boolean>>;
  start: number;
  setStart: Dispatch<SetStateAction<number>>;
  executeCleanInputs: () => void;
}

const Context = createContext({} as IContext);

const LineContextProvider = ({ children }: IContextProps) => {
  const [DFIDLINHA, setDFIDLINHA] = useState<string>('');
  const [DFNOMELINHA, setDFNOMELINHA] = useState<string>('');
  const [DFATIVO, setDFATIVO] = useState<string>('');
  const [DFIDREGIONAL, setDFIDREGIONAL] = useState<string>('');
  const [DFIDCARRETEIRO, setDFIDCARRETEIRO] = useState<string>('');
  const [DFIDUNIDADE, setDFIDUNIDADE] = useState<string>('');

  const [loadingLine, setIsLoadingLine] = useState<boolean>(false);
  const [openSideModalFilterLine, setOpenSideModalFilterLine] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [selectedLine, setSelectedLine] = useState<Line>({});
  const [line, setLine] = useState<Line[]>([]);

  const [limit] = useState<number>(50);
  const [offset, setOfsset] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [hasClick, setHasClick] = useState<boolean>(false);

  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [start, setStart] = useState(1);
  const Limit = `limit=${limit}`;
  const Offset = `offset=${offset}`;
  const IDLINHA = `DFIDLINHA=${DFIDLINHA}`;
  const NOMELINHA = `DFNOMELINHA=${DFNOMELINHA}`;
  const ATIVO = `DFATIVO=${DFATIVO}`;
  const CARRETEIRO = `DFIDCARRETEIRO=${DFIDCARRETEIRO}`;
  const IDUNIDADE = `DFIDUNIDADE=${DFIDUNIDADE}`;
  const IDREGIONAL = `DFIDREGIONAL=${DFIDREGIONAL}`;

  const openSideModalFilterLineAction = () => {
    setOpenSideModalFilterLine(!openSideModalFilterLine);
  };

  useEffect(() => {
    if (hasClick) {
      setIsLoadingLine(true);
      META_COLLECT_API.get(
        `/linha?${Limit}&${Offset}&${IDLINHA}&${NOMELINHA}&${ATIVO}&${IDREGIONAL}&${CARRETEIRO}&${IDUNIDADE}`,
        {
          headers: {
            path,
          },
        },
      )
        .then(response => {
          setLine(response.data.linhas);
          setQuantity(response.data.quantidade);
          setIsLoadingLine(false);
          setHasClick(false);
        })
        .catch(() => {
          setIsLoadingLine(false);
          setHasClick(false);
        });
    }
  }, [hasClick]);

  const loadUnitsActionWithParams = () => {
    setIsLoadingLine(true);
    META_COLLECT_API.get(`/linha?${Limit}&offset=0`, {
      headers: {
        path,
      },
    })
      .then(response => {
        setLine(response.data.linhas);
        setQuantity(response.data.quantidade);
        setIsLoadingLine(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingLine(false);
        setStart(1);
      });
  };

  const executeCleanInputs = () => {
    setDFIDLINHA('');
    setDFNOMELINHA('');
    setDFATIVO('');
    setDFIDCARRETEIRO('');
    setDFIDREGIONAL('');
    setDFIDUNIDADE('');
    setOpenInfo(false);
  };

  const cleanAllInputsAction = () => {
    executeCleanInputs();
    openSideModalFilterLineAction();
    loadUnitsActionWithParams();
    setPage(1);
    setStart(1);
  };

  const filterLineAction = () => {
    setOfsset(0);
    setPage(1);
    openSideModalFilterLineAction();
    setIsLoadingLine(true);
    META_COLLECT_API.get(
      `/linha?${Limit}&offset=0&${IDLINHA}&${NOMELINHA}&${ATIVO}&${IDREGIONAL}&${CARRETEIRO}&${IDUNIDADE}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(response => {
        setLine(response.data.linhas);
        setQuantity(response.data.quantidade);
        setIsLoadingLine(false);
        setStart(1);
      })
      .catch(() => {
        setIsLoadingLine(false);
        setStart(1);
      });
  };

  const loadLineAction = () => {
    setIsLoadingLine(true);
    loadUnitsActionWithParams();
  };

  useEffect(() => {
    if (selectedPage.option !== 'linha') {
      executeCleanInputs();
      setOfsset(0);
      setPage(1);
      setStart(1);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        cleanAllInputsAction,
        limit,
        offset,
        openInfo,
        page,
        quantity,
        setHasClick,
        setOfsset,
        setOpenInfo,
        setPage,
        DFATIVO,
        DFIDCARRETEIRO,
        DFIDLINHA,
        DFIDREGIONAL,
        DFIDUNIDADE,
        DFNOMELINHA,
        filterLineAction,
        line,
        loadingLine,
        loadLineAction,
        openSideModalFilterLine,
        openSideModalFilterLineAction,
        selectedLine,
        setDFATIVO,
        setDFIDCARRETEIRO,
        setDFIDLINHA,
        setDFIDREGIONAL,
        setDFIDUNIDADE,
        setDFNOMELINHA,
        setLine,
        setSelectedLine,
        executeCleanInputs,
        start,
        setStart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useLineContext() {
  const context = useContext(Context);
  return context;
}

export { LineContextProvider, useLineContext };
