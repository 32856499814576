import NotChecked from '../assets/boxcheck/checknegative.svg';
import Checked from '../assets/boxcheck/checkpositive.svg';

const returnCheckType = (type: string) => {
  if (type === 'S' || type === 's') {
    return Checked;
  }
  return NotChecked;
};

export { returnCheckType };
