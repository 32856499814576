import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListVehicles } from '../../../components/List/ListVehicles';
import { LoadingData } from '../../../components/LoadingData';
import { InfoVehicle } from '../../../components/Modal/InfoVehicle';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useVehicleContext } from '../../../context/ProviderPage/Veiculo';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Veiculo = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingVehicle,
    loadVehicleAction,
    openSideModalFilterVehicleAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    vehicle,
    start,
    setStart,
  } = useVehicleContext();

  useEffect(() => {
    setSelectedPage({ option: 'caminhao' });
    loadVehicleAction();
  }, []);

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'caminhao' }}
        pageTitle="Veículo"
        filterAction={openSideModalFilterVehicleAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingVehicle === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingVehicle ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {vehicle.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhum veículo foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListVehicles data={vehicle} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: vehicle.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoVehicle
          option="caminhao"
          titleModal="Veículo selecionado"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Veiculo };
