import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Option } from '../../../../../types/Option';

interface IContainerOption extends Option {
  sidemenu: 'open' | 'closed';
  selected: Option;
}

export const ContainerOption = styled(Link)<IContainerOption>`
  width: ${({ sidemenu }) => (sidemenu === 'open' ? '50px' : '90%')};
  min-height: 50px;
  border-radius: ${({ sidemenu }) => (sidemenu === 'open' ? 5 : 40)}px;
  display: flex;
  align-items: center;
  align-self: center;
  ${({ sidemenu }) =>
    sidemenu === 'open' &&
    css`
      justify-content: center;
    `}
  background-color: ${({ selected, option, theme }) =>
    selected.option === option ? theme.COLORS.GREEN_30 : 'transparent'};
  margin: 0.4rem;
  text-decoration: none;
  transition: 0.3s all;
  @media (max-width: 650px) {
    padding: 0 1rem;
  }
  &:hover {
    background-color: ${({ selected, option, theme }) =>
      selected.option !== option && transparentize(0.8, theme.COLORS.GREEN_30)};
  }

  .icon-view {
    transition: 0.3s all;
    min-width: 35px;
    min-height: 30px;
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      min-width: 25px;
      min-height: 25px;
      width: 25px;
      height: 25px;
      transition: 0.3s all;
      @media (max-width: 650px) {
        width: 35px;
        height: 35px;
      }
      ${({ sidemenu }) =>
        sidemenu &&
        css`
          transform: scale(0.6);
        `};
    }
  }

  li {
    display: ${({ sidemenu }) => sidemenu === 'open' && 'none'};
    margin-left: 0.3rem;
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: 0.8rem;
    transition: 0.3s all;
    list-style: none;
    @media (max-width: 850px) {
      font-size: 0.7rem;
    }
    @media (max-width: 650px) {
      font-size: 1rem;
    }
  }
`;
