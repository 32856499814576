/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactNode } from 'react';

import { Option } from '../../types/Option';
import { returnIcon } from '../../utils/returnIcon';
import { FilterButton } from '../Buttons/FilterButton';
import { ContainerPageHeader } from './styles';

interface IPageHeader {
  pageIcon: Option;
  pageTitle: string;
  filterAction?: () => void;
  hasFilter?: boolean;
  button?: ReactNode;
}

const PageHeader = ({
  pageIcon,
  pageTitle,
  filterAction,
  hasFilter = true,
  button,
}: IPageHeader) => {
  return (
    <ContainerPageHeader>
      <div className="selected-page">
        <img src={returnIcon({ option: pageIcon.option })} />
        <h1>{pageTitle}</h1>
      </div>
      {!hasFilter ? (
        <div style={{ height: 50 }} />
      ) : (
        <FilterButton width={130} action={filterAction} />
      )}
      {button && button}
    </ContainerPageHeader>
  );
};

export { PageHeader };
