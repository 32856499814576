import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppVersion } from '../../../types/AppVersion';
import { RedirectToSignin } from '../pages/AuthPages/RedirectToSignin';
import { Signin } from '../pages/AuthPages/Signin';

export const AuthRoutes: FC<AppVersion> = ({ version }) => {
  return (
    <Routes>
      <Route path={`${version}`} element={<Signin {...{ version }} />} />
      <Route path={'*'} element={<RedirectToSignin {...{ version }} />} />
    </Routes>
  );
};
