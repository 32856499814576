import { useTankContext } from '../../../context/ProviderPage/Tanque';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import {
  ColumnItens,
  ContainerCheck,
  ContainerItemModal,
  RowItens,
} from '../ModalInfoTemplete/styles';

export const InfoTank = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedTank } = useTankContext();
  const { DFATIVO, DFCOLETASELETIVA, DFDESCTANQUE, DFIDTANQUE, DFNOMELINHA } =
    selectedTank;

  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <ColumnItens>
        <RowItens>
          <ContainerItemModal>
            <label>Código</label>
            <div>
              <span>{DFIDTANQUE || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerItemModal>
            <label>Descrição</label>
            <div>
              <span>{DFDESCTANQUE || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
        </RowItens>
        <RowItens>
          <ContainerItemModal>
            <label>Linha</label>
            <div>
              <span>{DFNOMELINHA || 'Não informado'}</span>
            </div>
          </ContainerItemModal>
          <ContainerCheck>
            <div>
              <label>Ativo</label>
              <img src={returnCheckType(DFATIVO!)} />
            </div>
            <div>
              <label>Coleta Seletiva</label>
              <img src={returnCheckType(DFCOLETASELETIVA!)} />
            </div>
          </ContainerCheck>
        </RowItens>
      </ColumnItens>
    </ModalInfoTemplete>
  );
};
