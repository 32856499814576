import { ReactNode } from 'react';

import { Container } from '../../styles/global.styles';
import { MenuMobile } from '../MenuMobile';
import ClosedCollectPrint from '../Modal/ClosedCollectPrint';
import { ModalToSelectNewUnityToWork } from '../Modal/ModalToSelectNewUnityToWork';
import { RenderAllFilters } from '../SideFilterMenu';
import { SideMenu } from './SideMenu';

interface IPanel {
  children?: ReactNode;
}

export const Panel = ({ children }: IPanel) => {
  return (
    <Container>
      <SideMenu>{children}</SideMenu>
      <RenderAllFilters />
      <ModalToSelectNewUnityToWork />
      <MenuMobile />
      <ClosedCollectPrint />
    </Container>
  );
};
