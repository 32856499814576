import { transparentize } from 'polished';
import styled from 'styled-components';

export const CustomLabelWithInputContaner = styled.div`
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  height: 80px;
  label {
    margin-bottom: 0.4rem;
  }
  input {
    width: 200px;
    height: 110px;
    outline: 0;
    padding: 0 0.6rem;
    border-radius: 0.4rem;
    background-color: transparent;
    border: 1px solid ${({ theme }) => transparentize(0.9, theme.COLORS.GREY)};
  }
`;
