import Loading from 'react-loading';

import { ContainerLoading } from './styles';

interface ILoadingData {
  title: string;
}

const LoadingData = ({ title }: ILoadingData) => {
  return (
    <ContainerLoading>
      <Loading color="grey" type="spin" width={100} height={100} />
      <span>{title}</span>
    </ContainerLoading>
  );
};

export { LoadingData };
