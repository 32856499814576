import { ReactNode } from 'react';

import { AuthPagerovider } from './AuthProviderPages';
import { FilterProvider } from './ProviderPage';
import { PageContextProvider } from './usePage';
import { SideMenuContextProvider } from './useSideMenu';

interface IAppProvider {
  children: ReactNode;
}

const AppProvider = ({ children }: IAppProvider) => {
  return (
    <PageContextProvider>
      <SideMenuContextProvider>
        <AuthPagerovider>
          <FilterProvider>{children}</FilterProvider>
        </AuthPagerovider>
      </SideMenuContextProvider>
    </PageContextProvider>
  );
};

export default AppProvider;
