/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */

import { Buffer } from 'buffer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Close from '../../../assets/Close.svg';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { Register } from '../../../types/Register';
import { formatDate } from '../../../utils/date/formatDate';
import { ContainerOverlayModal, ContentModal } from './styles';

export interface IModal {
  isOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  selectedRegister: Register;
}

export const ModalSelectedImage = ({
  closeModal,
  isOpen,
  selectedRegister,
}: IModal) => {
  const { sideMenuWidth } = useSideMenuContext();

  const verifyClose = (e: any) => {
    if (e.target.id === 'container-overlay-modal') {
      closeModal(false);
    }
  };

  const [showContentModal, setShowContentModal] = useState(false);

  const buffer =
    selectedRegister &&
    selectedRegister.DFREGISTROIMAGEM &&
    Buffer.from(selectedRegister.DFREGISTROIMAGEM.data).toString();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowContentModal(true);
      }, 0);
    } else {
      setTimeout(() => {
        setShowContentModal(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ContainerOverlayModal
      isOpen={isOpen}
      onClick={verifyClose}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal isOpen={isOpen} periodic={showContentModal}>
        <div className="close-button-selected-image">
          <img src={Close} onClick={() => closeModal(true)} />
        </div>
        {selectedRegister && (
          <div className="not-showed-modal">
            <img src={`data:image/jpg;base64,${buffer}`} />
            <div className="column">
              <span>
                <b>Tipo: </b>
                {selectedRegister.DFTIPOREGISTRO === 'T'
                  ? 'Teperatura'
                  : selectedRegister.DFTIPOREGISTRO === 'Q'
                  ? 'Qualidade'
                  : 'Ocorrência'}
              </span>
              <span>
                <b>Data: </b>
                {formatDate(selectedRegister.DFDATAREGISTRO!)}
              </span>
              <span>
                <b>Hora: </b>
                {selectedRegister.DFHORAREGISTRO || '_:_'}
              </span>
              <p>
                <b>Observação: </b>
                {selectedRegister.DFOBSERVACAO}
              </p>
            </div>
          </div>
        )}
      </ContentModal>
    </ContainerOverlayModal>
  );
};
