import { transparentize } from 'polished';
import styled from 'styled-components';

export const ContainerMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    transparentize(0.2, theme.COLORS.GREEN_DARK)};
  transition: 0.3s all;
  img {
    width: 350px;
    transition: 0.3s all;
    @media (max-width: 650px) {
      width: 250px;
    }
    @media (max-width: 500px) {
      width: 200px;
    }
    @media (max-width: 350px) {
      width: 150px;
    }
    @media (max-width: 250px) {
      width: 100px;
    }
  }
`;
