/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { useState } from 'react';
import Loading from 'react-loading';

import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useLinkedTankContext } from '../../../context/ProviderPage/TanquesVinculado';
import { Tank } from '../../../types/Tank';
import { formatDate } from '../../../utils/date/formatDate';
import { returnCheckType } from '../../../utils/returnCheckType';
import { AnimateTr, ContainerListPage } from '../styles';
import { handleUnlinkTank } from './services';

interface IListLinkedTanks {
  data: Tank[];
}

export const ListLinkedTanks = ({ data }: IListLinkedTanks) => {
  const { loadLinkedTankAction } = useLinkedTankContext();
  const { path } = useSigninContext();

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Coleta Seletiva</th>
            <th className="list-th">Código</th>
            <th className="list-th">Imei</th>
            <th className="list-th">Data</th>
            <th className="list-th">Descrição</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Produtores</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((tank, index) => {
            const {
              DFATIVO,
              DFCOLETASELETIVA,
              DFDESCTANQUE,
              DFIDTANQUE,
              DFNOMELINHA,
              DFQUANTIDADEDEVINCULOS,
              DFIMEI,
              DFDATALOGIN,
              DFHORALOGIN,
            } = tank;
            const [unlinkLoading, setUnlinkLoading] = useState<boolean>(false);

            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFCOLETASELETIVA!)}
                  />
                </td>
                <td className="list-td-small">
                  {DFIDTANQUE || 'Não informado'}
                </td>
                <td className="list-td-small">{DFIMEI || 'Não informado'}</td>
                <td className="list-td-small">
                  {formatDate(DFDATALOGIN) || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCTANQUE || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFQUANTIDADEDEVINCULOS || '0'}
                </td>
                <td className="list-td-small">
                  <div>
                    <button
                      className="small-button"
                      disabled={unlinkLoading}
                      onClick={() =>
                        handleUnlinkTank({
                          setUnlinkLoading,
                          tank,
                          loadLinkedTankAction,
                          path,
                        })
                      }
                    >
                      Desvincular
                      {unlinkLoading && (
                        <Loading width={20} height={20} type="spin" />
                      )}
                    </button>
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
