import { transparentize } from 'polished';
import styled from 'styled-components';

interface IContainerFooter {
  open: boolean;
}

export const ContainerFooter = styled.div<IContainerFooter>`
  transition: 0.3s all;
  width: 200px;
  height: ${({ open }) => (open ? '220px' : '32px')};
  background-color: ${transparentize(0, '#FFF')};
  position: absolute;
  z-index: 99;
  bottom: 0;
  padding: 8px 12px;
  margin: 8px;
  /* margin-left: 46px; */
  border-radius: 6px;
  overflow-y: auto;
  border: 2.4px solid rgba(0, 0, 0, 0.19);

  .title-footer {
    transition: 0.3s all;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: ${({ open }) => !open && '100%'};
    h1 {
      transition: 0.3s all;
      font-size: 0.9rem;
      font-weight: bold;
      color: '#000';
    }

    .button-open-options {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0.2rem;
      transition: 0.3s all;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }

  .container-footer-grid {
    margin-top: 8px;
    transition: 0.3s all;
    display: ${({ open }) => !open && 'none'};
  }
`;

export const NavigateToCollectContainer = styled.div`
  cursor: pointer;
  transition: 0.3s all;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.GREEN_100};
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: 0;
  margin: 8px;
  /* border: 2px solid rgba(0, 0, 0, 0.2); */

  .title-footer {
    transition: 0.3s all;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    h1 {
      transition: 0.3s all;
      font-size: 0.9rem;
      font-weight: bold;
      color: '#000';
    }
  }
`;

export const ContainerCollectInformation = styled.div<IContainerFooter>`
  transition: 0.3s all;
  width: 220px;
  height: ${({ open }) => (open ? '230px' : '32.5px')};
  background-color: ${transparentize(0, '#FFF')};
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  margin: 8px;
  margin-left: 46px;
  border-radius: 6px;
  overflow-y: auto;
  border: 2.4px solid rgba(0, 0, 0, 0.19);

  .title-footer {
    padding: 8px 12px;
    transition: 0.3s all;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: ${({ open }) => !open && '100%'};
    h1 {
      transition: 0.3s all;
      font-size: 0.9rem;
      font-weight: bold;
      /* margin-left: 6px; */
      color: '#000';
    }

    .button-open-options {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0.2rem;
      transition: 0.3s all;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }

  .item {
    padding: 8px 12px;
    display: flex;
    h1 {
      transition: 0.3s all;
      font-size: 0.9rem;
      font-weight: bold;
      color: '#000';
    }
  }

  .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .container-footer-grid {
    transition: 0.3s all;
    display: ${({ open }) => !open && 'none'};
  }
  .line {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .progress-empty {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: lightgray;
    .current-collect {
      height: 6px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.COLORS.GREEN_30};
    }
  }
`;
