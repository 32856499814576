import { useEffect } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListTanksToRelease } from '../../../components/List/ListTanksToRelease';
import { LoadingData } from '../../../components/LoadingData';
import { PageHeader } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useTankToReleaseContextProvider } from '../../../context/ProviderPage/Vincular';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

export const BondCollect = () => {
  const { setSelectedPage } = usePageContext();

  const {
    loadingTankToRelease,
    tankToRelease,
    openSideModalFilterTankToReleaseAction,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
    loadTankToReleaseAction,
  } = useTankToReleaseContextProvider();

  const { user } = useSigninContext();

  useEffect(() => {
    if (user) {
      setSelectedPage({ option: 'vincular' });
      loadTankToReleaseAction();
    }
  }, [user]);
  return (
    <>
      <PageHeader
        pageIcon={{ option: 'vincular' }}
        pageTitle="Vincular"
        filterAction={openSideModalFilterTankToReleaseAction}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingTankToRelease === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingTankToRelease ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {tankToRelease.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma registro foi encontrado!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  <ListTanksToRelease data={tankToRelease} />
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: tankToRelease.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
      </ContainerPage>
    </>
  );
};
