import { useClosedCollectContext } from '../../../context/ProviderPage/Encerrada';
import { formatDate } from '../../../utils/date/formatDate';
import { returnCheckType } from '../../../utils/returnCheckType';
import { IModal, ModalInfoTemplete } from '../ModalInfoTemplete';
import { ContainerItemModal, RowItens } from '../ModalInfoTemplete/styles';

export const InfoClosedCollect = ({
  closeModal,
  isOpen,
  titleModal,
  option,
}: IModal) => {
  const { selectedClosedCollect } = useClosedCollectContext();
  return (
    <ModalInfoTemplete
      option={option}
      titleModal={titleModal}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {selectedClosedCollect && (
        <>
          <RowItens>
            <ContainerItemModal>
              <label>Ordem Coleta</label>
              <div>
                <span>
                  {selectedClosedCollect.DFORDEMCOLETA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Carreteiro</label>
              <div>
                <span>
                  {selectedClosedCollect.DFNOMECARRETEIRO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Veículo</label>
              <div>
                <span>
                  {selectedClosedCollect.DFPLACAVEICULO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Linha</label>
              <div>
                <span>
                  {selectedClosedCollect.DFNOMELINHA || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Data Programada</label>
              <div>
                <span>
                  {formatDate(selectedClosedCollect.DFDATAPROGRAMADA) ||
                    'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Data Saída</label>
              <div>
                <span>
                  {formatDate(selectedClosedCollect.DFDATASAIDA) ||
                    'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Data Coleta</label>
              <div>
                <span>
                  {formatDate(selectedClosedCollect.DFDATACOLETA) ||
                    'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
            <ContainerItemModal>
              <label>Total em litros</label>
              <div>
                <span>
                  {selectedClosedCollect.DFTOTALARMAZENADO || 'Não informado'}
                </span>
              </div>
            </ContainerItemModal>
          </RowItens>
          <RowItens>
            <ContainerItemModal>
              <label>Encerrada</label>
              <img
                src={returnCheckType(selectedClosedCollect.DFBLOQUEIOERP!)}
              />
            </ContainerItemModal>
          </RowItens>
        </>
      )}
    </ModalInfoTemplete>
  );
};
