import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { META_COLLECT_API } from '../../../api';
import { Configuration } from '../../../types/Configuration';
import { useSigninContext } from '../../AuthProviderPages/Signin';
import { usePageContext } from '../../usePage';

interface IContextProps {
  children: ReactNode;
}

type OnCreateNewConfiguration = {
  config: {
    DFDISTRIBUIR: string;
    DFREGUAATRAS: string;
    DFQUALIDADE: string;
  };
};

interface IContext {
  loadingConfigurations: boolean;
  configurations: Configuration[];
  loadConfigurationsAction: () => void;
  openCreateNewConfiguration: boolean;
  openAndCloseCreateNewConfiguration: () => void;
  onCreateNewConfiguration: ({ config }: OnCreateNewConfiguration) => void;
}

const Context = createContext({} as IContext);

const ConfigurationContextProvider = ({ children }: IContextProps) => {
  const { path } = useSigninContext();
  const { selectedPage } = usePageContext();
  const [loadingConfigurations, setLoadingConfigurations] = useState(true);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [openCreateNewConfiguration, setOpenCreateNewConfiguration] =
    useState(false);

  const loadConfigurationsAction = () => {
    META_COLLECT_API.get('/configuracao/panel', {
      headers: {
        path,
      },
    })
      .then(({ data }) => {
        setConfigurations(data.configuracoes);
        setLoadingConfigurations(false);
      })
      .catch(() => {
        setLoadingConfigurations(false);
      });
  };

  const openAndCloseCreateNewConfiguration = () => {
    setOpenCreateNewConfiguration(!openCreateNewConfiguration);
  };

  const onCreateNewConfiguration = ({ config }: OnCreateNewConfiguration) => {
    setLoadingConfigurations(true);
    openAndCloseCreateNewConfiguration();
    META_COLLECT_API.patch('/configuracao/panel/atualizar', config, {
      headers: {
        path,
      },
    })
      .then(() => {
        loadConfigurationsAction();
      })
      .catch(() => {
        setLoadingConfigurations(false);
      });
    loadConfigurationsAction();
  };

  useEffect(() => {
    if (selectedPage.option !== 'configuracoes') {
      setOpenCreateNewConfiguration(false);
    }
  }, [selectedPage]);

  return (
    <Context.Provider
      value={{
        loadingConfigurations,
        loadConfigurationsAction,
        configurations,
        openCreateNewConfiguration,
        openAndCloseCreateNewConfiguration,
        onCreateNewConfiguration,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useConfiguraionContext() {
  const context = useContext(Context);
  return context;
}

export { ConfigurationContextProvider, useConfiguraionContext };
