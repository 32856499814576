/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

import { AppVersion } from '../../../types/AppVersion';
import { META_COLLECT_API } from '../api';
import { useSigninContext } from '../context/AuthProviderPages/Signin';
import { usePageContext } from '../context/usePage';
import { LoadScreen } from '../pages/AuthPages/LoadScreen';
import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';

export const Routes: FC<AppVersion> = ({ version }) => {
  const { user, setUser, setPath } = useSigninContext();
  const { setVersion } = usePageContext();

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setVersion(version);
    const response = sessionStorage.getItem('meta_collect_user');
    if (response) {
      const responseData = JSON.parse(response);
      if (responseData) {
        META_COLLECT_API.defaults.headers = { path: responseData.path } as any;
        setUser(responseData);
        setPath(responseData.path);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  return loading === true ? (
    <LoadScreen />
  ) : user ? (
    <AppRoutes {...{ version }} />
  ) : (
    <AuthRoutes {...{ version }} />
  );
};
