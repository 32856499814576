/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Loading from 'react-loading';

import Close from '../../../assets/Close.svg';
import { useReleasedCollectContext } from '../../../context/ProviderPage/Liberar';
import { useTankToReleaseContextProvider } from '../../../context/ProviderPage/Vincular';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { CollectToBond } from '../../../types/CollectToBond';
import { formatDateAndHour } from '../../../utils/date/formatDateAndHour';
import { SelectCollectToBondWithTank } from '../../Selects/SelectCollectToBondWithTank';
import {
  ContainerOverlayModal,
  ContentModal,
  StorageProducerRegister,
} from './styles';

export interface IModal {
  isOpen?: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  DFIDTANQUE: string;
  loadStorages: () => void;
}

export const BondStorageProducerWithCollect = ({
  closeModal,
  isOpen,
  DFIDTANQUE,
  loadStorages,
}: IModal) => {
  const { sideMenuWidth } = useSideMenuContext();
  const { selectedStorageProducerToRelease } =
    useTankToReleaseContextProvider();

  const verifyClose = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    const target = e.target as HTMLDivElement;
    if (target.id === 'container-overlay-modal') {
      closeModal(false);
    }
  };

  const [showContentModal] = useState(false);
  const [loadingCollectsToBond, setLoadingCollectsToBond] = useState(false);
  const [collects, setCollects] = useState<CollectToBond[]>([]);
  const [collect, setCollect] = useState<CollectToBond | undefined>();
  const [loadAction, setLoadAction] = useState<boolean>(false);
  const { loadCollectsToBond, bondTankIntoCollect } =
    useReleasedCollectContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (selectedStorageProducerToRelease && isOpen) {
      loadCollectsToBond({
        DFDATADACOLETA: selectedStorageProducerToRelease.DFDATADACOLETA,
        setCollects,
        setLoadingCollectsToBond,
        DFIDTANQUE,
      });
    }
  }, [selectedStorageProducerToRelease, isOpen]);

  return (
    <ContainerOverlayModal
      isOpen={isOpen!}
      onClick={e => verifyClose(e)}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal isOpen={isOpen!} periodic={showContentModal}>
        <div className="close">
          <div className="close-button-selected-image">
            <img src={Close} onClick={() => closeModal(false)} />
          </div>
        </div>
        <h2>Registros de armazenamento</h2>

        <div className="list-registers">
          {selectedStorageProducerToRelease ? (
            selectedStorageProducerToRelease.DFARMAZENAMENTOS.map(
              (
                {
                  DFNOMEPROPRIEDADE,
                  DFVOLUMETOTAL,
                  DFDATADACOLETA,
                  DFHORACRIACAO,
                  DFNOMEPRODUTOR,
                  DFMATRICULA,
                },
                index,
              ) => {
                return (
                  <StorageProducerRegister
                    key={index}
                    style={{
                      borderBottom: `${
                        index + 1 <
                          selectedStorageProducerToRelease.DFARMAZENAMENTOS
                            .length && '1px solid gray'
                      }`,
                    }}
                  >
                    <div className="column">
                      <span>
                        {DFMATRICULA} - {DFNOMEPROPRIEDADE}
                      </span>
                      <span>{DFNOMEPRODUTOR}</span>
                    </div>
                    <div
                      className="column"
                      style={{
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span>
                        <b>
                          {formatDateAndHour(DFDATADACOLETA, DFHORACRIACAO)}
                        </b>
                      </span>
                      <span>{DFVOLUMETOTAL} Litros</span>
                    </div>
                  </StorageProducerRegister>
                );
              },
            )
          ) : (
            <></>
          )}
        </div>
        <SelectCollectToBondWithTank
          {...{
            collects,
            setValue: setCollect,
            value: collect,
            loadingCollectsToBond,
          }}
        />
        <button
          className="bond-button"
          onClick={() => {
            bondTankIntoCollect({
              collect: collect!,
              selectedStorageProducerToRelease:
                selectedStorageProducerToRelease!,
              loadStorages,
              setLoadAction,
            });
            setCollect(undefined);
          }}
        >
          {loadAction ? (
            <Loading width={25} height={25} type="spin" />
          ) : (
            'Vincular'
          )}
        </button>
      </ContentModal>
    </ContainerOverlayModal>
  );
};
