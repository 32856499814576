import { transparentize } from 'polished';
import styled from 'styled-components';

import themes from '../../../../themes';

export interface ITankListOptionContainer {
  DFTIPOITEMCOLETA: 'C' | 'N' | 'S';
}

export const returnColor = (DFTIPOITEMCOLETA: 'C' | 'N' | 'S') => {
  switch (DFTIPOITEMCOLETA) {
    case 'C':
      return '#52aab2';
    case 'N':
      return '#DF8736';
    case 'S':
      return '#CA2020';
    default:
      return themes.COLORS.GREY_50;
  }
};

export const TankListOptionContainer = styled.div<ITankListOptionContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ DFTIPOITEMCOLETA }) =>
    transparentize(0.4, returnColor(DFTIPOITEMCOLETA))};
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    filter: brightness(0.8);
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  .option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
    }
    span {
      font-weight: 500;
      margin-left: 0.6rem;
    }
    margin-right: 0.6rem;
  }
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .row-tank {
    display: flex;
    flex-direction: row;
    img {
      margin-right: 0.6rem;
    }
    span:first-of-type {
      font-weight: bold;
    }
  }
  .column-tank {
    display: flex;
    flex-direction: column;
  }
`;
